import React,{useEffect, useState, useRef} from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Card } from "@mui/material";
import { Box } from "@mui/system";
import { getConvertedDate3 } from '../../../../utils/common';

// import Events from "./events";
import { useSelector } from "react-redux";

export default function Calendar() {
    const { RosterReducers: { rosterList } } = useSelector((state) => state)    
    const [events, setEvents] = useState([]);
    const tempinitsform = useRef();

    const initsform = () => {
        if (rosterList.length) {
            const arr = []
            rosterList.forEach(m => {
                const { Cafeteria, fromDate, cVendorName, ToDate } = m
                const endDate = new Date(ToDate)
                endDate.setDate(endDate.getDate() + 1)
                arr.push({ title: `${Cafeteria}-${cVendorName}`, start: fromDate.slice(0,10), end: getConvertedDate3(endDate) })
                // arr.push({ title: `${Cafeteria}-${cVendorName}`, start: ToDate.slice(0,10) })
            })
            setEvents(arr)
        }
    };
    
    tempinitsform.current = initsform;
    
    useEffect(() => {
       tempinitsform.current();
    }, [rosterList]);

    // useEffect(() => {
    //     if (rosterList.length) {
    //         const arr = []
    //         rosterList.forEach(m => {
    //             const { Cafeteria, fromDate, cVendorName, ToDate } = m
    //             const endDate = new Date(ToDate)
    //             endDate.setDate(endDate.getDate() + 1)
    //             arr.push({ title: `${Cafeteria}-${cVendorName}`, start: fromDate.slice(0,10), end: getConvertedDate3(endDate) })
    //             // arr.push({ title: `${Cafeteria}-${cVendorName}`, start: ToDate.slice(0,10) })
    //         })
    //         setEvents(arr)
    //     }
    // }, [rosterList])

    return (
        <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "10px 10px 10px 10px" }}>
            <Box p='20px'>
                <FullCalendar
                    // defaultView="dayGridMonth"
                    // header={{
                    //     left: "prev,next",
                    //     center: "title",
                    //     right: "dayGridMonth,timeGridWeek,timeGridDay"
                    // }}
                    themeSystem="Simplex"
                    plugins={[dayGridPlugin]}
                    events={events}
                    dayMaxEvents={true}

                />
            </Box>
        </Card>
    );
}