import { call, put, takeEvery } from "redux-saga/effects";
import { MENU_LIST_WATCHER, ADD_CART_WATCHER, GET_CART_WATCHER, ADD_MINUS_WATCHER, CLEAR_CART_WATCHER, GET_DAYSOFWEEK_WATCHER, GET_ORDER_TOTALCOST_WATCHER, VIEW_CART_WATCHER, WEEKLY_BOOKING_WATCHER, MEAL_LIST_WATCHER, CAFE_MENUS_WATCHER, ADD_QUANTITY_WATCHER } from "../../actionTypes/user";
import { updateCategory, updateMenuList, updateCartList, updateDaysOfWeekList, updateGetOrderTotalCost, updateViewCart, updateWeeklyBooking } from "../../actionCreators/user/home";
import { updateOpenLoader, updateFalseLoader } from "../../actionCreators/spinner";
import Service from "../../../services/user/home"
import ServiceM from "../../../services/admin/menuMaster"
import { updateAlert } from "../../actionCreators/alert";

function* getMenuListEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.getMenus, action.payload);

    yield put(updateCategory(data.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getMealListEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ServiceM.getAll, action.payload);

    yield put(updateMenuList(data.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* addCartEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.addCart, action.payload);

    let { data: cart } = yield call(Service.getCart, action.cartData);
    yield put(updateCartList(cart.recordset));

    let { data: total } = yield call(Service.getOrderTotalCost, action.userId);
    yield put(updateGetOrderTotalCost(total.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getCartEffect(action) {
  try {
    yield put(updateOpenLoader());

    // let { data: cart } = yield call(Service.viewCart, action.viewCart);
    // yield put(updateViewCart(cart.recordset));

    let { data } = yield call(Service.getCart, action.payload);
    yield put(updateCartList(data.recordset));

    yield put(updateFalseLoader());

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* addMinusEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.addMinus, action.id, action.payload);

    let { data: cart } = yield call(Service.getCart, action.cartData);
    yield put(updateCartList(cart.recordset));

    let { data: total } = yield call(Service.getOrderTotalCost, action.userId);
    yield put(updateGetOrderTotalCost(total.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* addQuantityEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.addQuantity, action.id, action.payload);
    action.setOpenPopup(null)
    action.setNewQty('')
    let { data: cart } = yield call(Service.getCart, action.cartData);
    yield put(updateCartList(cart.recordset));

    let { data: total } = yield call(Service.getOrderTotalCost, action.userId);
    yield put(updateGetOrderTotalCost(total.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* clearCartEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.clearCart, action.id);

    let { data: cart } = yield call(Service.getCart, action.cartData);
    yield put(updateCartList(cart.recordset));

    let { data: total } = yield call(Service.getOrderTotalCost, action.id);
    yield put(updateGetOrderTotalCost(total.recordset));
    yield put(updateFalseLoader());

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getDaysOfWeekEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.getDaysOfWeek, action.data);
    yield put(updateDaysOfWeekList(data.recordset));
    yield put(updateFalseLoader());

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getOrderTotalCostEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.getOrderTotalCost, action.id);
    yield put(updateGetOrderTotalCost(data.recordset));
    yield put(updateFalseLoader());

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* viewCartEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.viewCart, action.data);
    yield put(updateViewCart(data.recordset));
    yield put(updateFalseLoader());

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* weeklyBookingEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.weeklyBooking, action.data);
    yield put(updateWeeklyBooking(data.recordsets[6]));
    yield put(updateFalseLoader());

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* cafeMenusEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.cafeteria, action.data);
    yield put(updateMenuList(data.recordset));
    yield put(updateFalseLoader());

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

//saga 
export const MenuListSaga = [
  takeEvery(MENU_LIST_WATCHER, getMenuListEffect),
  takeEvery(MEAL_LIST_WATCHER, getMealListEffect),
  takeEvery(ADD_CART_WATCHER, addCartEffect),
  takeEvery(GET_CART_WATCHER, getCartEffect),
  takeEvery(ADD_MINUS_WATCHER, addMinusEffect),
  takeEvery(ADD_QUANTITY_WATCHER, addQuantityEffect),
  takeEvery(CLEAR_CART_WATCHER, clearCartEffect),
  takeEvery(GET_DAYSOFWEEK_WATCHER, getDaysOfWeekEffect),
  takeEvery(GET_ORDER_TOTALCOST_WATCHER, getOrderTotalCostEffect),
  takeEvery(VIEW_CART_WATCHER, viewCartEffect),
  takeEvery(WEEKLY_BOOKING_WATCHER, weeklyBookingEffect),
  // takeEvery(KIOSK_WATCHER, kioskEffect),
  takeEvery(CAFE_MENUS_WATCHER, cafeMenusEffect),
]
