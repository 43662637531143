import React from 'react';
import { useState, useEffect } from 'react';
import VendorTableHead from './vendorTableHeader';
import Scrollbar from '../../../Scrollbar';
import VendorTableRow from './vendorTableRow';
import { useDispatch, useSelector } from 'react-redux'
import { getVendorWatcher } from '../../../../redux/actionCreators/admin/vendorManagement';
// material
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Pagination,
    Typography,
    Box
} from '@mui/material';

const TABLE_HEAD = [
    { id: 'idNumber', label: 'ID Number', alignRight: false },
    { id: 'vendorID', label: 'Vendor ID', alignRight: false },
    { id: 'vendorName', label: 'Vendor Name', alignRight: false },
    { id: 'vendorDesc', label: 'Vendor Desc', alignRight: false },
    { id: 'vendorAddress', label: 'Vendor Address', alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

const VendorManagementTable = () => {

    let firstIndex = 0;

    const dispatch = useDispatch();
    const { VendorReducers: { vendorList } } = useSelector((state) => state)

    const [page, setPage] = useState(1);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage] = useState(5);

    useEffect(() => {
        let data = { ACTION: "SELECT" }
        dispatch(getVendorWatcher(data))
    }, [dispatch])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const filteredData = applySortFilter(vendorList, getComparator(order, orderBy));
    const isUserNotFound = filteredData?.length === 0;

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800, overflow:'unset' }}>
                        <Table>
                            <VendorTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                //   rowCount={USERLIST.length}
                                //   numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    // .slice(page * 5, page * 5 + 5)
                                    .map((m, i) => {
                                        return (
                                                <VendorTableRow key={m.NID} rowData={m} handleRequestSort={handleRequestSort} order={order} orderBy={orderBy} />
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(vendorList?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default VendorManagementTable;