import React from 'react';
import {
    TableCell,
    Typography,
    TableRow,
} from '@mui/material';


const SaleReportTableRow = ({ rowData }) => {

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                <TableCell align="left" sx={{ width: '100px' }}>
                    <Typography variant='t14_600'>
                        {rowData.cMenuName}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '100px' }}>
                    <Typography variant='t14_600'>
                        {rowData.nTotalRate}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '200px' }}>
                    <Typography variant='t14_600'>
                        {rowData.BCP}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '200px' }}>
                    <Typography variant='t14_600'>
                        {rowData.OTHERS}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '200px' }}>
                    <Typography variant='t14_600'>
                        {rowData.Quantity}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '200px' }}>
                    <Typography variant='t14_600'>
                        {rowData.Rate}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '200px' }}>
                    <Typography variant='t14_600'>
                        {rowData.Gst}
                    </Typography>
                </TableCell>
                <TableCell align="right" sx={{ width: '200px' }}>
                    <Typography variant='t14_600'>
                        {rowData.ToatlRate}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    )
}

export default SaleReportTableRow