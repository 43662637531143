import http from "../../http-common";

class LoginService {
    login(data) {
        return http.post(`/profile/login`, data);
    }

    getProfile(id, data) {
        return http.post(`/profile/${id}`, data);
    }

    profileUpdate(id, data) {
        return http.post(`/profile/update/${id}`, data);
    }

    passwordUpdate(id, data) {
        return http.post(`/profile/passwordUpdate/${id}`, data);
    }

    forgetPassword(id, data) {
        return http.post(`/profile/forgetPassword/${id}`, data);
    }

    getRoleNames(data) {
        return http.post(`/getRoleNames`, data);
    }

    sendMail(data) {
        return http.post(`/profile/sendMail`, data);
    }
}

export default new LoginService();