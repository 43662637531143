import React, { useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
import ContractorBookingTable from '../../../components/contractor/contractorBooking/contractorBookingTable';
import ContractorBookingHeader from '../../../components/contractor/contractorBooking/headerCard';
import { useDispatch, useSelector } from 'react-redux';
import { TABLE_HEAD } from '../../../components/contractor/contractorBooking/contractorBookingTable';
import { getBookingHistoryWatcher } from '../../../redux/actionCreators/contractor'

const ContractorBooking = () => {

    const { ContractorRoleReducers: { bookingHistoryList }, ProfileReducers: { loginState } } = useSelector((state) => state)

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);

    const nUserID = loginState[0]?.nID || 0
    const nid = String(nUserID)


    // useEffect(() => {
    //     let data = { "ACTION": "ContractorList" }
    //     dispatch(getBookingHistoryWatcher(data))
    // }, [])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = bookingHistoryList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        const data = {
            ACTION: "ContractorListSearch",
            NID: nid,
            FromDate: fromDate,
            ToDate: toDate,
        }
        dispatch(getBookingHistoryWatcher(data))
        setPage(1)
    }

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports", "Contractor Booking History"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Contractor Booking History</Typography>
                </Box>
                <Box p='10px 0'>
                    <ContractorBookingHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={bookingHistoryList} columnData={TABLE_HEAD} />
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <ContractorBookingTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                </Card>

            </Box>
        </>
    )
}

export default ContractorBooking;
