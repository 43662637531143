import { CAB_LIST, CREATE_CAB_BOOKING_WATCHER, GET_CAB_BOOKING_WATCHER, GET_LEADER_MAIL_ID_WATCHER, LEADER_MAIL_LIST } from "../../actionTypes/cab";

// Worker triggering actionCreators

export function getCabBookingWatcher(data) {
    return { type: GET_CAB_BOOKING_WATCHER, payload: data };
}

export function createCabBookingWatcher(data, navigate) {
    return { type: CREATE_CAB_BOOKING_WATCHER, payload: data, navigate };
}

export function getLeaderMailIdWatcher(data) {
    return { type: GET_LEADER_MAIL_ID_WATCHER, payload: data };
}


// Redux state changing actionCreators
export function cabList(data) {
    return { type: CAB_LIST, payload: data };
}

export function leaderMailList(data) {
    return { type: LEADER_MAIL_LIST, payload: data};
}
