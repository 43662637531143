import React from 'react';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import { IconButton } from '@mui/material';
// import DeleteDialog from '../../../pages/admin/kitchen/deleteDialog';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const ActionButtons = ({ viewFunction, type, status, tickIconFunction, handleDelete, handleEdit, cancelIconFunction, deleteDialogOpen, setDeleteDialogOpen }) => {

    // const [dialogOpen, setDialogOpen] = useState(false);

    const editIconFunc = () => {
        // setDialogOpen(true);
        // setArrow(1)
        handleEdit()
    }

    return (
        <>
            <div>
                {status === 'list' ?
                    <>
                        {(type === 'userHistory' || type === 'kioskUserHistory' || type === 'specialHistory' || type === 'orderTracking' || type === 'splOrder' || type === 'employeeBooking') &&
                            <IconButton onClick={viewFunction}>
                                <RemoveRedEyeOutlinedIcon sx={{ color: 'common.black' }} />
                            </IconButton>
                        }


                        {(type === 'feedback') &&
                            <IconButton onClick={editIconFunc}>
                                <ModeOutlinedIcon sx={{ color: 'common.black' }} />
                            </IconButton>
                        }

                    </>
                    :
                    <>
                        {type === 'feedback' &&
                            <>
                                <IconButton onClick={cancelIconFunction}>
                                    <CancelIcon sx={{ color: 'common.black' }} />
                                </IconButton>
                                <IconButton onClick={tickIconFunction}>
                                    <CheckCircleIcon sx={{ color: 'common.sgreen' }} />
                                </IconButton>
                            </>
                        }
                    </>
                }

            </div >
        </>

    )
}

export default ActionButtons;