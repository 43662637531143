import React, { useState, useRef } from 'react';
import { Button, Typography, Grid, Autocomplete, Stack, Checkbox } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { createEmployeeWatcher, getListByIdWatcher } from '../../../../redux/actionCreators/admin/employeeManagement';
import { useDispatch, useSelector } from 'react-redux'
import CssTextField from '../../../overrides/longSearchField';
import { styled } from '@mui/material/styles';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileUploadButton from "../../../overrides/fileUploadButton"
import CloseButton from "../../../overrides/closeButton"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useEffect } from 'react';

export const Input = styled('input')({
    display: 'none',
});

function EmployeeCreation(props) {
    const dispatch = useDispatch()
    const { EmployeeReducers: { roleNameList, accessPointList, divisionList, contractor, counter, employeeList, guestDrop, vendorName, listById, departmentNameList, categoryList }, ProfileReducers: { loginState } } = useSelector((state) => state)

    const { setOpen, status } = props;

    const [imageName, setImageName] = useState('')
    const [roleName, setroleName] = useState('')
    const tempinitsform = useRef();

    const nUserId = loginState[0].nID

    const custValidation = (Yup) => {
        if (roleName === 'Employee') {
            return {
                RFID_number: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('RFID Number is required').test("Already exist", "Already exist", (data) => (
                    !employeeList.some(d => d.cRFID === data)
                )),
                category: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('category is required'),
                department: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('department is required')
            }
        }
        if (roleName === 'ContractorAdmin') {
            return { contractor: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('contractor is required') }
        }
        if (roleName === 'CounterAdmin') {
            return { counter: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('counter is required') }
        }
        if (roleName === 'GuestAdmin') {
            return { guest: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('guest is required') }
        }
        if (roleName === 'FoodAdmin') {
            return { vendor: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('vendor is required') }
        }
        return {}
    }

    const EmployeeSchema = Yup.object().shape({

        employeeId: Yup.string('Enter your User Id').min(1, 'Too Short!').max(50, 'Too Long!').required('User Id is required'),
        employeeName: Yup.string('Enter your User Name').min(2, 'Too Short!').max(100, 'Too Long!').required('User Name is required'),
        role: Yup.string().required('Role is required'),
        // accessPoints: Yup.string().required('Access Point is required'),
        division: Yup.string().required('Division is required'),
        mobileNumber: Yup.string().matches(new RegExp('[0-9]{9}'), 'Invalid').required('Mobile Number is required'),
        password: Yup.string().min(8, 'Password should be of minimum 8 characters length').required('Password is required'),
        emailId: Yup.string().email('Email must be a valid email address').required('Email Id is required'),
        ...custValidation(Yup)
    });

    const formik = useFormik({
        initialValues: {
            employeeId: '',
            employeeName: '',
            role: '',
            emailId: '',
            mobileNumber: '',
            password: '',
            division: '',
            RFID_number: '',
            accessPoints: [],
            profileImage: '',
            contractor: '',
            counter: '',
            roleName: '',
            guest: '',
            vendor: '',
            department: '',
            category: ''
        },

        validationSchema: EmployeeSchema,

        onSubmit: () => {
            let values = { ...formik.values }
            let data = {
                ACTION: "INSERT",
                CEMPLOYEEID: values.employeeId,
                CEMPLOYEENAME: values.employeeName,
                CUSERNAME: "CU10439",
                cPassword: values.password,
                NROLEID: values.role,
                CMOBILENUMBER: values.mobileNumber,
                CEMAILID: values.emailId,
                NDEPARTMENTID: "2",
                NCONTRACTORID: values.contractor,
                NSECTORID: "1",
                NDESIGNATIONID: "1",
                NDIVISIONID: values.division,
                CRFID: values.RFID_number,
                NMODIFIEDBY: nUserId,
                nCounterID: values.counter,
                nAccessPoint: `${values.accessPoints}`,
                nRFIDUserId: "11112",
                CIMAGE: values.profileImage,
                NGUESTID: values.guest,
                NVENDORID: values.vendor,
                NCATEGORY: `${values.category}`,
                NDEPARTMENT: `${values.department}`,
            }

            dispatch(createEmployeeWatcher(data, setOpen))
        }
    });

    const handleClose = () => {
        setOpen(false)
    }

    const { errors, touched, handleSubmit, values, getFieldProps, setFieldValue } = formik;

    const onFileChange = (e) => {
        if (e) {
            var file = e.target.files[0];
            var name = e.target.files[0].name;
            setImageName(name);
            var reader = new FileReader();
            reader.onloadend = function () {
                setFieldValue("profileImage", reader.result)
            }
            reader.readAsDataURL(file);
        }
    }

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    // const neglectArr = ['ContractorAdmin', 'GuestAdmin', 'FoodAdmin']

    const getDataById = (ACTION, NID) => {
        dispatch(getListByIdWatcher({ ACTION, NID }))
    }

    const initsform = () => {
        if (listById.length && (values.contractor || values.guest || values.vendor)) {
            const { userId, userName, cEmailID, cMobileNumber } = listById[0]
            setFieldValue("employeeId", userId);
            setFieldValue("employeeName", userName);
            setFieldValue("emailId", cEmailID);
            setFieldValue("mobileNumber", cMobileNumber);
        }
    };
    
    tempinitsform.current = initsform;
    
    useEffect(() => {
       tempinitsform.current();
    }, [listById]);

    // useEffect(() => {
    //     if (listById.length && (values.contractor || values.guest || values.vendor)) {
    //         const { userId, userName, cEmailID, cMobileNumber } = listById[0]
    //         setFieldValue("employeeId", userId);
    //         setFieldValue("employeeName", userName);
    //         setFieldValue("emailId", cEmailID);
    //         setFieldValue("mobileNumber", cMobileNumber);
    //     }
    // }, [listById])

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                    <Typography variant='h5'
                        align='left'
                        style={{ paddingBottom: '10px' }}>
                        {status === 'edit' ? 'Edit User' : 'User Creation'}
                    </Typography>

                    <Grid
                        container
                        style={{ paddingTop: '10px' }}
                        spacing={3}
                        direction='row'
                    >

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <Autocomplete
                                required={true}
                                // disablePortal
                                id="checkboxes-tags-demo"
                                name="role"
                                fullWidth
                                options={roleNameList}
                                size="medium"
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => {
                                    setFieldValue("role", value.id);
                                    setroleName(value.name);
                                }}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Select Role"
                                    name='role'
                                    required={true}
                                    error={Boolean(touched.role && errors.role)}
                                    helperText={touched.role && errors.role}
                                />}
                            />
                        </Grid>

                        {roleName === 'ContractorAdmin' &&
                            <Grid
                                lg={3}
                                md={4}
                                sm={6}
                                xs={12}
                                item={true}
                            >
                                <Autocomplete
                                    id="combo-box-demo"
                                    name="contractor"
                                    fullWidth
                                    options={contractor}
                                    size="medium"
                                    autoHighlight
                                    onChange={(event, value) => {
                                        setFieldValue("contractor", value.id);
                                        getDataById('contractorById', value.id)
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <CssTextField {...params}
                                        label="Contractor"
                                        name='contractor'
                                        required={true}
                                        error={Boolean(touched.contractor && errors.contractor)}
                                        helperText={touched.contractor && errors.contractor}
                                    />}
                                />
                            </Grid>
                        }

                        {roleName === 'GuestAdmin' &&
                            <Grid
                                lg={3}
                                md={4}
                                sm={6}
                                xs={12}
                                item={true}
                            >
                                <Autocomplete
                                    id="combo-box-demo"
                                    name="guest"
                                    fullWidth
                                    options={guestDrop}
                                    size="medium"
                                    autoHighlight
                                    onChange={(event, value) => {
                                        setFieldValue("guest", value.id);
                                        getDataById('guestById', value.id)
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <CssTextField {...params}
                                        label="Guest"
                                        name='guest'
                                        required={true}
                                        error={Boolean(touched.guest && errors.guest)}
                                        helperText={touched.guest && errors.guest}
                                    />}
                                />
                            </Grid>
                        }

                        {roleName === 'CounterAdmin' &&
                            <Grid
                                lg={3}
                                md={4}
                                sm={6}
                                xs={12}
                                item={true}
                            >
                                <Autocomplete
                                    id="combo-box-demo"
                                    name="counter"
                                    fullWidth
                                    options={counter}
                                    size="medium"
                                    autoHighlight
                                    onChange={(event, value) => {
                                        setFieldValue("counter", value.id);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <CssTextField {...params}
                                        label="Counter"
                                        name='counter'
                                        required={true}
                                        error={Boolean(touched.counter && errors.counter)}
                                        helperText={touched.counter && errors.counter}
                                    />}
                                />
                            </Grid>
                        }

                        {roleName === 'FoodAdmin' &&
                            <Grid
                                lg={3}
                                md={4}
                                sm={6}
                                xs={12}
                                item={true}
                            >
                                <Autocomplete
                                    id="combo-box-demo"
                                    name="vendor"
                                    fullWidth
                                    options={vendorName}
                                    size="medium"
                                    autoHighlight
                                    onChange={(event, value) => {
                                        setFieldValue("vendor", value.id);
                                        getDataById('vendorById', value.id)
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <CssTextField {...params}
                                        label="Vendor"
                                        name='vendor'
                                        required={true}
                                        error={Boolean(touched.vendor && errors.vendor)}
                                        helperText={touched.vendor && errors.vendor}
                                    />}
                                />
                            </Grid>
                        }

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                fullWidth
                                placeholder='User Id'
                                label='User Id'
                                name="email"
                                {...getFieldProps("employeeId")}
                                error={Boolean(touched.employeeId && errors.employeeId)}
                                helperText={touched.employeeId && errors.employeeId}
                                color='primary'
                                type='text'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='User Name'
                                label='User Name'
                                name='employeeName'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("employeeName")}
                                error={Boolean(touched.employeeName && errors.employeeName)}
                                helperText={touched.employeeName && errors.employeeName}
                            />
                        </Grid>
                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Email Id'
                                label='Email Id'
                                name='emailId'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("emailId")}
                                error={Boolean(touched.emailId && errors.emailId)}
                                helperText={touched.emailId && errors.emailId}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Mobile Number'
                                label='Mobile Number'
                                name='mobileNumber'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("mobileNumber")}
                                error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                                helperText={touched.mobileNumber && errors.mobileNumber}
                            />
                        </Grid>


                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Password'
                                label='Password'
                                name='password'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("password")}
                                error={Boolean(touched.password && errors.password)}
                                helperText={touched.password && errors.password}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <Autocomplete
                                id="combo-box-demo"
                                name="division"
                                fullWidth
                                options={divisionList}
                                size="medium"
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("division", value.id);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Division"
                                    name='division'
                                    required={true}
                                    error={Boolean(touched.division && errors.division)}
                                    helperText={touched.division && errors.division}
                                />}
                            />
                        </Grid>

                        {roleName === 'Employee' &&
                            <>
                                <Grid
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    item={true}
                                >
                                    <CssTextField
                                        required={true}
                                        style={{}}
                                        fullWidth={true}
                                        placeholder='RFID Number'
                                        label='RFID/QR Number'
                                        name='RFID_number'
                                        color='primary'
                                        type='text'
                                        variant='outlined'
                                        {...getFieldProps('RFID_number')}
                                        error={Boolean(touched.RFID_number && errors.RFID_number)}
                                        helperText={touched.RFID_number && errors.RFID_number}
                                    />
                                </Grid>

                                <Grid
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    item={true}
                                >
                                    <Autocomplete
                                        id="combo-box-demo"
                                        name="department"
                                        fullWidth
                                        options={departmentNameList}
                                        size="medium"
                                        autoHighlight
                                        onChange={(event, value) => {
                                            setFieldValue("department", value.id);
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <CssTextField {...params}
                                            label="department"
                                            name='department'
                                            required={true}
                                            error={Boolean(touched.department && errors.department)}
                                            helperText={touched.department && errors.department}
                                        />}
                                    />
                                </Grid>

                                <Grid
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    item={true}
                                >
                                    <Autocomplete
                                        id="combo-box-demo"
                                        name="category"
                                        fullWidth
                                        options={categoryList}
                                        size="medium"
                                        autoHighlight
                                        onChange={(event, value) => {
                                            setFieldValue("category", value.id);
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <CssTextField {...params}
                                            label="category"
                                            name='category'
                                            required={true}
                                            error={Boolean(touched.category && errors.category)}
                                            helperText={touched.category && errors.category}
                                        />}
                                    />
                                </Grid>

                            </>
                        }

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id="combo-box-demo"
                                name="accessPoints"
                                fullWidth
                                options={accessPointList}
                                size="medium"
                                autoHighlight
                                onChange={(event, value) => {
                                    let Val = value.map(m => m.name)
                                    setFieldValue("accessPoints", Val);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </li>
                                )}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Access Ponits"
                                    name='accessPoints'
                                    required={true}
                                    error={Boolean(touched.accessPoints && errors.accessPoints)}
                                    helperText={touched.accessPoints && errors.accessPoints}
                                />}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <label htmlFor="contained-button-file">
                                <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={onFileChange} />
                                <FileUploadButton
                                    variant="contained"
                                    component="span"
                                    fullWidth
                                    // onClick={imageFunction}
                                    endIcon={<FileUploadOutlinedIcon sx={{ color: 'common.black' }} />}
                                    type='button'
                                    sx={{ height: '55px' }}
                                >
                                    <Typography variant='t16_600' color='grey.600'>
                                        {imageName ? imageName : 'Upload Profile'}
                                    </Typography>
                                </FileUploadButton >
                            </label>
                        </Grid>

                        <Grid
                            xs={12}
                            item={true}
                        >
                            <Stack spacing={2} direction='row' justifyContent='end' >
                                <CloseButton onClick={() => handleClose()}
                                    sx={{
                                        //width: '120px',
                                        height: '42px',
                                        left: '0px',
                                        top: '0px',
                                        minWidth: '120px',
                                        marginTop: 'auto',
                                    }}
                                    variant='contained'
                                    size='medium'
                                    type='button'>
                                    <Typography variant='t14_400' color='common.black'>Cancel</Typography>
                                </CloseButton>
                                <Button onClick={handleSubmit}
                                    style={{
                                        //width: '120px',
                                        height: '42px',
                                        left: '0px',
                                        top: '0px',
                                        minWidth: '120px',
                                        backgroundColor: 'common.Bblack',
                                        marginTop: 'auto'
                                    }}
                                    variant='contained'
                                    size='medium'
                                    type='submit'
                                >
                                    <Typography variant='t14_400' color='grey.0'>Submit</Typography>
                                </Button>
                            </Stack>

                        </Grid>

                    </Grid>
                </Form>
            </FormikProvider>

        </>
    );

}

export default EmployeeCreation;