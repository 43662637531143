import { Box, Button, Card, Typography } from "@mui/material";
import React from "react";
// import Dialog from "../../components/dialog";
import CssTextField from "../../components/overrides/longSearchField";
import BlackButton from "../../components/overrides/blackButton";
import { forgetPasswordWatcher } from "../../redux/actionCreators/profile";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { useDispatch } from "react-redux";
// import { senMailWatcher } from "../../redux/actionCreators/profile";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { decryptAes } from "../../utils/common";

const Dialogcontent = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { userName } = useParams()
  const navigate = useNavigate()
  const tempinitsform = useRef();

  const Schema = Yup.object().shape({
    newPassword: Yup.string().required("This field is required"),
    RetypePASSWORD: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      "Passwords must match"
    ).required("This field is required"),
    userName: Yup.string().required("User name is required")
  });

  const formik = useFormik({
    initialValues: {
      userName: "",
      newPassword: "",
      RetypePASSWORD: ''
    },
    validationSchema: Schema,

    onSubmit: () => {
      let { RetypePASSWORD, userName } = { ...formik.values };
      const data = {
        "ACTION": "userForgetPassword",
        "cPassword": RetypePASSWORD
      }
      dispatch(forgetPasswordWatcher(userName, data));
      navigate('/login', { replace: true })
    },
  });

  const initsform = () => {
    if (userName) {
      const decrypt = decryptAes(userName)
      setFieldValue('userName', decrypt)
    }
  };

  tempinitsform.current = initsform;

  useEffect(() => {
    tempinitsform.current();
  }, [userName]);


  // useEffect(() => {
  //   if (userName) {
  //     const decrypt = decryptAes(userName)
  //     setFieldValue('userName', decrypt)
  //   }
  // }, [userName])

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>

      <Card
        style={{
          boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`,
          margin: 'auto',
          height: 'max-content'
        }}
      >
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <Box p="20px" display="flex" flexDirection="column">
              <Typography variant="t20_600">Reset Password</Typography>
              <Box mt="52px">
                <Typography variant="t14_600">User Name</Typography>
                <CssTextField
                  sx={{
                    mt: "7px",
                    "& .MuiOutlinedInput-input": {
                      height: "12px",
                    },
                  }}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  {...getFieldProps("userName")}
                  error={Boolean(touched.userName && errors.userName)}
                  helperText={touched.userName && errors.userName}
                />
              </Box>
              <Box mt="32px">
                <Typography variant="t14_600"> New Password </Typography>
                <CssTextField
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      height: "12px",
                    },
                    mt: "7px",
                  }}
                  placeholder="New Password"
                  name="newPassword"
                  fullWidth
                  size="medium"
                  {...getFieldProps("newPassword")}
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  helperText={touched.newPassword && errors.newPassword}
                />
              </Box>
              <Box mt="32px">
                <Typography variant="t14_600"> Retype Password </Typography>
                <CssTextField
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      height: "12px",
                    },
                    mt: "7px",
                  }}
                  placeholder="Retype Password"
                  name="retype"
                  fullWidth
                  size="medium"
                  {...getFieldProps("RetypePASSWORD")}
                  error={Boolean(touched.RetypePASSWORD && errors.RetypePASSWORD)}
                  helperText={touched.RetypePASSWORD && errors.RetypePASSWORD}
                />
              </Box>

              <Box mt="47px" ml="auto">
                <Button
                  variant="contained"
                  sx={{
                    height: 42,
                    width: 120,
                    mr: "19px",
                    backgroundColor: (theme) => theme.palette.common.cButton,
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.common.cButton,
                    },
                    color: "common.black",
                  }}
                  component={Link}
                  to='/login'
                  type='button'
                >
                  <Typography>Cancel</Typography>
                </Button>
                <BlackButton type='submit' sx={{ height: 42, width: 120 }}>
                  <Typography>Submit</Typography>
                </BlackButton>
              </Box>
            </Box>
          </Form>
        </FormikProvider>
      </Card>
    </Box>

  );
};

export default Dialogcontent;
