import React, { useState } from 'react';
import {
    TableRow,
    TableCell,
    Typography,
    Collapse,
    Card
} from '@mui/material';
import VendorChildTable from './vendorChildTable';
import ActionButtons from '../../dashboard/actionButtons';
import EditTextField from '../../../overrides/editField';
import { updateVendorWatcher, deleteVendorWatcher, updateCabVendorWatcher, deleteCabVendorWatcher } from '../../../../redux/actionCreators/admin/vendorManagement';
import { useDispatch } from 'react-redux'

const VendorTableRow = ({ rowData }) => {
    const dispatch = useDispatch();

    const [expandOpen, setExpandOpen] = useState(false);
    const [status, setStatus] = useState('list');
    const [editData, setEditData] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const handleEditChange = (e) => {
        const { name, value } = e.target
        setEditData({ ...editData, [name]: value })
    }

    const handleEdit = () => {
        let data = { ...rowData }
        setEditData(data);
        setStatus('edit');
    }

    const handleDelete = async () => {
        let data = { "ACTION": "DELETE", ...rowData }
        await dispatch(deleteCabVendorWatcher(data))
        setStatus('list');
        setDeleteDialogOpen(false);
    }

    const tickIconFunction = async () => {
        let data = { ...editData }
        data.ACTION = "UPDATE"
        // data.cMenuName = JSON.stringify(editData.cMenuName)
        await dispatch(updateCabVendorWatcher(data));
        setStatus('list');
    }

    const cancelIconFunction = () => {
        setEditData({});
        setStatus('list');
    }

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                {status === 'list' ?
                    <>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.sID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.vendorName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.email}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.description}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.hrEmail}
                            </Typography>
                        </TableCell>
                    </>
                    :
                    <>
                        <TableCell align="left" sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant='t14_600'>
                                {rowData.sID}
                            </Typography>
                        </TableCell>
                        
                        <TableCell align="left">
                            <EditTextField
                                name='vendorName'
                                value={editData.vendorName}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='email'
                                value={editData.email}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='description'
                                value={editData.description}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='hrEmail'
                                value={editData.hrEmail}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                    </>
                }
                <TableCell align="right">
                    <ActionButtons status={status} setStatus={setStatus} expandOpen={expandOpen} setExpandOpen={setExpandOpen} type={'vendor'} tickIconFunction={tickIconFunction} handleDelete={handleDelete} handleEdit={handleEdit} cancelIconFunction={cancelIconFunction} setDeleteDialogOpen={setDeleteDialogOpen} deleteDialogOpen={deleteDialogOpen} />
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell colSpan={6} style={{ padding: 0 }}>
                    <Collapse in={expandOpen} timeout="auto" unmountOnExit>
                        <Card sx={{ bgcolor: 'grey.200' }} >
                            <VendorChildTable expandOpen={expandOpen} rowData={rowData} handleEditChange={handleEditChange} status={status} editData={editData} />
                        </Card>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )

}

export default VendorTableRow