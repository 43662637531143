import * as CryptoJS from "crypto-js";

export const getCurDate = (date) => {
  let dt = date || new Date();
  let localTime = dt.getTime();
  let localOffset = dt.getTimezoneOffset() * 60000;
  let utc = localTime + localOffset;
  let offset = 5.5;
  let IST = utc + 3600000 * offset;
  let new_dt = new Date(IST);
  let current_timezone_time = `${new_dt
    .getFullYear()
    .toString()
    .padStart(4, "0")}-${(new_dt.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new_dt.getDate().toString().padStart(2, "0")}`;

  return current_timezone_time;
};

export const getConvertedDate = (val) => {
  if(!val) return null
  let dt = val;
  let localTime = dt.getTime();
  let localOffset = dt.getTimezoneOffset() * 60000;
  let utc = localTime + localOffset;
  let offset = 5.5;
  let IST = utc + 3600000 * offset;
  let new_dt = new Date(IST);
  let current_timezone_time = `${new_dt
    .getDate()
    .toString()
    .padStart(2, "0")}/${(new_dt.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${new_dt.getFullYear().toString().padStart(4, "0")}`;

  return current_timezone_time;
};

export const getConvertedDate4 = (val) => {
  let dt = val;
  let localTime = dt.getTime();
  let localOffset = dt.getTimezoneOffset() * 60000;
  let utc = localTime + localOffset;
  let offset = 5.5;
  let IST = utc + 3600000 * offset;
  let new_dt = new Date(IST);
  let current_timezone_time = `${(new_dt.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${new_dt
      .getDate()
      .toString()
      .padStart(2, "0")}/${new_dt.getFullYear().toString().padStart(4, "0")}`;

  return current_timezone_time;
};



export const getConvertedDate2 = (val) => {
  let dt = val;
  let localTime = dt.getTime();
  let localOffset = dt.getTimezoneOffset() * 60000;
  let utc = localTime + localOffset;
  let offset = 5.5;
  let IST = utc + 3600000 * offset;
  let new_dt = new Date(IST);
  let current_timezone_time = `${new_dt
    .getFullYear()
    .toString()
    .padStart(4, "0")}-${(new_dt.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new_dt.getDate().toString().padStart(2, "0")}`;

  return current_timezone_time;
};

export const getConvertedDate3 = (val) => {
  let dt = val;
  let localTime = dt.getTime();
  let localOffset = dt.getTimezoneOffset() * 60000;
  let utc = localTime + localOffset;
  let offset = 5.5;
  let IST = utc + 3600000 * offset;
  let new_dt = new Date(IST);
  let current_timezone_time = `${new_dt
    .getFullYear()
    .toString()
    .padStart(4, "0")}-${(new_dt.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new_dt.getDate().toString().padStart(2, "0")}`;

  return current_timezone_time;
};

export const getCurDateTime = () => {
  let dt = new Date();
  let localTime = dt.getTime();
  let localOffset = dt.getTimezoneOffset() * 60000;
  let utc = localTime + localOffset;
  let offset = 5.5;
  let IST = utc + 3600000 * offset;
  let new_dt = new Date(IST);
  let current_timezone_time = `${new_dt
    .getFullYear()
    .toString()
    .padStart(4, "0")}-${(new_dt.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new_dt.getDate().toString().padStart(2, "0")} ${new_dt
    .getHours()
    .toString()
    .padStart(2, "0")}:${new_dt
    .getMinutes()
    .toString()
    .padStart(2, "0")}:${new_dt.getSeconds().toString().padStart(2, "0")}`;

  return current_timezone_time;
};

export const Gender = [
  {
    id: "1",
    name: "Male",
  },
  {
    id: "2",
    name: "Female",
  },
  {
    id: "3",
    name: "Others",
  },
];

export const decryptAes = (ciphertext = "") => {
  const key = "bb1af26c6190864a5457f8a526388fb9";
  const iv = "8af520ae6e0369d8";

  const ciphertextWA = CryptoJS.enc.Hex.parse(ciphertext);
  const keyWA = CryptoJS.enc.Utf8.parse(key);
  const ivWA = CryptoJS.enc.Utf8.parse(iv);
  const ciphertextCP = { ciphertext: ciphertextWA };

  const decrypted = CryptoJS.AES.decrypt(ciphertextCP, keyWA, { iv: ivWA });

  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export const timeVal = (today)=>{
  let currentHour = today.getHours().toString().length === 1 ? `0${today.getHours()}` : today.getHours()
  let currentMin = today.getMinutes().toString().length === 1 ? `0${today.getMinutes()}` : today.getMinutes()
  return currentHour + ":" + currentMin < '19:00'
}

export function inWords(num) {
  const getNum = num.split('.')[0]
  const getNum1 = num.split('.')[1]

  if (getNum.length > 9) return 'overflow';

  function price_in_words(price) {
      var sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
        dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
        tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
        handle_tens = function(dgt, prevDgt) {
          return 0 == dgt ? "" : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt])
        },
        handle_utlc = function(dgt, nxtDgt, denom) {
          return (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") + (0 != nxtDgt || dgt > 0 ? " " + denom : "")
        };
    
      var str = "",
        digitIdx = 0,
        digit = 0,
        nxtDigit = 0,
        words = [];
      if (price += "", isNaN(parseInt(price))) str = "";
      else if (parseInt(price) > 0 && price.length <= 10) {
        for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) switch (digit = price[digitIdx] - 0, nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0, price.length - digitIdx - 1) {
          case 0:
            words.push(handle_utlc(digit, nxtDigit, ""));
            break;
          case 1:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 2:
            words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? " and" : "") : "");
            break;
          case 3:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 4:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 5:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 6:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 7:
            words.push(handle_utlc(digit, nxtDigit, "Crore"));
            break;
          case 8:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 9:
            words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2] ? " and" : " Crore") : "")
        }
        str = words.reverse().join("")
      } else str = "";
      return str
    
    }

  let getStr = price_in_words(+getNum);
  let newStr = price_in_words(+getNum1);

  if (getNum1) {
      getStr += newStr ? 'and ' + newStr + 'paise Only' : 'Only'
  }else{
    getStr += ' Only'
  }

  return getStr;
}

export const getCurTime = (date) => {
  let dt = new Date(date);
  let localTime = dt.getTime();
  let localOffset = dt.getTimezoneOffset() * 60000;
  let utc = localTime + localOffset;
  let offset = 5.5;
  let IST = utc + 3600000 * offset;
  let new_dt = new Date(IST);
  let current_timezone_date = `${new_dt
    .getDate()
    .toString()
    .padStart(2, "0")}/${(new_dt.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${new_dt.getFullYear().toString().padStart(4, "0")}`;
  var hourEnd = date.indexOf(":");
  var H = date.slice(10, hourEnd);
  var h = H % 12 || 12;
  var ampm = H < 12 ? " AM" : " PM";
  let time = h + date.substr(hourEnd, 3) + ampm;
  // document.write(time);
  return time;
};

export const time = [
  {time: "12:00 AM"},
  {time: "12:30 AM"},
  {time: "01:00 AM"},
  {time: "01:30 AM"},
  {time: "02:00 AM"},
  {time: "02:30 AM"},
  {time: "03:00 AM"},
  {time: "03:30 AM"},
  {time: "04:00 AM"},
  {time: "04:30 AM"},
  {time: "05:00 AM"},
  {time: "05:30 AM"},
  {time: "06:00 AM"},
  {time: "06:30 AM"},
  {time: "07:00 AM"},
  {time: "07:30 AM"},
  {time: "08:00 AM"},
  {time: "08:30 AM"},
  {time: "09:00 AM"},
  {time: "09:30 AM"},
  {time: "10:00 AM"},
  {time: "10:30 AM"},
  {time: "11:00 AM"},
  {time: "11:30 AM"},
  {time: "12:00 PM"},
  {time: "12:30 PM"},
  {time: "01:00 PM"},
  {time: "01:30 PM"},
  {time: "02:00 PM"},
  {time: "02:30 PM"},
  {time: "03:00 PM"},
  {time: "03:30 PM"},
  {time: "04:00 PM"},
  {time: "04:30 PM"},
  {time: "05:00 PM"},
  {time: "05:30 PM"},
  {time: "06:00 PM"},
  {time: "06:30 PM"},
  {time: "07:00 PM"},
  {time: "07:30 PM"},
  {time: "08:00 PM"},
  {time: "08:30 PM"},
  {time: "09:00 PM"},
  {time: "09:30 PM"},
  {time: "10:00 PM"},
  {time: "10:30 PM"},
  {time: "11:00 PM"},
  {time: "11:30 PM"},
]

export const getTime = (val) => {
  let dt = val;
  let localTime = dt.getTime();
  let localOffset = dt.getTimezoneOffset() * 60000;
  let utc = localTime + localOffset;
  let offset = 5.5;
  let IST = utc + 3600000 * offset;
  let new_dt = new Date(IST);
  let time = `${new_dt
    .getHours()
    .toString()
    .padStart(2, "0")}`;

  return time;
};