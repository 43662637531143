import React from 'react';
import { useState } from 'react';
import Scrollbar from '../../Scrollbar';
// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    Pagination,
    Box
} from '@mui/material';
import CommonTableHead from '../../foodVendor/reports/commonTableHead.js';
// import { getDeliveryHistoryWatcher } from '../../../redux/actionCreators/admin/reports';
import DeliveryHistoryTableRow from './tableRow';
import { useSelector } from 'react-redux';

export const TABLE_HEAD = [
    // { id: 'Booking ID ', label: 'Booking ID', alignRight: false, title: 'Booking ID', field: 'nBookingID' },
    // { id: 'Contractor ID', label: 'Contractor ID', alignRight: false, title: 'Contractor ID', field: 'Contractor ID' },
    { id: 'Contractor Name', label: 'Contractor Name', alignRight: false, title: 'Contractor Name', field: 'Contractor Name' },
    // { id: 'MenuType', label: 'MenuType', alignRight: false, title: 'MenuType', field: 'Menu Name' },
    { id: 'MealName', label: 'MealName', alignRight: false, title: 'MealName', field: 'MealName' },
    { id: 'OrderDate', label: 'Date', alignRight: false, title: 'Date', field: 'OrderDate' },
    { id: 'Quantity', label: 'Quantity', alignRight: false, title: 'Quantity', field: 'Quantity' },
    { id: 'Swiped', label: 'Swiped', alignRight: false, title: 'Swiped', field: 'Swiped' },
    { id: 'UnSwiped', label: 'UnSwiped', alignRight: false, title: 'UnSwiped', field: 'UnSwiped' },
    // { id: 'Rate', label: 'Rate', alignRight: false, title: 'Rate', field: 'Rate' },
    // { id: 'Total Rate', label: 'Total Rate', alignRight: false, title: 'Total', field: 'Total Rate' },
    { id: 'Status', label: 'Status', alignRight: false, title: 'Status', field: 'Status' },
    // { id: 'Cafeteria', label: 'Cafeteria', alignRight: false, title: 'Cafeteria', field: 'Cafeteria' },
    // { id: 'action', label: 'Action', alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const DHistoryTable = ({ searchVal, searchText, page, setPage}) => {

    const { ReportsReducers: { DeliveryHistoryList } } = useSelector((state) => state)

    let firstIndex = 0;

    // const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [action] = useState(true); 
    const [rowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const tableData = searchText ? searchVal : DeliveryHistoryList
    const filteredData = applySortFilter(tableData, getComparator(order, orderBy));
    const isUserNotFound = filteredData?.length === 0;

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{ overflow: 'unset' }} >
                        <Table>
                            <CommonTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                action={action}
                                //   rowCount={USERLIST.length}
                                //   numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    // .slice(page * 5, page * 5 + 5)
                                    .map((m, i) => {
                                        return (
                                            <DeliveryHistoryTableRow key={m.SNO} rowData={m} index={i} />
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(tableData?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default DHistoryTable;