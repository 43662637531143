import { CAB_LIST, LEADER_MAIL_LIST } from "../../actionTypes/cab";

const intialState = {
  CabList: [],
  LeaderMailList: []
};

export default function CabReducers(state = intialState, action) {
  switch (action.type) {

    case CAB_LIST:
      return { ...state, CabList: action.payload };
    
    case LEADER_MAIL_LIST:
      return { ...state, LeaderMailList : action.payload };

    default:
      return state;
  }
}
