import React from 'react';
import { useState, useEffect } from 'react';
import Scrollbar from '../../../Scrollbar';
import { useDispatch, useSelector } from 'react-redux'
import { getKioskUserHistoryWatcher } from '../../../../redux/actionCreators/admin/reports';
// material
import {
    Table,
    TableBody,
    TableContainer,
    Pagination,
    Box,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import KioskUserHistoryTableHead from './tableHead';
import KioskUserHistoryTableRow from './tableRow';

export const TABLE_HEAD = [
    { id: 'EmployeeName', label: 'Employee Name', alignRight: false, title: 'Employee Name', field: 'EmployeeName' },
    { id: 'UserName', label: 'User Name', alignRight: false, title: 'User Name', field: 'UserName' },
    { id: 'Users', label: 'Users', alignRight: false, title: 'Users', field: 'Users' },
    { id: 'OrderDate', label: 'Order Date', alignRight: false, title: 'Order Date', field: 'OrderDate' },
    { id: 'Kiosk', label: 'Kiosk', alignRight: false, title: 'Kiosk', field: 'Kiosk' },
    // { id: 'Breakfast', label: 'Breakfast', alignRight: false, title: 'Breakfast', field: 'Breakfast' },
    // { id: 'Dinner', label: 'Dinner', alignRight: false, title: 'Dinner', field: 'Dinner' },
    // { id: 'Supper', label: 'Supper', alignRight: false, title: 'Supper', field: 'Supper' },
    // { id: 'Lunch', label: 'Lunch', alignRight: false, title: 'Lunch', field: 'Lunch' },
    // { id: 'Snacks', label: 'Snacks', alignRight: false, title: 'Snacks', field: 'Snacks' },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

const KioskUserHistoryTable = ({ searchVal, searchText, setPageChange, page, setPage }) => {

    const { ReportsReducers: { KioskUserHistoryList } } = useSelector((state) => state)

    let firstIndex = 0;

    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage] = useState(5);

    useEffect(() => {
        let data = { "ACTION": "kioskUserHistory" }
        dispatch(getKioskUserHistoryWatcher(data))
    }, [dispatch])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    // const filteredData = [{ EmployeeName: 'aaa' }]

    const tableData = searchText ? searchVal : KioskUserHistoryList
    const filteredData = applySortFilter(tableData, getComparator(order, orderBy));
    const isUserNotFound = filteredData?.length === 0;

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{overflow:'unset'}} >
                        <Table>
                            <KioskUserHistoryTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                onRequestSort={handleRequestSort}
                                action={true}
                            />
                            <TableBody>
                                {filteredData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    // .slice(page * 5, page * 5 + 5)
                                    .map((m, i) => {
                                        return (
                                            <KioskUserHistoryTableRow key={i} rowData={m} index={i} setPageChange={setPageChange} />
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(filteredData?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default KioskUserHistoryTable;