import { Navigate, useRoutes } from "react-router-dom";

// layouts
import Layout from "../layouts";
//not found
import NotFound from "../pages/page404";
//login
import Login from "../pages/login";
//ResetPassword
import ResetPassword from "../pages/resetPassword";
//Roles
import Admin from "./admin";
import Employee from "./user";
import FoodAdmin from "./foodVendor";
import ContractorAdmin from "./contractor";
import GuestAdmin from "./guest";
import CounterAdmin from "./swipe";
import KioskAdmin from "./kioskAdmin";
import FoodClerk from "./foodClerk";
import Cab from "./cab";
import Kiosk from "./kiosk";
import NoLogin from "./noLogin";
import { useSelector } from "react-redux";
import Invoice from "../pages/admin/invoice";

// ----------------------------------------------------------------------

export default function Router() {

  const { ProfileReducers: { loginState } } = useSelector(state => state)

  const roles = {
    Admin,
    FoodAdmin,
    ContractorAdmin,
    GuestAdmin,
    KioskAdmin,
    CounterAdmin,
    FoodClerk
  }

  const getRoutes = () => {
    if (loginState.length) {
      const cRoleName = loginState[0].cRoleName
      return cRoleName === 'Employee' ? [Employee, Cab, Kiosk] : [roles[cRoleName]]
    } else {
      return [NoLogin]
    }
  }

  return useRoutes([
    ...getRoutes(),
    {
      path: "/",
      element: <Layout />,
      children: [
        { index: true, element: <Login /> },
        { path: "login", element: <Login /> },
        { path: "404", element: <NotFound /> },
        { path: "resetPassword/:userName", element: <ResetPassword /> },
        {
          path: "*",
          element: <Navigate to="/404" />,
        },
        { path: "invoice/:type/:from/:to/:contractorID", element: <Invoice/> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
