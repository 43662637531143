import React, { useState } from 'react';
import {
    TableCell,
    Typography,
    TableRow,
    Box
} from '@mui/material';
import { base_url } from '../../../http-common';
import SwithAction from './switchAction';
// import { EditOutlined } from '@mui/icons-material';
// import ActionButtons from '/actionButton';
// import { useDispatch } from 'react-redux'
// import EditTextField from '../../../overrides/editField';
// import { updateFeedbackWatcher } from '../../../../redux/actionCreators/admin/reports';

const MenuListTableRow = ({ rowData, index }) => {

    console.log("rowData",rowData)
    // const dispatch = useDispatch();

    const [status] = useState('list');
    // const [editData, setEditData] = useState({});


    // const handleEditChange = (e) => {
    //     const { name, value } = e.target
    //     setEditData({ ...editData, [name]: value })
    // }

    // const handleEdit = () => {
    //     let data = { ...rowData }
    //     //data.cMenuName = data.cMenuName[0]
    //     setEditData(data);
    //     setStatus('edit');
    // }

    // const tickIconFunction = async () => {
    //     let values = { ...editData }
    //     let data = {
    //         "Action": "replyFeed",
    //         "nReply": values.Reply
    //     }
    //     await dispatch(updateFeedbackWatcher(rowData.nID, data));
    //     setStatus('list');
    // }

    // const cancelIconFunction = () => {
    //     setEditData({});
    //     setStatus('list');
    // }

    return (
        <>
            <TableRow
                hover={status === 'list' ? true : false}
                tabIndex={-1}
            >
                {/* {status === 'list' ?
                    <> */}
                        <TableCell align="left" sx={{ width: '200px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', pr: '20px' }}>
                                <Box
                                    component="img"
                                    src={`${base_url}/${rowData.cImage}`}
                                    sx={{
                                        height: 50,
                                        minWidth: 50,
                                        width: 50,
                                        borderRadius: "12px",
                                        objectFit: "cover",
                                        mr: "10px",
                                    }}
                                ></Box>
                                <Typography variant='t14_600' >
                                    {rowData.cMealName}
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '200px' }}>
                            <Typography variant='t14_600'>
                                {rowData.cMenuName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '200px' }}>
                            <Typography variant='t14_600'>
                                {rowData.ctype}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '200px' }}>
                            <Typography variant='t14_600'>
                                {rowData.cMenuDesc}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '200px' }}>
                            <Typography variant='t14_600'>
                                {rowData.nTotalRate}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '200px' }}>
                            <Typography variant='t14_600'>
                                {`${rowData.bIsActive}` === "true" ? "Active" : "Deactive"}
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <SwithAction rowData={rowData} />
                        </TableCell>
                    {/* </>
                    :
                    <>
                        <TableCell>
                            <Typography>
                                {rowData.EmployeeName}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {rowData.MenuName}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {rowData.Command}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <EditTextField
                                name='Reply'
                                value={editData.Reply || ""  }
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {rowData.FeedDate}
                            </Typography>
                        </TableCell>
                    </>
                }
                <TableCell align="left" sx={{ width: '150px' }}>
                    <ActionButtons type={'feedback'} setStatus={setStatus} status={status} setEditData={setEditData} rowData={rowData} tickIconFunction={tickIconFunction}
                        handleEdit={handleEdit} cancelIconFunction={cancelIconFunction} />
                </TableCell> */}
            </TableRow>
        </>
    )
}

export default MenuListTableRow