import { Box, Card, Typography } from '@mui/material'
import React, { useState } from 'react'
import Breadcrumbs from '../../../components/breadcrumbs'
import CarousalHeader from '../../../components/admin/reports/Carousal/header'
import CarousalTable from '../../../components/admin/reports/Carousal/carousalTable'
import { useSelector } from 'react-redux';
import NewCarousel from '../../../components/admin/reports/Carousal/newCarousel'
import CreationDialog from '../../../components/dialog'

export default function Carousal() {

    const { CarouselReducers: { CarouselImageList } } = useSelector((state) => state)
    const [open, setOpen] = useState(false)

    // const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = CarouselImageList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    return (
        <>
            <Box p='30px'>
                <Breadcrumbs content={["Reports", "Login Carousel"]} />
                <Box p='15px 0'>
                    <Typography variant='t24_700' > Login Carousel </Typography>
                </Box>
                <Box p='10px 0'>
                    <CarousalHeader setOpen={setOpen} handleSearch={handleSearch} searchText={searchText} />
                </Box>
                <Card style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <CarousalTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                </Card>
                <CreationDialog open={open} setOpen={setOpen} maxWidth='sm' >
                    <NewCarousel setOpen={setOpen} />
                </CreationDialog>
            </Box>
        </>
    )
}
