import React from 'react';
import { useState, useEffect, useRef } from 'react';
import Scrollbar from '../../../Scrollbar';
import SpecialHistoryTableHead from './tableHead';
import { useDispatch, useSelector } from 'react-redux'
import { getSpecialHistoryWatcher } from '../../../../redux/actionCreators/admin/reports';
import SpecialHistoryTableRow from './tableRow';
// material
import {
    Table,
    TableBody,
    TableContainer,
    Pagination,
    Box,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';

const TABLE_HEAD = [
    { id: 'EmployeeName', label: 'Employee Name', alignRight: false },
    { id: 'UserName', label: 'User Name', alignRight: false },
    { id: 'Users', label: 'Users', alignRight: false },
    { id: 'OrderDate', label: 'orderDate', alignRight: false },
    { id: 'Breakfast', label: 'Breakfast', alignRight: false },
    { id: 'Dinner', label: 'Dinner', alignRight: false },
    { id: 'Supper', label: 'Supper', alignRight: false },
    { id: 'Lunch', label: 'Lunch', alignRight: false },
    { id: 'Snacks', label: 'Snacks', alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

const SpecialHistoryTable = ({ searchVal, searchText, setPageChange }) => {

    const { ReportsReducers: { SpecialHistoryList } } = useSelector((state) => state)

    let firstIndex = 0;

    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('employeeName');
    const [rowsPerPage] = useState(5);
    const tempinitsform = useRef();

    const initsform = () => {
        let data = { "ACTION": "GetSpecialReport" }
        dispatch(getSpecialHistoryWatcher(data))
    };
    
    tempinitsform.current = initsform;
    
    useEffect(() => {
       tempinitsform.current();
    }, []);

    // useEffect(() => {
    //     let data = { "ACTION": "GetSpecialReport" }
    //     dispatch(getSpecialHistoryWatcher(data))
    // }, [])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const tableData = searchText ? searchVal : SpecialHistoryList
    const filteredData = applySortFilter(tableData, getComparator(order, orderBy));
    const isUserNotFound = filteredData?.length === 0;

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{overflow:'unset'}} >
                        <Table>
                            <SpecialHistoryTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                onRequestSort={handleRequestSort}
                                action={true}
                            />
                            <TableBody>
                                {filteredData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    // .slice(page * 5, page * 5 + 5)
                                    .map((m, i) => {
                                        return (
                                            <SpecialHistoryTableRow key={m} rowData={m} index={i} setPageChange={setPageChange} />
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(tableData?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default SpecialHistoryTable;