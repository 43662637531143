import React, { useState } from 'react';
import { Typography, Card, Grid, ToggleButton, Box, Autocomplete, Stack, alpha } from "@mui/material";
import CardTemplate from "./cardTemp"
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import StyledToggleButtonGroup from '../../../../overrides/toggleButton';
import TableIndex from './user';
import TotQtyTableIndex from './TotalQuantity';
import { getLastDayOrderSummaryWatcher } from '../../../../../redux/actionCreators/admin/orderSummary';
import CssTextField from '../../../../overrides/coloredTextField';
import { updateCafeteriaId } from '../../../../../redux/actionCreators/user/home';

const MealCount = ({ dayTabVal, dateChange }) => {

    const { OrderSummaryReducers: { mealCountData }, EmployeeReducers: { cafeteriaName } } = useSelector((state) => state)
    const dispatch = useDispatch();

    const userVal = (mealCountData.length && mealCountData[dayTabVal === 1 ? 1 : 5]) || []
    const contractorVal = (mealCountData.length && mealCountData[dayTabVal === 1 ? 3 : 7]) || []
    const guestVal = (mealCountData.length && mealCountData[dayTabVal === 1 ? 4 : 8]) || []
    const userSessionVal = (mealCountData.length && mealCountData[dayTabVal === 1 ? 2 : 6]) || []
    const sessionTotalVal = (mealCountData.length && mealCountData[dayTabVal === 1 ? 9 : 10]) || []

    const [alignment, setAlignment] = useState(1);
    // const [cafeteriaId, setCafeteriaId] = React.useState(0);

    const handleAlignment = (event, newAlignment) => {
        if (!newAlignment) {
            return
        }
        setAlignment(newAlignment);
    };

    const filterByCafe = (val) => {
        let data = {
            nCafeteriaId: val,
            CurentDate: dateChange
        }
        dispatch(getLastDayOrderSummaryWatcher(data))
        dispatch(updateCafeteriaId(val))
        // setCafeteriaId(val)
    }

    const defaultVal = { name: 'Select cafeteria', id: 0 }
    const cardVal = alignment === 1 ? userVal : (alignment === 2 ? contractorVal : guestVal)


    return (
        <>
            <Card sx={{ border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,  margin: '30px', bgcolor: theme=> alpha(theme.palette.primary.main, 0.1) }}>
                <Box m='25px 30px 20px auto' width='200px'>
                    <Autocomplete
                        sx={{ minWidth: '200px' }}
                        disablePortal
                        id="combo-box-demo"
                        name="selectMeal"
                        fullWidth
                        options={cafeteriaName}
                        defaultValue={defaultVal}
                        size="medium"
                        autoHighlight
                        onChange={(event, value) => {
                            filterByCafe(value.id);
                        }}
                        getOptionLabel={(option) => option.name === '-Select-' ? 'OverAll' : option.name}
                        isOptionEqualToValue={(option, value)=> option.id === value.id}
                        renderInput={(params) => <CssTextField sx={{
                            '& .MuiOutlinedInput-input': {
                                height: '16px',
                                color: 'common.white',
                            },
                        }}
                            // label="Select Cafeteria"
                            {...params}
                        />}
                    />
                </Box>

                <Grid container spacing={3} sx={{ marginBottom: '35px', marginTop: '10px' }} >
                    <Grid item xs={12} lg={9}>
                        <Box sx={{ marginLeft: '35px', marginRight: '30px' }} >
                            <TotQtyTableIndex tblData={sessionTotalVal} />
                        </Box>
                    </Grid>
                </Grid>

                <Stack direction={{ sm: 'row', xs: 'column' }}>
                    <div style={{ margin: '30px', marginBottom: 0 }}>
                        <Paper
                            elevation={0}
                            sx={{
                                display: 'flex',
                                border: (theme) => `1px solid ${theme.palette.divider}`,
                                flexWrap: 'wrap',
                                width: 'max-content'
                            }}
                        >
                            <StyledToggleButtonGroup
                                size="medium"
                                value={alignment}
                                exclusive
                                onChange={handleAlignment}
                                aria-label="text alignment"
                            >
                                <ToggleButton value={1} aria-label="left aligned" sx={{ width: '100px' }}>
                                    <Typography variant='t15_600'>Employee</Typography>
                                </ToggleButton>
                                <ToggleButton value={2} aria-label="centered">
                                    <Typography variant='t15_600'>Contractor</Typography>
                                </ToggleButton>
                                <ToggleButton value={3} aria-label="centered" sx={{ width: '100px' }}>
                                    <Typography variant='t15_600'>Guest</Typography>
                                </ToggleButton>

                            </StyledToggleButtonGroup>
                        </Paper>
                    </div>
                </Stack>

                {alignment !== 1 &&
                    <Grid container spacing={3} sx={{ padding: '30px' }}>
                        {cardVal.map(m =>
                            <Grid item key={m.cMealName} xs={12} lg={6} md={6} sm={6}>
                                <CardTemplate cardVal={m} />
                            </Grid>
                        )}
                    </Grid>
                }

                {alignment === 1 &&
                    <Grid container spacing={3} sx={{ marginBottom: '35px', marginTop: '10px' }} >
                        {userVal.map((m, i) => {
                            const tblData = userSessionVal?.filter(d => d.cMealName === m.cMealName)
                            return <Grid item key={m.cMealName} xs={12} lg={9} xl={6} mt='auto' >
                                <Box sx={{ marginLeft: '35px', marginRight: '30px' }} >
                                    <TableIndex cardData={m} tblData={tblData} />
                                </Box>
                            </Grid>
                        })}
                    </Grid>
                }
            </Card>
        </>

    )
}

export default MealCount;