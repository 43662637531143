import { useState } from 'react';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
// material
import { styled } from '@mui/material/styles';
import { Box, Drawer, Paper } from '@mui/material';
import BottomNav from './bottomNav';
import useResponsive from '../../hooks/useResponsive';
import NotificationSidebar from './notificationSidebar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

export default function UserLayout() {
  const [open, setOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  // const navigate = useNavigate()
  const { pathname } = useLocation();
  const getRole = !!matchPath({ path: '/user', end: false }, pathname)

  // const { ProfileReducers: { loginState } } = useSelector(state => state)

  // useEffect(() => {
  //   if (!loginState.length) {
  //     navigate('/login')
  //   }
  // }, [])

  const toggleDrawer = (open) => () => {
    setOpenNotification(open)
  };

  const isMobile = useResponsive('down', 'sm');

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} setOpenNotification={setOpenNotification} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <Drawer anchor="right" open={openNotification} onClose={toggleDrawer(false)}>
        <NotificationSidebar isOpenNotifiSidebar={openNotification} setOpenNotification={setOpenNotification} onCloseNotifiSidebar={() => setOpenNotification(false)} />
      </Drawer>
      
      <Box mt='64px' mb={{xs: getRole ? '55px' : '0px', sm: '0px'}} width='100%' bgcolor='common.bg'>
        <Outlet />

        {isMobile && getRole &&
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
            <BottomNav />
          </Paper>
        }

      </Box>
    </RootStyle>
  );
}
