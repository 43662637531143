import React from 'react';
import { useState } from 'react';
// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TableHead,
    Box, Pagination
} from '@mui/material';
import ConUserTableRow from './conUserTableRow'
import { useSelector } from 'react-redux'
import Scrollbar from '../../../../Scrollbar';

export const TABLE_HEAD = [
    { id: 'id', label: 'S.No', alignRight: false, title:'S.No', field: 'id' },
    { id: 'cContractorID ', label: 'ContractorID', alignRight: false, title: 'ContractorID', field: 'cContractorID' },
    { id: 'cUserName', label: 'UserName', alignRight: false, title: 'UserName', field: 'cUserName' },
    { id: 'cRFID', label: 'RFID', alignRight: false, title: 'RFID', field: 'cRFID' }
];

const ConUserTable = ({ searchText, searchVal }) => {

    const { ContractorReducers: { viewConUserList } } = useSelector(state => state)
    let firstIndex = 0;

    const [page, setPage] = useState(1);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const tableData = searchText ? searchVal : viewConUserList
    const isUserNotFound = tableData?.length === 0;

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{ overflow: 'unset' }}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    {TABLE_HEAD.map((headCell) => {
                                        return (<TableCell
                                            key={headCell.id}
                                            align={headCell.alignRight ? 'right' : 'left'}
                                        >
                                            <Typography variant='t14_400'>
                                                {headCell.label}
                                            </Typography>
                                        </TableCell>
                                        )
                                    })}
                                    <TableCell align='right' >
                                        <Typography variant='t14_400'>
                                            Actions
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData?.slice(firstIndex + 4 * (page - 1), 4 * page)
                                    .map((m, i) => {
                                        return (
                                            <ConUserTableRow key={m.id} rowData={m} />
                                        )
                                    }
                                    )}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(tableData?.length / 4)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default ConUserTable;