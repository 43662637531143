import React, { useEffect, useState } from 'react'
import { Typography, ToggleButton, Box, Autocomplete, Stack, Paper } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getFoodDashboardWatcher } from '../../../redux/actionCreators/foodVendor/index'
import MealCount from '../../../components/foodVendor/dashboard/session';
import { getDropDownWatcher } from '../../../redux/actionCreators/admin/employeeManagement';
import CssTextField from '../../../components/overrides/coloredTextField';
import StyledToggleButtonGroup from '../../../components/overrides/toggleButton';

function FoodVendorDasboard() {

  const dispatch = useDispatch()
  const { ProfileReducers: { loginState }, EmployeeReducers: { cafeteriaName } } = useSelector(state => state)
  const cEmployeeName = loginState[0]?.cEmployeeName || ''

  const [alignment, setAlignment] = useState(1);

  const current = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1)
  const tDate = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  const nDate = `${tomorrow.getDate()}/${tomorrow.getMonth() + 1}/${tomorrow.getFullYear()}`;

  const handleAlignment = (event, newAlignment) => {
    if (!newAlignment) {
      return
    }
    setAlignment(newAlignment);
  };

  useEffect(() => {
    let data = {
      "ACTION": "FoodAdminDashboard",
      "nCafeteriaId": 0
    }
    dispatch(getFoodDashboardWatcher(data))
    dispatch(getDropDownWatcher());
  }, [dispatch])

  const filterByCafe = (val) => {
    let data = {
      "ACTION": "FoodAdminDashboard",
      "nCafeteriaId": val
    }
    dispatch(getFoodDashboardWatcher(data))
  }

  const defaultVal = { name: 'Select cafeteria', id: '0' }

  return (
    <>
      <Stack direction='column' style={{ padding: '24px' }}>
        <Box >
          <Typography variant='t12_400' >Dashboard</Typography>
        </Box>
        <Box >
          <Typography variant='t24_700'>Welcome, {cEmployeeName}</Typography>
        </Box>
      </Stack>

      <Stack direction={{ xs: 'row' }} spacing={3} justifyContent='right' mr='30px' ml={{ xs: '30px', sm: 'auto' }}>
        <Box sx={{ marginBottom: 0 }}>
          <Paper
            elevation={0}
            sx={{
              display: 'flex',
              border: (theme) => `1px solid ${theme.palette.Boxider}`,
              flexWrap: 'wrap',
              width: 'max-content',
              height: '50px'
            }}
          >
            <StyledToggleButtonGroup
              size="medium"
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
            >
              <ToggleButton value={1} aria-label="left aligned" sx={{ width: '150px',p: 0 }}>
                <Typography variant='t15_600'>Today({tDate})</Typography>
              </ToggleButton>
              <ToggleButton value={2} aria-label="centered">
                <Typography variant='t15_600'>Next Day({nDate})</Typography>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Paper>
        </Box>

        <Box sx={{}}>
          <Autocomplete
            sx={{ minWidth: '200px' }}
            disablePortal
            id="combo-box-demo"
            name="selectMeal"
            fullWidth
            options={cafeteriaName}
            defaultValue={defaultVal}
            size="medium"
            autoHighlight
            onChange={(event, value) => {
              filterByCafe(value.id);
            }}
            getOptionLabel={(option) => option.name === '-Select-' ? 'OverAll' : option.name}
            renderInput={(params) => <CssTextField sx={{
              '& .MuiOutlinedInput-input': {
                height: '16px',
                color: 'common.white',
              },
            }}
              // label="Select Cafeteria"
              {...params}
            />}
          />
        </Box>
      </Stack>

      <Box>
        <MealCount dayTabVal={alignment} />
      </Box>
    </>
  )
}

export default FoodVendorDasboard;