import React, { useState } from 'react';
import { Button, Typography, Grid,  Stack } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { createContractorWatcher } from '../../../../redux/actionCreators/admin/contractorManagement';
import { useDispatch, useSelector } from 'react-redux'
import CssTextField from '../../../overrides/longSearchField';
import { styled } from '@mui/material/styles';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileUploadButton from "../../../overrides/fileUploadButton"
import CloseButton from '../../../overrides/closeButton';

export const Input = styled('input')({
    display: 'none',
});


function ContractorCreation(props) {
    const dispatch = useDispatch()

    const { setOpen, status } = props;

    const {ProfileReducers: {loginState}} = useSelector(state => state)
    const nUserID = loginState[0]?.nID || 0

    const [imageName, setImageName] = useState('')

    const ContractorSchema = Yup.object().shape({

        contractorId: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Contractor Id is required'),
        contractorName: Yup.string('Enter your name').min(2, 'Too Short!').max(50, 'Too Long!').required('Contractor Name is required'),
        contractorAddress: Yup.string('Enter your address').required('Contractor Address is required'),
        mobileNumber: Yup.string().matches(new RegExp('[0-9]{9}'), 'Invalid').required('Mobile number is required'),
        emailId: Yup.string('Enter your EmailId').email('Email must be a valid email address').required('Email is required'),
    });

    const formik = useFormik({
        initialValues: {
            contractorId: '',
            contractorName: '',
            contractorDesc: '',
            contractorAddress: '',
            landLineNumber: '',
            mobileNumber: '',
            emailId: '',
            profileImage: '',
        },

        validationSchema: ContractorSchema,

        onSubmit: () => {
            let values = { ...formik.values }
            let data = {
                ACTION: "INSERT",
                CCONTRACTORID: values.contractorId,
                CCONTRACTORNAME: values.contractorName,
                CCONTRACTORADDRESS: values.contractorAddress,
                CLANDLINENUMBER: values.landLineNumber,
                CMOBILENUMBER: values.mobileNumber,
                CEMAILID: values.emailId,
                CCONTRACTORDESC: values.contractorDesc,
                NUSERID: nUserID,
                CIMAGE: values.profileImage
            }
            dispatch(createContractorWatcher(data, setOpen))
        }
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    const handleClose = () => {
        setOpen(false)
    }

    const onFileChange = (e) => {
        if (e) {
            var file = e.target.files[0];
            var name = e.target.files[0].name;
            setImageName(name);
            var reader = new FileReader();
            reader.onloadend = function () {
                setFieldValue("profileImage", reader.result)
            }
            reader.readAsDataURL(file);
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                    <Typography variant='h5'
                        align='left'
                        style={{ paddingBottom: '10px' }}>
                        {status === 'edit' ? 'Edit Contractor' : 'Contractor Creation'}
                    </Typography>

                    <Grid
                        container
                        style={{ paddingTop: '10px' }}
                        spacing={3}
                        direction='row'
                    >
                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='contractor Id'
                                label='Contractor Id'
                                name='contractorId'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("contractorId")}
                                error={Boolean(touched.contractorId && errors.contractorId)}
                                helperText={touched.contractorId && errors.contractorId}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Contractor Name'
                                label='Contractor Name'
                                name='contractorName'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("contractorName")}
                                error={Boolean(touched.contractorName && errors.contractorName)}
                                helperText={touched.contractorName && errors.contractorName}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                style={{}}
                                fullWidth={true}
                                placeholder='Contractor Desc'
                                label='Contractor Description'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("contractorDesc")}
                                error={Boolean(touched.contractorDesc && errors.contractorDesc)}
                                helperText={touched.contractorDesc && errors.contractorDesc}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                style={{}}
                                fullWidth={true}
                                required
                                placeholder='Contractor Address'
                                label='Contractor Address'
                                name='contractorAddress'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("contractorAddress")}
                                error={Boolean(touched.contractorAddress && errors.contractorAddress)}
                                helperText={touched.contractorAddress && errors.contractorAddress}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                // required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Land Line Number'
                                label='Land Line Number'
                                name='landLineNumber'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("landLineNumber")}
                                error={Boolean(touched.landLineNumber && errors.landLineNumber)}
                                helperText={touched.landLineNumber && errors.landLineNumber}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                style={{}}
                                fullWidth={true}
                                required
                                placeholder='Mobile Number'
                                label='Mobile Number'
                                name='mobileNumber'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("mobileNumber")}
                                error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                                helperText={touched.mobileNumber && errors.mobileNumber}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                // required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Email Id'
                                label='Email Id'
                                name='emailId'
                                color='primary'
                                required
                                type='text'
                                variant='outlined'
                                {...getFieldProps("emailId")}
                                error={Boolean(touched.emailId && errors.emailId)}
                                helperText={touched.emailId && errors.emailId}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <label htmlFor="contained-button-file">
                                <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={onFileChange} />
                                <FileUploadButton
                                    variant="contained"
                                    component="span"
                                    fullWidth
                                    // onClick={imageFunction}
                                    endIcon={<FileUploadOutlinedIcon sx={{ color: 'common.black' }} />}
                                    type='button'
                                    sx={{ height: '55px' }}
                                >
                                    <Typography variant='t16_600' color='grey.600'>
                                        {imageName ? imageName : 'Upload Profile'}
                                    </Typography>
                                </FileUploadButton >
                            </label>
                        </Grid>

                        <Grid
                            xs={12}
                            item={true}
                        >
                            <Stack spacing={2} direction='row' justifyContent='end' >
                            <CloseButton onClick={() => handleClose()}
                                sx={{
                                    //width: '120px',
                                    height: '42px',
                                    left: '0px',
                                    top: '0px',
                                    minWidth: '120px',
                                    marginTop: 'auto',
                                }}
                                variant='contained'
                                size='medium'
                                type='button'>
                                <Typography variant='t14_400' color='common.black'>Cancel</Typography>
                            </CloseButton>
                            <Button onClick={handleSubmit}
                                style={{
                                    //width: '120px',
                                    height: '42px',
                                    left: '0px',
                                    top: '0px',
                                    minWidth: '120px',
                                    backgroundColor: 'common.Bblack',
                                    marginTop: 'auto'
                                }}
                                variant='contained'
                                size='medium'
                                type='submit'
                            >
                                <Typography variant='t14_400' color='grey.0'>Submit</Typography>
                            </Button>
                            </Stack>
                            
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </>
    );

}

export default ContractorCreation;