import React, { useEffect, useState } from 'react'
import { Box, Card, Typography } from '@mui/material'
import Breadcrumbs from '../../../components/breadcrumbs'
import { useDispatch, useSelector } from 'react-redux'
import KioskHeader from '../../../components/kiosk/dashboard/headerCard'
import KioskTable, { TABLE_HEAD } from '../../../components/kiosk/dashboard/table'
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { ws_url } from '../../../http-common'
import { getKioskBookingDetailsWatcher, getNotificationDetailsWatcher } from '../../../redux/actionCreators/kiosk'
// import { updateAlert } from '../../../redux/actionCreators/alert'
import CustomAlert from '../../../components/CustomAlert'

const clientWS = new W3CWebSocket(ws_url);

export default function Kiosk() {

    const { KioskRoleReducers: { kioskDashboardList }, ProfileReducers: { loginState } } = useSelector((state) => state)
    const cEmployeeName = loginState[0]?.cEmployeeName || ''
    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState({})

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = kioskDashboardList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    useEffect(() => {
        clientWS.onmessage = async (message) => {
            let { event, title, body } = JSON.parse(message.data)
            if (event === 'newOrder') {
                let data = {
                    "ACTION": "getKioskBookingDetails"
                }
                dispatch(getKioskBookingDetailsWatcher(data, true))
                dispatch(getNotificationDetailsWatcher({"ACTION": "Select"}, true))
                setMessage({title, body})
                setOpen(true)
            }
        }
    }, [dispatch])

    return (
        <>
            <Box p="24px">
                <Breadcrumbs content={["Dashboard"]} />
                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant='t24_700' > {`Welcome, ${cEmployeeName}`} </Typography>
                </Box>
                <Box p='10px 0'>
                    <KioskHeader handleSearch={handleSearch} searchText={searchText} rowData={kioskDashboardList} columnData={TABLE_HEAD} />
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <KioskTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                </Card>
                <CustomAlert open={open} setOpen={setOpen} message={message} />
            </Box>
        </>
    )
}
