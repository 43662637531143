import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Typography,
  Collapse,
  Button,
  Grid,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box } from "@mui/system";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import OrderList from "../../currentOrder/orderList";
// import { DataUsageTwoTone } from "@mui/icons-material";
import { getGst } from "../../../../../utils/gst";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const ChildTableRow = ({ rowData, index, page, rowsPerPage }) => {
  const [open, setOpen] = useState(false);
 
  return (
    <>
      <TableRow hover sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="left">
          <Typography variant="t14_600">{index + 1 + ((page - 1) * rowsPerPage)}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="t14_600">{rowData.nID}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="t14_600">{rowData.dOrderDate || ''}</Typography>
        </TableCell>
        {/* <TableCell align="left">
          <Typography variant="t14_600">{rowData.mealType}</Typography>
        </TableCell> */}
        <TableCell align="left">
          <Box display="flex" alignItems="center">
            {rowData.kioskStatus ? 
              rowData.kioskStatus === "Delivered" ?
              <>
                {/* <TableCell align="left"> */}
                  <Typography variant="t14_600">
                    {rowData.kioskStatus}
                  </Typography>
                  <CheckCircleOutlineIcon
                      sx={{ ml: "14px", color: "common.sgreen" }}
                  />
                {/* </TableCell> */}
              </>
              :
              <>
                <Typography variant="t14_600">
                    {rowData.kioskStatus}
                </Typography>
                <ErrorOutlineIcon
                    sx={{ ml: "22px", color: "primary.main" }}
                />
              </>
            :
            rowData.bIsCancelled ?
              <>
                <Typography variant="t14_600">Cancelled</Typography>
                <CancelOutlinedIcon
                  sx={{ ml: "10px", color: "common.black" }}
                />
              </>
              :
              rowData.bIsDelivered ?
                <>
                  <Typography variant="t14_600">Delivered</Typography>
                  <CheckCircleOutlineIcon
                    sx={{ ml: "14px", color: "common.sgreen" }}
                  />
                </>
                :
                <>
                  <Typography variant="t14_600">Pending</Typography>
                  <ErrorOutlineIcon
                    sx={{ ml: "22px", color: "primary.main" }}
                  />
                </>
            }
          </Box>
        </TableCell>
        <TableCell align="left">
          <Typography variant="t14_600">{rowData.Cafeteria || '---'}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="t14_600">{rowData.nToatlRate + +getGst(rowData.nToatlRate)}</Typography>
        </TableCell>
        <TableCell align="right">
          <Button
            variant="contained"
            onClick={() => setOpen(!open)}
            sx={{ minWidth: 35, height: 33, p: 0 }}
          >
            <KeyboardDoubleArrowDownIcon />
          </Button>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={12} style={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ p: "20px 0" }}>
              <Grid container spacing={3}>
                {/* {data?.map((d) => ( */}
                  <Grid item xs={12} sm={6} md={6}>
                    <OrderList value={rowData} />
                  </Grid>
                {/* ))} */}
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ChildTableRow;
