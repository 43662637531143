import { CREATE_GUEST_ORDER_WATCHER, CANCEL_GUEST_ORDER_WATCHER, GET_GROLE_SWIPE_HISTORY_WATCHER, GROLE_SWIPE_HISTORY_LIST, GET_GUEST_ROLE_COUNT_LIST, GUEST_ROLE_USER_COUNT_WATCHER } from "../../actionTypes/guest";

// Worker triggering actionCreators

// export function getGuestBookingHistoryWatcher(data) {
//     return { type: GET_GUEST_BOOKING_HISTORY_WATCHER, payload: data};
// }

// export function getGuestDashboardWatcher(data) {
//     return { type: GET_GUEST_DASHBOARD_WATCHER, payload: data};
// }

export function createGuestOrderWatcher(data) {
    return { type: CREATE_GUEST_ORDER_WATCHER, payload: data};
}

export function cancelGuestOrderWatcher(id, data, response) {
    return { type: CANCEL_GUEST_ORDER_WATCHER, payload: data, response, id};
}

export function getGRoleSwipeHistoryWatcher(data) {
    return { type: GET_GROLE_SWIPE_HISTORY_WATCHER, payload: data };
}

export function selectGuestRoleCountWatcher(data) {
    return { type: GUEST_ROLE_USER_COUNT_WATCHER, payload: data };
}

// Redux state changing actionCreators

// export function guestBookingHistoryList(data) {
//     return { type: GUEST_BOOKING_HISTORY_LIST, payload: data };
// }

// export function guestDashboardList(data) {
//     return { type: GUEST_DASHBOARD_DATA, payload: data };
// }

export function gRoleSwipeHistoryList(data) {
    return { type: GROLE_SWIPE_HISTORY_LIST, payload: data };
}

export function selectGuestRoleCountList(data) {
    return { type: GET_GUEST_ROLE_COUNT_LIST, payload: data};
}