import http from "../../http-common";

class ScreenService {

    getContractor(data) {
        return http.post(`/swipeScreen/dashboard`, data);
    }

    getEmployee(data) {
        return http.post(`/swipeScreen/nextSwipe`, data);
    }

    getSwipeData(data) {
        return http.post(`/swipeScreen/swipeData`, data);
    }

}

export default new ScreenService();