//layout
import NavLayout from '../layouts/dashboard';
import CabBooking from "../pages/user/cab/cabBooking";
import BookingHistory from "../pages/user/cab/bookingHistory";

const User = {
  path: "/cab",
  element: <NavLayout />,
  children: [
    { index: true, element: <CabBooking /> },
    { path: "bookingHistory", element: <BookingHistory /> },
  ],
};

export default User;
