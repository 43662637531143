import React from 'react';
import { useState } from 'react';
import GuestChildHead from './guestChildHead';
// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
} from '@mui/material';
import EditTextField from '../../../overrides/editField';


const TABLE_HEAD = [
    { id: 'CLANDLINENUMBER ', label: 'Landline Number ', alignRight: false },
    { id: 'CMOBILENUMBER', label: 'Mobile Number', alignRight: false },
    { id: 'CEMAILID', label: 'Email ID', alignRight: false }
];

const GuestChildTable = ({ expandOpen, rowData, handleEditChange, status, editData }) => {

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <>
            {expandOpen &&
                <TableContainer sx={{ maxWidth: 650, overflow:'unset' }}>
                    <Table>
                        <GuestChildHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            //   rowCount={USERLIST.length}
                            //   numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            <TableRow
                                // hover
                                tabIndex={-1}
                                sx={{ '& > *': { borderBottom: 'unset' } }}
                            >
                                {status === 'list' ?
                                    <>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.CLANDLINENUMBER}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.CMOBILENUMBER}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.CEMAILID}
                                            </Typography>
                                        </TableCell>
                                    </>
                                    :
                                    <>
                                        <TableCell align="left">
                                            <EditTextField
                                                name='CLANDLINENUMBER'
                                                value={editData.CLANDLINENUMBER}
                                                onChange={handleEditChange}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <EditTextField
                                                name='CMOBILENUMBER'
                                                value={editData.CMOBILENUMBER}
                                                onChange={handleEditChange}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <EditTextField
                                                name='CEMAILID'
                                                value={editData.CEMAILID}
                                                onChange={handleEditChange}
                                            />
                                        </TableCell>
                                    </>
                                }
                            </TableRow>
                        </TableBody>

                    </Table>
                </TableContainer>
            }
        </>
    )
}

export default GuestChildTable;