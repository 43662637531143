import React, { useState } from 'react';
import {
    TableCell,
    Typography,
    TableRow,
    Collapse,
    Card,
} from '@mui/material';
import { getOrderTrackingViewWatcher } from '../../../../redux/actionCreators/admin/reports';
import { useDispatch, useSelector } from 'react-redux'
import ViewDialog from '../../../dialog';
import ViewPopup from './viewPopup';
import OrderTrackChildTable from './orderTrackChildTable';
import OrderTActionButtons from './orderTActionButtons';

const OrderTrackingTableRow = ({ rowData, handleRequestSort, order,orderBy }) => {

    const dispatch = useDispatch();

    const { ReportsReducers: { orderTrackingViewList } } = useSelector(state => (state))

    const [expandOpen, setExpandOpen] = useState(false);
    const [status] = useState('list'); //, setStatus
    const [open, setOpen] = useState(false);

    const viewFunction = () => {
        dispatch(getOrderTrackingViewWatcher({orderId: rowData.cOrderID}, setOpen))
    }

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                <TableCell align="left">
                    <OrderTActionButtons type={'orderTracking'} status={status} viewFunction={viewFunction} expandOpen={expandOpen} setExpandOpen={setExpandOpen} />
                    {/* <ActionButtons status={status} setStatus={setStatus} expandOpen={expandOpen} setExpandOpen={setExpandOpen} type={'orderTracking'}/> */}
                </TableCell>
                {/* <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.EmployeeName}
                    </Typography>
                </TableCell> */}
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.cUserName}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.cMenuName}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                    {rowData.cafeteriaName}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                    {rowData.cDeliveryCounter || '---'}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.dOrderDate}
                    </Typography>
                </TableCell>
                {/* <TableCell align="center" sx={{ width: '100px' }}>
                    <Typography variant='t14_600'>
                        {rowData.cReferenceID}
                    </Typography>
                </TableCell>
                <TableCell align="center" sx={{ width: '100px' }}>
                    <Typography variant='t14_600'>
                        {rowData.cBankTrackingID}
                    </Typography>
                </TableCell> */}
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.status}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.cMealName}
                    </Typography>
                </TableCell>
                {/* <TableCell align="center" sx={{ width: '50px' }}>
                    <Typography variant='t14_600'>
                        {rowData.nQuantity}
                    </Typography>
                </TableCell> */}
                {/* <TableCell align="center" sx={{ width: '100px' }}>
                    <Typography variant='t14_600'>
                        {rowData.nToatlRate}
                    </Typography>
                </TableCell>
                <TableCell align="center" sx={{ width: '200px' }}>
                    <Typography variant='t14_600'>
                    {rowData.totalAmount}
                    </Typography>
                </TableCell> */}
                
            {/* </TableRow> */}

            {/* {orderTrackingViewList.map((v, i) => { */}
            <ViewDialog open={open} setOpen={setOpen} maxWidth='xs' >
                <ViewPopup setOpen={setOpen} viewData={orderTrackingViewList} />
            </ViewDialog>
            {/* })} */}

            {/* <TableCell align="right" sx={{ minWidth: '100px' }}>
                    <ActionButtons status={status} setStatus={setStatus} expandOpen={expandOpen} setExpandOpen={setExpandOpen} type={'employee'}/>
            </TableCell> */}
            </TableRow>

            <TableRow>
                <TableCell colSpan={12} style={{ padding: 0 }}>
                    <Collapse in={expandOpen} timeout="auto" unmountOnExit>
                        <Card sx={{ bgcolor: 'grey.200' }} >
                            <OrderTrackChildTable expandOpen={expandOpen} rowData={rowData} handleRequestSort={handleRequestSort} orderBy={orderBy} order={order} status={status} />
                        </Card>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default OrderTrackingTableRow