import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import { updateAlertClose } from "../../redux/actionCreators/alert";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import MuiAlert from "@mui/material/Alert";
import { Typography } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars() {
  const {
    LoaderReducers: { isAlert, message, color },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(updateAlertClose());
  };

  return (
    <Snackbar
      open={isAlert}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={color} sx={{ width: "100%" }}>
        <Typography variant="t14_400">
        {message}
        </Typography>
      </Alert>
    </Snackbar>
  );
}
