// Cab Watcher Actions
export const GET_CAB_BOOKING_WATCHER = "GET_CAB_BOOKING_WATCHER"

export const CREATE_CAB_BOOKING_WATCHER = "CREATE_CAB_BOOKING_WATCHER"

export const GET_LEADER_MAIL_ID_WATCHER = "GET_LEADER_MAIL_ID_WATCHER"
export const LEADER_MAIL_LIST = "LEADER_MAIL_LIST"

//..............................................................................................................................................//

// Cab Reducer Actions
export const CAB_LIST = "CAB_LIST"