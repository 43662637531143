import React from 'react';
import EmployeeManagementTemp from './employeeManagementTemp';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography } from "@mui/material";
// import { useDispatch } from 'react-redux'

const EmployeeManagement = () => {
   
    return (
        <>
            <Box p='30px'>
                <Breadcrumbs content={["Dashboard", "User Management"]} />
                <Box p='15px 0'>
                    <Typography variant='t24_700' > User Management </Typography>
                </Box>
                <EmployeeManagementTemp />
            </Box>
        </>
    )
}

export default EmployeeManagement