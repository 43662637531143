import React from 'react';
import { Card, Grid, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

export default function CardTemp({tabVal}) {

    const { OrderSummaryReducers: { orderSummaryFilteredData } } = useSelector((state) => state)

    const cardValue = orderSummaryFilteredData.length ? orderSummaryFilteredData[0] : {}

    return (
        <>
            <Grid container spacing={3} sx={{ padding: '30px' }}>
                <Grid item xs={12} lg={3} md={6} sm={6} >
                    <Card sx={{ padding: '20px', border: (theme) => `1px solid ${theme.palette.grey[200]}`, boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, bgcolor: 'common.d1', color: 'common.white' }}>
                        <Box >
                            <Typography variant='t32_600' >
                            {tabVal === 1 ? cardValue.LastDayEmp : cardValue.EmployeeBooking}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant='t18_500' >
                                {'Employee'}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={3} md={6} sm={6} >
                    <Card sx={{ padding: '20px', border: (theme) => `1px solid ${theme.palette.grey[200]}`, boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, bgcolor: 'common.d2', color: 'common.white' }}>
                        <Box >
                            <Typography variant='t32_600' >
                            {tabVal === 1 ? cardValue.LastDayContractor : cardValue.ContractorBooking}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant='t18_500' >
                                {'Contractor'}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={3} md={6} sm={6} >
                    <Card sx={{ padding: '20px', border: (theme) => `1px solid ${theme.palette.grey[200]}`, boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`,
                bgcolor: 'common.d3', color: 'common.white' }}>
                        <Box >
                            <Typography variant='t32_600' >
                            {tabVal === 1 ? cardValue.LastDayFoodClerk : cardValue.FoodClerk}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant='t18_500' >
                                {'Food Clerk'}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={3} md={6} sm={6} >
                    <Card sx={{ padding: '20px', border: (theme) => `1px solid ${theme.palette.grey[200]}`, boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, 
                bgcolor: 'common.d4', color: 'common.white' }}>
                        <Box >
                            <Typography variant='t32_600' >
                            {tabVal === 1 ? cardValue.LastDaySpd : cardValue.SpdBooking}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant='t18_500' >
                                {'Guest'}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </>

    );
}
