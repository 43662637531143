import React from 'react';
import ContractorManagementTemp from './contractorManagementTemp';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography } from "@mui/material";


const ContractorManagement = () => {
    return (
        <>
            <Box p='30px'>
                <Breadcrumbs content={["Dashboard", "Contractor Management"]} />
                <Box p='15px 0'>
                    <Typography variant='t24_700' > Contractor Management </Typography>
                </Box>
                <ContractorManagementTemp />
            </Box>
        </>
    )
}

export default ContractorManagement;