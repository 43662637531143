import React, { useEffect, useState } from 'react'
import { Typography, Box, Autocomplete, Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getCounterAdminWatcher } from '../../../redux/actionCreators/screen/screen'
import MealCount from '../../../components/swipeScreen/dashboard/session';
import { getDropDownWatcher } from '../../../redux/actionCreators/admin/employeeManagement';
import CssTextField from '../../../components/overrides/coloredTextField';
// import StyledToggleButtonGroup from '../../../components/overrides/toggleButton';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { updateCafeteriaId } from '../../../redux/actionCreators/user/home';

function CounterAdminDasboard() {

  const dispatch = useDispatch()
  const { ProfileReducers: { loginState }, EmployeeReducers: { cafeteriaName }, UserHomeReducers: { cafeteriaId } } = useSelector(state => state)
  const cEmployeeName = loginState[0]?.cEmployeeName || ''

  // const [alignment, setAlignment] = useState(1);

  // const handleAlignment = (event, newAlignment) => {
  //   if (!newAlignment) {
  //     return
  //   }
  //   setAlignment(newAlignment);
  // };

  const [dateChange, setDateChange] = useState(new Date())

  useEffect(() => {
    let data = {
      "ACTION": "CounterAdminDashboard",
      "nCafeteriaId": 0
    }
    dispatch(getCounterAdminWatcher(data))
    dispatch(getDropDownWatcher());
  }, [dispatch])

  const filterByCafe = (val) => {
    let data = {
      "ACTION": "CounterAdminDashboard",
      "nCafeteriaId": val,
      "CurentDate": dateChange
    }
    dispatch(getCounterAdminWatcher(data))
    dispatch(updateCafeteriaId(val))
  }

  const defaultVal = { name: 'Select cafeteria', id: 0 }

  const dateApiFunc = (e) => {
    let data = {
      "ACTION": "CounterAdminDashboard",
      nCafeteriaId: cafeteriaId,
      CurentDate: e,
    }
    setDateChange(e)
    dispatch(getCounterAdminWatcher(data))
  }

  return (
    <>
      <Stack direction='column' style={{ padding: '24px' }}>
        <Box >
          <Typography variant='t12_400' >Dashboard</Typography>
        </Box>
        <Box >
          <Typography variant='t24_700'>Welcome, {cEmployeeName}</Typography>
        </Box>
      </Stack>

      {/* <Stack direction={{ xs: 'row' }} spacing={3} justifyContent='right' mr='30px' ml={{ xs: '30px', sm: 'auto' }}>
        <Box sx={{ marginBottom: 0 }}>
          <Paper
            elevation={0}
            sx={{
              display: 'flex',
              border: (theme) => `1px solid ${theme.palette.Boxider}`,
              flexWrap: 'wrap',
              width: 'max-content',
              height: '50px'
            }}
          >
            <StyledToggleButtonGroup
              size="medium"
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
            >
              <ToggleButton value={1} aria-label="left aligned" sx={{ width: '100px' }}>
                <Typography variant='t15_600'>Today</Typography>
              </ToggleButton>
              <ToggleButton value={2} aria-label="centered">
                <Typography variant='t15_600'>Next Day</Typography>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Paper>
        </Box>
      </Stack> */}


      <Stack direction='row' justifyContent='end' spacing={3} mr='30px' >

        <LocalizationProvider dateAdapter={AdapterDateFns}>

          <DatePicker
            // label='Order Date'
            inputFormat="dd/MM/yyyy"
            // inputFormat="DD-MM-YYYY"
            renderInput={(params) => <TextField {...params}
              sx={{
                '& label.Mui-focused': {
                  color: 'grey.300',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'grey.300',
                  },
                  '&:hover fieldset': {
                    borderColor: 'grey.300',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'grey.300',
                  },
                  width: '200px',
                  backgroundColor: 'common.white'
                },
                '& .MuiOutlinedInput-input': {
                  height: '16px',

                },

              }}
            />}
            value={dateChange}
            onChange={(e) => {
              dateApiFunc(e)
            }}
          />
        </LocalizationProvider>

        <Autocomplete
          sx={{ width: '230px' }}
          disablePortal
          id="combo-box-demo"
          name="selectMeal"
          fullWidth
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={cafeteriaName}
          defaultValue={defaultVal}
          size="medium"
          autoHighlight
          onChange={(event, value) => {
            filterByCafe(value.id);
          }}
          getOptionLabel={(option) => option.name === '-Select-' ? 'OverAll' : option.name}
          renderInput={(params) => <CssTextField sx={{
            '& .MuiOutlinedInput-input': {
              height: '16px',
              color: 'common.white',
            },
          }}
            // label="Select Cafeteria"
            {...params}
          />}
        />

      </Stack>



      <Box>
        <MealCount dayTabVal={1} />
      </Box>
    </>
  )
}

export default CounterAdminDasboard;