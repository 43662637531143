import * as React from "react";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCartWatcher, getOrderTotalCostWatcher } from "../../redux/actionCreators/user/home";
import { Link as RouterLink } from 'react-router-dom';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { updateAlert } from "../../redux/actionCreators/alert";

export default function TemporaryDrawer() {

  const {UserHomeReducers: {cartList}, ProfileReducers: {loginState}} = useSelector(state => state)

  const dispatch = useDispatch()

  const nUserID = loginState[0]?.nID || 0

  React.useEffect(() => {
      const data = {
        ACTION: "CHECKLIST",
        nUserID,
      };
      const viewCart = {
        nUserID,
      };
      dispatch(getCartWatcher(data, viewCart));
      dispatch(getOrderTotalCostWatcher(nUserID));
  }, [dispatch, nUserID]);

  // const getCount = () => {
  //   let total = 0
  //   cartList.forEach(d=>{
  //     total += d.nQuantity
  //   })
  //   return total
  // }

  const handleClick = () => {
    dispatch(updateAlert('Cart is empty', 'error'));
  }

  return (
    <React.Fragment>

      {cartList.length ?
        <Button variant='outlined' sx={{ height: 40 }} startIcon={<ShoppingCartOutlinedIcon />} component={RouterLink} to='/user/viewCart'>
          <Typography color="common.black" variant="t14_400">
            ({cartList.length})
          </Typography>
        </Button>
        :
        <Button variant='outlined' sx={{ height: 40 }} onClick={handleClick} startIcon={<ShoppingCartOutlinedIcon />} >
          <Typography color="common.black" variant="t14_400">
            ({cartList.length})
          </Typography>
        </Button>
      }

    </React.Fragment>
  );
}
