import React from 'react';
import { Typography, Card, Grid } from "@mui/material";
import CardTemplate from "../../../components/foodVendor/dashboard/card"
import { useSelector } from 'react-redux';

const DayOrder = () => {

    const { ContractorRoleReducers: { contractorDashboardData } } = useSelector((state) => state)

    const cardVal = (contractorDashboardData.length && contractorDashboardData[0]) || []
    const totalVal = (contractorDashboardData.length && contractorDashboardData[1]) || []


    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}` }}>
                <Grid container spacing={3} sx={{ padding: '30px' }} >
                    <Grid item lg={6} xs={6} sx={{ justifyContent: 'left' }}>
                        <div>
                            <Typography variant='t20_600'>Today's Order</Typography>
                            <Typography variant='t20_600' color='primary' fontFamily='inter' pl='10px'>
                                {totalVal.length && totalVal[0].TotalQuantity}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>

                <Grid container sx={{}}>
                    {cardVal.map(m =>
                        <Grid item key={m.cMealName} xs={12} lg={3} md={3} sm={4} sx={{ marginLeft: '15px', marginRight: '50px', justifyContent: 'center', marginBottom: '15px', marginTop: '15px', width: '100px' }}>
                            <CardTemplate count={m.Quantity} title={m.cMealName} />
                        </Grid>
                    )}
                </Grid>
            </Card>
        </>

    )
}

export default DayOrder;