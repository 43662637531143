import React from 'react';
import { Card, Grid, Button, InputAdornment, Stack, Typography } from "@mui/material";
import SearchField from '../../overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import BlackButton from '../../overrides/blackButton';
import { getConvertedDate } from '../../../utils/common';
import { ExportCsv } from "@material-table/exporters";

const CommonHeader = ({ handleSearch, searchText, handleSubmitAction, rowData, columnData, defaultDate =null }) => {

    // const [open, setOpen] = useState(false);
    let last = new Date();
    last.setDate(last.getDate() - 1)
    const [fromDate, setFromDate] = React.useState(defaultDate);
    const [toDate, setToDate] = React.useState(defaultDate);

    const handleFromChange = (e) => {
        setFromDate(e)
    }

    const handleToChange = (e) => {
        setToDate(e)
    }

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >
                    <Grid item lg={3.5} xs={12} md={3} sm={12} sx={{ justifyContent: 'left' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px',

                                }
                            }}
                            placeholder='Search' fullWidth onChange={handleSearch} value={searchText} />
                    </Grid>
                    <Grid item lg={4} xs={12} md={4} sm={12} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={fromDate}
                                    label='From Date'
                                    onChange={handleFromChange}
                                    renderInput={(params) => <SearchField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '48px',

                                        },
                                        '& label': {
                                            fontSize: '.9rem'
                                        }
                                    }} fullWidth {...params} />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={toDate}
                                    label='To Date'
                                    onChange={handleToChange}
                                    renderInput={(params) => <SearchField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '48px',
                                        },
                                        '& label': {
                                            fontSize: '.9rem'
                                        }
                                    }} fullWidth {...params} />}
                                />
                            </LocalizationProvider>

                        </Stack>
                    </Grid>
                    <Grid item lg={2} xs={12} md={2} sm={6} >
                        <Button variant='contained' sx={{ height: '48px', backgroundColor: 'common.Bblack', '&:hover':{backgroundColor: 'common.Bblack'} }} fullWidth endIcon={<DoubleArrowIcon />} onClick={() => handleSubmitAction(getConvertedDate(fromDate), getConvertedDate(toDate))} >
                            <Typography variant='t16_400_1'>Submit</Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={2.5} xs={12} md={3} sm={6} display='flex'>
                        <Button variant='contained' sx={{ height: '49px' }} endIcon={<DoubleArrowIcon />} fullWidth onClick={() => ExportCsv(columnData, rowData, "Export file")}>
                            <Typography variant='t16_500'>Export to Excel</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default CommonHeader;