// component
import { HomeOutlined, ShoppingCartOutlined } from '@mui/icons-material'
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import LocalTaxiOutlinedIcon from '@mui/icons-material/LocalTaxiOutlined';
// ----------------------------------------------------------------------

export const sidebarMain = [
  {
    title: 'Home',
    path: '/user',
    icon: <HomeOutlined color='inherit' />
  },
  {
    title: 'Menu',
    path: '/user/menu',
    icon: <ShoppingCartOutlined color='inherit' />
  },
  // {
  //   title: 'Bulk',
  //   path: '/user/bulk',
  //   icon: <ShoppingCartOutlined color='inherit' />
  // },
  {
    title: 'Order Management',
    icon: <ShoppingCartOutlined color='inherit' />,
    children: [
      {
        title: 'Current Order',
        path: '/user/currentOrder',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Order History',
        path: '/user/orderHistory',
        icon: <ShoppingCartOutlined color='inherit' />
      },
    ]
  },
  {
    title: 'Others',
    icon: <ShoppingCartOutlined color='inherit' />,
    children: [
      {
        title: 'My Ratings',
        path: '/user/myRatings',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'My Feedbacks',
        path: '/user/myFeedbacks',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Contact Us',
        path: '/user/contactUs',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Terms & Condition',
        path: '/user/terms&Condition',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'About Us',
        path: '/user/aboutUs',
        icon: <ShoppingCartOutlined color='inherit' />
      },
    ]
  }

];

export const Apps = [
  {
    title: 'Food',
    path: '/user',
    icon: <FastfoodOutlinedIcon color='inherit' />
  },
  {
    title: 'Cab',
    path: '/cab',
    icon: <LocalTaxiOutlinedIcon color='inherit' />
  },
  {
    title: 'Kiosk',
    path: '/kiosk',
    icon: <ShoppingCartOutlined color='inherit' />
  },
];

export const Cab = [
  {
    title: 'Cab Booking',
    path: '/cab',
    icon: <LocalTaxiOutlinedIcon color='inherit' />
  },
  {
    title: 'Booking History',
    path: '/cab/bookingHistory',
    icon: <LocalTaxiOutlinedIcon color='inherit' />
  },
];

export const sidebarOrder = [
  {
    title: 'Current Order',
    path: '/user/currentOrder',
    icon: <ShoppingCartOutlined color='inherit' />
  },
  {
    title: 'Order History',
    path: '/user/orderHistory',
    icon: <ShoppingCartOutlined color='inherit' />
  },
  {
    title: 'My Ratings',
    path: '/user/myRatings',
    icon: <ShoppingCartOutlined color='inherit' />
  },
  {
    title: 'My Feedbacks',
    path: '/user/myFeedbacks',
    icon: <ShoppingCartOutlined color='inherit' />
  },

];

export const sidebarOthers = [
  {
    title: 'Contact Us',
    path: '/user/contactUs',
    icon: <ShoppingCartOutlined color='inherit' />
  },
  {
    title: 'Terms & Condition',
    path: '/user/terms&Condition',
    icon: <ShoppingCartOutlined color='inherit' />
  },

];
