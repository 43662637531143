import React,{useEffect} from 'react'
import { Typography, Grid } from "@mui/material";
import DayOrder from './dayOrder';
import NextDayBooking from './nextDay';
import { useDispatch, useSelector } from 'react-redux';
import { getContractorDashboardWatcher } from '../../../redux/actionCreators/contractor';

export default function Dashboard() {
    const dispatch = useDispatch();
    const { ProfileReducers: { loginState } } = useSelector(state => state)
    const cEmployeeName = loginState[0]?.cEmployeeName || ''
    const userId = loginState[0]?.nID || ''

    useEffect(() => {
        let data ={
            "ACTION":"ContractorDashboard",
            UserID: userId
        }
        dispatch(getContractorDashboardWatcher(data));
    }, [dispatch, userId])
    
    return (
        <>
            <Grid container style={{ padding: '24px' }}>
                <Grid item xs={12}>
                    <Typography variant='t12_400' >Dashboard</Typography>
                </Grid>
                <Grid item mt='8px' xs={12}>
                    <Typography variant='t24_700'>{`Welcome, ${cEmployeeName}`}</Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '20px' }}>
                    <DayOrder />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '20px' }}>
                    <NextDayBooking />
                </Grid>
            </Grid>
        </>
    )
}
