import React, { useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card, Button } from "@mui/material";
import SpecialHistoryHeader from '../../../components/admin/reports/specialHistory/header';
import SpecialHistoryTable from '../../../components/admin/reports/specialHistory/table';
import { useDispatch, useSelector } from 'react-redux';
import { getSpecialHistoryWatcher } from '../../../redux/actionCreators/admin/reports';
import { ExportCsv } from "@material-table/exporters";
import { SUBTABLE_HEAD } from '../../../components/admin/reports/specialHistory/subTable';
import SpecialHistorySubTable from '../../../components/admin/reports/specialHistory/subTable';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

const SpecialHistory = () => {
    const { ReportsReducers: { SpecialHistoryList } } = useSelector((state) => state)

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [pageChange, setPageChange] = useState(false);

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = SpecialHistoryList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
    }

    const handleSubmitAction = (fromDate, toDate) => {
        const data = {
            ACTION: "GetSpecialReportSearch",
            StartDate: fromDate,
            EndDate: toDate,
            UserId: "33",
        }
        dispatch(getSpecialHistoryWatcher(data))
    }

    return (
        <>
            {!pageChange ?

                <Box p="24px">

                    <Breadcrumbs content={["Reports ", "Special History"]} />

                    <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                        <Typography variant="t24_700">Special History</Typography>
                    </Box>
                    <Box p='10px 0'>
                        <SpecialHistoryHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} />
                    </Box>
                    <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                        <SpecialHistoryTable setPageChange={setPageChange} searchVal={searchVal} searchText={searchText} />
                    </Card>

                </Box>
                :

                <>
                    <Box p="24px">
                        <Box p='20px 0' display='flex' justifyContent='right' >
                            <Button sx={{ mr: '15px', minWidth: '100px', }} variant='contained' onClick={() => setPageChange(false)} >
                            <Typography variant='t16_400_1'>
                                Back
                                </Typography>
                            </Button>
                            <Button variant='contained' sx={{ height: '50px', minWidth: '150px', mr: '15px' }} endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(SUBTABLE_HEAD, SpecialHistoryList, "Special History")}>
                                <Typography variant='t16_500'>Export to Excel</Typography>
                            </Button>

                        </Box>
                        <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                            <SpecialHistorySubTable />
                        </Card>
                    </Box>
                </>
            }
        </>

    )
}

export default SpecialHistory;