import React from 'react';
import { Switch } from '@mui/material';
import { useDispatch } from 'react-redux'
import { updateMenuListStatusWatcher } from '../../../redux/actionCreators/kiosk';

const SwithAction = ({ rowData }) => {
    const dispatch = useDispatch();

    const handleChange = (event) => {
        let data = {
            "ACTION": `${rowData.bIsActive}` === "true" ? "DEACTIVE" : "ACTIVE"
        }
        dispatch(updateMenuListStatusWatcher(rowData.nID, data))
    };

    return(

        <>
            <Switch 
                checked={`${rowData.bIsActive}` === "true" ? true : false} 
                onChange={handleChange}
                color="primary" 
            />
        </>

    )
}

export default SwithAction