import React from 'react';
import Paper from '@mui/material/Paper';
import { Box, TextField, ToggleButton, Typography } from '@mui/material';
import StyledToggleButtonGroup from '../../../overrides/toggleButton';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import CssTextField from '../../../overrides/longSearchField';
import { useDispatch, useSelector } from 'react-redux';
import { getLastDayOrderSummaryWatcher } from '../../../../redux/actionCreators/admin/orderSummary';

export default function CustomizedDividers({ setTabval, dateChange, setDateChange }) {

  const [alignment, setAlignment] = React.useState('right');
  const dispatch = useDispatch();

  const { UserHomeReducers: { cafeteriaId } } = useSelector((state) => state);

  // const current = new Date();
  // const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

  const handleAlignment = (event, newAlignment) => {
    if (!newAlignment) {
      return
    }
    setAlignment(newAlignment);
    if (newAlignment === 'left') {
      setTabval(0)
    }
    if (newAlignment === 'right') {
      setTabval(1)
    }
  };

  const dateApiFunc = (e) =>{
    let data = {
      CurentDate: e,
      nCafeteriaId: cafeteriaId
  }
  setDateChange(e)
  dispatch(getLastDayOrderSummaryWatcher(data))
  }

  const customComp = React.forwardRef((props, ref) => {
    return <Box {...props} ref={ref} />
  });

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap',
        }}
      >
        <StyledToggleButtonGroup
          size="medium"
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
        >
          <ToggleButton value="left" sx={{ width: '150px', p: 0 }} aria-label="left aligned" >
            <Typography variant='t15_600'>Last Week</Typography>

          </ToggleButton>

          <ToggleButton component={customComp} value="right" sx={{ p: 0 }} aria-label="centered">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // label='Order Date'
                inputFormat="dd/MM/yyyy"
                // inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params}
                  sx={{
                    '& label.Mui-focused': {
                      color: 'grey.300',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: alignment === 'right' ? 'grey.300' : 'common.white',
                      },
                      '&:hover fieldset': {
                        borderColor: 'grey.300',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'grey.300',
                      },
                      width: '200px'
                    },
                  }}
                  fullWidth
                  size='small'
                />}
                value={dateChange}
                onChange={(e) => {
                  dateApiFunc(e)
                }}
              />
            </LocalizationProvider>
          </ToggleButton>

        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
}
