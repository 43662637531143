import React from 'react';
import { useState, useEffect } from 'react';
// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    Box,
    Pagination
} from '@mui/material';
import CarousalTableHead from './carousalTableHead';
import Scrollbar from '../../../Scrollbar';
// import ActionButtons from '../../dashboard/actionButtons';
import { getCarouselWatcher } from '../../../../redux/actionCreators/admin/carousel';
import { useDispatch, useSelector } from 'react-redux';
import CarouselTableRow from './tableRow';

export const TABLE_HEAD = [
    { id: 'image', label: 'Image', alignRight: false, title: 'Image', field: 'cImage' },
    { id: 'description ', label: 'Description', alignRight: false, title: 'Description', field: 'cDescription' },
    { id: 'url', label: 'URL', alignRight: false, title: 'Url', field: 'cUrl' },
    { id: 'action', label: 'Action', alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const CarousalTable = ({ searchVal, searchText, page, setPage}) => {

    const { CarouselReducers: { CarouselImageList } } = useSelector((state) => state)
    let firstIndex = 0;

    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage] = useState(5);

    useEffect(() => {
        let data = {
            "ACTION": "Select"
        }
        dispatch(getCarouselWatcher(data))
    }, [dispatch])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const tableData = searchText ? searchVal : CarouselImageList
    const filteredData = applySortFilter(tableData, getComparator(order, orderBy));
    const isUserNotFound = filteredData?.length === 0;

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{ overflow: 'unset' }} >
                        <Table>
                            <CarousalTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                //   rowCount={USERLIST.length}
                                //   numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    // .slice(page * 5, page * 5 + 5)
                                    .map((c, i) => {
                                        return (
                                            <CarouselTableRow key={c.nId} rowData={c} index={i} />
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(tableData?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default CarousalTable;