import { UPDATE_CURRENT_ORDER, CURRENT_ORDER_WATCHER, ORDER_HISTORY_WATCHER, UPDATE_ORDER_HISTORY, NEXT_DAY_ORDER_WATCHER, NEXT_WEEK_ORDER_WATCHER, DELIVERED_ORDER_WATCHER, UPCOMING_ORDER_WATCHER, UPDATE_NEXT_DAY_ORDER, UPDATE_NEXT_WEEK_ORDER, UPDATE_DELIVERED_ORDER, UPDATE_UPCOMING_ORDER, CHECKOUT_WATCHER, ORDER_BOOKING_WATCHER, PAYMENT_WATCHER, INSERT_FEEDBACK_WATCHER, RATE_FEEDBACK_WATCHER, USER_ORDER_CANCEL_WATCHER, USER_ORDER_HISTORY_CANCEL_WATCHER, VERIFY_PAYTM_WATCHER } from "../../actionTypes/currentOrder";

// Worker triggering actionCreators

export function currentOrderWatcher(data) {
    return { type: CURRENT_ORDER_WATCHER, payload: data};
}

export function orderHistoryWatcher(data) {
    return { type: ORDER_HISTORY_WATCHER, payload: data};
}

export function nextdayOrderWatcher(data) {
    return { type: NEXT_DAY_ORDER_WATCHER, payload: data};
}

export function nextweekOrderWatcher(data) {
    return { type: NEXT_WEEK_ORDER_WATCHER, payload: data};
}

export function deliveredOrderWatcher(data) {
    return { type: DELIVERED_ORDER_WATCHER, payload: data};
}

export function upcomingOrderWatcher(data) {
    return { type: UPCOMING_ORDER_WATCHER, payload: data};
}

export function checkoutWatcher(data) {
    return { type: CHECKOUT_WATCHER, payload: data};
}

export function orderBookingWatcher(data, dispatch, navigate) {
    return { type: ORDER_BOOKING_WATCHER, payload: data, dispatch, navigate};
}

export function paymentWatcher(data, navigate) {
    return { type: PAYMENT_WATCHER, payload: data, navigate};
}

export function insertFeedBackWatcher(data) {
    return { type: INSERT_FEEDBACK_WATCHER, payload: data};
}

export function rateFeedBackWatcher(id, data) {
    return { type: RATE_FEEDBACK_WATCHER, payload: data, id};
}

export function userOrderCancelWatcher(data) {
    return { type: USER_ORDER_CANCEL_WATCHER, payload: data};
}

export function userOrderHistoryCancelWatcher(data) {
    return { type: USER_ORDER_HISTORY_CANCEL_WATCHER, payload: data};
}

export function verifyPaytmWatcher(data) {
    return { type: VERIFY_PAYTM_WATCHER, payload: data};
}

// Redux state changing actionCreators

export function updateCurrentOrder(data) {
    return { type: UPDATE_CURRENT_ORDER, payload: data };
}

export function updateOrderHistory(data) {
    return { type: UPDATE_ORDER_HISTORY, payload: data };
}

export function updateNextDayOrder(data) {
    return { type: UPDATE_NEXT_DAY_ORDER, payload: data };
}

export function updateNextWeekOrder(data) {
    return { type: UPDATE_NEXT_WEEK_ORDER, payload: data };
}

export function updateDeliveredOrder(data) {
    return { type: UPDATE_DELIVERED_ORDER, payload: data };
}

export function updateUpcomingOrder(data) {
    return { type: UPDATE_UPCOMING_ORDER, payload: data };
}
