import { Card, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef } from "react";
import Breadcrumbs from "../../../../components/breadcrumbs";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustTextField from '../../../../components/overrides/searchField'
import OrderHistoryTable from './table'
import { useDispatch, useSelector } from "react-redux";
import { orderHistoryWatcher } from "../../../../redux/actionCreators/user/currentOrder";
import BlackButton from "../../../../components/overrides/blackButton";
import _ from 'lodash'
import { getConvertedDate } from "../../../../utils/common";

const OrderHistory = () => {
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const tempinitsform = useRef();

  const dispatch = useDispatch()

  const {ProfileReducers: {loginState}, CurrentOrderReducers: { orderHistory }} = useSelector(state => state)
  const nUserID = loginState[0]?.nID || 0

  const initsform = () => {
    const data = {
      nUserID,
      "cAction": "History",
      // "Fdate": "10/06/2022",
      // "Tdate": "10/06/2022"
    }
    dispatch(orderHistoryWatcher(data))
  };

  tempinitsform.current = initsform;

  useEffect(() => {
    tempinitsform.current();
  }, []);

  // useEffect(() => {
  //   const data = {
  //     nUserID,
  //     "cAction": "History",
  //     // "Fdate": "10/06/2022",
  //     // "Tdate": "10/06/2022"
  //   }
  //   dispatch(orderHistoryWatcher(data))
  //   // let today = new Date()
  //   // let toDate= new Date()
  //   // toDate.setDate(toDate.getDate()+3)
  //   // setFromDate(today)
  //   // setToDate(toDate)
  // }, [])

    const handleFromChange = (e) => {
        setFromDate(e)
    }

    const handleToChange = (e) => {
      setToDate(e)
    }

    const handleSubmit = () => {

      const data = {
        nUserID,
        "cAction": "HistorySearch",
        "Fdate": getConvertedDate(fromDate),
        "Tdate": getConvertedDate(toDate)
      }
      dispatch(orderHistoryWatcher(data))
      setPage(1)
  }

  const groupOrder = _.groupBy(orderHistory, (d) => d.nBookingID);

  return (
    <Box p="24px">
      <Breadcrumbs content={["My Order", "Order History"]} />

      <Box mt="14px" display='flex' flexDirection={{xs:'column', md: 'row'}} >
        <Typography variant="t24_700">Order History</Typography>
        <Stack direction='row' ml={{md:'auto', xs:0}} mt={{md:'0px', xs: '20px'}} spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={fromDate}
              label='From Date'
              onChange={handleFromChange}
              renderInput={(params) => <CustTextField {...params} sx={{
                width: 176,
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'common.white',
                },
              }} size='small' />}
            />
          </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={toDate}
              label='To Date'
              onChange={handleToChange}
              renderInput={(params) => <CustTextField {...params} sx={{
                width: 176,
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'common.white',
                },
              }} size='small' />}
            />
          </LocalizationProvider>

          <BlackButton onClick={handleSubmit} sx={{pl:'20px', pr:'20px'}}>
            <Typography variant="t14_400" >
            Submit
            </Typography>
          </BlackButton>
        </Stack>
        
      </Box>

      <Box mt='24px'>
      <Card style={{ boxShadow: (theme)=> `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
        <OrderHistoryTable page={page} setPage={setPage} groupOrder={groupOrder}/>

        </Card>
      </Box>
    </Box>
  );
};

export default OrderHistory;
