import React from 'react';
import { useState, useEffect } from 'react';
import Scrollbar from '../../Scrollbar';
// material
import {
    Table,
    TableBody,
    TableContainer,
    Pagination,
    Box,
    Typography,
    TableCell,
    TableRow
} from '@mui/material';
import CommonTableHead from '../../foodVendor/reports/commonTableHead.js';
// import { getCabBookingWatcher } from '../../../redux/actionCreators/cab';
import { useDispatch, useSelector } from 'react-redux';
import BookingHisTableRow from './tableRow';
import useResponsive from '../../../hooks/useResponsive';

export const TABLE_HEAD = [
    { id: 'UserName', label: 'User Name', alignRight: false, title: 'User Name', field: 'EmployeeName' },
    { id: 'OrderDate', label: 'OrderDate', alignRight: false, title: 'OrderDate', field: 'OrderDate' },
    { id: 'Department', label: 'Department', alignRight: false, title: 'Department', field: 'Department' },
    { id: 'MobileNo', label: 'Mobile No', alignRight: false, title: 'MobileNo', field: 'MobileNo' },
    { id: 'TypeOfCab', label: 'Type Of Cab', alignRight: false, title: 'TypeOfCab', field: 'TypeOfCab' },
    { id: 'cabVendor', label: 'Cab Vendor', alignRight: false, title: 'cabVendor', field: 'cabVendor' },
    { id: 'CostCentre', label: 'Cost Centre', alignRight: false, title: 'CostCentre', field: 'CostCentre' },
    { id: 'DateOfTravelOn', label: 'Date Of Travel On', alignRight: false, title: 'DateOfTravelOn', field: 'DateOfTravelOn' },
    { id: 'DateOfTravelOut', label: 'Date Of Travel Out', alignRight: false, title: 'DateOfTravelOut', field: 'DateOfTravelOut' },
    { id: 'EmailID', label: 'Email ID', alignRight: false, title: 'EmailID', field: 'EmailID' },
    { id: 'SupervisiorEmailID', label: 'Supervisior EmailID', alignRight: false, title: 'Supervisior EmailID', field: 'SupervisiorEmailID' },
    { id: 'Flight/TrainNumber', label: 'Flight / TrainNumber', alignRight: false, title: 'Flight/TrainNumber', field: 'Flight/TrainNumber' },

    // { id: 'Gender', label: 'Gender', alignRight: false, title: 'Gender', field: 'Gender' },
    // { id: 'HREmailID', label: 'HR Email ID', alignRight: false, title: 'HREmailID', field: 'HREmailID' },
    // { id: 'ReportTimeTo', label: 'Report Time To', alignRight: false, title: 'ReportTimeTo', field: 'ReportTimeTo' }, 
    // { id: 'ReportingAddress', label: 'Reporting Address', alignRight: false, title: 'ReportingAddress', field: 'ReportingAddress' },
    // { id: 'ReportingTimeFrom', label: 'Reporting Time From', alignRight: false, title: 'ReportingTimeFrom', field: 'ReportingTimeFrom' }, 
    // { id: 'SpecialInstructions', label: 'Special Instructions', alignRight: false, title: 'SpecialInstructions', field: 'SpecialInstructions' }, 
    // { id: 'SupervisiorEmailID', label: 'Supervisior Email ID', alignRight: false, title: 'SupervisiorEmailID', field: 'SupervisiorEmailID' },
    // { id: 'TravelDestinationFrom', label: 'Travel Destination From', alignRight: false, title: 'TravelDestinationFrom', field: 'TravelDestinationFrom' },
    // { id: 'TravelDestinationTo', label: 'Travel Destination To', alignRight: false, title: 'TravelDestinationTo', field: 'TravelDestinationTo' },
    // { id: 'TypeOfTrip', label: 'Type Of Trip', alignRight: false, title: 'TypeOfTrip', field: 'TypeOfTrip' },
    // { id: 'UsageType', label: 'Usage Type', alignRight: false, title: 'UsageType', field: 'UsageType' },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array.map((el, index) => ( [el, index]));
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order; 
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const BookingHistoryTable = ({ searchText, searchVal, CabList}) => {

    // const { CabReducers: { CabList } } = useSelector((state) => state)

    const isMobile = useResponsive('down', 'sm')

    let firstIndex = 0;

    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage] = useState(5);
    const [action] = useState(true)

    // useEffect(() => {
    //     let data = {
    //         "ACTION": "SELECT"
    //     }
    //     dispatch(getCabBookingWatcher(data))
    // }, [dispatch])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const tableData = searchText ? searchVal : CabList
    const filteredData = applySortFilter(tableData, getComparator(order, orderBy));
    const isUserNotFound = filteredData?.length === 0;

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{ overflow: 'unset' }} >
                        <Table>
                            <CommonTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                action={action}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    .map((f, i) => {
                                        return (
                                            <BookingHisTableRow key={i} rowData={f} index={i} />
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(tableData?.length / rowsPerPage)}
                    page={page}
                    size={isMobile ? "small" : 'medium'}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default BookingHistoryTable;