import http from "../../http-common";

class ReportsServices {
    getReports(data) {
        return http.post("/admin/reports", data);
    }

    getOrderHistory(data) {
        return http.post("/admin/orderHistory", data);
    }

    getGuestOrderHistory(data) {
        return http.post("/admin/guestOrderHistory", data);
    }

    getDayByDayOrder(data) {
        return http.post("/admin/daybydayReports", data);
    }

    getPushNotification(data) {
        return http.post("/admin/pushNotification", data);
    }

    createPushNotification(data) {
        return http.post("/admin/pushAdd", data);
    }

    getOrderTracking(data) {
        return http.post("/admin/orderTracking", data);
    }

    getKioskUserHistory(data) {
        return http.post("/kiosk/getKioskReport", data);
    }

    getKioskOrderTracking(data) {
        return http.post("/kiosk/getKioskReport", data);
    }

    getContractorSwipeHistory(data) {
        return http.post("/admin/contractorSwipeHistory", data);
    }

    getGuestSwipeHistory(data) {
        return http.post("/admin/guestSwipeHistory", data);
    }

    getDeliveryHistory(data) {
        return http.post("/admin/deliveryHistory", data);
    }

    getGuestDeliveryHistory(data) {
        return http.post("/admin/guestDeliveryHistory", data);
    }

    // getAdminRateList(data) {
    //     return http.post("/admin/getRatings", data);
    // }

    getRatingFeedbackList(data) {
        return http.post("/admin/getRatingsAndFeedback", data);
    }

    getActivityList(data) {
        return http.post("/admin/getActivity", data);
    }

    getSpecialOrderTracking(data) {
        return http.post("/admin/orderTracking", data);
    }

    getSaleReport(data) {
        return http.post("/admin/countList", data);
    }

    getSaleMenu(data) {
        return http.post("/admin/menuList", data);
    }

    updateFeedback(id, data) {
        return http.post(`/admin/rplyFeed/${id}`, data);
    }

    getReportsView(id, data) {
        return http.get(`/user/razorpay/payment/${id}`, data);
    }

    verifyPaytm(data) {
        return http.post("/user/verifyPaytm", data);
    }

    contractorInvoice(data) {
        return http.post("/admin/contractorInvoice", data);
    }

    getCabReports(data) {
        return http.post(`/user/cabBooking`, data);
    }

}

export default new ReportsServices();