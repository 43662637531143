import React from 'react'
import { Box, Typography, Card } from "@mui/material";
import SessionTable from './employeeTable';

const EmployeeTableIndex = ({ cardData, tblData }) => {

    return (
        <>

            <Box display='flex' justifyContent='right' >
                <Box display='flex' justifyContent='center' alignItems='center' bgcolor='common.black' height='37px' width='100px' borderRadius="6px 6px 0px 0px">
                    <Typography variant="t18_800" color='common.white'>
                        {cardData?.Quantity}
                    </Typography>
                </Box>
            </Box>

            <Box display='flex' >
                <Box display='flex' justifyContent='center' alignItems='center' bgcolor='primary.main' width='43px' borderRadius="6px 0px 0px 6px" >
                    <Typography variant="t14_600__18" color='common.white' style={{ transform: "rotate(-90deg)" }} >
                        {cardData?.cMealName}
                    </Typography>
                </Box>
                <Card sx={{ borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`, borderRight: (theme) => `1px solid ${theme.palette.grey[300]}`, width: '100%', height: '100%', borderRadius: "0px 0px 6px 0px" }}>
                    <SessionTable tableData={tblData} />
                </Card>
            </Box>
        </>
    )
}

export default EmployeeTableIndex