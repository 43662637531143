import React from "react";
import { useState } from "react";
import TableHead from "./tableHeader";
import Scrollbar from "../../../../../components/Scrollbar";
import ChildTableRow from "./tableRow";
import { useSelector } from "react-redux";

// material
import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TableContainer
} from "@mui/material";

const TABLE_HEAD = [
  { id: "2", label: "Order No", alignRight: false },
  { id: "3", label: "Date", alignRight: false },
  //   { id: "4", label: "Meal Type", alignRight: false },
  { id: "5", label: "Status", alignRight: false },
  { id: "6", label: "Cafeteria", alignRight: false },
  { id: "7", label: "Amount", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EmployeeManagementTable = ({ groupOrder, page, setPage }) => {

  const { CurrentOrderReducers: { orderHistory }} = useSelector(state => state)

  let firstIndex = 0

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("empName");
  const [rowsPerPage] = useState(5);
  const [filterName] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  //   const arr = [{ id: '1', orderNo: '#258', date: 'May 27, 2022  08.22 AM', mealType: 'Employee', status: 'Delivered', amount: '48.00' },
  //   { id: '2', orderNo: '#258', date: 'May 27, 2022  08.22 AM', mealType: 'Employee', status: 'Delivered', amount: '48.00' },
  //   ]

  // const arr = Object.keys(groupOrder);

  const dataTable = applySortFilter(
    orderHistory,
    getComparator(order, orderBy),
    filterName
  );
  const isUserNotFound = dataTable?.length === 0;

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, overflow: "unset" }}>
          <Table>
            <TableHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              //   rowCount={USERLIST.length}
              //   numSelected={selected.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {dataTable
                .slice(
                  firstIndex + rowsPerPage * (page - 1),
                  rowsPerPage * page
                )
                .map((m, i) => {
                  return <ChildTableRow index={i} rowsPerPage={rowsPerPage} page={page} key={m.nID} rowData={m} />;
                })}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow display='flex'>
                  <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                    <Typography variant='t18_500' >
                      No records found
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box spacing={2} display="flex" justifyContent="right" paddingTop="20px">
        <Pagination
          shape="rounded"
          color="primary"
          count={Math.ceil(dataTable?.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
    </>
  );
};

export default EmployeeManagementTable;
