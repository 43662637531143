import React, { useEffect, useRef } from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CategoryCard from "../../../components/user/categoryCard";
import Dishes from "./dishes";
import { useDispatch, useSelector } from "react-redux";
import {
  cafeMenusWatcher,
  getDaysOfWeekWatcher,
  // getMealListWatcher,
  getMenuListWatcher,
} from "../../../redux/actionCreators/user/home";
import { getCurDate } from "../../../utils/common";
import SummaryDrawer from "../../../components/user/summaryDrawer";
import Scrollbar from "../../../components/Scrollbar";
import useResponsive from "../../../hooks/useResponsive";

const CategoryPage = () => {
  const {
    UserHomeReducers: { categoryList, cafeteriaId },
    ProfileReducers: {loginState}
  } = useSelector((state) => state);

  const [activeCateg, setActiveCateg] = React.useState("");
  const [value, setValue] = React.useState({});
  const tempinitsform = useRef();
  const tempinitform = useRef();
  const tempform = useRef();

  const isMobile = useResponsive('down', 'sm');

  const dispatch = useDispatch();

  const nUserID = loginState[0]?.nID || 0

  const handleChange = (nID) => {
    setActiveCateg(nID);
  };

  const initsform = () => {
    dispatch(getMenuListWatcher({ Action: "MealMenuList" }));
    const getDate = getCurDate();

    const dateData = {
      cCurrentDay: getDate,
    };
    dispatch(getDaysOfWeekWatcher(dateData));
  };

  tempinitsform.current = initsform;

  useEffect(() => {
    tempinitsform.current();
  }, []);


  // useEffect(() => {
  //   dispatch(getMenuListWatcher({ Action: "MealMenuList" }));
  //   const getDate = getCurDate();

  //   const dateData = {
  //     cCurrentDay: getDate,
  //   };
  //   dispatch(getDaysOfWeekWatcher(dateData));
  // }, []);

  const initform = () => {
    categoryList.length && setActiveCateg(categoryList[0].nID)
  };

  tempinitform.current = initform;

  useEffect(() => {
    tempinitform.current();
  }, [categoryList]);

  // useEffect(()=>{
  //   categoryList.length && setActiveCateg(categoryList[0].nID)
  // },[categoryList])

  const inits = () => {
    if (activeCateg && Object.keys(value).length) {
      const data = {
        "ACTION":"MealSELECT",
        "NID": cafeteriaId,
        "MEALID":  `${activeCateg}`,
        "UserId": nUserID,
        "orderDate": value.formatDate
    }
      // dispatch(getMealListWatcher(data))
      dispatch(cafeMenusWatcher(data))
    }
  };

  tempform.current = inits;

  useEffect(() => {
    tempform.current();
  }, [activeCateg, cafeteriaId, value]);

  // useEffect(() => {
  //   if (activeCateg && Object.keys(value).length) {
  //     const data = {
  //       "ACTION":"MealSELECT",
  //       "NID": cafeteriaId,
  //       "MEALID":  `${activeCateg}`,
  //       "UserId": nUserID,
  //       "orderDate": removeSymbolDate(value.date)
  //   }
  //     // dispatch(getMealListWatcher(data))
  //     dispatch(cafeMenusWatcher(data))
  //   }
  // }, [activeCateg, cafeteriaId, value])

  return (
    <Box p="24px" pb='0px'>
      <Box display="flex" mb='24px' flexDirection="column">
        <Typography variant="t13_500" sx={{ color: "grey.600" }}>
          Home
        </Typography>

        <Box mt="11px" display="flex" alignItems='center'>
          <Typography  variant="t24_700">
            Category
          </Typography>
          <Box ml='auto'>
          <SummaryDrawer />

          </Box>
        </Box>
      </Box>

      <Box height={isMobile ? 'auto' : 'calc(100vh - 182px)'}>
        <Scrollbar >
          <Box>
            <Grid container spacing={2}>
              {categoryList.map((d, i) => (
                <Grid
                  item
                  lg={2.4}
                  md={2.4}
                  sm={
                    categoryList.length - 1 === i || categoryList.length - 2 === i
                      ? 6
                      : 4
                  }
                  xs={12}
                  onClick={() => handleChange(d.nID)}
                  key={d.nID}
                >
                  <CategoryCard selectedDate={value.formatDate} activeCateg={activeCateg} value={d} />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box pb='24px'>
            <Dishes value={value} setValue={setValue} activeCateg={activeCateg} />
          </Box>
        </Scrollbar>
      </Box>
    </Box>
  );
};

export default CategoryPage;
