import React from 'react';

// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer
} from '@mui/material';
import CabBookingChildTableHead from './cabBookingChildHead';


export const SUB_TABLE_HEAD = [
    { id: 'Gender', label: 'Gender', alignRight: false, title: 'Gender', field: 'Gender' },
    { id: 'HREmailID', label: 'HR Email ID', alignRight: false, title: 'HREmailID', field: 'HREmailID' },
    { id: 'ReportingAddress', label: 'Reporting Address', alignRight: false, title: 'ReportingAddress', field: 'ReportingAddress' },
    { id: 'ReportingTimeFrom', label: 'Reporting Time From', alignRight: false, title: 'ReportingTimeFrom', field: 'ReportingTimeFrom' }, 
    { id: 'ReportTimeTo', label: 'Report Time To', alignRight: false, title: 'ReportTimeTo', field: 'ReportTimeTo' }, 
    { id: 'SpecialInstructions', label: 'Special Instructions', alignRight: false, title: 'SpecialInstructions', field: 'SpecialInstructions' }, 
    { id: 'SupervisiorEmailID', label: 'Supervisior Email ID', alignRight: false, title: 'SupervisiorEmailID', field: 'SupervisiorEmailID' },
    { id: 'TravelDestinationFrom', label: 'Travel Destination From', alignRight: false, title: 'TravelDestinationFrom', field: 'TravelDestinationFrom' },
    { id: 'TravelDestinationTo', label: 'Travel Destination To', alignRight: false, title: 'TravelDestinationTo', field: 'TravelDestinationTo' },
    { id: 'TypeOfTrip', label: 'Type Of Trip', alignRight: false, title: 'TypeOfTrip', field: 'TypeOfTrip' },
    { id: 'UsageType', label: 'Usage Type', alignRight: false, title: 'UsageType', field: 'UsageType' },
];

const CabBookingChildTable = ({ expandOpen, rowData, order, orderBy, handleRequestSort }) => {

    // const { EmployeeReducers: { accessPointList, divisionList, departmentNameList, categoryList } } = useSelector((state) => state)

    // let division = divisionList.find(m => m.id === rowData.nDivisionID) || {}
    // let category = categoryList.find(m => m.name === rowData.categoryName) || {}
    // let department = departmentNameList.find(m => m.name === rowData.departmentName) || {}
    // let getAccesspointNames = rowData.nAccessPoint?.split(',') || []
    // let accessPoints = accessPointList.filter(m => getAccesspointNames.includes(m.name)) || []

    return (
        <>
            {expandOpen &&
                <TableContainer sx={{ overflow: 'unset' }}>
                    <Table>
                        <CabBookingChildTableHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={SUB_TABLE_HEAD}
                            //   rowCount={USERLIST.length}
                            //   numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            <TableRow
                                // hover
                                tabIndex={-1}
                                sx={{ '& > *': { borderBottom: 'unset' } }}
                            >
                                <>
                                    <TableCell align="left" >
                                        <Typography variant='t14_600'>
                                            {rowData.Gender}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" >
                                        <Typography variant='t14_600'>
                                            {rowData.HREmailID}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" >
                                        <Typography variant='t14_600'>
                                            {rowData.ReportingAddress}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" >
                                        <Typography variant='t14_600'>
                                            {rowData.ReportingTimeFrom}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" >
                                        <Typography variant='t14_600'>
                                            {rowData.ReportTimeTo}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" >
                                        <Typography variant='t14_600'>
                                            {rowData.SpecialInstructions}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" >
                                        <Typography variant='t14_600'>
                                            {rowData.SupervisiorEmailID}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" >
                                        <Typography variant='t14_600'>
                                            {rowData.TravelDestinationFrom}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" >
                                        <Typography variant='t14_600'>
                                            {rowData.TravelDestinationTo}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" >
                                        <Typography variant='t14_600'>
                                            {rowData.TypeOfTrip}
                                        </Typography>
                                    </TableCell> 
                                    <TableCell align="left" >
                                        <Typography variant='t14_600'>
                                            {rowData.UsageType}
                                        </Typography>
                                    </TableCell>
                                </>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    )
}

export default CabBookingChildTable;