import React, { useEffect, useRef } from 'react';
import { Typography, Grid, Button, Card, Autocomplete, Stack, Box } from "@mui/material";
import CssTextField from '../../../overrides/longSearchField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchField from '../../../overrides/longSearchField';
import { getConvertedDate3 } from '../../../../utils/common';
import { useFormik, FormikProvider, Form } from 'formik';
import * as Yup from 'yup';
import { createRosterWatcher } from '../../../../redux/actionCreators/admin/rosterManagement';
import { updateAlert } from '../../../../redux/actionCreators/alert';
import { useDispatch, useSelector } from 'react-redux';
import CloseButton from '../../../overrides/closeButton';

export default function NewRoster({ setPageChange, setPage }) {

    const dispatch = useDispatch()
    const { EmployeeReducers: { vendorName, cafeteriaName }, RosterReducers: { rosterList } } = useSelector((state) => state)
    const tempinitsform = useRef();

    const handleFromChange = (e) => {
        let convertedDate = getConvertedDate3(e)
        setFieldValue("FROMDATE", convertedDate)
    }

    const handleToChange = (e) => {
        let convertedDate = getConvertedDate3(e)
        setFieldValue("TODATE", convertedDate)
    }

    const pages = () => {
        setPageChange(false)
    }

    const Schema = Yup.object().shape({
        // dateField: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('user type is required'),
        FROMDATE: Yup.date('Select From Date').required('Select From Date'),
        TODATE: Yup.date('Select From Date').required('Select To Date').test("Invalid Date", "Invalid Date", (data) => {
            if (new Date(formik.values.FROMDATE) > data) {
                return false
            }
            return true
        }),
        cafeteria: Yup.string('Select cafeteria').min(1, 'Too Short!').max(50, 'Too Long!').required('Choose a Cafeteria'),
        vendor: Yup.string('Select vendor').min(1, 'Too Short!').max(50, 'Too Long!').required('Choose a Vendor'),
    });

    const formik = useFormik({
        initialValues: {
            cafeteria: '',
            vendor: '',
            FROMDATE: '',
            TODATE: '',
            filteredCafeteria: []
        },

        validationSchema: Schema,

        onSubmit: () => {
            let values = { ...formik.values }
            let data = {
                "ACTION": "INSERT",
                "CAFETERIA": values.cafeteria,
                "VENDOR": +values.vendor,
                "FROMDATE": values.FROMDATE,
                "TODATE": values.TODATE
            }
            const availableDate = (rosterList.length && rosterList.find(m => ((((new Date(data.TODATE) >= new Date(m.fromDate.slice(0, 10))) && (new Date(data.TODATE) <= new Date(m.ToDate.slice(0, 10)))) ||
                ((new Date(data.FROMDATE) >= new Date(m.fromDate.slice(0, 10))) && (new Date(data.FROMDATE) <= new Date(m.ToDate.slice(0, 10)))))
                && (+m.CafeteriaID === +data.CAFETERIA)))) || {}

            // const availableDate = rosterList.length && rosterList.find(m => ((new Date(data.FROMDATE) <= new Date(m.fromDate.slice(0, 10)) && new Date(data.TODATE) >= new Date(m.fromDate.slice(0, 10))) ||
            //     (new Date(data.FROMDATE) <= new Date(m.ToDate.slice(0, 10)) && new Date(data.TODATE)) >= new Date(m.ToDate.slice(0, 10))) && +m.CafeteriaID === data.CAFETERIA) || {}

            if (Object.values(availableDate).length) {
                dispatch(updateAlert("Selected date is not available", "error"))
            }
            else {
                dispatch(createRosterWatcher(data, setPageChange))
            }
        }
    });

    const { errors, touched, setFieldValue, values } = formik;

    const initsform = () => {
        if (cafeteriaName.length > 0 && values.TODATE && values.FROMDATE) {

            const availableDate = (rosterList.length && rosterList.filter(m => (((new Date(values.TODATE) >= new Date(m.fromDate.slice(0, 10))) && (new Date(values.TODATE) <= new Date(m.ToDate.slice(0, 10)))) ||
                ((new Date(values.FROMDATE) >= new Date(m.fromDate.slice(0, 10))) && (new Date(values.FROMDATE) <= new Date(m.ToDate.slice(0, 10))))))) || []

            // const availableDate = rosterList.length && rosterList.filter(m => ((new Date(values.FROMDATE) <= new Date(m.fromDate.slice(0, 10)) && new Date(values.TODATE) >= new Date(m.fromDate.slice(0, 10))) ||
            // (new Date(values.FROMDATE) <= new Date(m.ToDate.slice(0, 10)) && new Date(values.TODATE)) >= new Date(m.ToDate.slice(0, 10)))) || []

            const convertedData = availableDate.map(m => +m.CafeteriaID)
            let availableCafeteria = cafeteriaName.filter(m => !convertedData.includes(+m.id))
            setFieldValue("filteredCafeteria", availableCafeteria)
        }
    };
    
    tempinitsform.current = initsform;
    
    useEffect(() => {
       tempinitsform.current();
    }, [values.FROMDATE, values.TODATE]);

    // useEffect(() => {
    //     if (cafeteriaName.length > 0 && values.TODATE && values.FROMDATE) {

    //         const availableDate = rosterList.length && rosterList.filter(m => (((new Date(values.TODATE) >= new Date(m.fromDate.slice(0, 10))) && (new Date(values.TODATE) <= new Date(m.ToDate.slice(0, 10)))) ||
    //             ((new Date(values.FROMDATE) >= new Date(m.fromDate.slice(0, 10))) && (new Date(values.FROMDATE) <= new Date(m.ToDate.slice(0, 10)))))) || []

    //         // const availableDate = rosterList.length && rosterList.filter(m => ((new Date(values.FROMDATE) <= new Date(m.fromDate.slice(0, 10)) && new Date(values.TODATE) >= new Date(m.fromDate.slice(0, 10))) ||
    //         // (new Date(values.FROMDATE) <= new Date(m.ToDate.slice(0, 10)) && new Date(values.TODATE)) >= new Date(m.ToDate.slice(0, 10)))) || []

    //         const convertedData = availableDate.map(m => +m.CafeteriaID)
    //         let availableCafeteria = cafeteriaName.filter(m => !convertedData.includes(+m.id))
    //         setFieldValue("filteredCafeteria", availableCafeteria)
    //     }

    // }, [values.FROMDATE, values.TODATE])

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                    <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "16px 16px 0px 0px", padding: '20px' }}>
                        <Grid container spacing={3} sx={{ padding: '30px' }} >
                            <Grid item lg={6} xs={6} sx={{ justifyContent: 'left' }}>
                                <div>
                                    <Typography variant='t20_600'>Add Roster</Typography>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} display='flex' flexDirection='row' justifyContent='left' sx={{ padding: '30px' }}>
                            <Grid item
                                lg={6}
                                xs={6}
                                md={6}
                            >
                                <Box pb='20px'>
                                    <Typography variant='t14_600'> Select Date </Typography>
                                </Box>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            disablePast
                                            value={values.FROMDATE}
                                            label='From Date'
                                            name="FROMDATE"
                                            onChange={handleFromChange}
                                            renderInput={(params) => <SearchField sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    height: 50
                                                },
                                                '& label': {
                                                    top: -2
                                                }
                                            }} fullWidth {...params}
                                                error={Boolean(touched.FROMDATE && errors.FROMDATE)}
                                                helperText={touched.FROMDATE && errors.FROMDATE}
                                            />}
                                        />
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            disablePast
                                            value={values.TODATE}
                                            label='To Date'
                                            name="TODATE"
                                            onChange={handleToChange}
                                            renderInput={(params) => <SearchField sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    height: 50
                                                },
                                                '& label': {
                                                    top: -2
                                                }
                                            }} fullWidth {...params}
                                                error={Boolean(touched.TODATE && errors.TODATE)}
                                                helperText={touched.TODATE && errors.TODATE}
                                            />}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                            </Grid>

                            <Grid item lg={6} xs={6} md={6}>
                                <Box pb='20px'>
                                    <Typography variant='t14_600'> Cafeteria </Typography>
                                </Box>
                                <Autocomplete
                                    id="combo-box-demo"
                                    name="cafeteria"
                                    fullWidth
                                    options={values.filteredCafeteria}
                                    size="small"
                                    autoHighlight
                                    onChange={(event, value) => {
                                        setFieldValue("cafeteria", value.id);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <CssTextField {...params}
                                        placeholder="Cafeteria"
                                        name='cafeteria'
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: 50
                                            },
                                        }}
                                        error={Boolean(touched.cafeteria && errors.cafeteria)}
                                        helperText={touched.cafeteria && errors.cafeteria}
                                    />}
                                />
                            </Grid>
                            <Grid item lg={6} xs={6} md={6}>
                                <Box pb='15px'>
                                    <Typography variant='t14_600'> Vendor </Typography>
                                </Box>
                                <Autocomplete
                                    id="combo-box-demo"
                                    name="vendor"
                                    fullWidth
                                    options={vendorName}
                                    size="small"
                                    autoHighlight
                                    onChange={(event, value) => {
                                        setFieldValue("vendor", value.id);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <CssTextField {...params}
                                        placeholder="Vendor"
                                        name='vendor'
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: 50
                                            },
                                        }}
                                        error={Boolean(touched.vendor && errors.vendor)}
                                        helperText={touched.vendor && errors.vendor}
                                    />}
                                />
                            </Grid>
                        </Grid>

                        <Grid container
                            display='flex'
                            flexDirection='row'
                            justifyContent='flex-end'
                            spacing={2}
                            sx={{ padding: '30px' }}
                        >
                            <Grid item >
                                <CloseButton sx={{
                                    width: '120px',
                                    height: '42px',
                                    left: '0px',
                                    top: '0px',
                                }} variant='contained' type='button'
                                    onClick={pages}>
                                    <Typography variant='t14_400' color='common.black'> Cancel </Typography>
                                </CloseButton>
                            </Grid>
                            <Grid item >
                                <Button sx={{
                                    width: '120px',
                                    height: '42px',
                                    left: '0px',
                                    top: '0px',
                                    backgroundColor: 'common.Bblack',
                                    '&:hover':{
                                    backgroundColor: 'common.Bblack',

                                    }
                                }} variant='contained' color='primary' type='submit'>
                                    <Typography variant='t14_400' color='inherit'>Submit</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Form>
            </FormikProvider>
        </>
    )
}
