import React from 'react';
import {
    TableCell,
    Typography,
    TableRow,
    Rating
} from '@mui/material';


const RatingTableRow = ({ rowData }) => {

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                <TableCell align="left" sx={{ width: '470px' }}>
                    <Typography variant='t14_600'>
                        {rowData.EmployeeName}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '300px' }}>
                    <Typography variant='t14_600'>
                        {rowData.MenuName}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '340px' }}>
                    <Rating size='small' name="read-only" value={rowData.Rating} readOnly />
                </TableCell>
                <TableCell align="left" sx={{ width: '340px' }}>
                    <Typography variant='t14_600'>
                        {rowData.RateDate}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    )
}

export default RatingTableRow