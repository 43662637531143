import { Box, Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import Breadcrumbs from '../../../components/breadcrumbs'

export default function AboutUs() {
  return (
    <>
      <Box p='30px'>
        <Breadcrumbs content={["About Us"]} />

        <Card sx={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "6px 6px 0px 0px", padding: { md: '20px', xs: '10px' }, mt: '30px' }}>
          <CardContent >
            <Typography variant='t24_600'>About Us</Typography>
          </CardContent>
          <CardContent>
            <Typography variant='t14_400'>
              In India S2V Group provides Catering and Support Services including extensive outdoor catering expertise to clients in a wide variety of sectors and marketplaces. Everyone in S2V is committed to consistently delivering superior service in the most efficient way, for the shared benefit of our clients, customers, employees, and other stakeholders.
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant='t18_600'>Our Vision – </Typography>
            <Typography variant='t14_400'>
              sets out what we want to be To be a world-class provider of contract foodservice and support services , renowned for our great people, our great service, and our great results.
            </Typography>
          </CardContent>

          <CardContent>
            <Typography variant='t18_600'>Our Values – </Typography>
            <Typography variant='t14_400'>
              these set out what we collectively believe in and guide our behaviours ,can-do Safely, Responsibility, Win Through Teamwork, Passion for Quality, Openness, Trust and Integrity.
            </Typography>
          </CardContent>

          <CardContent>
            <Typography variant='t18_600'>Our Mission – </Typography>
            <Typography variant='t14_400'>
              sets out how we are going to achieve this Everyone in Vassi is committed to consistently delivering superior service in the most efficient way, for the shared benefit of our customers and employees.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}
