import React, { useState } from 'react';
import {
    TableCell,
    Typography,
    TableRow
} from '@mui/material';
import ActionButtons from '../../reports/actionButton';
import { getUserHistoryViewWatcher } from '../../../../redux/actionCreators/admin/reports';
import { useDispatch } from 'react-redux'


const UserHistoryTableRow = ({ rowData, setPageChange }) => {
    const dispatch = useDispatch();

    const [status] = useState('list');

    const viewFunction = () => {
        setPageChange(true);

        let data1 = {
            "ACTION": "GetUserFoodDetailsReport",
            "UserId": rowData.UserName,
            "Date": rowData.OrderDate?.split('/').reverse().join('-')
        }
        dispatch(getUserHistoryViewWatcher(data1))
    }

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                <TableCell align="left">
                    <Typography variant='t14_600' >
                        {rowData.EmployeeName}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.UserName}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Users}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.OrderDate}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Breakfast || 0}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Dinner || 0}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Supper || 0}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Lunch || 0}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Snacks || 0}
                    </Typography>
                </TableCell>
                <TableCell align="right">
                    <ActionButtons type={'userHistory'} status={status} viewFunction={viewFunction} />
                </TableCell>
            </TableRow>
        </>
    )
}

export default UserHistoryTableRow