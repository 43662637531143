import React, { useEffect, useRef } from 'react'
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography } from "@mui/material";
import DayByDayHeader from '../../../components/admin/reports/dayByDayOrder/headerCard';
import TableIndex from '../../../components/admin/reports/dayByDayOrder/Index';
import { getBreakfastDayByDayOrderWatcher, getLunchDayByDayOrderWatcher, getDinnerByDayOrderWatcher, getSnacksByDayOrderWatcher, getSupperByDayOrderWatcher } from '../../../redux/actionCreators/admin/reports';
import { useDispatch, useSelector } from 'react-redux'
import { getCurDate } from "../../../utils/common";

export default function DayByDayOrder() {

    const { ReportsReducers: { DinnerList, BreakfastList, SupperList, LunchList, SnacksList } } = useSelector((state) => state)
    const dispatch = useDispatch();
    const getDate = getCurDate();
    const tempinitsform = useRef();

    const initsform = () => {
        let Bdata = {
            "Action": "GetDayByDay",
            "Mela": "Breakfast",
            "Date": getDate
        }
        dispatch(getBreakfastDayByDayOrderWatcher(Bdata))

        let Ldata = {
            "Action": "GetDayByDay",
            "Mela": "Lunch",
            "Date": getDate
        }
        dispatch(getLunchDayByDayOrderWatcher(Ldata))

        let Sdata = {
            "Action": "GetDayByDay",
            "Mela": "Snacks",
            "Date": getDate
        }
        dispatch(getSnacksByDayOrderWatcher(Sdata))

        let Ddata = {
            "Action": "GetDayByDay",
            "Mela": "Dinner",
            "Date": getDate
        }
        dispatch(getDinnerByDayOrderWatcher(Ddata))

        let SRdata = {
            "Action": "GetDayByDay",
            "Mela": "Supper",
            "Date": getDate
        }
        dispatch(getSupperByDayOrderWatcher(SRdata))
    };
    
    tempinitsform.current = initsform;
    
    useEffect(() => {
       tempinitsform.current();
    }, []);

    // useEffect(() => {
    //     let Bdata = {
    //         "Action": "GetDayByDay",
    //         "Mela": "Breakfast",
    //         "Date": getDate
    //     }
    //     dispatch(getBreakfastDayByDayOrderWatcher(Bdata))

    //     let Ldata = {
    //         "Action": "GetDayByDay",
    //         "Mela": "Lunch",
    //         "Date": getDate
    //     }
    //     dispatch(getLunchDayByDayOrderWatcher(Ldata))

    //     let Sdata = {
    //         "Action": "GetDayByDay",
    //         "Mela": "Snacks",
    //         "Date": getDate
    //     }
    //     dispatch(getSnacksByDayOrderWatcher(Sdata))

    //     let Ddata = {
    //         "Action": "GetDayByDay",
    //         "Mela": "Dinner",
    //         "Date": getDate
    //     }
    //     dispatch(getDinnerByDayOrderWatcher(Ddata))

    //     let SRdata = {
    //         "Action": "GetDayByDay",
    //         "Mela": "Supper",
    //         "Date": getDate
    //     }
    //     dispatch(getSupperByDayOrderWatcher(SRdata))
    // }, [])

    const mergeResult = [BreakfastList, LunchList, SnacksList, DinnerList, SupperList];

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports", "Day By Day Order"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Day By Day Order</Typography>
                </Box>

                <Box p='10px 0'>
                    <DayByDayHeader />
                </Box>

                {
                    mergeResult.map((m, i) => {
                        return <Box p='10px 0' key={i}>
                            <TableIndex cardData={m} />
                        </Box>
                    })
                }
            </Box>
        </>
    )
}
