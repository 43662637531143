import React from 'react';
import { Card, Grid, Button, InputAdornment, Typography, Stack, Autocomplete } from "@mui/material";
import SearchField from '../../../components/overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getConvertedDate } from '../../../utils/common';
import { ExportCsv } from "@material-table/exporters";
// import { getDeliveryHistoryWatcher } from '../../../redux/actionCreators/admin/reports';
import CssTextField from '../../../components/overrides/coloredTextField';
import { useSelector } from 'react-redux';

const DeliveryHistoryHeader = ({ handleSearch, searchText, handleSubmitAction, rowData, columnData }) => {
    const { EmployeeReducers: { cafeteriaName } } = useSelector((state) => state);
    // const nUserID = loginState[0]?.nID || 0
    // const dispatch = useDispatch()
      
    // const [open, setOpen] = useState(false);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);
    const [cafeteriaId, setCafeteriaId] = React.useState(0);

    const handleFromChange = (e) => {
        setFromDate(e)
    }

    const handleToChange = (e) => {
        setToDate(e)
    }

    const filterByCafe = (val) => {
        setCafeteriaId(val)
    }
    const defaultVal = {name:'Select cafeteria', id: 0}

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >
                    <Grid item lg={3} xs={12} md={8} sm={7} sx={{ justifyContent: 'left' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: 50
                                }
                            }}
                            placeholder='Search'
                            fullWidth
                            onChange={handleSearch}
                            value={searchText}
                        />
                    </Grid>
                    <Grid item lg={2.3} xs={12} md={4} sm={5} sx={{ justifyContent: 'left', color: 'common.black' }} >
                    <Autocomplete
                                sx={{ minWidth: '150px' }}
                                disablePortal
                                id="combo-box-demo"
                                name="selectMeal"
                                fullWidth
                                options={cafeteriaName}
                                defaultValue={defaultVal}
                                size="medium"
                                autoHighlight
                                onChange={(event, value) => {
                                    filterByCafe(value.id);
                                }}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => <CssTextField sx={{
                                    '& .MuiOutlinedInput-input': {
                                        height: '16px',
                                        color: 'common.white',
                                    },
                                }}
                                // label="Select Cafeteria"
                                 {...params} 
                                 />}
                            />
                            </Grid>
                    <Grid item lg={3.5} xs={12} md={7} sm={9} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={fromDate}
                                    label='From Date'
                                    onChange={handleFromChange}
                                    renderInput={(params) => <SearchField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: 50
                                        },
                                        '& label': {
                                            fontSize: '.9rem'
                                        }
                                    }} fullWidth {...params} />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={toDate}
                                    label='To Date'
                                    onChange={handleToChange}
                                    renderInput={(params) => <SearchField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: 50
                                        },
                                        '& label': {
                                            fontSize: '.9rem'
                                        }
                                    }} fullWidth {...params} />}
                                />
                            </LocalizationProvider>
                        </Stack>
                    </Grid>
                    <Grid item lg={1.2} xs={12} md={2} sm={3} sx={{ justifyContent: 'center' }} >
                        <Button variant='contained' sx={{ height: 47, backgroundColor: 'common.Bblack', '&:hover':{backgroundColor: 'common.Bblack'} }} fullWidth endIcon={<DoubleArrowIcon />} onClick={() => handleSubmitAction(getConvertedDate(fromDate), getConvertedDate(toDate), cafeteriaId)}>
                            <Typography variant='t16_400_1' color='common.white'>Submit</Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={2} xs={12} md={3} sm={12} sx={{ justifyContent: 'center' }} >
                        <Button variant='contained' fullWidth sx={{ height: '50px' }} endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(columnData, rowData, "Guest Delivery History")}>
                            <Typography variant='t16_500'>Export to Excel</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default DeliveryHistoryHeader;