import React from 'react';
import { Card, Grid, Button, InputAdornment, Stack, Autocomplete, Typography, Box } from "@mui/material";
import SearchField from '../../../overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { List } from '../../../List';
import BlackButton from '../../../overrides/blackButton'
import CssTextField from '../../../overrides/coloredTextField';
import { getConvertedDate } from '../../../../utils/common';
import { ExportCsv } from "@material-table/exporters";
import { TABLE_HEAD } from '../../../admin/reports/saleReport/saleReportTable'
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { Opt } from '../../../swipeScreen/employee';
// import { useFormik, Form, FormikProvider } from 'formik';

const SaleReportHeader = ({ handleSearch, searchText, handleSubmitAction, rowData, columnData, selectedTypeHandler }) => {

    // const [open, setOpen] = useState(false);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);

    const { ReportsReducers: { SaleReportList } } = useSelector((state) => state)

    const handleFromChange = (e) => {
        setFromDate(e)
    }

    const handleToChange = (e) => {
        setToDate(e)
    }

    const makeHeader = (type) => {
        const arr = []
        TABLE_HEAD(type).forEach(d => {
            arr.push(d.label)
        })
        return arr
    }

    const brHead = makeHeader('Breakfast')
    const dnHead = makeHeader('Dinner')
    const lnHead = makeHeader('Lunch')
    const snHead = makeHeader('Snacks')
    const erHead = makeHeader('Supper')

    const makeValue = (type) => {
        const getMeal = SaleReportList[0]?.find(d => type === d.cMealName) || {}
        const rowVal = SaleReportList[1]?.filter(m => m.nMealID === getMeal.nID) || []
        return rowVal.map(d => [d.cMenuName, d.Rate, d.BCP, d.OTHERS, d.Quantity, d.nTotalRate, d.Gst, d.ToatlRate])
    }

    const brRow = makeValue('Breakfast')
    const dnRow = makeValue('Dinner')
    const lnRow = makeValue('Lunch')
    const snRow = makeValue('Snacks')
    const erRow = makeValue('Supper')

    const data = [
        ["", "BreakFast", ""],
        brHead,
        ...brRow,
        ["", "Dinner", ""],
        dnHead,
        ...dnRow,
        ["", "Supper", ""],
        lnHead,
        ...lnRow,
        ["", "Lunch", ""],
        snHead,
        ...snRow,
        ["", "Snacks", ""],
        erHead,
        ...erRow,
    ];

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={2} sx={{ padding: '30px', display: 'flex' }} >
                    <Grid item lg={2} xs={12} md={2} sm={12} sx={{ justifyContent: 'left' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px'
                                }
                            }}
                            placeholder='Search'
                            fullWidth
                            onChange={handleSearch}
                            value={searchText}
                        />
                    </Grid>
                    <Grid item lg={10} xs={12} md={10} sm={12} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={fromDate}
                                    label='From Date'
                                    onChange={handleFromChange}
                                    renderInput={(params) => <SearchField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '48px'
                                        },
                                        '& label': {
                                            fontSize: '.9rem'
                                        }
                                    }} fullWidth {...params} />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={toDate}
                                    label='To Date'
                                    onChange={handleToChange}
                                    renderInput={(params) => <SearchField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '48px'
                                        },
                                        '& label': {
                                            fontSize: '.9rem'
                                        }
                                    }} fullWidth {...params} />}
                                />
                            </LocalizationProvider>

                            <BlackButton variant='contained'
                                sx={{
                                    height: '48px',
                                    minWidth: '120px',
                                    // backgroundColor: 'common.Bblack'
                                }} onClick={() => handleSubmitAction(getConvertedDate(fromDate), getConvertedDate(toDate))}
                            >
                                <Typography variant='t16_400_1'>Submit</Typography>
                            </BlackButton>
                            <Box>
                                <Autocomplete
                                    onChange={(event, value) => {
                                        selectedTypeHandler(value.name)
                                    }}
                                    disablePortal
                                    id="combo-box-demo"
                                    name="selectMeal"
                                    //value={formValues.selectType === null ? '' : formValues.selectType}
                                    fullWidth
                                    options={Opt}
                                    defaultValue={Opt[0]}
                                    size="medium"
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <CssTextField sx={{
                                        '& .MuiOutlinedInput-input': {
                                            height: '16px',
                                            minWidth: '158px',
                                            color: 'common.white'
                                        },
                                    }} {...params} />}
                                />
                            </Box>

                            <CSVLink style={{ textDecoration: 'unset' }} data={data}>
                                <Button variant='contained' sx={{ height: '50px', minWidth: '200px' }} endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(columnData, rowData, "Sale Report")}>
                                    <Typography variant='t16_500'>Export to Excel</Typography>
                                </Button>
                            </CSVLink>

                        </Stack>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default SaleReportHeader;