//Admin
import { combineReducers } from "redux";
import ContractorReducers from "./admin/contractorManagement";
import VendorReducers from "./admin/vendorManagement";
import EmployeeReducers from "./admin/employeeManagement";
import MenuMasterReducers from "./admin/menuMaster";
import MenuItemMasterReducers from "./admin/menuItemMaster";
import ContractorBookReducers from "./admin/contractorBook";
import GuestBookReducers from "./admin/guestBook";
import OrderSummaryReducers from "./admin/orderSummary";
import ReportsReducers from "./admin/reports";
import CarouselReducers from "./admin/carousel"
import RosterReducers from "./admin/rosterManagement"
import GuestReducers from "./admin/guestManagement";

//user
import UserHomeReducers from "./user/home";
import UserFeedBackReducers from "./user/feedBack";
import UserRatingReducers from "./user/rating";
import CurrentOrderReducers from "./user/currentOrder";
import UserKioskReducers from "./user/kiosk";

//loader
import LoaderReducers from './spinner'

//profile
import ProfileReducers from "./profile"

//Food Vendor
import FoodVendorReducers from "./foodVendor/index"

//Kiosk
import KioskRoleReducers from "./kiosk"

//contractor
import ContractorRoleReducers from "./contractor";

//contractor
import GuestRoleReducers from "./guest";

//Swipe
import SwipeReducers from "./screen/screen";
import CabReducers from "./cab";

export default combineReducers({
  ContractorReducers,
  EmployeeReducers,
  VendorReducers,
  MenuMasterReducers,
  MenuItemMasterReducers,
  ContractorBookReducers,
  GuestBookReducers,
  OrderSummaryReducers,
  ReportsReducers,
  CarouselReducers,
  RosterReducers,
  GuestReducers,

  //user
  UserHomeReducers,
  UserFeedBackReducers,
  UserRatingReducers,
  CurrentOrderReducers,
  UserKioskReducers,

  //loader
  LoaderReducers,

  //profile
  ProfileReducers,

  //Food Vendor
  FoodVendorReducers,

  //Kiosk
  KioskRoleReducers,

  //contractor
  ContractorRoleReducers,

  //guest
  GuestRoleReducers,

  //Screen
  SwipeReducers,

  //Cab
  CabReducers,
  
});
