import React, { useEffect, useState } from 'react';
import ToggleButton from '../../../components/admin/dashboard/orderSummary/toggleButton';
import { Typography, Card, Grid, Box } from "@mui/material";
import IconCard from '../../../components/admin/dashboard/orderSummary/iconCard'
import CardTemp from '../../../components/admin/dashboard/orderSummary/card';
import MealCount from '../../../components/admin/dashboard/orderSummary/session/index';
import { useDispatch } from 'react-redux';
import { getLastDayOrderSummaryWatcher } from '../../../redux/actionCreators/admin/orderSummary';
import { getDropDownWatcher } from '../../../redux/actionCreators/admin/employeeManagement';

const OrderSummary = ({ LastDayData }) => {
    const dispatch = useDispatch();
    const [tabVal, setTabval] = useState(1);
    const [dateChange, setDateChange] = useState(new Date())

    useEffect(() => {
        let data = {
            nCafeteriaId: 0
        }
        dispatch(getLastDayOrderSummaryWatcher(data));
        dispatch(getDropDownWatcher());
    }, [dispatch])

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}` }}>
                <Grid container spacing={3} sx={{ padding: '30px' }} >
                    <Grid item lg={6} xs={12} sx={{ justifyContent: 'left' }}>
                        <div>
                            <Typography variant='t20_600'>Orders Summary</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={{ justifyContent: 'right', display: 'flex' }}>
                        <ToggleButton dateChange={dateChange} setDateChange={setDateChange} setTabval={setTabval} />
                    </Grid>
                </Grid>

                <Box >
                    <IconCard tabVal={tabVal} />
                </Box>

                <Box>
                    <CardTemp tabVal={tabVal} />
                </Box>

                <Box>
                    <MealCount dateChange={dateChange} dayTabVal={tabVal} />
                </Box>
            </Card>
        </>

    )
}

export default OrderSummary;