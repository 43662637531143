import { CREATE_VENDOR_WATCHER, UPDATE_VENDOR_WATCHER, DELETE_VENDOR_WATCHER, GET_VENDOR_WATCHER, VENDOR_LIST, GET_CAB_VENDOR_WATCHER, CREATE_CAB_VENDOR_WATCHER, UPDATE_CAB_VENDOR_WATCHER, DELETE_CAB_VENDOR_WATCHER } from "../../actionTypes/admin";

// Worker triggering actionCreators

export function getVendorWatcher(data) {
    return { type: GET_VENDOR_WATCHER, payload: data };
}

export function createVendorWatcher(data, setOpen) {
    return { type: CREATE_VENDOR_WATCHER, payload: data, setOpen };
}

export function updateVendorWatcher(id, data) {
    return { type: UPDATE_VENDOR_WATCHER, payload: data, id };
}

export function deleteVendorWatcher(id, data) {
    return { type: DELETE_VENDOR_WATCHER, payload: data, id };
}

export function getCabVendorWatcher(data) {
    return { type: GET_CAB_VENDOR_WATCHER, payload: data };
}

export function createCabVendorWatcher(data, setOpen) {
    return { type: CREATE_CAB_VENDOR_WATCHER, payload: data, setOpen };
}

export function updateCabVendorWatcher(data) {
    return { type: UPDATE_CAB_VENDOR_WATCHER, payload: data };
}

export function deleteCabVendorWatcher(data) {
    return { type: DELETE_CAB_VENDOR_WATCHER, payload: data };
}


// Redux state changing actionCreators
export function vendorList(data) {
    return { type: VENDOR_LIST, payload: data };
}
