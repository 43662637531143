import React, { useEffect, useState } from 'react'
import { getInvoice } from './common'
import Services from '../../../services/admin/reports'
import { useParams } from 'react-router-dom'
import { getGst, getGstOnly } from '../../../utils/gst'
import { inWords } from '../../../utils/common'
import { companyDetails } from '../../../utils/constants'
import { updateFalseLoader, updateOpenLoader } from '../../../redux/actionCreators/spinner'
import { useDispatch } from 'react-redux'
import { updateAlert } from '../../../redux/actionCreators/alert'

function Invoice() {
    const { from, to, contractorID } = useParams()
    const [invoiceData, setInvoiceData] = useState({})
    const dispatch = useDispatch()

    const fetchFun = async () => {
        const data = {
            Active: 'ContractorInvoice',
            Fdate: from,
            Tdate: to,
            Nid: contractorID
        }
        try {
            dispatch(updateOpenLoader())
            const { data: { recordset } } = await Services.contractorInvoice(data)
            dispatch(updateFalseLoader())

            let cEmployeeName = "";
            let cUserName = "";
            let nGst = 0
            let nGst1 = 0
            let Amount = 0
            let TotalAmount = 0
            let nID = "";
            let RupeesInwords = "";
            let fDateformat = "";
            let tDateformat = "";
            let info = "";

            if (recordset.length > 0) {
                info += "<tr>"
                    + "<td colspan='4' class='thick-border th'>Menu Name</td>"
                    + " <td colspan='4' class='thick-border th'> Menu Quantity</td>"
                    + " <td colspan='4' class='thick-border th'>Menu Amount</td>"
                    +
                    "</tr>";

                for (let i = 0; i < recordset.length; i++) {
                    cEmployeeName = recordset[i]["cEmployeeName"]
                    cUserName = recordset[i]["cUserName"]
                    nGst += getGstOnly(+recordset[i]["nToatlRate"]);
                    nGst1 = getGstOnly(+recordset[i]["nToatlRate"]);
                    Amount += recordset[i]["nToatlRate"]
                    TotalAmount += +recordset[i]["nToatlRate"] + +nGst1
                    nID = from.split('-')[2] + to.split('-')[2] + contractorID.padStart(6, '0')
                    RupeesInwords = inWords(`${TotalAmount}`);
                    info += "<tr>"
                        + "<td colspan='4' class='text-center bordery-0'>" + recordset[i]["cMenuName"] + "</td>"
                        + " <td colspan='4' class='text-center bordery-0'> " + recordset[i]["nQuantity"] + "</td>"
                        + " <td colspan='4' class='text-center bordery-0'> " + recordset[i]["nRate"] + "</td>"
                        +
                        "</tr>";
                    fDateformat = from
                    tDateformat = to
                }

                const obj = {
                    NO: "MAA/" + nID + "/21-22",
                    DATE: fDateformat,
                    CUSTOMER_USERNAME: cEmployeeName,
                    CUSTOMER_USERID: cUserName,
                    CUSTOMER_NAME: companyDetails.EmployeeCompanyName,
                    CUSTOMER_ADDRESS: companyDetails.EmployeeCompanyAddress,
                    CUSTOMER_GSTIN: companyDetails.EmployeeCompanyGSTIN,
                    FROM: fDateformat,
                    TO: tDateformat,
                    FOOD_AMOUNT: Amount,
                    SERVICE_AMOUNT: 0,
                    TAXABLE: Amount + 0,
                    SGST: nGst / 2,
                    CGST: nGst / 2,
                    SUBTOTAL: Amount,
                    ROUNDOFF: 0,
                    TOTAL: TotalAmount,
                    TOTAL_AS_TEXT: RupeesInwords,
                    INFO: info
                }
                setInvoiceData(obj)

            }
        } catch (e) {
            dispatch(updateFalseLoader())
            dispatch(updateAlert('Something went wrong', 'error'))
        }

    }

    useEffect(() => {
        fetchFun()
    }, [])

    useEffect(()=>{
        if(Object.keys(invoiceData).length)
        window.print();
    },[invoiceData])

    return (
        <div dangerouslySetInnerHTML={{ __html: getInvoice(invoiceData) }} ></div>
    )
}

export default Invoice