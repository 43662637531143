import styled from "@emotion/styled";
import { Button } from "@mui/material";

const CloseButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
    color: theme.palette.common.black,
  }));

  export default CloseButton