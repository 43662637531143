import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LogoutIcon from '@mui/icons-material/Logout';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
// import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Typography } from '@mui/material';
import { updateLogin } from '../../redux/actionCreators/profile';
import { updateAlert } from '../../redux/actionCreators/alert';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { getCartWatcher, getOrderTotalCostWatcher } from '../../redux/actionCreators/user/home';

export default function BottomNav() {
  // const [value, setValue] = React.useState(0);

  const {UserHomeReducers: {cartList}, ProfileReducers: {loginState}} = useSelector(state => state)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const tempinitsform = React.useRef();

  const { pathname } = useLocation();
  const isCart = !!matchPath({ path: '/user/viewCart', end: false }, pathname)
  const isProfile = !!matchPath({ path: '/user/profile', end: false }, pathname)
  // const isUser = !!matchPath({ path: '/user', end: false }, pathname)

  const handleLogout = () => {
    sessionStorage.clear()
    localStorage.clear()
    dispatch(updateLogin([]))
    dispatch(updateAlert('Successfully logged out', 'success'))
  }

  const handleCart = () => {
    if(cartList.length){
      navigate('/user/viewCart')
    }else{
      dispatch(updateAlert('Cart is empty', 'error'));
    }
  }

  const handleProfile = () => {
    navigate('/user/profile')
  }

  const nUserID = loginState[0]?.nID || 0

  const initsform = () => {
    const data = {
      ACTION: "CHECKLIST",
      nUserID,
    };
    const viewCart = {
      nUserID,
    };
    dispatch(getCartWatcher(data, viewCart));
    dispatch(getOrderTotalCostWatcher(nUserID));
  };

  tempinitsform.current = initsform;

  React.useEffect(() => {
    tempinitsform.current();
  }, []);

  // React.useEffect(() => {
  //     const data = {
  //       ACTION: "CHECKLIST",
  //       nUserID,
  //     };
  //     const viewCart = {
  //       nUserID,
  //     };
  //     dispatch(getCartWatcher(data, viewCart));
  //     dispatch(getOrderTotalCostWatcher(nUserID));
  // }, []);

  return (
    <Box>
      <BottomNavigation
        showLabels
        onChange={(event, newValue) => {
          // setValue(newValue);
        }}
        sx={{bgcolor: 'common.sideBar'}}
      >
        {/* <BottomNavigationAction sx={{color: 'common.bNav'}} onClick={handleLogout} label={
          <Typography variant='t14_400' >
            Notifications
          </Typography>
        } icon={<NotificationsNoneOutlinedIcon />} /> */}
        <BottomNavigationAction sx={{color: 'common.bNav'}} onClick={handleLogout} label={
          <Typography variant='t14_400' >
            Logout
          </Typography>
        } icon={<LogoutIcon />} />
        <BottomNavigationAction sx={{color: isCart ? 'primary.main' : 'common.bNav'}} onClick={handleCart} label={
          <Typography variant='t14_400' >
            Cart ({cartList.length})
          </Typography>
        } icon={<ShoppingCartOutlinedIcon />} />
        <BottomNavigationAction onClick={handleProfile} sx={{color: isProfile ? 'primary.main' : 'common.bNav'}} label={
          <Typography variant='t14_400' >
            My Profile
          </Typography>
        } icon={<AccountCircleOutlinedIcon />} />
      </BottomNavigation>
    </Box>
  );
}
