import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Box, Typography } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomAlert({ open, message, setOpen }) {

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false)
    };

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={3000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} icon={false} severity='success' sx={{ minWidth: "250px" }}>
                <Box display='flex' flexDirection='column' >
                    <Typography variant="t16_400">
                        {message.title}
                    </Typography>
                    <Typography variant="t14_400">
                        {message.body}
                    </Typography>
                </Box>
            </Alert>
        </Snackbar>
    );
}
