import { Button, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
// import { useState } from 'react'
import CloseButton from '../../overrides/closeButton'
import CreateDialog from '../../dialog'
import { useSelector } from "react-redux";

const ConfirmDialog = ({ openCD, setOpenCD, handleCheckout, mealType}) => {

    const {
        EmployeeReducers: { cafeteriaName },
        UserHomeReducers: { cafeteriaId },
      } = useSelector((state) => state);
    
    const findCafeName = () =>{
        const { name } = cafeteriaName?.find((d) => d.id === cafeteriaId) || {};
        return name || null
    } 

    return (
        <>
            <CreateDialog open={openCD} setOpen={setOpenCD} maxWidth='sm' >
                <Box display='flex' height='100%' width='100%' flexDirection='column' >
                    <Typography color='common.black' variant='t16_400'>Are you sure you want to book the order in {findCafeName()} ?</Typography>
                    <Stack spacing={2} direction='row' mt='20px' justifyContent='end' >
                        <CloseButton
                            style={{
                                width: '100px',
                                height: '35px',
                                left: '0px',
                                top: '0px',
                            }}
                            variant='contained'
                            fullWidth
                            size='medium'
                            type='cancel'
                            onClick={() => setOpenCD(false)}
                        >
                            <Typography color='common.black' variant='t14_400'>No</Typography>
                        </CloseButton>
                        <Button
                            style={{
                                width: '100px',
                                height: '36px',
                                left: '0px',
                                top: '0px',
                            }}
                            variant='contained'
                            fullWidth
                            size='medium'
                            onClick={()=>{handleCheckout();setOpenCD(false)}}
                        >
                            <Typography color='common.white' variant='t14_400'>Yes</Typography>
                        </Button>
                    </Stack>
                </Box>
            </CreateDialog>
        </>

    )
}

export default ConfirmDialog