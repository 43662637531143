import { Button, Card, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import ImageTemp from "../../../components/user/dishesCard/imageTemp";
import BlackButton from "../../../components/overrides/blackButton";
import { clearCartWatcher } from "../../../redux/actionCreators/user/home";
import { useDispatch, useSelector } from "react-redux";
import { getGst, gstRate } from "../../../utils/gst";
import { orderBookingWatcher } from "../../../redux/actionCreators/user/currentOrder";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../../components/user/confirmDialog/confirmDialog";

const ViewCart = () => {

  const { UserHomeReducers: { cartList, getOrderTotalCost }, ProfileReducers: { loginState } } = useSelector(state => state)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const nUserID = loginState[0]?.nID || 0
  const cRFID = loginState[0]?.cRFID || 0

  const [openCD, setOpenCD] = useState(false);

  const mealType = cartList[0]?.Cafeteria

  const clearCartApi = () => {
    const cartData = {
      "ACTION": "CHECKLIST",
      nUserID
    }
    dispatch(clearCartWatcher(nUserID, cartData))
  }

  const handleCheckout = () => {
    const data = {
      "ACTION": "INSERT",
      "UserID": nUserID,
      "RFID": cRFID,
      "nGst": getGst(getTotal()),
      "Total": getTotal(),
      "OrderBY": "E",
      "CreatedBy": nUserID
    }
    dispatch(orderBookingWatcher(data, dispatch, navigate))
  }

  const handleDialogOpen = () => {
    setOpenCD(true)
  }

  // const dispatchPayment = (data) => {
  //   dispatch(paymentWatcher(data, pushCurrent))
  // }

  // const pushCurrent = () => {
  //   navigate('/user/currentOrder')
  // }

  const getTotal = () => {
    const total = getOrderTotalCost.length && getOrderTotalCost[0].nTotalost
    return total
  }

  return (
    <>
    <Box p="24px">
      <Breadcrumbs content={["View Cart"]} />

      <Box mt="10px" display='flex' alignItems='center'>
        <Typography variant="t24_700">View Cart</Typography>

        <BlackButton onClick={clearCartApi} sx={{ height: 42, width: 120, ml: 'auto' }}>
          <Typography variant='t14_400'>
            Clear All
          </Typography>
        </BlackButton>
      </Box>

      <Box mt="20px">
        <Card
          sx={{
            bgcolor: "common.white",
            borderRadius: "16px 16px 0px 0px",
            p: "30px"
          }}
        >
          <Grid container spacing={4}>
            {cartList.map((d) => (
              <Grid
                display='flex'
                flexDirection='column'
                key={d.NID} item xs={12} sm={6} md={4} lg={4} xl={3}>
                <ImageTemp isViewCart={true} value={{ ...d, nTotalRate: d.nTotalPrice, nID: d.nMenuID }} />
                <Divider sx={{ mt: "20px" }} />
                <Box mt="16px" display="flex" justifyContent="center">
                  <Typography variant="t14_400" color="grey.500">
                    {d.dOrderDate}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Grid container mt="54px" spacing={2}>
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="column">
                <Typography variant="t20_600">Order Summary</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={6}>
                  <Box display="flex" textAlign="right" flexDirection="column">
                    <Typography variant="t18_600">Sub Total</Typography>
                    <Typography mt="8px" color="grey.500" variant="t14_400">
                      GST {gstRate}%
                    </Typography>
                    <Typography mt="28px" variant="t18_600">
                      Total
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" textAlign="right" flexDirection="column">
                    <Typography variant="t18_600">₹{getTotal()}</Typography>
                    <Typography mt="8px" color="grey.500" variant="t18_600">
                      ₹{getGst(getTotal())}
                    </Typography>
                    <Typography mt="22px" color="primary" variant="t24_600">
                      ₹{+getTotal() + +getGst(getTotal())}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Box display='flex' mt='45px'>
            <Button onClick={handleDialogOpen} sx={{ ml: 'auto', minWidth: 223, height: 64 }} variant="contained">
              <Typography>
                Place order
              </Typography>
            </Button>
          </Box>
        </Card>
      </Box>
    </Box>
    {
        openCD &&
          <ConfirmDialog openCD={openCD} setOpenCD={setOpenCD} handleCheckout={handleCheckout} mealType={mealType}/>
    }
    </>
  );
};

export default ViewCart;
