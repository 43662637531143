import React, { useState, useEffect } from 'react';
import Scrollbar from '../../../Scrollbar';
// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    Pagination,
    Box
} from '@mui/material';
import CommonTableHead from '../../../foodVendor/reports/commonTableHead.js';
import { useDispatch, useSelector } from 'react-redux';
import { getRatingFeedbackWatcher } from '../../../../redux/actionCreators/admin/reports';
import RatingTableRow from './tableRow';

export const TABLE_HEAD = [
    { id: 'userName', label: 'User Name', alignRight: false, title: 'User Name', field: 'EmployeeName' },
    { id: 'menuName ', label: 'Menu Name ', alignRight: false, title: 'Menu Name', field: 'MenuName' },
    { id: 'rating', label: 'Rating', alignRight: false, title: 'Rating', field: 'Rating' },
    { id: 'rateDate', label: 'Rate Date', alignRight: false, title: 'Rate Date', field: 'RateDate' },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const RatingTable = ({ searchVal, searchText, page, setPage  }) => {

    const { ReportsReducers: { RatingFeedbackList } } = useSelector((state) => state)

    let firstIndex = 0;

    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage] = useState(5);

    useEffect(() => {
        const data = { "Action": "adminRateList" }
        dispatch(getRatingFeedbackWatcher(data))
    }, [dispatch])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const tableData = searchText ? searchVal : RatingFeedbackList
    const filteredData = applySortFilter(tableData, getComparator(order, orderBy));
    const isUserNotFound = filteredData?.length === 0;

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{overflow:'unset'}} >
                        <Table>
                            <CommonTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    // .slice(page * 5, page * 5 + 5)
                                    .map((r, i) => {
                                        return (
                                            <RatingTableRow key={i} rowData={r} index={i} />
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(tableData?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default RatingTable;