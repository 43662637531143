// Contractor
import Kiosk from '../pages/kiosk/dashboard';
import KioskMenuList from '../pages/kiosk/menuList'
//profile
import Profile from '../pages/profile'
import ChangePassword from '../pages/changePassword'
//layout
import NavLayout from '../layouts/dashboard';

const KioskAdmin =  {
  path: '/kioskAdmin',
  element: <NavLayout />,
  children: [
    { index: true, element: <Kiosk /> },
    { path: "kioskMenuList", element: <KioskMenuList/> },
    { path: "profile", element: <Profile /> },
    { path: "changePassword", element: <ChangePassword /> },
  ]
}

export default KioskAdmin;
