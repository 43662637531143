import React, { useState } from 'react'
import { Box, Card, Typography } from '@mui/material'
import Breadcrumbs from '../../../../components/breadcrumbs'
import NewRoster from '../../../../components/admin/kitchen/rosterManagement/newRoster'
import RosterManagementTable from '../../../../components/admin/kitchen/rosterManagement/rosterManagement'
import RosterHeader from '../../../../components/admin/kitchen/rosterManagement/headerCard'
import FullCalendar from '../../../../components/admin/kitchen/rosterManagement/fullCalendar'
import { useSelector } from 'react-redux'

export default function RosterManagement() {

    const { RosterReducers: { rosterList } } = useSelector((state) => state)

    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [pageChange, setPageChange] = useState(false);

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = rosterList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
    }

    return (
        <>
            {!pageChange ?
                <Box p='30px'>
                    <Breadcrumbs content={["Roster Management"]} />
                    <Box p='15px 0'>
                        <Typography variant='t24_700' > Roster Management </Typography>
                    </Box>

                    <Box p='15px 0'>
                        <FullCalendar />
                    </Box>

                    <Box p='10px 0'>
                        <RosterHeader setPageChange={setPageChange} handleSearch={handleSearch} searchText={searchText} />
                    </Box>

                    <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 6px 6px", padding: '20px' }}>
                        <RosterManagementTable searchVal={searchVal} searchText={searchText} />
                    </Card>
                </Box>

                :

                <>
                    <Box>
                        <NewRoster setPageChange={setPageChange} />
                    </Box>
                </>

            }
        </>
    )
}
