//layout
import NavLayout from '../layouts/dashboard';
import Kiosk from "../pages/user/kiosk";

const User = {
  path: "/kiosk",
  element: <NavLayout />,
  children: [
    { index: true, element: <Kiosk /> },
  ],
};

export default User;
