import React, { useState, useEffect, useRef } from 'react';
import { Button, Typography, Grid, Autocomplete, Box } from '@mui/material';
import CssTextField from '../../../overrides/longSearchField';
// import { List } from '../../../List';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { createMenuItemMasterWatcher } from '../../../../redux/actionCreators/admin/menuItemMaster';
import { useDispatch, useSelector } from 'react-redux'
// import BlackButton from '../../../overrides/blackButton';
import { getMenuDropdownWatcher } from '../../../../redux/actionCreators/admin/menuMaster';
import CloseButton from '../../../overrides/closeButton';


function NewMenuItemMaster(props) {
    const dispatch = useDispatch()
    const { MenuMasterReducers: { mealDropdownList, menuDropdownList } } = useSelector((state) => state)

    const type = (mealDropdownList.length && mealDropdownList[2]) || []
    const meal = (mealDropdownList.length && mealDropdownList[3]) || []

    const { setOpen } = props;
    const [mealVal, setMealVal] = useState('')
    const tempinitsform = useRef();

    const MenuMasterSchema = Yup.object().shape({
        userType: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('User Type is required'),
        mealName: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Meal Name is required'),
        menuCode: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Menu Code is required'),
        rate: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Rate is required'),
        quantity: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Quantity is required'),
        // mealName: Yup.string('Enter your Employee Name').min(2, 'Too Short!').max(50, 'Too Long!').required('meal name is required'),
        // emailId: Yup.string('Enter your EmailId').email('Email must be a valid email address').required('Email is required'),
    });

    const formik = useFormik({
        initialValues: {
            userType: '',
            mealName: '',
            menuCode: '',
            itemName: '',
            menuDescription: '',
            quantity: '',
            rate: ''
        },

        validationSchema: MenuMasterSchema,

        onSubmit: () => {
            let values = { ...formik.values }
            let data = {
                ACTION: "INSERT",
                nMealID: `${values.mealName}`,
                nMenuID: `${values.menuCode}`,
                cItemName: values.itemName,
                cItemDesc: values.menuDescription,
                nQuantity: values.quantity,
                nRate: values.rate,
                nCreatedBy: values.userType,
                // cVendorID: "2"
            }

            dispatch(createMenuItemMasterWatcher(data, setOpen))
        }
    });

    const handleClose = () => {
        setOpen(false)
    }

    const initsform = () => {
        if (mealVal) {
            let data = { "NMEALID": `${mealVal}` }
            dispatch(getMenuDropdownWatcher(data))
        }
    };
    
    tempinitsform.current = initsform;
    
    useEffect(() => {
       tempinitsform.current();
    }, [mealVal]);

    // useEffect(() => {
    //     if (mealVal) {
    //         let data = { "NMEALID":`${mealVal}` }
    //         dispatch(getMenuDropdownWatcher(data))
    //     }

    // }, [mealVal])

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                <Box textAlign='center'>
                        <Typography variant='t20_600'
                            style={{ paddingBottom: '10px' }}>
                            New Items
                        </Typography>
                    </Box>

                    <Grid
                        container
                        style={{ padding: '30px' }}
                        spacing={3}
                        direction='row'
                    >

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="userType"
                                fullWidth
                                options={type}
                                onChange={(event, value) => {
                                    setFieldValue("userType", value.id);
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Select Type"
                                    required={true}
                                    error={Boolean(touched.userType && errors.userType)}
                                    helperText={touched.userType && errors.userType}
                                />}
                            />
                        </Grid>

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="mealName"
                                fullWidth
                                options={meal.filter((a) => a.nMealApplicable === values.userType)}
                                onChange={(event, value) => {
                                    setFieldValue("mealName", value.id);
                                    setMealVal(value.id);
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Select Meal"
                                    required={true}
                                    error={Boolean(touched.mealName && errors.mealName)}
                                    helperText={touched.mealName && errors.mealName}
                                />}
                            />
                        </Grid>

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="menuCode"
                                fullWidth
                                options={menuDropdownList}
                                onChange={(event, value) => {
                                    setFieldValue("menuCode", value.id);
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Menu Code"
                                    required={true}
                                    error={Boolean(touched.menuCode && errors.menuCode)}
                                    helperText={touched.menuCode && errors.menuCode}
                                />}
                            />
                        </Grid>

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                // required={true}
                                style={{}}
                                fullWidth={true}
                                label='Item Name'
                                name='itemName'
                                //color='primary'
                                type='text'
                                //variant='filled'
                                {...getFieldProps("itemName")}
                            // error={Boolean(touched.itemName && errors.itemName)}
                            // helperText={touched.itemName && errors.itemName}
                            />
                        </Grid>

                        <Grid
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                // required={true}
                                style={{}}
                                fullWidth={true}
                                label='Item Description'
                                name='menuDescription'
                                //color='primary'
                                type='text'
                                //variant='filled'
                                {...getFieldProps("menuDescription")}
                            // error={Boolean(touched.menuDescription && errors.menuDescription)}
                            // helperText={touched.menuDescription && errors.menuDescription} 
                            />
                        </Grid>

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                label='Quantity'
                                name='quantity'
                                //color='primary'
                                type='text'
                                //variant='filled'
                                {...getFieldProps("quantity")}
                                error={Boolean(touched.quantity && errors.quantity)}
                                helperText={touched.quantity && errors.quantity}
                            />
                        </Grid>

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                label='Rate'
                                name='rate'
                                //color='primary'
                                type='text'
                                //variant='filled'
                                {...getFieldProps("rate")}
                                error={Boolean(touched.rate && errors.rate)}
                                helperText={touched.rate && errors.rate}
                            />
                        </Grid>

                        <Grid container
                            display='flex'
                            flexDirection='row'
                            justifyContent='flex-end'
                            spacing={2}
                            sx={{ paddingTop: '30px' }}
                        >
                            <Grid item >
                                <CloseButton sx={{
                                    width: '120px',
                                    height: '42px',
                                    left: '0px',
                                    top: '0px',
                                }} variant='contained' type='button'
                                    onClick={() => handleClose()}>
                                    <Typography variant='t14_400' color='common.black'> Cancel </Typography>
                                </CloseButton>
                            </Grid>
                            <Grid item >
                                <Button sx={{
                                    width: '120px',
                                    height: '42px',
                                    left: '0px',
                                    top: '0px',
                                    backgroundColor: 'common.Bblack',
                                    '&:hover':{
                                        backgroundColor: 'common.Bblack',
                                        }
                                }} variant='contained' color='primary' type='submit' onClick={handleSubmit}>
                                    <Typography variant='t14_400' color='inherit'>Submit</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </>
    );

}

export default NewMenuItemMaster;