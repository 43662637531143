import { Box, Card, Typography } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import FeedbackHeaderCard from '../../../components/user/myFeedback/headerCard'
import MyFeedbackTable from '../../../components/user/myFeedback/myFeedbackTable'
import Breadcrumbs from '../../../components/breadcrumbs'
import { useDispatch, useSelector } from 'react-redux'
import { feedBackListWatcher } from '../../../redux/actionCreators/user/feedBack'

export default function MyFeedback() {

    const dispatch = useDispatch()
    const { UserFeedBackReducers: { feedBackList }, ProfileReducers: {loginState} } = useSelector(state => state)

    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const tempinitsform = useRef();

    const nUserID = loginState[0]?.nID || 0

    const initsform = () => {
        const userId = nUserID
        const data = {"Action":"userFeedList"}
        dispatch(feedBackListWatcher(userId, data))
    };
    
    tempinitsform.current = initsform;
    
    useEffect(() => {
       tempinitsform.current();
    }, []);

    // useEffect(()=>{
    //     const userId = nUserID
    //     const data = {"Action":"userFeedList"}
    //     dispatch(feedBackListWatcher(userId, data))
    // },[])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = feedBackList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }


    return (
        <>
            <Box p='30px'>
                <Breadcrumbs content={["My Order", "My Feedback"]} />
                <Box p='15px 0'>
                    <Typography variant='t24_700' > My Feedback </Typography>
                </Box>
                <Box p='10px 0'>
                    <FeedbackHeaderCard handleSearch={handleSearch} searchText={searchText} />
                </Box>
                <Card style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <MyFeedbackTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                </Card>
            </Box>
        </>
    )
}
