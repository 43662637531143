import React, { useRef, useState } from 'react';
import Popover from '@mui/material/Popover';
// import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ClickAwayListener, MenuItem, MenuList, Paper } from '@mui/material';
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useDispatch } from 'react-redux'
import { updateBookingDetailsStatusWatcher } from '../../../redux/actionCreators/kiosk';
import { useEffect } from 'react';
import { sendNtfySingle } from '../../../firebase';

const options = ['Accept', 'Preparing', 'Ready', 'Delivered'];

const getMsg = {
    Accept: 'Your order has been accepted',
    Preparing: 'Your order is being prepare',
    Ready: 'Your order is ready to pickup',
    Delivered: 'Your order is successfully delivered'
}

export default function DropAction({ rowData }) { 
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    
    const [selectedIndex, setSelectedIndex] = useState();
    const [open, setOpen] = useState(false);
    const tempinitsform = useRef();

    // const {ProfileReducers: {loginState}} = useSelector(state => state)
    // const nUserID = loginState[0]?.nID || 0

    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    const handleClose = () => {
        setOpen(false)
        setAnchorEl(null);
    };

    const initsform = () => {
        if(rowData.kioskStatus === "Waiting for approval"){
            setSelectedIndex(0);
        }
        else if(rowData.kioskStatus === "Accept"){
            setSelectedIndex(1);
        }
        else if(rowData.kioskStatus === "Preparing"){
            setSelectedIndex(2);
        }
        else if(rowData.kioskStatus === "Ready"){
            setSelectedIndex(3);
        }
        else if(rowData.kioskStatus === "Delivered"){
            setSelectedIndex(4);
        }
    };
    
    tempinitsform.current = initsform;
    
    useEffect(() => {
       tempinitsform.current();
    }, [rowData]);

    const handleMenuItemClick = (event, index, status) => {
        let data = {
            "ACTION": "updateKioskStatus",
            "kioskStatus": status
        }
        const message = [
            `Hello ${rowData.cEmployeeName}`,
            getMsg[status],
            rowData.cToken
        ]
        dispatch(updateBookingDetailsStatusWatcher(rowData.nID, data))
        sendNtfySingle(...message)
        setSelectedIndex(index + 1);
        setOpen(false);
    };

    const handleToggle = (event) => {
        setOpen((prevOpen) => !prevOpen);
        setAnchorEl(event.currentTarget);
    };

    //   const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Button aria-describedby={id} variant="contained" onClick={handleToggle}>
                <KeyboardDoubleArrowDownIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                                <MenuItem
                                    key={option}
                                    disabled={index === selectedIndex ? false : true}
                                    selected={index === selectedIndex}
                                    onClick={(event) => handleMenuItemClick(event, index, option)}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popover>
        </div>
    );
}
