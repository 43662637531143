import React, { useState } from 'react';
import {
    TableRow,
    TableCell,
    Typography,
    Collapse,
    Card,
    Autocomplete
} from '@mui/material';
import EmployeeChildTable from './empChildTable';
import ActionButtons from '../../dashboard/actionButtons';
import EditTextField from '../../../overrides/editField';
import { updateEmployeeWatcher, deleteEmployeeWatcher } from '../../../../redux/actionCreators/admin/employeeManagement';
import { useDispatch, useSelector } from 'react-redux'

const EmpTableRow = ({ rowData, handleRequestSort, order, orderBy, role }) => {
    const dispatch = useDispatch();
    const { EmployeeReducers: { roleNameList } } = useSelector((state) => state)

    const [expandOpen, setExpandOpen] = useState(false);
    const [status, setStatus] = useState('list');
    const [editData, setEditData] = useState({});
    const [passErr, setPassErr] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const handleEditChange = (e) => {
        const { name, value } = e.target
        setEditData({ ...editData, [name]: value })
        if (name === 'cPassword' && value.length < 8) {
            setPassErr('minimum 8 characters')
        }
        else {
            setPassErr('')
        }
    }

    const handleEdit = () => {
        let data = { ...rowData }
        setEditData(data);
        setStatus('edit');
    }

    const handleDelete = async () => {
        let data = { ACTION: "DELETE" }
        await dispatch(deleteEmployeeWatcher(rowData.nID, { data }))
        setStatus('list');
        setDeleteDialogOpen(false);
    }

    const tickIconFunction = async () => {
        let values = { ...editData }
        let data = {
            ACTION: "UPDATE",
            CEMPLOYEEID: values.cEmployeeID,
            CEMPLOYEENAME: values.cEmployeeName,
            CUSERNAME: values.cUserName,
            cPassword: values.cPassword,
            NROLEID: values.nRoleID,
            CMOBILENUMBER: values.cMobileNumber,
            CEMAILID: values.cEmailID,
            NDEPARTMENTID: values.nDepartmentID,
            NCONTRACTORID: values.nContractorID,
            NSECTORID: values.nSectorID,
            NDESIGNATIONID: values.nDesignationID,
            NDIVISIONID: values.nDivisionID,
            CRFID: values.cRFID,
            NMODIFIEDBY: values.nModifiedBy,
            nCounterID: values.nCounterID,
            nAccessPoint: values.nAccessPoint,
            NCATEGORY: values.nCategory,
            NDEPARTMENT: values.nDepartment,
            SROLEID : role,
        }
        // data.cMenuName = JSON.stringify(editData.cMenuName)
        if (!values.cPassword) {
            return setPassErr('required')
        }
        if (passErr) {
            setPassErr('minimum 8 characters')
        }
        else {
            setPassErr('')
            dispatch(updateEmployeeWatcher(rowData.nID, data));
            setStatus('list');
        }
    }

    const cancelIconFunction = () => {
        setEditData({});
        setStatus('list');
        setPassErr('')
    }

    let roleName = (roleNameList.length && roleNameList.find(m => m.id === rowData.nRoleID)) || {}

    return (
        <>

            <TableRow
                hover
                tabIndex={-1}
                sx={{ '& > *': { borderBottom: 'unset' } }}
            >
                {status === 'list' ?
                    <>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cEmployeeID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cEmployeeName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cEmailID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cMobileNumber}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cRoleName}
                            </Typography>
                        </TableCell>
                    </>
                    :
                    <>
                        <TableCell align="left" sx={{ display: 'flex', alignItems: 'center' }}>
                            <EditTextField
                                name='cEmployeeID'
                                value={editData.cEmployeeID}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='cEmployeeName'
                                value={editData.cEmployeeName}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='cEmailID'
                                value={editData.cEmailID}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='cMobileNumber'
                                value={editData.cMobileNumber}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell align="left" >
                            <Autocomplete
                                id="combo-box-demo"
                                name="role"
                                fullWidth
                                options={roleNameList}
                                autoHighlight
                                size='small'
                                defaultValue={roleName}
                                // value={roleName.name}
                                onChange={(event, value) => {
                                    setEditData({ ...editData, "nRoleID": value.id });
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <EditTextField {...params}
                                    sx={{ minWidth: '160px' }}
                                    // label="Edit Role"
                                    name='role'
                                />}
                            />
                        </TableCell>
                    </>
                }
                <TableCell align="right" sx={{ minWidth: '100px' }}>
                    <ActionButtons status={status} setStatus={setStatus} expandOpen={expandOpen} setExpandOpen={setExpandOpen} type={'employee'} tickIconFunction={tickIconFunction} handleDelete={handleDelete} handleEdit={handleEdit} cancelIconFunction={cancelIconFunction} setDeleteDialogOpen={setDeleteDialogOpen} deleteDialogOpen={deleteDialogOpen} />
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell colSpan={6} style={{ padding: 0 }}>
                    <Collapse in={expandOpen} timeout="auto" unmountOnExit>
                        <Card sx={{ bgcolor: 'primary.table' }} >
                            <EmployeeChildTable expandOpen={expandOpen} rowData={rowData} handleRequestSort={handleRequestSort} orderBy={orderBy} order={order} handleEditChange={handleEditChange} status={status} editData={editData} setEditData={setEditData} passErr={passErr} />
                        </Card>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>

    )
}

export default EmpTableRow