import React, { useEffect, useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
import DeliveryHistoryHeader from './header';
import DHistoryTable from '../../../components/contractor/deliveryHistory/table';
import { getDeliveryHistoryWatcher } from '../../../redux/actionCreators/admin/reports';
import { useDispatch, useSelector } from 'react-redux';
// import { TABLE_HEAD } from '../../../components/admin/reports/deliveryHistory/deliveryHistoryTable';
import { TABLE_HEAD } from '../../../components/contractor/deliveryHistory/table';
import { getDropDownWatcher } from '../../../redux/actionCreators/admin/employeeManagement';
import { SUB_TABLE_HEAD } from '../../../components/contractor/deliveryHistory/cRoleDHistoryChildTable';

const DeliveryHistory = () => {
    const { ReportsReducers: { DeliveryHistoryList }, ProfileReducers: { loginState } } = useSelector((state) => state)
    const nUserID = loginState[0]?.nID || 0

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);

    useEffect(() => {
        let data = {
            "ACTION": "ContractorDeliveryUser",
            "UserID": nUserID
        }
        dispatch(getDeliveryHistoryWatcher(data))
        dispatch(getDropDownWatcher());
    }, [dispatch, nUserID])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = DeliveryHistoryList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate, cafeteriaId=0) => {
        const data = {
            ACTION: "ContractorDeliveryUserSearch",
            FromDate: fromDate,
            ToDate: toDate,
            nCafeteriaId: cafeteriaId,
            UserID: nUserID,
        }
        if(!fromDate || !toDate ) return 
        dispatch(getDeliveryHistoryWatcher(data))
        setPage(1)
    }

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports", "Delivery History"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Delivery History</Typography>
                </Box>
                <Box p='10px 0'>
                    <DeliveryHistoryHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={DeliveryHistoryList} columnData={[...TABLE_HEAD,...SUB_TABLE_HEAD]} />
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <DHistoryTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                </Card>
            </Box>
        </>
    )
}

export default DeliveryHistory;