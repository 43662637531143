import { Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import { getConvertedDate2 } from "../../../utils/common";
import { base_url } from '../../../http-common';

const CategoryCard = ({ value, orderCount, activeCateg, selectedDate }) => {

  const {UserHomeReducers: {cartList}} = useSelector(state => state)

  const { cMealName, cImage, nID } = value

  const getCount = () => {
    let total = 0
    const getDate = selectedDate
    cartList.forEach(d => {
      if(d.cMealName === cMealName && getDate === getConvertedDate2(new Date(d.dOrderDate))){
        total += d.nQuantity
      }
    })
    return total
  }

  return (
    <Card
      sx={{
        p: "17px 26px",
        height: 160,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        borderRadius: "16px",
        position: "relative",
        border: (theme)=> activeCateg === nID ? `1px solid ${theme.palette.primary.main}` : 'unset',
        cursor: 'pointer'
      }}
    >
      <Box
        sx={{
          position: "absolute",
          right: 10,
          top: 10,
          height: "31px",
          width: "31px",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          display: getCount() ? 'flex' : 'none'
        }}
        bgcolor="common.darkBlue"
        color="grey.0"
      >
        {getCount()}
      </Box>

      <img src={`${base_url}/${cImage}`} alt='' />

      <Typography mt="16px" variant="t18_400" color="grey.500">
        {cMealName}
      </Typography>
    </Card>
  );
};

export default CategoryCard;
