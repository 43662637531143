import React from 'react'
import { Box, Typography, Card } from "@mui/material";
import DinnerTable from './dayByDayTable';

const TableIndex = ({ cardData }) => {

    const sumValues = (data) => {
        let Total = 0
        data.forEach(d => {
            const objTot = Object.values(d || {}).reduce((a, b) => {
                let val = !+b ? 0 : +b
                return a + val
            }, 0);
            Total += objTot
        })
        return Total;
    }

    const tbl_arr = (cardData.length && cardData[1]) || []
    const tbl_name = (tbl_arr.length && tbl_arr[0].cMealName) || ''

    return (
        <>
            <Box display='flex' justifyContent='right' >
                <Box display='flex' justifyContent='center' alignItems='center' bgcolor='common.black' height='37px' width='100px' borderRadius="6px 6px 0px 0px">
                    <Typography variant="t18_800" color='common.white'>
                        {sumValues(cardData[0] || [])}
                    </Typography>
                </Box>
            </Box>

            <Box display='flex' >
                <Box display='flex' justifyContent='center' alignItems='center' bgcolor='common.weekTab' width='43px' borderRadius="6px 0px 0px 6px" >
                    <Typography variant="t14_600__18" color='common.white' style={{ transform: "rotate(-90deg)" }} >
                        {tbl_name || ''}
                    </Typography>
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, width: '100%', height: '100%', borderRadius: "0px 0px 6px 0px" }}>
                    <DinnerTable tableData={cardData[0] || []} />
                </Card>
            </Box>
        </>
    )
}

export default TableIndex