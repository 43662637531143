import http from "../../http-common";

class ContractorBookService {
    create(data) {
        return http.post("/admin/addContractorBook", data);
    }

    cancel(id, data) {
        return http.post(`/admin/cancelOrder/${id}`, data);
    }

    getContractorNameList(data) {
        return http.post("/admin/contractorDropDown", data);
    }

    getMealQuantity(data) {
        return http.post("/admin/getMealQuantity", data);
    }
    getMealQuantityCon(data) {
        return http.post("/contractor/getMealQuantity", data);
    }

    selectAllMeal(data) {
        return http.post("/admin/selectAllMeal", data);
    }
}

export default new ContractorBookService();