import React, { useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
// import CommonHeader from '../../../components/foodVendor/commonHeader/header';
import EmpConTable from '../../../components/admin/reports/employee_contractor/emp_conTable';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeContractorWatcher } from '../../../redux/actionCreators/admin/emp_con';
import { TABLE_HEAD } from '../../../components/admin/reports/employee_contractor/emp_conTable';
import CRoleSwipeHistoryHeaderCard from '../../../components/contractor/reports/cRoleSwipeHistory/headerCard';

const Employee_contractor = () => {

    const { ReportsReducers: { EmployeeContractorList } } = useSelector((state) => state)

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const curDate = new Date()
    const past = curDate.setDate(curDate.getDate() - 7)
    const [fromDate, setFromDate] = React.useState(new Date(past));
    const [toDate, setToDate] = React.useState(new Date());
    const [selectedMeal, setSelectedMeal] = useState('');

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = EmployeeContractorList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        const data = {
            "ACTION": "Search",
            "FROMDATE": fromDate,
            "TODATE": toDate,
            MealType: selectedMeal,
        }
        dispatch(getEmployeeContractorWatcher(data))
        setPage(1)
    }

    const selectedMealHandler = (val) => {
        setSelectedMeal(val)
    }

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports", "User/Contractor/Guest"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">User/Contractor/Guest</Typography>
                </Box>
                <Box p='10px 0'>
                <CRoleSwipeHistoryHeaderCard selectedMealHandler={selectedMealHandler} handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={EmployeeContractorList} columnData={TABLE_HEAD} setFromDate={setFromDate} setToDate={setToDate} toDate={toDate} fromDate={fromDate}/>
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <EmpConTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                </Card>

            </Box>
        </>
    )
}

export default Employee_contractor;