import { call, put, takeEvery, select } from "redux-saga/effects";
import { CURRENT_ORDER_WATCHER, DELIVERED_ORDER_WATCHER, NEXT_DAY_ORDER_WATCHER, NEXT_WEEK_ORDER_WATCHER, ORDER_HISTORY_WATCHER, UPCOMING_ORDER_WATCHER, ORDER_BOOKING_WATCHER, PAYMENT_WATCHER, INSERT_FEEDBACK_WATCHER, RATE_FEEDBACK_WATCHER, USER_ORDER_CANCEL_WATCHER, USER_ORDER_HISTORY_CANCEL_WATCHER, VERIFY_PAYTM_WATCHER } from "../../actionTypes/currentOrder";
import { orderHistoryWatcher, paymentWatcher, updateCurrentOrder, updateDeliveredOrder, updateNextDayOrder, updateNextWeekOrder, updateOrderHistory, updateUpcomingOrder } from "../../actionCreators/user/currentOrder";
import Service from "../../../services/user/currentOrder"
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";
// import theme from '../../../theme/palette'
import { clearCartWatcher } from "../../actionCreators/user/home";
import { upcomingOrderWatcher } from '../../actionCreators/user/currentOrder';


function* currentOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.currentOrder, action.payload);

    yield put(updateCurrentOrder(data.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}


function* orderHistoryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.orderHistory, action.payload);

    yield put(updateOrderHistory(data.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* nextdayOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.currentOrder, action.payload);

    yield put(updateNextDayOrder(data.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* nextweekOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.currentOrder, action.payload);

    yield put(updateNextWeekOrder(data.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* deliveredOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.currentOrder, action.payload);
    yield put(updateDeliveredOrder(data.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* upcomingOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.currentOrder, action.payload);

    yield put(updateUpcomingOrder(data.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

// function* orderBookingEffect(action) {
//   try {
//     yield put(updateOpenLoader());
//     let {data} = yield call(Service.orderBooking, action.payload);

//     // Getting the order details back
//     const { amount, id: order_id, currency, notes } = data;
//     const {ProfileReducers: {loginState}, UserHomeReducers: { cafeteriaId }} = yield select((state) => state)
    
//     const { cEmployeeName, cEmailID, cMobileNumber, nID } = loginState[0] || {}
//     const nUserID = nID || 0
    
//     const options = {
//       key: process.env.REACT_APP_KEY_ID, // Enter the Key ID generated from the Dashboard
//       amount: amount.toString(),
//       currency: currency,
//       name: cEmployeeName,
//       description: "BCP-PITSTOP",
//       image: '',
//       order_id: order_id,
//       handler: function (response) {
//         const payload = {
//           nOrderID: notes.OrderNID,
//           cOrderID: notes.OrderID,
//           nTotalRate: amount,
//           cPaymentStatus: 'Y',
//           cPaymentDesc: 'Success',
//           cBankTrackingID: response.razorpay_payment_id,
//           cReferenceID: response.razorpay_order_id,
//           nUserID,
//           nCafeteriaId: cafeteriaId
//         };

//         action.dispatchPayment(payload)
//       },
//       prefill: {
//         name: cEmployeeName,
//         email: cEmailID,
//         contact: cMobileNumber,
//       },
//       notes: {
//         address: "Soumya Dey Corporate Office",
//       },
//       theme: {
//         color: theme.primary.main,
//       },
//     };

//     yield put(updateFalseLoader());
//     const paymentObject = new window.Razorpay(options);
//     paymentObject.on("payment.failed", function (response) {
      
//     });
//     paymentObject.open();
//   } catch (e) {
//     yield put(updateFalseLoader());
//     yield put(updateAlert(e.response?.data.message, 'error'));
//   }
// }

function* orderBookingPaytmEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.orderBookingPaytm, action.payload);

    // Getting the order details back
    const { body: { amount, OrderNID, OrderID, txnToken } } = data;
    const { ProfileReducers: { loginState }, UserHomeReducers: { cafeteriaId } } = yield select((state) => state)

    const { nID } = loginState[0] || {}
    const nUserID = nID || 0

    const config = {
      "root": "",
      "flow": "DEFAULT",
      "data": {
        "orderId": OrderID, /* update order id */
        "token": txnToken, /* update token value */
        "tokenType": "TXN_TOKEN",
        "amount": amount /* update amount */
      },
      "handler": {
        "notifyMerchant": function () {
          
        },
        transactionStatus: function (data) {
          window.Paytm.CheckoutJS.close()
          if (data.STATUS === 'TXN_SUCCESS') {
            const payload = {
              nOrderID: OrderNID,
              cOrderID: OrderID,
              nTotalRate: amount,
              cPaymentStatus: 'Y',
              cPaymentDesc: 'Success',
              cBankTrackingID: data.TXNID,
              cReferenceID: data.BANKTXNID,
              nUserID,
              nCafeteriaId: cafeteriaId
            };
            action.dispatch(paymentWatcher(payload, action.navigate))
          } else {
            action.dispatch(updateAlert('Payment failed', 'error'))
          }

        }
      },
      "merchant": {
        "redirect": false
      },
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
        action.dispatch(updateFalseLoader())
        window.Paytm.CheckoutJS.invoke();
      }).catch(function onError(error) {
        action.dispatch(updateFalseLoader())
      });
    }

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e.response?.data.message, 'error'));
  }
}

function* paymentEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.payment, action.payload);

    const { loginState } = yield select((state) => state.ProfileReducers)

    const nUserID = loginState[0]?.nID || 0
    
    const cartData = {
      "ACTION":"CHECKLIST",
       nUserID
    }
    yield put(clearCartWatcher(nUserID, cartData))
    action.navigate('/user/orderHistory')
    yield put(updateAlert('Successfully order placed', 'success'));
    yield put(updateFalseLoader());
  } catch (e) { 
    yield put(updateFalseLoader());
  }
}

function* insertFeedBackEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.insertFeedBack, action.payload);

    yield put(updateAlert('Thanks for your FeedBack', 'success'));
    yield put(updateFalseLoader());
  } catch (e) { 
    yield put(updateFalseLoader());
  }
}

function* rateFeedBackEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.rateFeedBack, action.id, action.payload);

    yield put(updateFalseLoader());
  } catch (e) { 
    yield put(updateFalseLoader());
  }
}

function* orderCancelEffect(action) {
    try {
    yield put(updateOpenLoader());
    yield call(Service.cancelOrder, action.payload);

    let data1 ={"nUserID":action.payload.nUserID, "cAction": "UpcomingOrder"}    
    yield put(upcomingOrderWatcher(data1));
    yield put(updateAlert("Order cancelled successfully", 'success'));
    yield put(updateFalseLoader());
  } catch (e) { 
    yield put(updateAlert(e.response.data || 'Something went wrong', 'error'));
    yield put(updateFalseLoader());
  }
}

function* userOrderHistoryCancelEffect(action) {
  try {
  yield put(updateOpenLoader());
  yield call(Service.cancelOrderHistory, action.payload);

  let data1 ={"nUserID":action.payload.nUserID, "cAction": "History"}    
  yield put(orderHistoryWatcher(data1));
  yield put(updateAlert("Order cancelled successfully", 'success'));
  yield put(updateFalseLoader());
} catch (e) { 
  yield put(updateAlert(e.response.data || 'Something went wrong', 'error'));
  yield put(updateFalseLoader());
}
}

function* verifyPaytmEffect(action) {
    try {
    yield put(updateOpenLoader());
    yield call(Service.verifyPaytm, action.payload);
    
    yield put(updateFalseLoader());
  } catch (e) { 
    yield put(updateAlert("Something went wrong", 'error'));
    yield put(updateFalseLoader());
  }
}

//saga 
export const currentOrderSaga = [
  takeEvery(CURRENT_ORDER_WATCHER, currentOrderEffect),
  takeEvery(ORDER_HISTORY_WATCHER, orderHistoryEffect),
  takeEvery(NEXT_DAY_ORDER_WATCHER, nextdayOrderEffect),
  takeEvery(NEXT_WEEK_ORDER_WATCHER, nextweekOrderEffect),
  takeEvery(DELIVERED_ORDER_WATCHER, deliveredOrderEffect),
  takeEvery(UPCOMING_ORDER_WATCHER, upcomingOrderEffect),
  takeEvery(ORDER_BOOKING_WATCHER, orderBookingPaytmEffect),
  takeEvery(PAYMENT_WATCHER, paymentEffect),
  takeEvery(INSERT_FEEDBACK_WATCHER, insertFeedBackEffect),
  takeEvery(RATE_FEEDBACK_WATCHER, rateFeedBackEffect),
  takeEvery(USER_ORDER_CANCEL_WATCHER, orderCancelEffect),
  takeEvery(USER_ORDER_HISTORY_CANCEL_WATCHER, userOrderHistoryCancelEffect),
  takeEvery(VERIFY_PAYTM_WATCHER, verifyPaytmEffect),
]