import { SELECTED_ITEMS, UPDATE_CATEGORY, UPDATE_MENUS, UPDATE_CART, UPDATE_DAYSOFWEEK, UPDATE_ORDER_TOTALCOST, UPDATE_VIEW_CART, UPDATE_WEEKLY_BOOKING, UPDATE_KIOSK, UPDATE_CAFETERIA_ID } from "../../actionTypes/user";

const intialState = {
  selectedItems: [],
  categoryList: [],
  mealMenuList: [],
  cartList: [],
  daysOfWeek: [],
  getOrderTotalCost: [],
  viewCartList: [],
  weeklyBookingDates: [],
  kiosk: [],
  cafeteriaId : ''
};

export default function UserHomeReducers(state = intialState, action) {
  switch (action.type) {

    case SELECTED_ITEMS:
      return { ...state, selectedItems: action.payload };

    case UPDATE_CATEGORY:
      return { ...state, categoryList: action.payload };

    case UPDATE_MENUS:
      return { ...state, mealMenuList: action.payload };

    case UPDATE_CART:
      return { ...state, cartList: action.payload };

    case UPDATE_DAYSOFWEEK:
      return { ...state, daysOfWeek: action.payload };

    case UPDATE_ORDER_TOTALCOST:
      return { ...state, getOrderTotalCost: action.payload };

    case UPDATE_VIEW_CART:
      return { ...state, viewCartList: action.payload };

    case UPDATE_WEEKLY_BOOKING:
      return { ...state, weeklyBookingDates: action.payload };

    case UPDATE_KIOSK:
      return { ...state, kiosk: action.payload };

    case UPDATE_CAFETERIA_ID:
      return { ...state, cafeteriaId: action.payload };

    default:
      return state;
  }
}
