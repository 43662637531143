import { all } from "redux-saga/effects";

import { EmployeeManagementSaga } from "./admin/employeeManagement";
import { ContractorManagementSaga } from "./admin/contractorManagement";
import { VendorManagementSaga } from "./admin/vendorManagement";
import { MenuMasterSaga } from "./admin/menuMaster";
import { MenuItemMasterSaga } from "./admin/menuItemMaster";
import { ContractorBookSaga } from "./admin/contractorBook";
import { OrderSummarySaga } from "./admin/orderSummary";
import { ReportsSaga } from "./admin/reports";
import { EmployeeContractorSaga } from "./admin/emp_con"
import { CarouselSaga } from "./admin/carousel"
import { RosterManagementSaga } from "./admin/rosterManagement"
import { GuestManagementSaga } from "./admin/guestManagement";
import { GuestBookSaga } from "./admin/guestBook";

//user
import { MenuListSaga } from "./user/home";
import { feedBackListSaga } from "./user/feedBack";
import { ratingListSaga } from "./user/rating";
import { currentOrderSaga } from "./user/currentOrder";
import { KioskSaga } from "./user/kiosk";

//profile
import { profileSaga } from "./profile";

//contractor
import { contractorRoleSaga } from "./contractor"

//guest
import { guestRoleSaga } from "./guest"

//Food Vendor
import { FoodVendorSaga } from "./foodVendor/index";

//Kiosk
import {kioskRoleSaga} from "./kiosk"

//Screen
import { SwipeSaga } from "./screen/screen"
import { CabSaga } from "./cab";

// import watchers from other files
export default function* rootSaga() {
  yield all([
    ...EmployeeManagementSaga,
    ...ContractorManagementSaga,
    ...VendorManagementSaga,
    ...MenuMasterSaga,
    ...MenuItemMasterSaga,
    ...ContractorBookSaga,
    ...OrderSummarySaga,
    ...ReportsSaga,
    ...EmployeeContractorSaga,
    ...CarouselSaga,
    ...RosterManagementSaga,
    ...GuestManagementSaga,
    ...GuestBookSaga,

    //user
    ...MenuListSaga,
    ...feedBackListSaga,
    ...ratingListSaga,
    ...currentOrderSaga,
    ...KioskSaga,

    //profile
    ...profileSaga,

    //contractor
    ...contractorRoleSaga,

    //guest
    ...guestRoleSaga,

    //Food Vendor
    ...FoodVendorSaga,

    //Kiosk
    ...kioskRoleSaga,

    //Screen
    ...SwipeSaga,

    //Cab
    ...CabSaga,

  ]);
}
