import React, { useEffect, useRef } from 'react';
import { Typography, Card, Grid, Button, Box, Stack } from "@mui/material";
import CssTextField from '../../../../components/overrides/longSearchField'
// import BlackButton from '../../../../components/overrides/blackButton'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { cnsListWatcher } from '../../../../redux/actionCreators/admin/contractorBook';
import { createContractorOrderWatcher, cancelContractOrderWatcher } from '../../../../redux/actionCreators/contractor';
import { updateAlert } from '../../../../redux/actionCreators/alert';
import CancelDialog from '../../../../components/admin/kitchen/contractorBook/cancelDialog';
import { timeVal } from '../../../../utils/common';
import { getDropDownWatcher } from '../../../../redux/actionCreators/admin/employeeManagement';
import { getConvertedDate } from '../../../../utils/common';
import { selectGuestCountWatcher } from '../../../../redux/actionCreators/admin/guestBook';

const MealBooking = () => {

    const dispatch = useDispatch();
    const { ContractorBookReducers: { menuIdList }, EmployeeReducers: { cafeteriaName }, GuestBookReducers: { guestUserCount }, } = useSelector((state) => state)

    const { ProfileReducers: { loginState } } = useSelector(state => state)
    const nUserName = loginState[0]?.cUserName || ''
    const nContractorName = loginState[0]?.cEmployeeName || ''
    const nContractorId = loginState[0]?.nID || ''
    const nUserID = loginState[0]?.nID
    const nCRoleID = loginState[0]?.nContractorID
    const tempinitsform = useRef();
    const tempinits = useRef();
    const tempinit = useRef();
    const temp = useRef();

    const gCount = guestUserCount[0]?.GuserCount ? guestUserCount[0]?.GuserCount : 0

    var today = new Date();
    var bookingTime = timeVal(today)
    const cafeteriaList = cafeteriaName?.filter(m => m.id !== 0) || []

    const custValidation = (Yup) => {
        if (cafeteriaList.length) {
            const cust = {}
            const meals = ['Breakfast', 'Lunch', 'Snacks', 'Dinner', 'Supper', 'Snacks(Take away)']
            cafeteriaList.forEach(m => {
                const diff = cafeteriaList.filter(f => f.id !== m.id)

                meals.forEach(d => {
                    const getBal = (values) => {
                        const bal = diff.reduce((a, b) => {
                            return a + (values[`${d}-${b.id}`] || 0)
                        }, 0)
                        return bal
                    }

                    cust[`${d}-${m.id}`] = Yup.number().test("Invalid count", "Invalid count", (data = 0, mm) => {
                        if ((data + getBal(mm.parent)) > gCount) {
                            return false
                        }
                        return true
                    })
                })

            })
            return cust
        }
        return {}
    }

    const Schema = Yup.object().shape({
        // contractorID: Yup.string().min(1, 'Too Short!').max(500, 'Too Long!').required('Please select contractor'),
        ...custValidation(Yup)
    });

    const formik = useFormik({
        initialValues: {
            userName: '',
            contractorID: '',
            contractorName: '',
            orderDate: '',
            supperDate: ''
        },
        validationSchema: Schema,

        onSubmit: () => {
            const { userName, contractorID, orderDate, contractorName, supperDate, ...rest } = { ...formik.values }
            let mealArr = []

            Object.keys(rest).forEach((key, index) => {
                const mealObj = menuIdList.find(m => m.cmealName === key) || {}
                if (rest[key] !== 0 && +rest[key] !== +mealObj.nQuantity) {
                    mealArr.push({ ...mealObj, nQuantity: +rest[key] })
                }
            })

            const data = {
                "ACTION": "InsertOrderBooking",
                "nContractorID": `${contractorID}`,
                "bookingData": mealArr,
                nUserID,
                nBookingID: getBookingId()
            }

            if (mealArr.length) {
                dispatch(createContractorOrderWatcher(data))
            }
        }
    });
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values } = formik;

    const initsform = () => {
        if (values.contractorID !== '') {
            let data = {
                "ACTION": "Cns",
                "nID": `${values.contractorID}`
            }
            let dat = {
                "ACTION": "CuserCount",
                "nID": `${nCRoleID}`
            }
            dispatch(cnsListWatcher(data, true))
            dispatch(selectGuestCountWatcher(dat))
        }
    };

    tempinitsform.current = initsform;

    useEffect(() => {
        tempinitsform.current();
    }, [values.contractorID]);


    // useEffect(() => {
    //     if (values.contractorID !== '') {
    //         let data = {
    //             "ACTION": "Cns",
    //             "nID": `${values.contractorID}`
    //         }
    //         dispatch(cnsListWatcher(data, true))
    //     }
    // }, [values.contractorID])

    const getBookingId = () => {
        const get = menuIdList.find(d => d.nid) || {}
        return get.nid
    }

    const inits = () => {
        if (menuIdList.length) {
            menuIdList.map(m => (setFieldValue(m.cmealName, m.nQuantity)))
        }
    };

    tempinits.current = inits;

    useEffect(() => {
        tempinits.current();
    }, [menuIdList]);


    // useEffect(() => {
    //     if (menuIdList.length) {
    //         menuIdList.map(m => (setFieldValue(m.cmealName, m.nQuantity)))
    //     }
    // }, [menuIdList])

    const init = () => {
        if (!cafeteriaName.length) {
            dispatch(getDropDownWatcher());
        }
    };

    tempinit.current = init;

    useEffect(() => {
        tempinit.current();
    }, [cafeteriaName]);


    // useEffect(() => {
    //     if (!cafeteriaName.length) {
    //         dispatch(getDropDownWatcher());
    //     }
    // }, [cafeteriaName])

    const tempform = () => {
        setFieldValue("contractorID", nContractorId)
        setFieldValue("contractorName", nContractorName)
        setFieldValue("userName", nUserName)
        const today = new Date();
        let tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        setFieldValue("orderDate", tomorrow)
        setFieldValue("supperDate", getConvertedDate(tomorrow))
    };

    temp.current = tempform;

    useEffect(() => {
        temp.current();
    }, []);


    // useEffect(() => {
    //     setFieldValue("contractorID", nContractorId)
    //     setFieldValue("contractorName", nContractorName)
    //     setFieldValue("userName", nUserName)
    //     const today = new Date();
    //     let tomorrow = new Date(today);
    //     tomorrow.setDate(tomorrow.getDate() + 1);
    //     setFieldValue("orderDate", tomorrow)
    // }, [])

    const handleCancel = () => {

        if (!getBookingId()) {
            return dispatch(updateAlert('There is no order to cancel', 'error'))
        }

        if (values.contractorID !== '') {
            let id = getBookingId()
            let data = {
                ACTION: "OrderCancel",
                nContractorId: values.contractorID
            }
            dispatch(cancelContractOrderWatcher(id, data))
        }
    }

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}` }}>
                <Grid container spacing={3} sx={{ padding: '30px' }} >
                    <Grid item lg={6} xs={6} sx={{ justifyContent: 'left' }}>
                        <div>
                            <Typography variant='t20_600'> Meal Booking </Typography>
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={3} display='flex' flexDirection='row' justifyContent='left' sx={{ padding: '30px', pb: '60px' }}>
                    <Grid item
                        lg={4}
                        xs={4}
                        md={4}
                        sm={4}
                    >
                        <Typography variant='t14_600'> User Name </Typography>
                        <CssTextField sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '42px'
                            }
                        }}
                            placeholder='User Name'
                            name='contractorName'
                            fullWidth
                            size='medium'
                            {...getFieldProps("contractorName")}
                            error={Boolean(touched.contractorName && errors.contractorName)}
                            helperText={touched.contractorName && errors.contractorName}
                        />
                    </Grid>

                    <Grid item
                        lg={4}
                        xs={4}
                        md={4}
                        sm={4}
                    >
                        <Typography variant='t14_600'> User ID </Typography>
                        <CssTextField sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '42px'
                            }
                        }}
                            placeholder='User ID'
                            name='userName'
                            fullWidth
                            size='medium'
                            {...getFieldProps("userName")}
                            error={Boolean(touched.userName && errors.userName)}
                            helperText={touched.userName && errors.userName}
                        />
                    </Grid>

                    <Grid item
                        lg={4}
                        xs={4}
                        md={4}
                        sm={4}
                    >
                        <Typography variant='t14_600'> Order Date </Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                // label='Order Date'
                                name="orderDate"
                                onChange={() => {
                                    // setFieldValue("orderDate", newValue)
                                }}
                                // {...getFieldProps("orderDate")}
                                value={values.orderDate}
                                renderInput={(params) => <CssTextField {...params} fullWidth sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                {cafeteriaList?.map((m, i) => (
                    <Grid key={m.id} container spacing={3} display='flex' flexDirection='row' justifyContent='left' sx={{ padding: '30px', pt: 0 }}>

                        <Box bgcolor='primary.main' height='50px' width='80px' m='auto 10px 0 20px' borderRadius='4px' display='flex' alignItems='center' justifyContent='center'>
                            <Typography variant='t16_400' color='common.white'>{`${m.name}`}</Typography></Box>
                        <Grid item
                            lg={1.8}
                            xs={4}
                            md={3}
                        >
                            <Typography variant='t14_600'> Breakfast </Typography>
                            <CssTextField sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '42px'
                                }
                            }}
                                placeholder='Breakfast'
                                name={`Breakfast-${m.id}`}
                                fullWidth
                                size='medium'
                                {...getFieldProps(`Breakfast-${m.id}`)}
                                value={values[`Breakfast-${m.id}`] || ''}
                                error={Boolean(touched[`Breakfast-${m.id}`] && errors[`Breakfast-${m.id}`])}
                            />
                        </Grid>

                        <Grid item
                            lg={1.8}
                            xs={4}
                            md={3}
                        >
                            <Typography variant='t14_600'> Lunch </Typography>
                            <CssTextField sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '42px'
                                }
                            }}
                                placeholder='Lunch'
                                name={`Lunch-${m.id}`}
                                fullWidth
                                size='medium'
                                {...getFieldProps(`Lunch-${m.id}`)}
                                value={values[`Lunch-${m.id}`] || ''}
                                error={Boolean(touched[`Lunch-${m.id}`] && errors[`Lunch-${m.id}`])}
                            />
                        </Grid>

                        <Grid item
                            lg={1.8}
                            xs={4}
                            md={3}
                        >
                            <Typography variant='t14_600'> Snacks </Typography>
                            <CssTextField sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '42px'
                                }
                            }}
                                placeholder='Snacks'
                                name={`Snacks-${m.id}`}
                                fullWidth
                                size='medium'
                                {...getFieldProps(`Snacks-${m.id}`)}
                                value={values[`Snacks-${m.id}`] || ''}
                                error={Boolean(touched[`Snacks-${m.id}`] && errors[`Snacks-${m.id}`])}
                            />
                        </Grid>

                        <Grid item
                            lg={1.8}
                            xs={4}
                            md={3}
                        >
                            <Typography variant='t14_600'> Dinner </Typography>
                            <CssTextField sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '42px'
                                }
                            }}
                                placeholder='Dinner'
                                name={`Dinner-${m.id}`}
                                fullWidth
                                size='medium'
                                {...getFieldProps(`Dinner-${m.id}`)}
                                value={values[`Dinner-${m.id}`] || ''}
                                error={Boolean(touched[`Dinner-${m.id}`] && errors[`Dinner-${m.id}`])}
                            />
                        </Grid>

                        <Grid item
                            lg={1.8}
                            xs={4}
                            md={3}
                        >
                            <Typography variant='t14_600'> Supper </Typography>
                            <CssTextField sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '42px'
                                }
                            }}
                                placeholder='Supper'
                                name={`Supper-${m.id}`}
                                fullWidth
                                size='medium'
                                {...getFieldProps(`Supper-${m.id}`)}
                                value={values[`Supper-${m.id}`] || ''}
                                error={Boolean(touched[`Supper-${m.id}`] && errors[`Supper-${m.id}`])}
                            />
                        </Grid>

                        <Grid item
                            lg={1.85}
                            xs={6}
                            md={3}
                        >
                            <Typography variant='t14_600'> {'Snacks(Take away)'} </Typography>
                            <CssTextField sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '42px'
                                }
                            }}
                                placeholder='Snacks(Take away)'
                                name={`Snacks(Take away)-${m.id}`}
                                fullWidth
                                size='medium'
                                {...getFieldProps(`Snacks(Take away)-${m.id}`)}
                                value={values[`Snacks(Take away)-${m.id}`] || ''}
                                error={Boolean(touched[`Snacks(Take away)-${m.id}`] && errors[`Snacks(Take away)-${m.id}`])}
                            />
                        </Grid>
                    </Grid>
                ))}

                <Stack color='common.sred' spacing={0.5} mt='20px' ml='30px' >
                    <Typography variant='t14_600'>Note:</Typography>
                    {bookingTime ?
                        <>
                            <Typography variant='t14_400'>{`Please note the supper booking is applicable for ${values.supperDate}`}</Typography>

                            <Typography variant='t14_400'>{`Maximum no of allowed quantity for booking in each session is ${values.contractorID ? gCount : 0}.`}</Typography>
                        </>
                        :
                        <Typography variant='t14_400'>{`Please note the order booking is not available after 7PM `}</Typography>
                    }
                </Stack>

                <Grid container
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-end'
                    spacing={2}
                    sx={{ padding: '50px' }}
                >
                    <Grid item >
                        <CancelDialog handleCancel={handleCancel} />
                    </Grid>
                    <Grid item >
                        <Button sx={{
                            width: '120px',
                            height: '42px',
                            left: '0px',
                            top: '0px',
                            backgroundColor: 'common.Bblack',
                            '&: hover': {
                                backgroundColor: 'common.Bblack',
                            }
                        }} variant='contained' color='primary' onClick={handleSubmit}
                            type='submit'
                            disabled={bookingTime ? false : true}
                        >
                            <Typography variant='t16_400' color='inherit'>Save</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </>

    )
}

export default MealBooking;