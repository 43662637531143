import { call, put, takeEvery } from "redux-saga/effects";
import { CREATE_CONTRACTOR_BOOK_WATCHER, CONTRACTOR_NAME_LIST_WATCHER, CANCEL_CONTRACTOR_BOOK_WATCHER, CNS_WATCHER, ALl_MEAL_WATCHER } from "../../actionTypes/admin";
import { contractorBookList, contractorNameList, cnsMenuIdList, selectAllMealList } from "../../actionCreators/admin/contractorBook";
import ContractorBookService from "../../../services/admin/contractorBook"
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* createContractorBookEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ContractorBookService.create, action.payload);

    yield put(contractorBookList(data));
    yield put(cnsMenuIdList(data));
    yield put(updateFalseLoader());
    yield put(updateAlert('Created Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e.response?.data.message, 'error'));
  }
}

function* cancelContractorBookEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ContractorBookService.cancel, action.id, action.payload);
    // action.response(data)
    yield put(contractorBookList(data));
    yield put(cnsMenuIdList(data));
    yield put(updateFalseLoader());
    yield put(updateAlert('Cancelled', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getContractorNameListEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ContractorBookService.getContractorNameList, action.payload);

    yield put(contractorNameList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getCnsListEffect(action) {
  try {
    yield put(updateOpenLoader());
    const api = !action.isCon ? ContractorBookService.getMealQuantity : ContractorBookService.getMealQuantityCon
    let { data } = yield call(api , action.payload);
    yield put(cnsMenuIdList(data));
    // yield put(cnsRateList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* selectAllMealEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ContractorBookService.selectAllMeal, action.payload);
    yield put(selectAllMealList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}


//saga 
export const ContractorBookSaga = [
  takeEvery(CREATE_CONTRACTOR_BOOK_WATCHER, createContractorBookEffect),
  takeEvery(CONTRACTOR_NAME_LIST_WATCHER, getContractorNameListEffect),
  takeEvery(CANCEL_CONTRACTOR_BOOK_WATCHER, cancelContractorBookEffect),
  takeEvery(CNS_WATCHER, getCnsListEffect),
  takeEvery(ALl_MEAL_WATCHER, selectAllMealEffect),
]


