import React from 'react';
import { Card, Grid, Button, InputAdornment, Typography, Stack } from "@mui/material";
import SearchField from '../../overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { getConvertedDate } from '../../../utils/common';
import { ExportCsv } from "@material-table/exporters";
import BlackButton from '../../overrides/blackButton';

const RatingHeaderCard = ({ handleSearch, searchText, handleDateAction, rowData, columnData, setRatingListLocal, ratingList }) => {

    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);

    const handleFromChange = (e) => {
        // let convertedDate = getConvertedDate(e)
        e.setHours(0, 0, 0, 0)
        setFromDate(e)
        // setTimeout(() => {
        //     if(toDate && convertedDate){
        //         handleDateAction(fromDate, toDate)
        //     }
        // }, 1000);
    }

    const handleToChange = (e) => {
        // let convertedDate = getConvertedDate(e)
        e.setHours(0, 0, 0, 0)
        setToDate(e)
        // setTimeout(() => {
        //     if(fromDate && convertedDate){
        //         handleDateAction(fromDate, toDate)
        //     }
        // }, 1000);
    }

    const handleSubmit = () => {

        const filterList = ratingList.filter(d => {
            const date = new Date(d.RateDate);
            return (date >= fromDate && date <= toDate);
        })

        setRatingListLocal(filterList)
    }

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >
                    <Grid item lg={4} xs={12} md={4} sm={12} sx={{ justifyContent: 'left' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: 50
                                }
                            }}
                            placeholder='Search'
                            fullWidth
                            value={searchText}
                            onChange={handleSearch}
                        />
                    </Grid>
                    <Grid item lg={8} xs={12} md={8} sm={12} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={fromDate}
                                    label='From Date'
                                    onChange={handleFromChange}
                                    renderInput={(params) => <SearchField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: 50
                                        },
                                        '& label': {
                                            fontSize: '.9rem'
                                        }
                                    }} fullWidth {...params} />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={toDate}
                                    label='To Date'
                                    onChange={handleToChange}
                                    renderInput={(params) => <SearchField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: 50
                                        },
                                        '& label': {
                                            fontSize: '.9rem'
                                        }
                                    }} fullWidth {...params} />}
                                />
                            </LocalizationProvider>

                            <BlackButton variant='contained' sx={{ height: '50px', minWidth: '100px'}} onClick={handleSubmit}>
                                <Typography variant='t16_400_1' >Submit</Typography>
                            </BlackButton>

                            <Button variant='contained' sx={{ height: '50px', minWidth: '200px' }} endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(columnData, rowData, "Exported file")}>
                                <Typography variant='t16_500'>Export to Excel</Typography>
                            </Button>

                        </Stack>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default RatingHeaderCard;