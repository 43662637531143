import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import OrderSummary from "../orderSummary";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateAlert } from "../../../../redux/actionCreators/alert";

export default function TemporaryDrawer() {
  const [state, setState] = React.useState(false);
  const dispatch = useDispatch()

  const { UserKioskReducers: {kioskCartList} } = useSelector((state) => state);

  const getTotal = () => {
    let total = 0
    kioskCartList.forEach(m => {
      total += +m.nTotalPrice
    })
    return total
  }

  const toggleDrawer = (open) => () => {
    if(!getTotal()){
      return dispatch(updateAlert('Cart is empty', 'error'));
    }
    setState(open)
  };

  return (
    <React.Fragment>
      <Button variant="contained" sx={{height: 44}} onClick={toggleDrawer(true)}>
        <Typography variant="t16_400">
          Book Now
        </Typography>
      </Button>
      
      <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
        <OrderSummary toggleDrawer={toggleDrawer} />
      </Drawer>
    </React.Fragment>
  );
}
