import React, { useState } from 'react';
import { alpha, Box, Grid } from '@mui/material';
import { Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useSelector } from 'react-redux';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import { getSwipeOrderSummaryWatcher } from '../../../../redux/actionCreators/admin/orderSummary';
import SwipeTable from './table';
import { Dialog, DialogContent } from '@mui/material';

const CreationDialog = (props) => {

    const { open, setOpen, children } = props;
    return (
        <>
            <Dialog
                fullWidth
                maxWidth='md'
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent >
                    {children}
                </DialogContent>
            </Dialog>
        </>
    )
}

export default function IconCard({ tabVal }) {
    // const dispatch = useDispatch();

    const { OrderSummaryReducers: { orderSummaryFilteredData } } = useSelector((state) => state)
    const [open, setOpen] = useState(false);
    const [type] = useState('');

    const cardValue = orderSummaryFilteredData.length ? orderSummaryFilteredData[0] : {}

    const getPercent = (value1, value2) => {
        const get = Math.round((+value1 / +value2) * 100)
        return (get === Infinity || isNaN(get)) ? 0 : get
    }

    // const swipeIconClick = () => {
    //     setOpen(true);
    //     setType('swipe')
    //     if (tabVal === 1) {
    //         let data = {
    //             "cAction": "lastDaySwiped"
    //         }
    //         dispatch(getSwipeOrderSummaryWatcher(data))
    //     }
    //     if (tabVal === 0) {
    //         let data = {
    //             "cAction": "lastWeekSwiped"
    //         }
    //         dispatch(getSwipeOrderSummaryWatcher(data))
    //     }
    // }

    // const unSwipeIconClick = () => {
    //     setOpen(true);
    //     setType('unswipe')
    //     if (tabVal === 1) {
    //         let data = {
    //             "cAction": "lastDayUnSwiped"
    //         }
    //         dispatch(getSwipeOrderSummaryWatcher(data))
    //     }
    //     if (tabVal === 0) {
    //         let data1 = {
    //             "cAction": "lastWeekUnSwiped"
    //         }
    //         dispatch(getSwipeOrderSummaryWatcher(data1))
    //     }
    // }

    const { TodayTotalBooking, LastDayTotalBooking, TodayWeekTotalBooking, TotalBooking, TodayWeekSwiped, Swiped, TodayWeekUnSwiped, UnSwiped, TodaySwipe, LastDaySwiped, TodayUnSwiped, LastDayUnSwipe } = cardValue

    return (
        <>
            <Grid container justifyContent='center' spacing={3} sx={{ padding: '30px' }}>
                <Grid item xs={12} lg={4} md={6} sm={6}>
                    <Card sx={{ height: '124px', border: (theme) => `1px solid ${alpha(theme.palette.common.d1, 0.2)}`, boxShadow: (theme) => `0px 12px 23px ${alpha(theme.palette.common.d1, 0.2)}` }}>
                        <Box display='flex' height='100%' p='20px' alignItems='center' >
                            <img src='/static/admin/coffee.svg' alt='' />
                            <Box width='100%' pl='16px'>
                                <Typography variant='t32_600' >
                                    {tabVal === 1 ? cardValue.TodayTotalBooking : cardValue.TotalBooking}
                                </Typography>
                                <Box display='flex' mt='4px' alignItems='center'>
                                    <Typography variant='t16_500' >
                                        {'Total Booking'}
                                    </Typography>
                                    <Box display='flex' pl='5px' ml='auto' alignItems='center'>
                                        <ShowChartIcon sx={{ color: 'primary.icon' }} />
                                        <Typography variant='t12_400' >
                                            {tabVal === 1 ? getPercent(TodayTotalBooking, LastDayTotalBooking) : getPercent(TodayWeekTotalBooking, TotalBooking)}%
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={12} lg={4} md={6} sm={6}>
                    <Card sx={{ height: '124px', border: (theme) => `1px solid ${alpha(theme.palette.common.d3, 0.2)}`, boxShadow: (theme) => `0px 12px 23px ${alpha(theme.palette.common.d3, 0.2)}` }}>
                        <Box display='flex' height='100%' p='20px' alignItems='center' >
                            <img src='/static/admin/coffee.svg' alt='' />
                            <Box width='100%' pl='16px'>
                                <Box display='flex' >
                                    <Typography variant='t32_600'>
                                        {tabVal === 1 ? cardValue.TodaySwipe : cardValue.Swiped}
                                    </Typography>
                                    {/* <Box display='flex' pl='5px' ml='auto' mt='-35px' alignItems='center'>
                                        <IconButton onClick={swipeIconClick}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Box> */}
                                </Box>
                                <Box display='flex' mt='4px' alignItems='center'>
                                    <Typography variant='t16_500' >
                                        {'Swiped'}
                                    </Typography>
                                    <Box display='flex' pl='5px' ml='auto' alignItems='center'>
                                        <ShowChartIcon sx={{ color: 'primary.icon' }} />
                                        <Typography variant='t12_400' >
                                            {tabVal === 1 ? getPercent(TodaySwipe, LastDaySwiped) : getPercent(TodayWeekSwiped, Swiped)}%
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={12} lg={4} md={6} sm={6}>
                    <Card sx={{ height: '124px', border: (theme) => `1px solid ${alpha(theme.palette.common.d2, 0.2)}`, boxShadow: (theme) => `0px 12px 23px ${alpha(theme.palette.common.d2, 0.2)}` }}>
                        <Box display='flex' height='100%' p='20px' alignItems='center' >
                            <img src='/static/admin/coffee.svg' alt='' />
                            <Box width='100%' pl='16px'>
                                <Box display='flex' >
                                    <Typography variant='t32_600' >
                                        {tabVal === 1 ? cardValue.TodayUnSwiped : cardValue.UnSwiped}
                                    </Typography>
                                    {/* <Box display='flex' pl='5px' ml='auto' mt='-35px' alignItems='center'>
                                        <IconButton onClick={unSwipeIconClick}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Box> */}
                                </Box>
                                <Box display='flex' mt='4px' alignItems='center'>
                                    <Typography variant='t16_500' >
                                        {'unSwiped'}
                                    </Typography>
                                    <Box display='flex' pl='5px' ml='auto' alignItems='center'>
                                        <ShowChartIcon sx={{ color: 'primary.icon' }} />
                                        <Typography variant='t12_400' >
                                            {tabVal === 1 ? getPercent(TodayUnSwiped, LastDayUnSwipe) : getPercent(TodayWeekUnSwiped, UnSwiped)}%
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <CreationDialog open={open} setOpen={setOpen} >
                <SwipeTable open={open} setOpen={setOpen} type={type} />
            </CreationDialog >
        </>
    );
}
