import { CONTRACTOR_BOOK_LIST, CONTRACTOR_NAME_LIST, CNS_MENUID_LIST, CNS_RATE_LIST, ALl_MEAL_LIST } from "../../actionTypes/admin";

const intialState = {
  contractorBookList: [],
  contractorNameList: [],
  menuIdList: [],
  rateList: [],
  allMealList: []
};

export default function ContractorBookReducers(state = intialState, action) {
  switch (action.type) {

    case CONTRACTOR_BOOK_LIST:
      return { ...state, contractorBookList: action.payload };

    case CONTRACTOR_NAME_LIST:
      return { ...state, contractorNameList: action.payload };

    case CNS_MENUID_LIST:
      return { ...state, menuIdList: action.payload };

    case CNS_RATE_LIST:
      return { ...state, rateList: action.payload };

    case ALl_MEAL_LIST:
      return { ...state, allMealList: action.payload };

    default:
      return state;
  }
}
