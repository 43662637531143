import React, { useEffect, useState, useRef } from 'react';
import {
    TableCell,
    Typography,
    TableRow,
    Autocomplete
} from '@mui/material';
import ActionButtons from '../../dashboard/actionButtons';
import EditTextField from '../../../overrides/editField';
import { updatefreezeBookingWatcher, deletefreezeBookingWatcher, getSpecialMenuWatcher } from '../../../../redux/actionCreators/admin/carousel';
import { useDispatch, useSelector } from 'react-redux'
// import { styled } from '@mui/material/styles';
import CssTextField from '../../../overrides/longSearchField'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getCurDate } from '../../../../utils/common';

// const Input = styled('input')({
//     display: 'none',
// });

const FreezeTableRow = ({ rowData, index }) => {

    const dispatch = useDispatch();

    const [status, setStatus] = useState('list');
    const [editData, setEditData] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    // const [isEdit, setEdit] = useState(false)
    const [mealVal, setMealVal] = useState('')
    const tempinitsform = useRef();

    const { MenuMasterReducers: { mealDropdownList }, CarouselReducers: {specialMenu} } = useSelector((state) => state)

    const mealNameList = (mealDropdownList.length && mealDropdownList[1]) || []
    // const [imageName, setImageName] = useState('')

    const handleEditChange = (e) => {
        const { name, value } = e.target
        setEditData({ ...editData, [name]: value })
    }

    const handleEdit = () => {
        let data = { ...rowData}

        setEditData(data);
        setStatus('edit');

        const apidata = {
            ACTION: 'SelectSpecial',
            mealName: `${MealNames}`
        }
        dispatch(getSpecialMenuWatcher(apidata))
        // setEdit(true)
    }

    const handleDelete = async () => {
        let data = { ACTION: "DELETE" }
        await dispatch(deletefreezeBookingWatcher(rowData.nID, { data }))
        setStatus('list');
        setDeleteDialogOpen(false);
    }

    const tickIconFunction = async () => {
        let values = { ...editData }
        let data = {
            ACTION: "UPDATE",
            freezeDate: getCurDate(new Date(values.freezeDate)),
            message: values.message,
            mealName: values.mealName,
            menuName: values.menuName,
            // menuName: values.menuName.map(d=>d.cMenuName)?.join(','),
        }
        await dispatch(updatefreezeBookingWatcher(values.nID, data));
        setStatus('list');
    }

    const cancelIconFunction = () => {
        setEditData({});
        setStatus('list');
    }

    const initsform = () => {
        if (mealVal) {

            const apidata = {
                ACTION: 'SelectSpecial',
                mealName: mealVal
            }
            dispatch(getSpecialMenuWatcher(apidata))
        }
    };
    
    tempinitsform.current = initsform;
    
    useEffect(() => {
       tempinitsform.current();
    }, [mealVal]);

    // useEffect(()=>{
    //     if (mealVal) {

    //     const apidata = {
    //         ACTION: 'SelectSpecial',
    //         mealName: mealVal
    //     }
    //     dispatch(getSpecialMenuWatcher(apidata))
    // }
    // },[mealVal])

    let MealNames = rowData.mealName?.split(',') || []
    let getMealNames = mealNameList.filter(m => MealNames.includes(m.cMealName)) || []

    let MenuNames = rowData.menuName?.split(',') || []
    let getMenuNames = MenuNames.map(d=>({cMenuName: d}))
    
    return (
        // <>
            <TableRow
                hover={status === 'list' ? true : false}
                tabIndex={-1}
            >
                {status === 'list' ?
                    <>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.sID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.freezeDate}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.message}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.mealName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.menuName}
                            </Typography>
                        </TableCell>
                    </>
                    :
                    <>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.nID}
                            </Typography>
                        </TableCell>

                        <TableCell align="left">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={editData.freezeDate}
                                    label='Freeze Date'
                                    name="freezeDate"
                                    onChange={(e) => setEditData({ ...editData, freezeDate: e })}
                                    renderInput={(params) => <CssTextField {...params}
                                        sx={{
                                            "& .MuiOutlinedInput-input": {
                                                height: '16px',
                                            },
                                        }} fullWidth {...params}
                                    />}
                                />
                            </LocalizationProvider>
                        </TableCell>

                        <TableCell align="left">
                            <EditTextField
                                name='message'
                                value={editData.message}
                                onChange={handleEditChange}
                            />
                        </TableCell>

                        <TableCell align="left">
                            <Autocomplete
                                id="combo-box-demo"
                                name="nAccessPoint"
                                multiple
                                fullWidth
                                options={mealNameList}
                                size="small"
                                autoHighlight
                                defaultValue={getMealNames}
                                // value={roleName.name}
                                onChange={(event, value) => {
                                    let Val = value.map(m => m.cMealName)
                                    setEditData({ ...editData, mealName: `${Val}` });
                                    setMealVal(`${Val}`);
                                }}
                                getOptionLabel={(option) => option.cMealName}
                                renderInput={(params) => <EditTextField {...params}
                                    sx={{ minWidth: '160px', '& .MuiOutlinedInput-root': { height: 'auto' } }}
                                    name='mealName'
                                />}
                            />
                        </TableCell>

                        <TableCell align="left">
                            <Autocomplete
                                id="combo-box-demo"
                                name="nAccessPoint"
                                multiple
                                fullWidth
                                options={specialMenu}
                                size="small"
                                autoHighlight
                                defaultValue={getMenuNames}
                                // value={editData.menuName}
                                // value={roleName.name}
                                onChange={(event, value) => {
                                    let Val = value.map(m => m.cMenuName)
                                    setEditData({ ...editData, menuName: `${Val}` });
                                }}
                                isOptionEqualToValue={(option, value)=>option.cMenuName === value.cMenuName}
                                getOptionLabel={(option) => option.cMenuName}
                                renderInput={(params) => <EditTextField {...params}
                                    sx={{ minWidth: '160px', '& .MuiOutlinedInput-root': { height: 'auto' } }}
                                    name='menuName'
                                />}
                            />
                        </TableCell>
                    </>
                }
                <TableCell align="right" sx={{ width: '200px' }}>
                    <ActionButtons type={'carousel'} setStatus={setStatus} status={status} setEditData={setEditData} rowData={rowData} tickIconFunction={tickIconFunction} handleDelete={handleDelete} handleEdit={handleEdit} cancelIconFunction={cancelIconFunction} setDeleteDialogOpen={setDeleteDialogOpen} deleteDialogOpen={deleteDialogOpen} />
                </TableCell>
            </TableRow>
        // </>
    )
}

export default FreezeTableRow