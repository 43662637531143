import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Dialog from "../../components/dialog";
import CssTextField from "../../components/overrides/longSearchField";
import BlackButton from "../../components/overrides/blackButton";
// import { forgetPasswordWatcher } from "../../redux/actionCreators/profile";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { useDispatch } from "react-redux";
import { senMailWatcher } from "../../redux/actionCreators/profile";

const Dialogcontent = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  const Schema = Yup.object().shape({
    userName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("User name is required"),
    mailId: Yup.string("Enter your EmailId")
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      userName: "",
      mailId: "",
    },
    validationSchema: Schema,

    onSubmit: () => {
      let { userName, mailId } = { ...formik.values };
      const base_url = window.location.origin;
      dispatch(senMailWatcher({ userName, mailId, base_url }, setOpen));
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } =
    formik;

  return (
    <Dialog open={open} setOpen={setOpen} maxWidth='sm'>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Box p="20px" display="flex" flexDirection="column">
            <Typography variant="t20_600">Forgot Password</Typography>

            <Box mt="52px">
              <Typography variant="t14_600">User Name</Typography>
              <CssTextField
                sx={{
                  mt: "7px",
                  "& .MuiOutlinedInput-input": {
                    height: "12px",
                  },
                }}
                fullWidth
                {...getFieldProps("userName")}
                error={Boolean(touched.userName && errors.userName)}
                helperText={touched.userName && errors.userName}
              />
            </Box>
            <Box mt="32px">
              <Typography variant="t14_600">Email Id</Typography>
              <CssTextField
                sx={{
                  mt: "7px",
                  "& .MuiOutlinedInput-input": {
                    height: "12px",
                  },
                }}
                fullWidth
                {...getFieldProps("mailId")}
                error={Boolean(touched.mailId && errors.mailId)}
                helperText={touched.mailId && errors.mailId}
              />
            </Box>

            <Box mt="47px" ml="auto">
              <Button
                variant="contained"
                sx={{
                  height: 42,
                  width: 120,
                  mr: "19px",
                  backgroundColor: (theme) => theme.palette.common.cButton,
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.common.cButton,
                  },
                  color: "common.black",
                }}
                onClick={() => setOpen(false)}
                type="button"
              >
                <Typography>Cancel</Typography>
              </Button>
              <BlackButton type="submit" sx={{ height: 42, width: 120 }}>
                <Typography>Send</Typography>
              </BlackButton>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default Dialogcontent;
