import React, { useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
import { getCRoleSwipeHistoryWatcher } from '../../../redux/actionCreators/contractor';
import { useSelector, useDispatch } from 'react-redux';
import { TABLE_HEAD } from '../../../components/admin/reports/swipeHistory/table';
import CRoleSwipeHistoryTable from '../../../components/contractor/reports/cRoleSwipeHistory/table';
import CRoleSwipeHistoryHeaderCard from '../../../components/contractor/reports/cRoleSwipeHistory/headerCard';

const CRoleSwipeHistory = () => {

    const { ContractorRoleReducers: { CRoleSwipeHistoryList }, ProfileReducers: { loginState } } = useSelector((state) => state)

    const nContractorUser = loginState[0]?.nContractorID
    || 0
    const nCuser = String(nContractorUser);

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [selectedMeal, setSelectedMeal] = useState('Breakfast');
    const [fromDate, setFromDate] = React.useState(new Date());
    const [toDate, setToDate] = React.useState(new Date());
    const [page, setPage] = useState(1);

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = CRoleSwipeHistoryList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        const data = {
            MealType: selectedMeal,
            SwipeDate: fromDate,
            SwipeDateTo: toDate,
            nContractorID: nCuser
        }
        dispatch(getCRoleSwipeHistoryWatcher(data))
        setPage(1)
    }

    const selectedMealHandler = (val) => {
        setSelectedMeal(val)
    }

    // const selectedMealHandler = useCallback((val) => {
    //     setSelectedMeal(val)
    // }, [selectedMeal])

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports", "Contractor Swipe History"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Contractor Swipe History</Typography>
                </Box>
                <Box p='10px 0'>
                    <CRoleSwipeHistoryHeaderCard selectedMealHandler={selectedMealHandler} handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={CRoleSwipeHistoryList} columnData={TABLE_HEAD} setFromDate={setFromDate} setToDate={setToDate} toDate={toDate} fromDate={fromDate}/>
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <CRoleSwipeHistoryTable page={page} setPage={setPage} selectedMeal={selectedMeal} searchVal={searchVal} searchText={searchText} toDate={toDate} fromDate={fromDate} />
                </Card>

            </Box>
        </>
    )
}

export default CRoleSwipeHistory;