import { vendorList } from "../../actionCreators/admin/vendorManagement";
import { call, put, takeEvery } from "redux-saga/effects";
import VendorManagementService from "../../../services/admin/vendorManagement";
import { CREATE_VENDOR_WATCHER, UPDATE_VENDOR_WATCHER, DELETE_VENDOR_WATCHER, GET_VENDOR_WATCHER, GET_CAB_VENDOR_WATCHER, CREATE_CAB_VENDOR_WATCHER, UPDATE_CAB_VENDOR_WATCHER, DELETE_CAB_VENDOR_WATCHER } from "../../actionTypes/admin";
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* getVendorEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(VendorManagementService.getAll, action.payload);

    yield put(vendorList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong', 'error'));
  }
}

function* createVendorEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(VendorManagementService.create, action.payload);
    action.setOpen(false)
    yield put(vendorList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Created Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong', 'error'));
  }
}

function* updateVendorEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(VendorManagementService.update, action.id, action.payload);
    yield put(vendorList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Updated Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong', 'error'));
  }
}

function* deleteVendorEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(VendorManagementService.delete, action.id, action.payload);

    yield put(vendorList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Deleted Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong', 'error'));
  }
}

function* getCabVendorEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(VendorManagementService.cabVendor, action.payload);

    yield put(vendorList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong', 'error'));
  }
}

function* createCabVendorEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(VendorManagementService.cabVendor, action.payload);
    action.setOpen(false)
    yield put(vendorList(data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Created Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong', 'error'));
  }
}

function* updateCabVendorEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(VendorManagementService.cabVendor, action.payload);
    yield put(vendorList(data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Updated Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong', 'error'));
  }
}

function* deleteCabVendorEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(VendorManagementService.cabVendor, action.payload);

    yield put(vendorList(data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Deleted Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong', 'error'));
  }
}

//saga 
export const VendorManagementSaga = [
  takeEvery(GET_VENDOR_WATCHER, getVendorEffect),
  takeEvery(CREATE_VENDOR_WATCHER, createVendorEffect),
  takeEvery(UPDATE_VENDOR_WATCHER, updateVendorEffect),
  takeEvery(DELETE_VENDOR_WATCHER, deleteVendorEffect),
  takeEvery(GET_CAB_VENDOR_WATCHER, getCabVendorEffect),
  takeEvery(CREATE_CAB_VENDOR_WATCHER, createCabVendorEffect),
  takeEvery(UPDATE_CAB_VENDOR_WATCHER, updateCabVendorEffect),
  takeEvery(DELETE_CAB_VENDOR_WATCHER, deleteCabVendorEffect)
]

