import React, { useEffect, useState, useRef } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
import RatingHeaderCard from '../../../components/user/myRatings/headerCard';
import MyRatingsTable from '../../../components/user/myRatings/myRatingtable';
import { useDispatch, useSelector } from 'react-redux';
import { ratingListWatcher } from '../../../redux/actionCreators/user/rating';
import {TABLE_HEAD} from '../../../components/user/myRatings/myRatingtable';

const MyRatings = () => {

    const dispatch = useDispatch()
    const { UserRatingReducers: { ratingList }, ProfileReducers: {loginState} } = useSelector(state => state)

    // const [open, setOpen] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const [ratingListLocal, setRatingListLocal] = useState([])
    const tempinitsform = useRef();
    const tempinitform = useRef();
    const nUserID = loginState[0]?.nID || 0

    const initsform = () => {
        const userId = nUserID
        const data = {"Action":"userRateList"}
        dispatch(ratingListWatcher(userId, data))
    };
    
    tempinitsform.current = initsform;
    
    useEffect(() => {
       tempinitsform.current();
    }, []);

    // useEffect(()=>{
    //     const userId = nUserID
    //     const data = {"Action":"userRateList"}
    //     dispatch(ratingListWatcher(userId, data))
    // },[])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = ratingListLocal.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleDateAction = (fromDate, toDate) => {
        const userId = nUserID
        const data = {"Action":"userRateList"}
        // const data = {
        //     ACTION: "AdminSearchContractorList",
        //     FromDate: fromDate,
        //     ToDate: toDate,
        //     UserId: "33",
        // }
        dispatch(ratingListWatcher(userId, data))
        setPage(1)
    }

    const initform = () => {
        setRatingListLocal(ratingList)
    };
    
    tempinitform.current = initform;
    
    useEffect(() => {
       tempinitform.current();
    }, [ratingList]);
    

    // useEffect(() => {
    //     setRatingListLocal(ratingList)
    // }, [ratingList])

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["My Order", "Order History"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">My Ratings</Typography>
                </Box>
                <Box p='10px 0'>
                    <RatingHeaderCard handleSearch={handleSearch} searchText={searchText} handleDateAction={handleDateAction} setRatingListLocal={setRatingListLocal} rowData = {ratingListLocal} ratingList={ratingList} columnData ={TABLE_HEAD}/>
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <MyRatingsTable page={page} setPage={setPage} ratingList={ratingListLocal} searchVal={searchVal} searchText={searchText}/>
                </Card>

            </Box>
        </>
    )
}

export default MyRatings;