//Admin
import AdminDashboard from "../pages/admin/dashboard";
// User Managemnet
import EmployeeManagement from "../pages/admin/userManagement/employeeManagement";
import ContractorManagement from "../pages/admin/userManagement/contractorManagement";
import VendorManagement from "../pages/admin/userManagement/vendorManagement";
import GuestManagement from "../pages/admin/userManagement/guestManagement";
import CabVendor from "../pages/admin/userManagement/cabVendor";
// Kitchen
import MenuMaster from "../pages/admin/kitchen/menuMaster";
import MenuItemMaster from "../pages/admin/kitchen/menuItemMaster";
import ContractorBook from "../pages/admin/kitchen/contractorBook";
import GuestBook from "../pages/admin/kitchen/guestBook";
import Roster from "../pages/admin/kitchen/rosterManagement/roster";
// Reports
import AdminContractorInvoice from "../pages/admin/reports/contractorInvoice";
import AdminDeliveryHistory from "../pages/admin/reports/deliveryHistory";
import AdminEmpCon from "../pages/admin/reports/emp_con";
// import AdminSpecialTracking from "../pages/admin/reports/specialTracking";
import AdminRating from "../pages/admin/reports/rating";
import AdminNotification from "../pages/admin/reports/pushNotification";
import AdminSaleReport from "../pages/admin/reports/saleReport";
import AdminOrderTracking from "../pages/admin/reports/orderTracking";
import KioskOrderTracking from "../pages/admin/reports/kioskOrderTracking";
import UserHistory from "../pages/admin/reports/userHistory";
import KioskUserHistory from "../pages/admin/reports/kioskUserHistory";
import ContractorSwipeHistory from "../pages/admin/reports/swipeHistory";
// import SpecialHistory from "../pages/admin/reports/specialHistory";
import LoginCarousel from "../pages/admin/reports/Carousel";
// import UserCarousal from "../pages/admin/reports/userCarousel";
import Feedbacks from "../pages/admin/reports/feedbacks";
import AdminOrderHistory from "../pages/admin/reports/orderHIstory";
import AdminDayByDayOrder from "../pages/admin/reports/dayByDayOrder";
import CabBooking from "../pages/user/cab/cabBooking";
import GuestOrderHistory from "../pages/admin/reports/guestOrderHistory";
import GuestDeliveryHistory from "../pages/admin/reports/guestDeliveryHistory";
import GuestSwipeHistory from "../pages/admin/reports/guestSwipeHistory";
import Activity from "../pages/admin/reports/activity";
import FreezeIndex from "../pages/admin/reports/freeze";
import EmpDeliveryHistory from "../pages/admin/reports/EmpDeliveryHistory";

//profile
import Profile from '../pages/profile'
import ChangePassword from '../pages/changePassword'
//layout
import NavLayout from '../layouts/dashboard';
import CabReports from "../pages/admin/reports/cabReports";
import SpecialHistory from "../pages/admin/reports/specialHistory";
import DynamicNotification from "../pages/admin/dynamicNotification";

const Admin = {
  path: "/admin",
  element: <NavLayout />,
  children: [
    { index: true, element: <AdminDashboard /> },
    // Profile
    { path: "profile", element: <Profile /> },
    { path: "changePassword", element: <ChangePassword /> },
    // User Managemnet
    { path: "user", element: <EmployeeManagement /> },
    { path: "contractor", element: <ContractorManagement /> },
    { path: "vendor", element: <VendorManagement /> },
    { path: "guest", element: <GuestManagement /> },
    { path: "cabVendor", element: <CabVendor /> },
    // Kitchen
    { path: "menuMaster", element: <MenuMaster /> },
    { path: "menuItemMaster", element: <MenuItemMaster /> },
    { path: "contractBook", element: <ContractorBook /> },
    { path: "guestBook", element: <GuestBook /> },
    { path: "rosterManagement", element: <Roster /> },
    // Reports
    { path: "userHistory", element: <UserHistory /> },
    { path: "kioskUserHistory", element: <KioskUserHistory /> },
    { path: "specialHistory", element: <SpecialHistory /> },
    { path: "orderHistory", element: <AdminOrderHistory /> },
    { path: "adminDayByDayOrder", element: <AdminDayByDayOrder /> },
    { path: "loginCarousel", element: <LoginCarousel /> },
    // { path: "foodWastage", element: <UserCarousal /> },
    { path: "contractorInvoice", element: <AdminContractorInvoice /> },
    { path: "deliveryHistory", element: <AdminDeliveryHistory /> },
    { path: "guestDeliveryHistory", element: <GuestDeliveryHistory /> },
    { path: "user_contractor", element: <AdminEmpCon /> },
    // { path: "specialTracking", element: <AdminSpecialTracking /> },
    { path: "rating", element: <AdminRating /> },
    { path: "notification", element: <AdminNotification /> },
    { path: "orderTracking", element: <AdminOrderTracking /> },
    { path: "kioskOrderTracking", element: <KioskOrderTracking /> },
    { path: "saleReport", element: <AdminSaleReport /> },
    { path: "contractorSwipeHistory", element: <ContractorSwipeHistory /> },
    { path: "guestSwipeHistory", element: <GuestSwipeHistory /> },
    { path: "feedbacks", element: <Feedbacks /> },
    { path: "cabBooking", element: <CabBooking /> },
    { path: "guestOrderHistory", element: <GuestOrderHistory /> },
    { path: "activity", element: <Activity /> },
    { path: "empDeliveryHistory", element: <EmpDeliveryHistory /> },
    { path: "freezeBooking", element: <FreezeIndex /> },
    { path: "cabReports", element: <CabReports /> },
    { path: "scheduleNotification", element: <DynamicNotification /> },
  ],
};

export default Admin;
