import React, { useState } from 'react';
import { Typography, Card, Grid, Button, Box, InputAdornment, Stack } from "@mui/material";
import SearchField from '../../../overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import Breadcrumbs from "../../../breadcrumbs";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import MenuItemTab from './tab';
import CreationDialog from '../../../dialog';
import NewMenuItemMaster from './NewMenuItemMaster';

const FilterCard = ({ searchText, handleSearch, setTableArr, tabVal, setTabVal }) => {

    const [open, setOpen] = useState(false);

    return (
        <>
            <Breadcrumbs content={['Kitchen', 'Menu Item Master']} />
            <Box p='15px 0'>
                <Typography variant='t24_700' >Menu Item Master </Typography>
            </Box>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >
                    <Grid item lg={3} xs={12} md={3} sm={12} sx={{ justifyContent: 'left' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px'
                                }
                            }}
                            placeholder='Search'
                            fullWidth
                            value={searchText}
                            onChange={handleSearch}
                        />
                    </Grid>
                    <Grid item lg={9} xs={12} md={9} sm={12} sx={{ justifyContent: 'center', color: 'common.black' }} >

                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} >
                            <MenuItemTab setTableArr={setTableArr} setTabVal= {setTabVal} tabVal={tabVal}/>

                            <Box display='flex' width={{ xs: '100%', md: 'auto' }} >
                                <Button variant='contained' endIcon={<DoubleArrowIcon />} sx={{ height: '50px', width: 161, ml: 'auto' }} onClick={() => setOpen(true)}>
                                    <Typography variant='t16_500'> Add Items </Typography>
                                </Button>
                            </Box>

                        </Stack>

                    </Grid>
                </Grid>
            </Card>
            <CreationDialog open={open} setOpen={setOpen} >
                <NewMenuItemMaster setOpen={setOpen} />
            </CreationDialog>
        </>
    )
}

export default FilterCard;