// Contractor
import Dashboard from '../pages/foodClerk';

//profile
import Profile from '../pages/profile'
import ChangePassword from '../pages/changePassword'
//layout
import NavLayout from '../layouts/dashboard';

const FoodClerk =  {
  path: '/foodClerk',
  element: <NavLayout />,
  children: [
    { index: true, element: <Dashboard /> },
    { path: "profile", element: <Profile /> },
    { path: "changePassword", element: <ChangePassword /> },
  ]
}

export default FoodClerk;
