import { VENDOR_LIST } from "../../actionTypes/admin";

const intialState = {
  vendorList: [],
};

export default function VendorReducers(state = intialState, action) {
  switch (action.type) {

    case VENDOR_LIST:
      return { ...state, vendorList: action.payload };

    default:
      return state;
  }
}
