import React, { useState } from 'react';
import { Card, Grid, Button, Stack, Typography } from "@mui/material";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useSelector } from 'react-redux';
import { CSVLink } from "react-csv";
import { getBreakfastDayByDayOrderWatcher, getLunchDayByDayOrderWatcher, getDinnerByDayOrderWatcher, getSnacksByDayOrderWatcher, getSupperByDayOrderWatcher } from '../../../../redux/actionCreators/admin/reports';
import { useDispatch } from 'react-redux'
import { getCurDate } from "../../../../utils/common";

const DayByDayHeader = () => {

    const { ReportsReducers: { BreakfastList, LunchList, DinnerList, SnacksList, SupperList } } = useSelector((state) => state)
    const dispatch = useDispatch();
    const getDate = getCurDate();
    const [tabVal, setTabVal] = useState(1);
    const brHead = Object.keys(BreakfastList[0]?.[0] || {}) 
    const dnHead = Object.keys(LunchList[0]?.[0] || {}) 
    const lnHead = Object.keys(DinnerList[0]?.[0] || {}) 
    const snHead = Object.keys(SnacksList[0]?.[0] || {}) 
    const erHead = Object.keys(SupperList[0]?.[0] || {}) 

    const brRow = BreakfastList[0]?.map(d=> Object.values(d)) || []
    const dnRow = LunchList[0]?.map(d=> Object.values(d)) || []
    const lnRow = DinnerList[0]?.map(d=> Object.values(d)) || []
    const snRow = SnacksList[0]?.map(d=> Object.values(d)) || []
    const erRow = SupperList[0]?.map(d=> Object.values(d)) || []


    const data = [
        ["", "BreakFast", ""],
        brHead,
        ...brRow,
        ["", "Dinner", ""],
        dnHead,
        ...dnRow,
        ["", "Supper", ""],
        lnHead,
        ...lnRow,
        ["", "Lunch", ""],
        snHead,
        ...snRow,
        ["", "Snacks", ""],
        erHead,
        ...erRow,
      ];

      const todayFunc = () => {
          setTabVal(1);
            let Bdata = {
                "Action": "GetDayByDay",
                "Mela": "Breakfast",
                "Date": getDate
            }
            dispatch(getBreakfastDayByDayOrderWatcher(Bdata))
    
            let Ldata = {
                "Action": "GetDayByDay",
                "Mela": "Lunch",
                "Date": getDate
            }
            dispatch(getLunchDayByDayOrderWatcher(Ldata))
    
            let Sdata = {
                "Action": "GetDayByDay",
                "Mela": "Snacks",
                "Date": getDate
            }
            dispatch(getSnacksByDayOrderWatcher(Sdata))
    
            let Ddata = {
                "Action": "GetDayByDay",
                "Mela": "Dinner",
                "Date": getDate
            }
            dispatch(getDinnerByDayOrderWatcher(Ddata))
    
            let SRdata = {
                "Action": "GetDayByDay",
                "Mela": "Supper",
                "Date": getDate
            }
            dispatch(getSupperByDayOrderWatcher(SRdata))    
      }

      const weekFunc = () => {
        setTabVal(2);
        var curr = new Date();
        var first = curr.getDate() - curr.getDay();
        var last = first + 6; 
        var weekStart = getCurDate(new Date(curr.setDate(first)))
        var weekEnd = getCurDate(new Date(curr.setDate(last)))
            let Bdata = {
                "Action":"GetWeek",
                "Mela":"Breakfast",
                "WeekStart":weekStart,
                "WeekEnd":weekEnd
            }
            dispatch(getBreakfastDayByDayOrderWatcher(Bdata))
    
            let Ldata = {
                "Action":"GetWeek",
                "Mela":"Lunch",
                "WeekStart":weekStart,
                "WeekEnd":weekEnd
            }
            dispatch(getLunchDayByDayOrderWatcher(Ldata))
    
            let Sdata = {
                "Action":"GetWeek",
                "Mela":"Snacks",
                "WeekStart":weekStart,
                "WeekEnd":weekEnd
            }
            dispatch(getSnacksByDayOrderWatcher(Sdata))
    
            let Ddata = {
                "Action":"GetWeek",
                "Mela":"Dinner",
                "WeekStart":weekStart,
                "WeekEnd":weekEnd
            }
            dispatch(getDinnerByDayOrderWatcher(Ddata))
    
            let SRdata = {
                "Action":"GetWeek",
                "Mela":"Supper",
                "WeekStart":weekStart,
                "WeekEnd":weekEnd
            }
            dispatch(getSupperByDayOrderWatcher(SRdata))    
      }

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={2} sx={{ padding: '30px', display: 'flex' }} >
                    <Grid item lg={6} xs={12} md={2} sm={6} sx={{ justifyContent: 'left' }}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <Button variant='contained' sx={{
                                height: '48px',
                                minWidth: '117px',
                                backgroundColor: tabVal === 1 ? 'primary.main' : 'grey.300',
                                '&:hover':{
                                backgroundColor: tabVal === 1 ? 'primary.main' : 'grey.300',
                                },
                                color: tabVal === 1 ? 'common.white' : 'common.black',
                            }} onClick={todayFunc} >
                                <Typography variant='t16_400' color='inherit'>Today</Typography>
                            </Button>

                            <Button variant='contained' sx={{
                                height: '48px',
                                minWidth: '117px',
                                backgroundColor: tabVal === 2 ? 'primary.main' : 'grey.300',
                                '&:hover':{
                                backgroundColor: tabVal === 2 ? 'primary.main' : 'grey.300',
                                },
                                color: tabVal === 2 ? 'common.white' : 'common.black',
                            }} onClick={weekFunc}>
                                <Typography variant='t16_400' color='inherit'>Week</Typography>
                            </Button>
                        </Stack>
                    </Grid>

                    <Grid item lg={6} xs={12} md={10} sm={6} display='flex' justifyContent='flex-end' >
                        <CSVLink style={{textDecoration: 'unset'}} data={data}><Button variant='contained' sx={{ height: '50px', minWidth: '200px' }} endIcon={<DoubleArrowIcon />}>
                            <Typography variant='t16_500'>Export to Excel</Typography>
                        </Button></CSVLink>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default DayByDayHeader;