import React from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import { IconButton } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteDialog from '../../../pages/admin/kitchen/deleteDialog';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const ActionButtons = ({ expandOpen = false, setExpandOpen, index, type, status, setStatus, rowData, tickIconFunction, handleDelete, handleEdit, cancelIconFunction, deleteDialogOpen = false, setDeleteDialogOpen, viewFunction, disableEdit, addIconFunc, viewIconFunc }) => {

    // const [dialogOpen, setDialogOpen] = useState(false);

    const editIconFunc = () => {
        // setDialogOpen(true);
        // setArrow(1)
        handleEdit()
    }

    const deleteIconFunction = () => {
        // setArrow(0)
        setDeleteDialogOpen(true)
    }

    return (
        <>
            <div>
                {status === 'list' ?
                    <>
                        {type !== 'orderTracking' && type !== 'guestDeliveryHistory' && type !== 'conDeliveryHistory' && type !== 'cRoleDeliveryHistory' && type !== 'gRoleDeliveryHistory' && type !== 'cabBooking' &&
                            <>
                                <IconButton disabled= {disableEdit} onClick={editIconFunc}>
                                    <ModeOutlinedIcon sx={{ color: disableEdit ? 'inherit' : 'common.black' }} />
                                </IconButton>
                                <IconButton onClick={deleteIconFunction}>
                                    <DeleteOutlineIcon sx={{ color: 'common.black' }} />
                                </IconButton>
                            </>
                        } 
                        {
                            type === 'contractor' && 
                            <>
                            <IconButton disabled= {disableEdit} onClick={addIconFunc}>
                                    <PersonAddIcon sx={{ color: 'common.black' }} />
                                </IconButton>
                                <IconButton onClick={viewIconFunc}>
                                    <VisibilityIcon sx={{ color: 'common.black' }} />
                                </IconButton>
                            </>
                        }

                        {
                            type === 'guest' && 
                            <>
                            <IconButton disabled= {disableEdit} onClick={addIconFunc}>
                                    <PersonAddIcon sx={{ color: 'common.black' }} />
                                </IconButton>
                                <IconButton onClick={viewIconFunc}>
                                    <VisibilityIcon sx={{ color: 'common.black' }} />
                                </IconButton>
                            </>
                        }

                        {type !== 'menuMaster' && type !== 'menuItemMaster' && type !== 'carousel' && type !== 'roster' && type !== 'contractorUser' && type !== 'guestUser' &&
                            <IconButton onClick={() => { setExpandOpen(!expandOpen) }}>
                                {expandOpen ? <ExpandLessIcon sx={{ color: 'common.black' }} /> : <ExpandMoreIcon sx={{ color: 'common.black' }} />}
                            </IconButton>
                        }

                        {type === 'roster' &&
                            <IconButton onClick={() => { setExpandOpen(!expandOpen) }}>
                                {expandOpen ? <VisibilityOffIcon sx={{ color: 'common.black' }} /> : <VisibilityIcon sx={{ color: 'common.black' }} />}
                            </IconButton>
                        }
                    </>
                    :
                    <>
                        <IconButton onClick={cancelIconFunction}>
                            <CancelIcon sx={{ color: 'common.black' }} />
                        </IconButton>
                        <IconButton onClick={tickIconFunction}>
                            <CheckCircleIcon sx={{ color: 'common.sgreen' }} />
                        </IconButton>

                        {type !== 'menuMaster' && type !== 'menuItemMaster' && type !== 'carousel' && type !== 'contractorUser' && type !== 'guestUser' && type !== 'roster' &&
                            <IconButton onClick={() => { setExpandOpen(!expandOpen) }}>
                                {expandOpen ? <ExpandLessIcon sx={{ color: 'common.black' }} /> : <ExpandMoreIcon sx={{ color: 'common.black' }} />}
                            </IconButton>
                        }
                    </>
                }

                <DeleteDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} type={type} handleDelete={handleDelete} />
            </div >
        </>

    )
}

export default ActionButtons;