import React, { useState } from 'react';
import {
    TableRow,
    TableCell,
    Typography,
    Collapse,
    Card
} from '@mui/material';
import ContractorChildTable from './contractorChildTable';
import ActionButtons from '../../dashboard/actionButtons';
import EditTextField from '../../../overrides/editField';
import { updateContractorWatcher, deleteContractorWatcher, getViewConUserWatcher, getAllConUserWatcher } from '../../../../redux/actionCreators/admin/contractorManagement';
import { useDispatch } from 'react-redux'
import AddDesign from './addIcon/addEmployeeDesign';
import ViewDesign from './viewIcon/viewEmployeeDesign';
import CreationDialog from '../../../dialog';
import DuplicationDesign from "./addIcon/duplicationDesign";

const ContractorTableRow = ({ rowData }) => {
    const dispatch = useDispatch();

    const [expandOpen, setExpandOpen] = useState(false);
    const [status, setStatus] = useState('list');
    const [editData, setEditData] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [addDialogOpen, setAddDialogOpen] = useState(false)
    const [viewDialogOpen, setViewDialogOpen] = useState(false)
    const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false)
    const [dupRfid, setDupRfid] = useState([])

    const handleEditChange = (e) => {
        const { name, value } = e.target
        setEditData({ ...editData, [name]: value })
    }

    const handleEdit = () => {
        let data = { ...rowData }
        setEditData(data);
        setStatus('edit');
    }

    const handleDelete = async () => {
        let data = { "ACTION": "DELETE" }
        await dispatch(deleteContractorWatcher(rowData.NID, { data }))
        setStatus('list');
        setDeleteDialogOpen(false);
    }

    const tickIconFunction = async () => {
        let data = { ...editData }
        data.ACTION = "UPDATE"
        // data.cMenuName = JSON.stringify(editData.cMenuName)
        await dispatch(updateContractorWatcher(rowData.NID, data));
        setStatus('list');
    }

    const cancelIconFunction = () => {
        setEditData({});
        setStatus('list');
    }

    const addIconFunc = () => {
        setAddDialogOpen(true)
        dispatch(getAllConUserWatcher())
    }

    const viewIconFunc = () => {
        setViewDialogOpen(true)
        let data = {
            nContractorID: rowData.NID
        }
        dispatch(getViewConUserWatcher(data))
        dispatch(getAllConUserWatcher())
    }

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                {status === 'list' ?
                    <>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.NID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.CCONTRACTORID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.CCONTRACTORNAME}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.CCONTRACTORDESC}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.CCONTRACTORADDRESS}
                            </Typography>
                        </TableCell>
                    </>
                    :
                    <>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.NID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='CCONTRACTORID'
                                value={editData.CCONTRACTORID}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='CCONTRACTORNAME'
                                value={editData.CCONTRACTORNAME}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='CCONTRACTORDESC'
                                value={editData.CCONTRACTORDESC}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='CCONTRACTORADDRESS'
                                value={editData.CCONTRACTORADDRESS}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                    </>
                }
                <TableCell align="right" sx={{ minWidth: '252px' }}>
                    <ActionButtons status={status} setStatus={setStatus} expandOpen={expandOpen} setExpandOpen={setExpandOpen} type={'contractor'} tickIconFunction={tickIconFunction} handleDelete={handleDelete} handleEdit={handleEdit} cancelIconFunction={cancelIconFunction} setDeleteDialogOpen={setDeleteDialogOpen} deleteDialogOpen={deleteDialogOpen} viewIconFunc={viewIconFunc} addIconFunc={addIconFunc} />
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell colSpan={6} style={{ padding: 0 }}>
                    <Collapse in={expandOpen} timeout="auto" unmountOnExit>
                        <Card sx={{ bgcolor: 'grey.200' }} >
                            <ContractorChildTable expandOpen={expandOpen} rowData={rowData} handleEditChange={handleEditChange} status={status} editData={editData} />
                        </Card>
                    </Collapse>
                </TableCell>
            </TableRow>
            <CreationDialog setOpen={setAddDialogOpen} open={addDialogOpen} maxWidth='sm'>
                <AddDesign setAddDialogOpen={setAddDialogOpen} rowDatas={rowData} setDuplicateDialogOpen={setDuplicateDialogOpen} setDupRfid={setDupRfid} />
            </CreationDialog>
            <CreationDialog setOpen={setViewDialogOpen} open={viewDialogOpen} maxWidth='md'>
                <ViewDesign setViewDialogOpen={setViewDialogOpen} />
            </CreationDialog>
            <CreationDialog setOpen={setDuplicateDialogOpen} open={duplicateDialogOpen} maxWidth='sm'>
                <DuplicationDesign setDuplicateDialogOpen={setDuplicateDialogOpen} rfid={dupRfid} />
            </CreationDialog>
        </>
    )

}

export default ContractorTableRow