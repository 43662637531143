import { CONTRACTOR_BOOK_LIST, CREATE_CONTRACTOR_BOOK_WATCHER, CANCEL_CONTRACTOR_BOOK_WATCHER, CONTRACTOR_NAME_LIST_WATCHER, CONTRACTOR_NAME_LIST, CNS_MENUID_LIST, CNS_RATE_LIST, CNS_WATCHER, ALl_MEAL_WATCHER, ALl_MEAL_LIST } from "../../actionTypes/admin";

// Worker triggering actionCreators
export function createContractBookWatcher(data) {
    return { type: CREATE_CONTRACTOR_BOOK_WATCHER, payload: data };
}

export function cancelContractBookWatcher(id, data, response) {
    return { type: CANCEL_CONTRACTOR_BOOK_WATCHER, payload: data, response, id};
}

export function getContractorNameListWatcher(data) {
    return { type: CONTRACTOR_NAME_LIST_WATCHER, payload: data };
}

export function cnsListWatcher(data, isCon) {
    return { type: CNS_WATCHER, payload: data , isCon};
}

export function selectAllMealWatcher(data) {
    return { type: ALl_MEAL_WATCHER, payload: data };
}

// Redux state changing actionCreators
export function contractorBookList(data) {
    return { type: CONTRACTOR_BOOK_LIST, payload: data };
}

export function contractorNameList(data) {
    return { type: CONTRACTOR_NAME_LIST, payload: data };
}

export function cnsRateList(data) {
    return { type: CNS_RATE_LIST, payload: data };
}

export function cnsMenuIdList(data) {
    return { type: CNS_MENUID_LIST, payload: data };
}

export function selectAllMealList(data) {
    return { type: ALl_MEAL_LIST, payload: data };
}