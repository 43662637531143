import http from "../../http-common";

class currentOrderService {
    currentOrder(data) {
        return http.post(`/user/currentOrder`, data);
    }

    orderHistory(data) {
        return http.post(`/user/orderHistory`, data);
    }

    orderBooking(data) {
        return http.post(`/user/orderBooking`, data);
    }

    orderBookingPaytm(data) {
        return http.post(`/user/orderBookingPaytm`, data);
    }

    payment(data) {
        return http.post(`/user/payment`, data);
    }

    insertFeedBack(data) {
        return http.post(`/user/insertFeedBack`, data);
    }
    
    rateFeedBack(id, data) {
        return http.post(`/user/rateFeedBack/${id}`, data);
    }

    cancelOrder(data) {
        return http.post("/user/orderCancel", data);
    }

    cancelOrderHistory(data) {
        return http.post("/user/orderCancel", data);
    }

    verifyPaytm(data) {
        return http.post("/user/verifyPaytm", data);
    }
}

export default new currentOrderService();