import { Autocomplete } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef } from "react";
// import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CssTextField from "../../components/overrides/searchField";
import { getDropDownWatcher } from "../../redux/actionCreators/admin/employeeManagement";
import { updateCafeteriaId } from "../../redux/actionCreators/user/home";

const Cafeteria = () => {
  const dispatch = useDispatch();

  const {
    EmployeeReducers: { cafeteriaName },
    UserHomeReducers: { cafeteriaId },
  } = useSelector((state) => state);
  const tempinitsform = useRef();
  const tempinitform = useRef();

  const initsform = () => {
    if(!cafeteriaName.length)
    dispatch(getDropDownWatcher());
  };

  tempinitsform.current = initsform;

  useEffect(() => {
    tempinitsform.current();
  }, []);

  // useEffect(() => {
  //   if(!cafeteriaName.length)
  //   dispatch(getDropDownWatcher());
  // }, []);

  const initform = () => {
    cafeteriaName.length && dispatch(updateCafeteriaId(cafeteriaName[1].id));
  };

  tempinitform.current = initform;

  useEffect(() => {
    tempinitform.current();
  }, [cafeteriaName]);

  // useEffect(() => {
  //   cafeteriaName.length && dispatch(updateCafeteriaId(cafeteriaName[1].id));
  // }, [cafeteriaName]);

  const handleChange = (e, value) => {
    dispatch(updateCafeteriaId(value?.id));
  };

  const getName = () => {
    const { name } = cafeteriaName.find((d) => d.id === cafeteriaId) || {};
    return name || null;
  };

  const newCafe = cafeteriaName.map((d) => ({ ...d, label: d.name }));
  return (
    <Box>
      <Autocomplete
        disablePortal
        value={getName()}
        id="combo-box-demo"
        options={newCafe}
        sx={{ width: 173 }}
        onChange={handleChange}
        isOptionEqualToValue={(data) => data.id === cafeteriaId}
        renderInput={(params) => (
          <CssTextField {...params} size='small' placeholder="Cafeteria" />
        )}
      />
    </Box>
  );
};

export default Cafeteria;
