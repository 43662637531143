import { MENU_MASTER_LIST, MEAL_DROPDOWN_LIST, MENU_DROPDOWN_LIST } from "../../actionTypes/admin";

const intialState = {
  menuMasterList: [],
  mealDropdownList: [],
  menuDropdownList: []
};

export default function MenuMasterReducers(state = intialState, action) {
  switch (action.type) {

    case MENU_MASTER_LIST:
      return { ...state, menuMasterList: action.payload };

    case MEAL_DROPDOWN_LIST:
      return { ...state, mealDropdownList: action.payload };

    case MENU_DROPDOWN_LIST:
      return { ...state, menuDropdownList: action.payload };

    default:
      return state;
  }
}
