import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
// material
import { styled, alpha } from '@mui/material/styles';
import { Box, Drawer, Typography } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Scrollbar from '../../components/Scrollbar';
import { AdminContent, FoodVendorContent, UserContent, ContractorContent, SwipeContent, CabContent, GuestContent, KioskContent } from './sidebarContent'
//
import { useTheme } from '@emotion/react';
import NavSection from '../../components/NavSection';
import { Apps } from './sidebarConfig/user';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 236;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const theme = useTheme()
  const isDesktop = useResponsive('up', 'lg');
  const isAdmin = !!matchPath({ path: '/admin', end: false }, pathname)
  const isVendor = !!matchPath({ path: '/foodVendor', end: false }, pathname)
  const isUser = !!matchPath({ path: '/user', end: false }, pathname)
  const isCab = !!matchPath({ path: '/cab', end: false }, pathname)
  const isKiosk = !!matchPath({ path: '/kiosk', end: false }, pathname)
  const isContractor = !!matchPath({ path: '/contractor', end: false }, pathname)
  const isGuest = !!matchPath({ path: '/guest', end: false }, pathname)
  const isKioskAdmin = !!matchPath({ path: '/kioskAdmin', end: false }, pathname)

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const getContent = () => (
    isAdmin ? AdminContent :
      isVendor ? FoodVendorContent :
        isUser || isKiosk ? UserContent :
          isContractor ? ContractorContent :
            isCab ? CabContent :
              isGuest ? GuestContent :
                isKioskAdmin ? KioskContent :
                  SwipeContent
  )

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        '& .simplebar-scrollbar': {
          '&:before': {
            backgroundColor: alpha(theme.palette.grey[500], 0.6)
          },
        }
      }}
    >
      <Box display='flex' flexDirection='column' pb='24px'
      >
        <Box mb={'40px'} display='flex' flexDirection='column'>
          <Typography mt='26px' ml='26px' variant='t24_700' color='primary' >
            BCP
            <Typography p='0 4px' variant='t24_700' color='common.foodRed' >
              -
            </Typography>
            PITSTOP
          </Typography>

          {(isCab || isUser || isKiosk) && <Box mt='30px' ><NavSection navConfig={Apps} exact={false} /></Box>}

        </Box>


        {getContent()}

      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'common.sideBar',
              boxShadow: `0px 8px 10px -5px ${alpha(theme.palette.common.sideBar, 0.20)}, 0px 16px 24px 2px ${alpha(theme.palette.common.sideBar, 0.14)}, 0px 6px 30px 5px ${alpha(theme.palette.common.sideBar, 0.12)}`
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'common.sideBar',
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
