import React, { useEffect, useState } from 'react'
import { Box, Card, Typography } from '@mui/material'
import Breadcrumbs from '../../../components/breadcrumbs'
import { useDispatch, useSelector } from 'react-redux';
import BookingHistoryRable from '../../../components/user/cabBooking/table';
import BookingHistoryHeader from '../../../components/user/cabBooking/header';
import { TABLE_HEAD } from '../../../components/user/cabBooking/table';
import { SUB_TABLE_HEAD } from '../../../components/user/cabBooking/cabBookingChildTable';
import { getCabReportsWatcher } from '../../../redux/actionCreators/admin/reports';
import { getCabBookingWatcher } from '../../../redux/actionCreators/cab';

export default function CabReports() {
    console.log("1")

    const dispatch = useDispatch()

    const { ReportsReducers: { CabReport } } = useSelector((state) => state)
    console.log("2", CabReport)

    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])

    useEffect(() => {
        console.log("3")
        let data = {
            "ACTION":"CabBookingHistory"
        }
        dispatch(getCabReportsWatcher(data))
        console.log("data 1", data)
    }, [dispatch])

    // useEffect(() => {
    //     let data = {
    //         "ACTION": "SELECT"
    //     }
    //     dispatch(getCabBookingWatcher(data))
    // }, [dispatch])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = CabReport.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
    }

    return (
        <>
            <Box p='30px'>
                <Breadcrumbs content={["Cab Reports"]} />
                <Box p='15px 0'>
                    <Typography variant='t24_700' > Cab Reports </Typography>
                </Box>
                <Box p='10px 0'>
                    <BookingHistoryHeader handleSearch={handleSearch} searchText={searchText} rowData={CabReport} columnData={[...TABLE_HEAD, ...SUB_TABLE_HEAD]} a />
                </Box>
                <Card style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "0 0 0px 0px", padding: '20px' }}>
                    <BookingHistoryRable CabList={CabReport} searchVal={searchVal} searchText={searchText} />
                </Card>
            </Box>
        </>
    )
}
