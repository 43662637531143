import React from 'react';
import { Card, Grid, Button, InputAdornment, Stack, Typography } from "@mui/material";
import SearchField from '../../overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getConvertedDate4 } from '../../../utils/common';
import { ExportCsv } from "@material-table/exporters";

const GuestBookingHeader = ({ handleSearch, searchText, rowData, columnData, handleSubmitAction }) => {

    // const [value, setValue] = React.useState(null);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);

    const handleFromChange = (e) => {
        setFromDate(e)
    }

    const handleToChange = (e) => {
        setToDate(e)
    }

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >
                    <Grid item lg={3.5} xs={12} md={3} sm={12} sx={{ justifyContent: 'left' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px'
                                }
                            }}
                            placeholder='Search' fullWidth onChange={handleSearch} value={searchText} />
                    </Grid>

                    <Grid item lg={4} xs={12} md={4} sm={9} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={fromDate}
                                    label='From Date'
                                    inputFormat= "MM/dd/yyyy"
                                    onChange={handleFromChange}
                                    renderInput={(params) => <SearchField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: 50
                                        },
                                        '& label': {
                                            fontSize: '.9rem'
                                        }
                                    }} fullWidth {...params} />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={toDate}
                                    label='To Date'
                                    inputFormat= "MM/dd/yyyy"
                                    onChange={handleToChange}
                                    renderInput={(params) => <SearchField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: 50
                                        },
                                        '& label': {
                                            fontSize: '.9rem'
                                        }
                                    }} fullWidth {...params} />}
                                />
                            </LocalizationProvider>
                        </Stack>
                    </Grid>
                    <Grid item lg={2} xs={12} md={2} sm={3} sx={{ justifyContent: 'center' }} >
                        <Button variant='contained' sx={{ height: 50, backgroundColor: 'common.Bblack', '&:hover':{backgroundColor: 'common.Bblack'} }} fullWidth endIcon={<DoubleArrowIcon />} onClick={() => handleSubmitAction(getConvertedDate4(fromDate), getConvertedDate4(toDate))}>
                            <Typography variant='t16_400_1' color='common.white'>Submit</Typography>
                        </Button>
                    </Grid>

                    <Grid item lg={2.5} xs={12} md={3} sm={12} sx={{ justifyContent: 'center', color: 'common.black' }} >

                        <Button variant='contained' sx={{ height: '50px' }} fullWidth endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(columnData, rowData, "Guest Booking History")} >
                            <Typography variant='t16_400_1'>Export To Excel</Typography>
                        </Button>

                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default GuestBookingHeader;