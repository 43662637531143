import React, { useState } from 'react'
import { Grid, Typography, Pagination } from '@mui/material'
import { Box } from '@mui/system'
import DishesCard from './dishesCard';
import { useSelector } from 'react-redux';

const Dishes = ({ setOrderCount, activeCateg, orderCount, products, searchText, searchVal }) => {

  const { UserKioskReducers: { kiosk } } = useSelector(state => state)
  const [page, setPage] = useState(1);
  // const [cardsPerPage, setCardsPerPage] = useState(12);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const indexOfLastCard = page * 12
  const indexOfFirstCard = indexOfLastCard - 12
  const kioskData = kiosk.slice(indexOfFirstCard, indexOfLastCard)

  //const kioskData = kiosk.length && kiosk[0] || []

  return (
    <Box pb='18px'>
      {kiosk.length ?
        <Grid container spacing={2.5}>
          {
            kioskData.map((d) =>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={d.nID}>
                <DishesCard isViewCart={true} kiosk={true} products={products} setOrderCount={setOrderCount} orderCount={orderCount} value={d} />

              </Grid>)
          }

        </Grid>
        :
        <Box mt='20px' height='calc(100vh - 530px)' alignItems='center' display='flex' justifyContent='center' >
          <Typography variant='t24_700' color='grey.500' >
            Kiosk not Available!
          </Typography>
        </Box>
      }
      <Box spacing={2} display='flex' justifyContent='right' mt='24px' >
        <Pagination
          shape="rounded"
          color='primary'
          count={Math.ceil(kiosk?.length / 12)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
    </Box>
  )
}

export default Dishes