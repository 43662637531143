import { Box, Card, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import CurrentOrder from './currentOrder';
import UpcomingOrder from './upcomingOrder';
import RecentDelivery from './recentDelivery';
import QuickOrder from './quickOrder';
import { currentOrderWatcher, nextdayOrderWatcher, nextweekOrderWatcher, deliveredOrderWatcher, upcomingOrderWatcher } from '../../../redux/actionCreators/user/currentOrder';
import { useDispatch, useSelector } from 'react-redux';
// import SlideBasicArrow from "./slider";
import { getUserCarouselWatcher } from '../../../redux/actionCreators/admin/carousel';

const Dashboard = () => {

  const dispatch = useDispatch()
  const { CarouselReducers: { foodWastage } } = useSelector(state => state)

  const { ProfileReducers: { loginState } } = useSelector(state => state)
  const nUserID = loginState[0]?.nID || 0
  const cEmployeeName = loginState[0]?.cEmployeeName || ''

  useEffect(() => {
    dispatch(currentOrderWatcher({ nUserID, "cAction": "TodayOrder" }))
    dispatch(nextdayOrderWatcher({ nUserID, "cAction": "nextdayOrder" }))
    dispatch(nextweekOrderWatcher({ nUserID, "cAction": "nextweekOrder" }))
    dispatch(deliveredOrderWatcher({ nUserID, "cAction": "DeliveredOrder" }))
    dispatch(upcomingOrderWatcher({ nUserID, "cAction": "UpcomingOrder" }))
    let data = {
      "ACTION": "FoodWastage"
    }
    dispatch(getUserCarouselWatcher(data))
  }, [dispatch, nUserID])

  const unSwipePer = foodWastage.length && (foodWastage[0]?.unSwiped/foodWastage[0]?.total*100 || 0).toFixed(0)

  const total = foodWastage[0]?.total

  return(
    <Box display='flex' width='100%'>

      <Box p='24px' width='100%'>

        <Box display='flex' flexDirection='column'>
          <Typography mb='14px' variant='t13_500' sx={{ color: 'grey.600' }}>
            Home
          </Typography>

          <Typography mb='19px' variant='t32_600'>
            Hello, {cEmployeeName}
          </Typography>
        </Box>

        {/* {CarouselImageList.length &&
          <Box width={{ xs: '100%', lg: 'calc(100% - 237px)' }}>
            <SlideBasicArrow slideBasicArrow={CarouselImageList} />
          </Box>
        }
          : */}
          {/* <Card sx={{ borderRadius: '16px', backgroundColor: "primary.main", backgroundSize: 'cover' }} >
            <Box textAlign='center' m= {{md:'40px 200px', xs: '30px 50px'}} >
              <Typography variant='t32_400' color='grey.0' >
                Yesterday's booked count was {total} and unswiped count was {unSwipePer}%
              </Typography>
            </Box>
          </Card> */}
        {/* } */}

        <CurrentOrder />
        <UpcomingOrder />
        <RecentDelivery />
        <QuickOrder />

      </Box>

    </Box>
  )
}

export default Dashboard