import React, { useState, useEffect } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
import SaleReportHeader from '../../../components/foodVendor/reports/saleReport/headerCard';
import SaleReportTable from '../../../components/admin/reports/saleReport/saleReportTable';
import { useDispatch, useSelector } from 'react-redux';
import { getSaleMenuWatcher } from '../../../redux/actionCreators/admin/reports';
import { TABLE_HEAD } from '../../../components/admin/reports/saleReport/saleReportTable';

const AdminSaleReport = () => {

    const { ReportsReducers: { SaleReportList } } = useSelector((state) => state)
    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    // const [selectedType, setSelectedType] = useState('');
    const [page, setPage] = useState(1);

    useEffect(() => {
        let data = {
            "Action": "MealMenuList",
            "Fdate": "11/06/2021",
            "Tdate": "11/06/2022"
        }
        dispatch(getSaleMenuWatcher(data))
    }, [dispatch])

    // useEffect(() => {
    //     let data1 = {
    //         "Action": "MealMenuListCon",
    //         "Fdate": "11/06/2021",
    //         "Tdate": "11/06/2022"
    //     }
    //     dispatch(getSaleMenuWatcher(data1))
    // }, [])

    const selectedTypeHandler = (val) => {
        // setSelectedType(val)
        if (val === "Employee") {
            let data = {
                "Action": "MealMenuList",
                "Fdate": "11/06/2021",
                "Tdate": "11/06/2022"
            }
            dispatch(getSaleMenuWatcher(data))
        } else {
            let data1 = {
                "Action": "MealMenuListCon",
                "Fdate": "11/06/2021",
                "Tdate": "11/06/2022"
            }
            dispatch(getSaleMenuWatcher(data1))
        }
    }


    // const selectedTypeHandler = useCallback((val) => {
    //     setSelectedType(val)
    //     if (val === "Employee") {
    //         let data = {
    //             "Action": "MealMenuList",
    //             "Fdate": "11/06/2021",
    //             "Tdate": "11/06/2022"
    //         }
    //         dispatch(getSaleMenuWatcher(data))
    //     } else {
    //         let data1 = {
    //             "Action": "MealMenuListCon",
    //             "Fdate": "11/06/2021",
    //             "Tdate": "11/06/2022"
    //         }
    //         dispatch(getSaleMenuWatcher(data1))
    //     }
    // }, [selectedType])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = SaleReportList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        const data = {
            Action: "MealMenuList",
            Fdate: fromDate,
            Tdate: toDate
        }
        dispatch(getSaleMenuWatcher(data))
        setPage(1)
    }

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports", "Sale Report"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Sale Report</Typography>
                </Box>
                <Box p='10px 0'>
                    <SaleReportHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={SaleReportList} columnData={TABLE_HEAD} selectedTypeHandler={selectedTypeHandler} />
                </Box>

                {Boolean(SaleReportList.length) && SaleReportList[0]?.map((m) => (
                    <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", marginBottom: '20px', marginTop: '30px', paddingBottom: '20px' }} key={m.nID}>
                        <SaleReportTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} table_header={m} />
                    </Card>
                ))}

            </Box>
        </>
    )
}

export default AdminSaleReport;