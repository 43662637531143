import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../../components/breadcrumbs";
import { currentOrderWatcher } from "../../../../redux/actionCreators/user/currentOrder";
import OrderCard from "./orderCard";

const CurrentOrder = () => {
  const dispatch = useDispatch();

  const {
    ProfileReducers: { loginState },
    CurrentOrderReducers: { currentOrder },
  } = useSelector((state) => state);
  const nUserID = loginState[0]?.nID || 0;
  const tempinitsform = useRef();

  const initsform = () => {
    const data = {
      nUserID,
      cAction: "Current",
      Fdate: "10/06/2022",
      Tdate: "10/06/2022",
    };
    dispatch(currentOrderWatcher(data));
  };

  tempinitsform.current = initsform;

  useEffect(() => {
    tempinitsform.current();
  }, []);

  // useEffect(() => {
  //   const data = {
  //     nUserID,
  //     cAction: "Current",
  //     Fdate: "10/06/2022",
  //     Tdate: "10/06/2022",
  //   };
  //   dispatch(currentOrderWatcher(data));
  // }, []);

  // const groupOrder = _.groupBy(currentOrder, (d) => d.nBookingID);
  // const newCurrent = Object.keys(groupOrder)
  return (
    <Box p="24px">
      <Breadcrumbs content={["My Order", "Current Order"]} />

      <Box mt="14px">
        <Typography variant="t24_700">Ongoing Order</Typography>
      </Box>
      
      {
        currentOrder.length ?
          <Box mt="24px">
            <Grid container spacing={3} >
              {currentOrder.map((m) => (
                <Grid item key={m.nID} xs={12}>
                  <OrderCard value={m} />
                </Grid>
              ))}
            </Grid>
          </Box>
          :
          <Box mt='20px' height='calc(100vh - 530px)' alignItems='center' display='flex' justifyContent='center' >
            <Typography variant='t24_700' color='grey.500' >
              No current order found!
            </Typography>
          </Box>
      }
      
    </Box>
  );
};

export default CurrentOrder;
