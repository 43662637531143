import { call, put, takeEvery } from "redux-saga/effects";
import { getKioskBookingDetailsList, getKioskMenuList, getNotificationCountList, getNotificationDetailsList } from "../../actionCreators/kiosk";
import KioskRoleServices from "../../../services/kiosk/index"
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";
import { GET_KIOSK_DASHBOARD_WATCHER, UPDATE_BOOKING_DETAILS_STATUS_WATCHER, GET_KIOSK_MENU_LIST_WATCHER, UPDATE_MENU_LIST_STATUS_WATCHER, GET_NOTIFICATION_DETAILS_WATCHER, GET_NOTIFICATION_COUNT_WATCHER, UPDATE_NOTIFICATION_DETAILS_WATCHER } from "../../actionTypes/kiosk";

function* getKioskBookingDetailsEffect(action) {
  try {
    if(!action.noLoader) yield put(updateOpenLoader());
    let { data } = yield call(KioskRoleServices.getKioskBookingDetails, action.payload);
    yield put(getKioskBookingDetailsList(data.recordset));
    if(!action.noLoader) yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    if(!action.noLoader) yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
} 

function* updateBookingDetailsStatusEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(KioskRoleServices.updateBookingDetailsStatus, action.id, action.payload);
    // action.response(data)
    yield put(getKioskBookingDetailsList(data.recordset));
    // yield put(contractorDashboardList(data.recordsets));
    yield put(updateFalseLoader());
    yield put(updateAlert('Updated Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e.response?.data.message, 'error'));
  }
} 

function* getKioskMenuListDetailsEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(KioskRoleServices.getKioskMenuListDetails, action.payload);

    yield put(getKioskMenuList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* updateMenuListStatusEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(KioskRoleServices.updateMenuListStatus, action.id, action.payload);
    // action.response(data)
    yield put(getKioskMenuList(data.recordset));
    // yield put(contractorDashboardList(data.recordsets));
    yield put(updateFalseLoader());
    yield put(updateAlert('Updated Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e.response?.data.message, 'error'));
  }
}

function* getNotificationDetailsEffect(action) {
  try {
    if(!action.noLoader) yield put(updateOpenLoader());
    let { data } = yield call(KioskRoleServices.getNotificationDetails, action.payload);
    yield put(getNotificationDetailsList(data.recordset));
    yield put(getNotificationCountList(data.recordsets[1]));
    if(!action.noLoader) yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    if(!action.noLoader) yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getNotificationCountEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(KioskRoleServices.getNotificationCount, action.payload);
    yield put(getNotificationCountList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* updateNotificationDetailsEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(KioskRoleServices.updateNotificationDetails, action.payload);
    // action.response(data)
    yield put(getNotificationDetailsList(data.recordset));
    yield put(getNotificationCountList(data.recordsets[1]));
    // yield put(contractorDashboardList(data.recordsets));
    yield put(updateFalseLoader());
    // yield put(updateAlert('Updated Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e.response?.data.message, 'error'));
  }
}


//saga 
export const kioskRoleSaga = [
  takeEvery(GET_KIOSK_DASHBOARD_WATCHER, getKioskBookingDetailsEffect),
  takeEvery(UPDATE_BOOKING_DETAILS_STATUS_WATCHER ,updateBookingDetailsStatusEffect),
  takeEvery(GET_KIOSK_MENU_LIST_WATCHER, getKioskMenuListDetailsEffect),
  takeEvery(UPDATE_MENU_LIST_STATUS_WATCHER, updateMenuListStatusEffect),
  takeEvery(GET_NOTIFICATION_DETAILS_WATCHER, getNotificationDetailsEffect),
  takeEvery(GET_NOTIFICATION_COUNT_WATCHER ,getNotificationCountEffect),
  takeEvery(UPDATE_NOTIFICATION_DETAILS_WATCHER ,updateNotificationDetailsEffect),
]