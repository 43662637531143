// USER Watcher Actions
    //dashboard
    // export const GET_GUEST_DASHBOARD_WATCHER = "GET_GUEST_DASHBOARD_WATCHER";

    export const CREATE_GUEST_ORDER_WATCHER = "CREATE_GUEST_ORDER_WATCHER";
    export const CANCEL_GUEST_ORDER_WATCHER = "CANCEL_GUEST_ORDER_WATCHER";

    //reports
    // export const GET_GUEST_BOOKING_HISTORY_WATCHER = "GET_GUEST_BOOKING_HISTORY_WATCHER";

    //guest role >> swipe history
    
    export const GET_GROLE_SWIPE_HISTORY_WATCHER = "GET_GROLE_SWIPE_HISTORY_WATCHER"

    export const GUEST_ROLE_USER_COUNT_WATCHER = "GUEST_ROLE_USER_COUNT_WATCHER"

// USER Reducer Actions
    //dashboard
    // export const GUEST_BOOKING_HISTORY_LIST = "GUEST_BOOKING_HISTORY_LIST";

    //reports
    // export const GUEST_DASHBOARD_DATA = "GUEST_DASHBOARD_DATA";

    //guest role >> Swipe History
    export const GROLE_SWIPE_HISTORY_LIST = "GROLE_SWIPE_HISTORY_LIST"

    export const GET_GUEST_ROLE_COUNT_LIST = "GET_GUEST_ROLE_COUNT_LIST"