import { employeeList, roleNameList, divisionList, accessPointList, departmentList, tripTypeList, typeOfCab, contractorList, vendorList, cafeteriaList, counterList, guestDropList, getListByIdList, deparmentNameList, categoryList, cabVendorList, typeOfTripList } from "../../actionCreators/admin/employeeManagement";
import { call, put, takeEvery } from "redux-saga/effects";
import EmployeeManagementService from "../../../services/admin/employeeManagement";
import { CREATE_EMPLOYEE_WATCHER, UPDATE_EMPLOYEE_WATCHER, DELETE_EMPLOYEE_WATCHER, GET_EMPLOYEE_WATCHER, GET_DROPDOWN_WATCHER, CREATE_BULK_EMPLOYEE_WATCHER, GET_LIST_BY_ID_WATCHER, CREATE_SCHEDULE_NOTIFICATION_WATCHER } from "../../actionTypes/admin";
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* getEmployeeEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(EmployeeManagementService.getAll, action.payload);
    yield put(employeeList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getDropDownEffect() {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(EmployeeManagementService.getRoleNames);
    yield put(roleNameList(data.recordsets[3]));
    yield put(divisionList(data.recordsets[2]));
    yield put(accessPointList(data.recordsets[8]));
    yield put(counterList(data.recordsets[7]));
    yield put(departmentList(data.recordsets[0]));
    yield put(tripTypeList(data.recordsets[12]));
    yield put(typeOfCab(data.recordsets[11]));
    yield put(contractorList(data.recordsets[6]));
    yield put(vendorList(data.recordsets[10]));
    yield put(cafeteriaList(data.recordsets[9]));
    yield put(guestDropList(data.recordsets[13]));
    yield put(deparmentNameList(data.recordsets[14]));
    yield put(categoryList(data.recordsets[15]));
    yield put(cabVendorList(data.recordsets[16]));
    yield put(typeOfTripList(data.recordsets[17]));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* createEmployeeEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(EmployeeManagementService.create, action.payload);
    action.setOpen(false)
    yield put(employeeList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Created Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* createBulkEmployeeEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(EmployeeManagementService.createBulkEmployee, action.payload);
    action.setAddDialogOpen(false)
    let { data: data1 } = yield call(EmployeeManagementService.getAll, {ACTION:'userSELECT'});
    yield put(employeeList(data1.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Created Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* updateEmployeeEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(EmployeeManagementService.update, action.id, action.payload);

    yield put(employeeList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Updated Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* deleteEmployeeEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(EmployeeManagementService.delete, action.id, action.payload);

    yield put(employeeList(data.data.recordset));
    yield put(updateAlert('Deleted Successfully', 'success'));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getListByIdEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(EmployeeManagementService.getListById, action.payload);
    yield put(getListByIdList(data.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* ScheduleNotificationEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(EmployeeManagementService.ScheduleNotification, action.payload);
    // yield put(ScheduleNotificationList(data.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

//saga 
export const EmployeeManagementSaga = [
  takeEvery(GET_EMPLOYEE_WATCHER, getEmployeeEffect),
  takeEvery(CREATE_EMPLOYEE_WATCHER, createEmployeeEffect),
  takeEvery(CREATE_BULK_EMPLOYEE_WATCHER, createBulkEmployeeEffect),
  takeEvery(UPDATE_EMPLOYEE_WATCHER, updateEmployeeEffect),
  takeEvery(DELETE_EMPLOYEE_WATCHER, deleteEmployeeEffect),
  takeEvery(GET_DROPDOWN_WATCHER, getDropDownEffect),
  takeEvery(GET_LIST_BY_ID_WATCHER, getListByIdEffect),
  takeEvery(CREATE_SCHEDULE_NOTIFICATION_WATCHER, ScheduleNotificationEffect),
]

