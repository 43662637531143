import React, { useEffect, useRef } from 'react'
import { Chip, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import DishesCard from '../../../components/user/dishesCard';
import { useSelector } from 'react-redux';

const Dishes = ({ setOrderCount, activeCateg, orderCount, products, value, setValue}) => {

  const {UserHomeReducers: {mealMenuList, daysOfWeek}} = useSelector(state => state)
  const tempinitsform = useRef();

  const handleChange = (name) => {
    setValue(name);
  };

  const initsform = () => {
    daysOfWeek.length && setValue(daysOfWeek[0])
  };

  tempinitsform.current = initsform;

  useEffect(() => {
    tempinitsform.current();
  }, [daysOfWeek]);

  // useEffect(()=>{
  //   daysOfWeek.length && setValue(daysOfWeek[0])
  // },[daysOfWeek])

  
  return (
    <Box mt='44px'>

      <Grid container spacing={2} justifyContent='center' >
        {daysOfWeek.map((d, i) => (
          <Grid item key={d.nID} >
            <Chip onClick={() => handleChange(d)} color={value.nID === d.nID ? 'primary' : 'default'} sx={{ height:40 }} label={
              <Typography variant='t12_500'>
                {d.date}
              </Typography>
            } />

          </Grid>
        ))}
      </Grid>
        
      {mealMenuList.length ?
        <Grid container mt='20px' spacing={2.5}>
          {
            mealMenuList.map((d) =>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={d.nID}>
                <DishesCard products={products} setOrderCount={setOrderCount} activeCateg={activeCateg} orderCount={orderCount} value={d} selectedDate={value} />

              </Grid>)
          }

        </Grid>
        :
        <Box mt='20px' height='calc(100vh - 530px)' alignItems='center' display='flex' justifyContent='center' >
          <Typography variant='t24_700' color='grey.500' >
            Dishes for this cafeteria currently not Available!
          </Typography>
        </Box>
      }
        
      </Box>
  )
}

export default Dishes