import React, { useEffect, useState } from 'react';
import { Typography, Autocomplete, Grid, Button, Stack, Box, Tooltip } from "@mui/material";
import CssTextField from '../../../components/overrides/longSearchField';
// import BlackButton from '../../../components/overrides/blackButton';
// import { List } from '../../List';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs  } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchField from '../../overrides/longSearchField';
import { getConvertedDate3, time } from '../../../utils/common';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { createCabBookingWatcher, getLeaderMailIdWatcher } from '../../../redux/actionCreators/cab';
import { getDropDownWatcher } from '../../../redux/actionCreators/admin/employeeManagement';
import * as Yup from 'yup';
import { Gender } from '../../../utils/common';
import CloseButton from '../../overrides/closeButton';
import { TimePicker } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getCabBookingWatcher } from '../../../redux/actionCreators/cab';

const Cab = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const { EmployeeReducers: { tripTypeList, typeOfCab, cabVendor, typeOfTrip }, ProfileReducers: { loginState }, CabReducers: { CabList, LeaderMailList } } = useSelector((state) => state)
    const nUserID = loginState[0]?.nID || 0
    
    const [ccEmail, setCCEmail] = useState("")
    
    useEffect(() => {
        let data = {
            "ACTION": "SELECT"
        }

        let data1 = {
            "ACTION": "leaderMailId"
        }
        dispatch( getCabBookingWatcher(data) )
        dispatch( getDropDownWatcher())
        dispatch( getLeaderMailIdWatcher(data1))
    }, [dispatch])

    const handleDateChange = (e, type) => {
        setFieldValue(type, e)
    }

    const CabSchema = Yup.object().shape({

        EMPLOYEENAME: Yup.string().min(1, 'Too Short!').max(100, 'Too Long!').required('User Name is required'),
        GENDER: Yup.string().min(1, 'Too Short!').max(100, 'Too Long!').required('Gender is required'),
        DEPARTMENT: Yup.string().min(1, 'Too Short!').max(100, 'Too Long!').required('Department is required'),
        // COSTCENTRE: Yup.string().min(1, 'Too Short!').max(100, 'Too Long!').required('Cost centre is required'),
        COSTCENTRE: Yup.string().min(10, 'Enter maximum 10 character').max(10, 'Enter minimum 10 character').required('Cost centre is required'),
        MOBILENO: Yup.string('Enter your Mobile Number').min(10, 'Enter Valid Mobile Number').max(10, 'Enter Valid Mobile Number').required('Mobile Number is required'),
        EMAILID: Yup.string('Enter your EmailId').email('Email must be a valid email address').required('Email is required'),
        HREMAILID: Yup.string('Enter your HR mail id').email('Email must be a valid email address').required('Email is required'),
        USAGETYPE: Yup.string().min(1, 'Too Short!').max(100, 'Too Long!').required('Usage type is required'),
        TYPEOFCAB: Yup.string().min(1, 'Too Short!').max(100, 'Too Long!').required('Cab is required'),
        TYPEOFTRIP: Yup.string().min(1, 'Too Short!').max(100, 'Too Long!').required('Trip is required'),
        ORDERDATE: Yup.date('Enter your Date').required('Order Date is required'),
        DATEOFTRAVELON: Yup.date('Enter your Date').required('This field is required'),
        DATEOFTRAVELOUT: Yup.date('Enter your Date').required('This field is required'),
        TRAVELDESTINATIONFROM: Yup.string().min(1, 'Too Short!').max(100, 'Too Long!').required('Vendor is required'),
        TRAVELDESTINATIONTO: Yup.string().min(1, 'Too Short!').max(100, 'Too Long!').required('Vendor is required'),
        REPORTINGADDRESS: Yup.string().min(1, 'Too Short!').max(100, 'Too Long!').required('Vendor is required'),
        FLIGHTORTRAINNUMBER: Yup.string().min(1, 'Too Short!').max(100, 'Too Long!'),
        // REPORTINGTIMEFROM: Yup.date('Enter your Date').required('Order Date is required'),
        // REPORTTIMETO: Yup.date('Enter your Date').required('Order Date is required'),
        SPECIALINSTRUCTIONS: Yup.string().min(1, 'Too Short!').max(100, 'Too Long!').required('Vendor is required'),
        VENDOR: Yup.string().min(1, 'Too Short!').max(100, 'Too Long!').required('Vendor is required'),
        SUPERVISIOREMAILID: Yup.string('Enter your Supervisor mail id').email('Email must be a valid email address').required('Email is required'),
        SUPERVISIOREMAILIDCHECK: Yup.string('Enter your Supervisor mail id').email('Email must be a valid email address')
    });

    const formik = useFormik({
        initialValues: {
            EMPLOYEENAME: "",
            GENDER: "",
            DEPARTMENT: "",
            COSTCENTRE: "",
            MOBILENO: "",
            EMAILID: "",
            SUPERVISIOREMAILID: "",
            HREMAILID: "",
            USAGETYPE: "",
            TYPEOFCAB: "",
            TYPEOFTRIP: "",
            ORDERDATE: new Date(),
            DATEOFTRAVELON: null,
            DATEOFTRAVELOUT: null,
            TRAVELDESTINATIONFROM: "",
            TRAVELDESTINATIONTO: "",
            REPORTINGADDRESS: "",
            FLIGHTORTRAINNUMBER: "",
            REPORTINGTIMEFROM: null,
            REPORTTIMETO: null,
            SPECIALINSTRUCTIONS: "",
            VENDOR: '',
            VENDOREMAIL: '',
            SUPERVISIOREMAILIDCHECK: ''
        },

        validationSchema: CabSchema,

        onSubmit: () => {
            let values = { ...formik.values }
            let data = {
                ACTION: "INSERT",
                nUserId: nUserID,
                EMPLOYEENAME: values.EMPLOYEENAME,
                GENDER: values.GENDER,
                DEPARTMENT: values.DEPARTMENT,
                COSTCENTRE: values.COSTCENTRE,
                MOBILENO: values.MOBILENO,
                EMAILID: values.EMAILID,
                SUPERVISIOREMAILID: values.SUPERVISIOREMAILID,
                SUPERVISIOREMAILIDCHECK: values.SUPERVISIOREMAILIDCHECK,
                HREMAILID: values.HREMAILID,
                USAGETYPE: values.USAGETYPE,
                TYPEOFCAB: values.TYPEOFCAB,
                TYPEOFTRIP: values.TYPEOFTRIP,
                ORDERDATE: values.ORDERDATE,
                DATEOFTRAVELON: getConvertedDate3(values.DATEOFTRAVELON),
                DATEOFTRAVELOUT: getConvertedDate3(values.DATEOFTRAVELOUT),
                TRAVELDESTINATIONFROM: values.TRAVELDESTINATIONFROM,
                TRAVELDESTINATIONTO: values.TRAVELDESTINATIONTO,
                REPORTINGADDRESS: values.REPORTINGADDRESS,
                FLIGHTORTRAINNUMBER: values.FLIGHTORTRAINNUMBER,
                REPORTINGTIMEFROM: values.REPORTINGTIMEFROM,
                REPORTTIMETO: values.REPORTTIMETO,
                SPECIALINSTRUCTIONS: values.SPECIALINSTRUCTIONS,
                CABVENDOR: values.VENDOR,
                VENDOREMAIL: values.VENDOREMAIL,
                CCEMAIL: ccEmail
            }

            dispatch(createCabBookingWatcher(data, navigate))
            console.log("submit", data)
        }
    });

    const { errors, touched, getFieldProps, setFieldValue, values, handleChange } = formik;  
    
    

    return (
        <>
            <FormikProvider value={formik}
            >
                <Form autoComplete="off" noValidate>
                    {/* <Grid container spacing={3} sx={{ padding: '30px' }} >
                        <Grid item lg={12} xs={12} sm={12} md={12} sx={{ justifyContent: 'left' }}>
                            <div>
                                <Typography variant='t20_600'> Cab Booking </Typography>
                            </div>
                        </Grid>
                    </Grid> */}
                    <Grid container spacing={3} display='flex' flexDirection='row' justifyContent='left' sx={{ padding: { md: '30px', xs: 0 } }}>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'>Vendor</Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                name="vendor"
                                fullWidth
                                options={cabVendor}
                                autoHighlight
                                onChange={(event, value) => {
                                    if(!value) return
                                    setFieldValue("VENDOR", value.name);
                                    const arr = []
                                    for(let key in value){
                                        if(key.startsWith('email') && value[key]){
                                            arr.push(value[key])
                                        }
                                    }
                                    setFieldValue('VENDOREMAIL', arr)
                                    setFieldValue('HREMAILID', value.hrEmail)
                                    setCCEmail(value.ccEmail)
                                    // console.log("log", ccEmail, value);
                                    // setFieldValue('SUPERVISIOREMAILID', value.ccEmail)
                                }}
                                //{...getFieldProps("TYPEOFCAB")}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    name='Vendor' sx={{ mt: '10px' }} placeholder='Vendor'
                                    error={Boolean(touched.VENDOR && errors.VENDOR)}
                                    helperText={touched.VENDOR && errors.VENDOR}
                                />}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> User / Guest Name </Typography>
                            <CssTextField
                                required={true}
                                placeholder='User / Guest Name'
                                name='empGuestName'
                                fullWidth
                                sx={{ mt: '10px' }}
                                {...getFieldProps("EMPLOYEENAME")}
                                error={Boolean(touched.EMPLOYEENAME && errors.EMPLOYEENAME)}
                                helperText={touched.EMPLOYEENAME && errors.EMPLOYEENAME}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Gender </Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                name="gender"
                                fullWidth
                                options={Gender}
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("GENDER", value.name);
                                }}
                                //{...getFieldProps("GENDER")}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    name='gender' sx={{ mt: '10px' }} placeholder='Gender'
                                    error={Boolean(touched.GENDER && errors.GENDER)}
                                    helperText={touched.GENDER && errors.GENDER}
                                />}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Department </Typography>
                            {/* <Autocomplete
                                id="combo-box-demo"
                                name="department"
                                fullWidth
                                options={departmentList}
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("DEPARTMENT", value.name);
                                }}
                                //{...getFieldProps("DEPARTMENT")}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    name='department' sx={{ mt: '10px' }} placeholder='Department'
                                />}
                            /> */}
                            <CssTextField
                                placeholder='Department'
                                name='department'
                                fullWidth
                                size='medium'
                                sx={{ mt: '10px' }}
                                {...getFieldProps("DEPARTMENT")}
                                error={Boolean(touched.DEPARTMENT && errors.DEPARTMENT)}
                                helperText={touched.DEPARTMENT && errors.DEPARTMENT}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Box display="flex" flexDirection="row" alignItems="center">
                              <Typography variant='t14_600' pr="5px"> Cost Centre </Typography>
                                <Tooltip placement="top" title= {<Typography variant='t14_600'>Should contain 10 characters</Typography>}>
                                <InfoOutlinedIcon sx={{ color: "common.black" }}/>
                              </Tooltip>
                            </Box>

                            <CssTextField
                                placeholder='Cost Centre'
                                name='costCentre'
                                fullWidth
                                size='medium'
                                sx={{ mt: '10px' }}
                                {...getFieldProps("COSTCENTRE")}
                                error={Boolean(touched.COSTCENTRE && errors.COSTCENTRE)}
                                helperText={touched.COSTCENTRE && errors.COSTCENTRE}
                                // inputProps={{ maxLength: 10, minLength: 10 }}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Mobile No. </Typography>
                            <CssTextField
                                required={true}
                                placeholder='Mobile No'
                                name='mobileNo'
                                fullWidth
                                size='medium'
                                sx={{ mt: '10px' }}
                                {...getFieldProps("MOBILENO")}
                                error={Boolean(touched.MOBILENO && errors.MOBILENO)}
                                helperText={touched.MOBILENO && errors.MOBILENO}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Email ID </Typography>
                            <CssTextField
                                required={true}
                                placeholder='Email ID'
                                name='email'
                                fullWidth
                                size='medium'
                                sx={{ mt: '10px' }}
                                {...getFieldProps("EMAILID")}
                                error={Boolean(touched.EMAILID && errors.EMAILID)}
                                helperText={touched.EMAILID && errors.EMAILID}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Supervisor Email ID </Typography>
                            <CssTextField
                                placeholder='Supervisor Email ID'
                                name='supervisorEmail'
                                fullWidth
                                size='medium'
                                sx={{ mt: '10px' }}
                                {...getFieldProps("SUPERVISIOREMAILID")}
                                error={ LeaderMailList.some((m) => m.emailId === values.EMAILID) ? Boolean(touched.SUPERVISIOREMAILIDCHECK && errors.SUPERVISIOREMAILIDCHECK) : Boolean(touched.SUPERVISIOREMAILID && errors.SUPERVISIOREMAILID)}
                                helperText={ LeaderMailList.some((m) => m.emailId === values.EMAILID) ? touched.SUPERVISIOREMAILIDCHECK && errors.SUPERVISIOREMAILIDCHECK : touched.SUPERVISIOREMAILID && errors.SUPERVISIOREMAILID}
                            />

                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> HR Email ID </Typography>
                            <CssTextField
                                placeholder='HR Email ID'
                                name='hrEmail'
                                fullWidth
                                size='medium'
                                InputProps={{
                                    readOnly: true,
                                  }}
                                sx={{ mt: '10px' }}
                                {...getFieldProps("HREMAILID")}
                                error={Boolean(touched.HREMAILID && errors.HREMAILID)}
                                helperText={touched.HREMAILID && errors.HREMAILID}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Usage Type </Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                name="usageType"
                                fullWidth
                                options={tripTypeList}
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("USAGETYPE", value.name);
                                }}
                                //{...getFieldProps("USAGETYPE")}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    name='usageType' sx={{ mt: '10px' }} placeholder='Usage Type'
                                    error={Boolean(touched.USAGETYPE && errors.USAGETYPE)}
                                    helperText={touched.USAGETYPE && errors.USAGETYPE}
                                />}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Type of Cab </Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                name="cabType"
                                fullWidth
                                options={typeOfCab}
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("TYPEOFCAB", value.name);
                                }}
                                //{...getFieldProps("TYPEOFCAB")}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    name='cabType' sx={{ mt: '10px' }} placeholder='Type of Cab'
                                    error={Boolean(touched.TYPEOFCAB && errors.TYPEOFCAB)}
                                    helperText={touched.TYPEOFCAB && errors.TYPEOFCAB}
                                />}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Type of Trip </Typography>

                            <Autocomplete
                                id="combo-box-demo"
                                name="cabType"
                                fullWidth
                                options={typeOfTrip}
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("TYPEOFTRIP", value.name);
                                }}
                                //{...getFieldProps("TYPEOFTRIP")}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    name='cabType' sx={{ mt: '10px' }} placeholder='Type of Trip'
                                    error={Boolean(touched.TYPEOFTRIP && errors.TYPEOFTRIP)}
                                    helperText={touched.TYPEOFTRIP && errors.TYPEOFTRIP}
                                />}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Order Date </Typography>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={values.ORDERDATE}
                                    onChange={(e) => handleDateChange(e, 'ORDERDATE')}
                                    renderInput={(params) => <SearchField sx={{ mt: '10px' }} fullWidth {...params}
                                        error={Boolean(touched.ORDERDATE && errors.ORDERDATE)}
                                        helperText={touched.ORDERDATE && errors.ORDERDATE}
                                    />}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Flight / Train Number </Typography>
                            <CssTextField
                                placeholder='Flight / Train Number'
                                name='fligh_train_no'
                                fullWidth
                                size='medium'
                                sx={{ mt: '10px' }}
                                {...getFieldProps("FLIGHTORTRAINNUMBER")}
                                error={Boolean(touched.FLIGHTORTRAINNUMBER && errors.FLIGHTORTRAINNUMBER)}
                                helperText={touched.FLIGHTORTRAINNUMBER && errors.FLIGHTORTRAINNUMBER}
                            />
                            {/* <Autocomplete
                            id="combo-box-demo"
                            name="fligh_train_no"
                            fullWidth
                            options={List}
                            autoHighlight
                            onChange={(event, value) => {
                                setFieldValue("FLIGHTORTRAINNUMBER", value.name);
                            }}
                            //{...getFieldProps("FLIGHTORTRAINNUMBER")}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <CssTextField {...params}
                                name='fligh_train_no' placeholder='Flight / Train Number'
                            />}
                        /> */}
                        </Grid>

                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Special Instruction </Typography>
                            <CssTextField
                                placeholder='Special Instruction'
                                name='specialInstruction'
                                fullWidth
                                size='medium'
                                sx={{ mt: '10px' }}
                                {...getFieldProps("SPECIALINSTRUCTIONS")}
                                error={Boolean(touched.SPECIALINSTRUCTIONS && errors.SPECIALINSTRUCTIONS)}
                                helperText={touched.SPECIALINSTRUCTIONS && errors.SPECIALINSTRUCTIONS}
                            />
                        </Grid>


                        <Grid item
                            lg={6}
                            xs={12}
                            md={6}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Date Of Travel </Typography>
                            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ mt: '10px' }} spacing={2}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        disablePast
                                        value={values.DATEOFTRAVELON}
                                        label='From'
                                        onChange={(e) => handleDateChange(e, 'DATEOFTRAVELON')}
                                        renderInput={(params) => <SearchField fullWidth {...params}
                                            error={Boolean(touched.DATEOFTRAVELON && errors.DATEOFTRAVELON)}
                                            helperText={touched.DATEOFTRAVELON && errors.DATEOFTRAVELON}
                                        />}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        disablePast
                                        value={values.DATEOFTRAVELOUT}
                                        label='To'
                                        onChange={(e) => handleDateChange(e, 'DATEOFTRAVELOUT')}
                                        renderInput={(params) => <SearchField fullWidth {...params}
                                            error={Boolean(touched.DATEOFTRAVELOUT && errors.DATEOFTRAVELOUT)}
                                            helperText={touched.DATEOFTRAVELOUT && errors.DATEOFTRAVELOUT}
                                        />}
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </Grid>
                        <Grid item
                            lg={6}
                            xs={12}
                            md={6}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Travel Destination </Typography>
                            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ mt: '10px' }} spacing={2}>
                                <CssTextField
                                    placeholder='From'
                                    name='from'
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps("TRAVELDESTINATIONFROM")}
                                    error={Boolean(touched.TRAVELDESTINATIONFROM && errors.TRAVELDESTINATIONFROM)}
                                    helperText={touched.TRAVELDESTINATIONFROM && errors.TRAVELDESTINATIONFROM}
                                />

                                <CssTextField
                                    placeholder='To'
                                    name='to'
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps("TRAVELDESTINATIONTO")}
                                    error={Boolean(touched.TRAVELDESTINATIONTO && errors.TRAVELDESTINATIONTO)}
                                    helperText={touched.TRAVELDESTINATIONTO && errors.TRAVELDESTINATIONTO}
                                />
                            </Stack>
                        </Grid>





                        <Grid item
                            lg={6}
                            xs={12}
                            md={6}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Reporting Time </Typography>
                            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ mt: '10px' }} spacing={2}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    name="REPORTINGTIMEFROM"
                                    fullWidth
                                    options={time}
                                    autoHighlight
                                    onChange={(event, value) => {
                                        setFieldValue("REPORTINGTIMEFROM", value.time);
                                    }}
                                    getOptionLabel={(option) => option.time}
                                    renderInput={(params) => <CssTextField {...params}
                                        name='REPORTINGTIMEFROM' placeholder='From'
                                    />}
                                />
                                <Autocomplete
                                    id="combo-box-demo"
                                    name="REPORTTIMETO"
                                    fullWidth
                                    options={time}
                                    autoHighlight
                                    onChange={(event, value) => {
                                        setFieldValue("REPORTTIMETO", value.time);
                                    }}
                                    //{...getFieldProps("GENDER")}
                                    getOptionLabel={(option) => option.time}
                                    renderInput={(params) => <CssTextField {...params}
                                        name='REPORTTIMETO' placeholder='To'
                                    />}
                                />
                                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        value={values.REPORTINGTIMEFROM}
                                        label='From'
                                        type="time"
                                        onChange={(newValue) => {
                                            setFieldValue('REPORTINGTIMEFROM', new Date(newValue).toISOString())
                                        }}
                                        renderInput={(params) => <SearchField fullWidth {...params} />}
                                    />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        value={values.REPORTTIMETO}
                                        label='To'
                                        type="time"
                                        onChange={(newValue) => {
                                            setFieldValue('REPORTTIMETO', new Date(newValue).toISOString())
                                        }}
                                        renderInput={(params) => <SearchField fullWidth {...params} />}
                                    />
                                </LocalizationProvider> */}
                            </Stack>
                        </Grid>

                        <Grid item
                            lg={6}
                            xs={12}
                            md={6}
                            sm={12}
                        >
                            <Typography variant='t14_600'> Reporting Address </Typography>
                            <CssTextField
                                placeholder='Reporting Address'
                                name='reportingAddress'
                                fullWidth
                                size='medium'
                                multiline
                                sx={{ mt: '10px' }}
                                {...getFieldProps("REPORTINGADDRESS")}
                                error={Boolean(touched.REPORTINGADDRESS && errors.REPORTINGADDRESS)}
                                helperText={touched.REPORTINGADDRESS && errors.REPORTINGADDRESS}
                            />
                        </Grid>
                        <Grid item
                            lg={12}
                            xs={12}
                            md={12}
                            sm={12}
                        >
                            <Stack spacing={2} mt='30px' justifyContent='end' direction='row' >
                              <CloseButton sx={{
                                width: '120px',
                                height: '42px',
                                left: '0px',
                                top: '0px',
                               }}
                               variant='contained'
                               type='button'
                               //onClick={{}}
                               >
                                <Typography variant='t14_400' color='common.black'> Cancel </Typography>
                               </CloseButton>
                               <Button sx={{
                                 width: '120px',
                                 height: '42px',
                                 left: '0px',
                                 top: '0px',
                                 backgroundColor: 'common.Bblack'
                                }}
                                variant='contained'
                                onClick={formik.handleSubmit}
                                >
                                 <Typography variant='t14_400' color='inherit'>Submit</Typography>
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </>
    )
}

export default Cab;