//User
import Home from "../pages/user/home";
import Menu from "../pages/user/menu";
import Bulk from "../pages/user/bulk";
import CurrentOrder from "../pages/user/orderManagement/currentOrder";
import OrderHistory from "../pages/user/orderManagement/orderHistory";
import MyRatings from "../pages/user/orderManagement/myRatings";
import MyFeedback from "../pages/user/orderManagement/myFeedback";
import ViewCart from "../pages/user/viewCart";
import ContactPage from "../pages/user/home/ContactPage";
import TermsAndCondition from "../pages/user/home/TermsAndCondition";
import AboutUs from "../pages/user/home/AboutUs";

//profile
import Profile from '../pages/profile'
import ChangePassword from '../pages/changePassword'
//layout
import NavLayout from '../layouts/dashboard';

const User = {
  path: "/user",
  element: <NavLayout />,
  children: [
    { index: true, element: <Home /> },
    { path: "menu", element: <Menu /> },
    { path: "bulk", element: <Bulk /> },
    // { path: "kiosk", element: <Kiosk /> },
    { path: "currentOrder", element: <CurrentOrder /> },
    { path: "orderHistory", element: <OrderHistory /> },
    { path: "myRatings", element: <MyRatings /> },
    { path: "contactUs", element: <ContactPage /> },
    { path: "myFeedbacks", element: <MyFeedback /> },
    { path: "terms&condition", element: <TermsAndCondition /> },
    { path: "viewCart", element: <ViewCart /> },
    { path: "profile", element: <Profile /> },
    { path: "changePassword", element: <ChangePassword /> },
    { path: "aboutUs", element: <AboutUs /> },
  ],
};

export default User;
