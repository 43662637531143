import React, { useState } from 'react';
import {
    TableCell,
    Typography,
    TableRow,
} from '@mui/material';
import ActionButtons from '../../reports/actionButton';
import { getSpecialHistoryViewWatcher } from '../../../../redux/actionCreators/admin/reports';
import { useDispatch } from 'react-redux'

const SpecialHistoryTableRow = ({ rowData, setPageChange }) => {
    const dispatch = useDispatch();

    const [status] = useState('list');

    const viewFunction = () => {
        setPageChange(true);

        let data = {
            "ACTION": "GetUserFoodDetailsReport",
            "UserId": rowData.UserName,
            "Date": rowData.OrderDate
        }
        dispatch(getSpecialHistoryViewWatcher(data))
    }


    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                <TableCell align="left">
                    <Typography variant='t14_600' >
                        {rowData.EmployeeName}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.UserName}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Users}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.OrderDate}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Breakfast}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Dinner}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Supper}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Lunch}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Snacks}
                    </Typography>
                </TableCell>
                <TableCell align="right">
                    <ActionButtons type={'specialHistory'} status={status} viewFunction={viewFunction} />
                </TableCell>
            </TableRow>
        </>
    )
}

export default SpecialHistoryTableRow