import { TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'

export default function SaleTableFooter({rowVal}) {

    const BCP_Total = rowVal.reduce((a,v) =>  +a + +v.BCP , 0 ).toFixed(2)
    const OTHERS_Total = rowVal.reduce((a,v) =>  +a + +v.OTHERS , 0 ).toFixed(2)
    const Quantity_Total = rowVal.reduce((a,v) =>  +a + +v.Quantity , 0 ).toFixed(2)
    const nTotalRate_Total = rowVal.reduce((a,v) =>  +a + +v.Rate , 0 ).toFixed(2)
    const Gst_Total = rowVal.reduce((a,v) =>  +a + +v.Gst , 0 ).toFixed(2)
    const ToatlRate_Total = rowVal.reduce((a,v) =>  +a + +v.ToatlRate , 0 ).toFixed(2)

    return (
        <>
            <TableRow>
                <TableCell sx={{ backgroundColor: 'common.Bblack' }}>
                    <Typography variant='t14_600' color='common.white'>Total</Typography>
                </TableCell>
                <TableCell sx={{ backgroundColor: 'common.Bblack' }} />
                <TableCell>
                    <Typography variant='t14_600'>{BCP_Total}</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='t14_600'>{OTHERS_Total}</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='t14_600'>{Quantity_Total}</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='t14_600'>{nTotalRate_Total}</Typography> 
                </TableCell>
                <TableCell >
                    <Typography variant='t14_600'>{Gst_Total}</Typography>
                </TableCell>
                <TableCell align='right'>
                    <Typography variant='t14_600'>{ToatlRate_Total}</Typography>
                </TableCell>
            </TableRow>
        </>
    )
}
