import React from 'react';
import {
    TableCell,
    Typography,
    TableRow
} from '@mui/material';


const SwipeHistoryTableRow = ({ rowData, index }) => {

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                <TableCell align="left">
                    <Typography variant='t14_600' >
                        {rowData["Contractor Name"]}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData["User Name"]}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Card}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Date}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.MealType}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.deliveryCounter || '---'}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    )
}

export default SwipeHistoryTableRow