import { Button, Divider, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash'
import { getGst, gstRate } from "../../../utils/gst";
import { base_url } from "../../../http-common";
import { orderBookingWatcher } from "../../../redux/actionCreators/user/currentOrder";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../../components/user/confirmDialog/confirmDialog";

const OrderSummary = ({ toggleDrawer }) => {

  const { UserHomeReducers: { cartList, getOrderTotalCost }, ProfileReducers: { loginState } } = useSelector(state => state)

  const nUserId = loginState[0]?.nID || 0
  const cRFID = loginState[0]?.cRFID || 0

  const [openCD, setOpenCD] = useState(false);

  const getTotal = () => {
    const total = getOrderTotalCost.length && getOrderTotalCost[0].nTotalost
    return total
  }

  const groupCart = _.groupBy(cartList, d => d.dOrderDate)

  const mealType = cartList[0]?.Cafeteria

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleCheckout = () => {
    const data = {
      "ACTION": "INSERT",
      "UserID": nUserId,
      "RFID": cRFID,
      "nGst": getGst(getTotal()),
      "Total": getTotal(),
      "OrderBY": "E",
      "CreatedBy": nUserId
    }
    dispatch(orderBookingWatcher(data, dispatch, navigate))
  }

  const handleDialogOpen = () => {
    setOpenCD(true)
  }

  // const dispatchPayment = (data) => {
  //   dispatch(paymentWatcher(data, pushCurrent))
  // }

  // const pushCurrent = () => {
  //   navigate('/user/currentOrder')
  // }

  return (
    <>
      <Box width="357px" p="24px" bgcolor="grey.0">

        <Box display='flex' alignItems='center' >
          <Typography variant='t24_700' color='primary' >
            BCP
            <Typography p='0 4px' variant='t24_700' color='common.foodRed' >
              -
            </Typography>
            PITSTOP
          </Typography>

          <Button onClick={toggleDrawer(false)} sx={{ ml: 'auto' }}>
            <Typography variant='t18_400' >
              Hide
            </Typography>
          </Button>

        </Box>

        <Box mt="49px" mb='31px'>
          <Typography variant="t24_700">Order Summary</Typography>
        </Box>


        {Object.keys(groupCart).map(t => (
          <Box key={t} mt='26px'>
            <Typography variant='t18_600' >
              {t}
            </Typography>
            <Stack mt="24px" spacing={1}>

              {groupCart[t]?.map(d => (
                <Box key={d.NID} display="flex" alignItems="center">
                  <img
                    style={{ width: "80px", height: "80px", borderRadius: "16px", objectFit: 'cover' }}
                    src={`${base_url}/${d.cImage}`}
                    alt=''
                  />
                  <Box display="flex" alignItems="center" ml="15px" width="100%">
                    <Box>
                      <Typography variant="t18_600">{d.cMenuName}</Typography>
                      <br />
                      <Typography variant="t17_400" color="grey.500">
                        x{d.nQuantity}
                      </Typography>
                    </Box>

                    <Typography variant="t18_600" ml="auto">
                      ₹{d.nTotalPrice}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Stack>

          </Box>
        ))}


        <Divider sx={{ mt: '40px', mb: '24px' }} />

        <Box display='flex'>
          <Typography color='grey.500' variant="t16_600">Sub Total</Typography>
          <Typography color='grey.500' variant="t16_600" ml='auto' >₹{getTotal()}</Typography>
        </Box>

        <Box display='flex' mt='9px' >
          <Typography color='grey.500' variant="t16_600">GST {gstRate} %</Typography>
          <Typography color='grey.500' variant="t16_600" ml='auto' >₹{getGst(getTotal())}</Typography>
        </Box>

        <Box display='flex' mt='26px' >
          <Typography variant="t18_600">Total</Typography>
          <Typography variant="t24_600" ml='auto' >₹{+getTotal() + +getGst(getTotal())}</Typography>
        </Box>

        <Button onClick={handleDialogOpen} variant="contained" fullWidth sx={{ mt: '38px', height: '64px' }}>
          <Typography variant="t18_500" >
            Place order
          </Typography>
        </Button>
      </Box>
      {
        openCD &&
          <ConfirmDialog openCD={openCD} setOpenCD={setOpenCD} handleCheckout={handleCheckout} mealType={mealType}/>
      }
    </>
  );
};

export default OrderSummary;
