// ADMIN Watcher Actions

// Admin >> Dashboard >> Order Summary 
export const GET_LAST_WEEK_ORDER_SUMMARY_WATCHER = "GET_LAST_WEEK_ORDER_SUMMARY_WATCHER";
export const GET_LAST_DAY_ORDER_SUMMARY_WATCHER = "GET_LAST_DAY_ORDER_SUMMARY_WATCHER";
export const GET_FILTERED_ORDER_SUMMARY_WATCHER = "GET_FILTERED_ORDER_SUMMARY_WATCHER";
export const GET_SWIPE_ORDER_SUMMARY_WATCHER = "GET_SWIPE_ORDER_SUMMARY_WATCHER";

// Admin >> User Mangement >> Employee Management
export const GET_EMPLOYEE_WATCHER = "GET_EMPLOYEE_WATCHER";
export const CREATE_EMPLOYEE_WATCHER = "CREATE_EMPLOYEE_WATCHER";
export const UPDATE_EMPLOYEE_WATCHER = "UPDATE_EMPLOYEE_WATCHER";
export const DELETE_EMPLOYEE_WATCHER = "DELETE_EMPLOYEE_WATCHER";
export const GET_DROPDOWN_WATCHER = "GET_DROPDOWN_WATCHER";
export const COUNTER_LIST = "COUNTER_LIST";
export const CREATE_BULK_EMPLOYEE_WATCHER = "CREATE_BULK_EMPLOYEE_WATCHER";
export const GET_LIST_BY_ID_WATCHER = "GET_LIST_BY_ID_WATCHER";
export const CAB_VENDOR_LIST = "CAB_VENDOR_LIST";
export const TYPE_OF_TRIP_LIST = "TYPE_OF_TRIP_LIST";

// Admin >> User Mangement >> Contractor Management
export const GET_CONTRACTOR_WATCHER = "GET_CONTRACTOR_WATCHER";
export const CREATE_CONTRACTOR_WATCHER = "CREATE_CONTRACTOR_WATCHER";
export const UPDATE_CONTRACTOR_WATCHER = "UPDATE_CONTRACTOR_WATCHER";
export const DELETE_CONTRACTOR_WATCHER = "DELETE_CONTRACTOR_WATCHER";
export const ADD_USER = "ADD_USER";
export const GET_ALL_CON_USER = "GET_ALL_CON_USER";
export const GET_VIEW_CON_USER = "GET_VIEW_CON_USER";
export const UPDATE_VIEW_CON_USER = "UPDATE_VIEW_CON_USER";
export const DELETE_VIEW_CON_USER = "DELETE_VIEW_CON_USER";

// Admin >> User Mangement >> Vendor Management
export const GET_VENDOR_WATCHER = "GET_VENDOR_WATCHER";
export const CREATE_VENDOR_WATCHER = "CREATE_VENDOR_WATCHER";
export const UPDATE_VENDOR_WATCHER = "UPDATE_VENDOR_WATCHER";
export const DELETE_VENDOR_WATCHER = "DELETE_VENDOR_WATCHER";

export const GET_CAB_VENDOR_WATCHER = "GET_CAB_VENDOR_WATCHER";
export const CREATE_CAB_VENDOR_WATCHER = "CREATE_CAB_VENDOR_WATCHER";
export const UPDATE_CAB_VENDOR_WATCHER = "UPDATE_CAB_VENDOR_WATCHER";
export const DELETE_CAB_VENDOR_WATCHER = "DELETE_CAB_VENDOR_WATCHER";

// Admin >> User Mangement >> Guest Management
export const GET_GUEST_WATCHER = "GET_GUEST_WATCHER";
export const CREATE_GUEST_WATCHER = "CREATE_GUEST_WATCHER";
export const UPDATE_GUEST_WATCHER = "UPDATE_GUEST_WATCHER";
export const DELETE_GUEST_WATCHER = "DELETE_GUEST_WATCHER";
export const ADD_GUEST_USER = "ADD_GUEST_USER";
export const GET_ALL_GUEST_USER = "GET_ALL_GUEST_USER";
export const GET_VIEW_GUEST_USER = "GET_VIEW_GUEST_USER";
export const UPDATE_VIEW_GUEST_USER = "UPDATE_VIEW_GUEST_USER";
export const DELETE_VIEW_GUEST_USER = "DELETE_VIEW_GUEST_USER";

// Admin >> Kitchen >> Menu Mater 
export const GET_MENU_MASTER_WATCHER = "GET_MENU_MASTER_WATCHER";
export const GET_MEAL_DROPDOWN_WATCHER = "GET_MEAL_DROPDOWN_WATCHER";
export const GET_MENU_DROPDOWN_WATCHER = "GET_MENU_DROPDOWN_WATCHER";
export const CREATE_MENU_MASTER_WATCHER = "CREATE_MENU_MASTER_WATCHER";
export const UPDATE_MENU_MASTER_WATCHER = "UPDATE_MENU_MASTER_WATCHER";
export const DELETE_MENU_MASTER_WATCHER = "DELETE_MENU_MASTER_WATCHER";

// Admin >> Kitchen >> Menu Item Master 
export const GET_MENU_ITEM_MASTER_WATCHER = "GET_MENU_ITEM_MASTER_WATCHER";
export const CREATE_MENU_ITEM_MASTER_WATCHER = "CREATE_MENU_ITEM_MASTER_WATCHER";
export const UPDATE_MENU_ITEM_MASTER_WATCHER = "UPDATE_MENU_ITEM_MASTER_WATCHER";
export const DELETE_MENU_ITEM_MASTER_WATCHER = "DELETE_MENU_ITEM_MASTER_WATCHER";

// Admin >> Kitchen >> Contractor Book 
export const CREATE_CONTRACTOR_BOOK_WATCHER = "CREATE_CONTRACTOR_BOOK_WATCHER";
export const CANCEL_CONTRACTOR_BOOK_WATCHER = "CANCEL_CONTRACTOR_BOOK_WATCHER";
export const CONTRACTOR_NAME_LIST_WATCHER = "CONTRACTOR_NAME_LIST_WATCHER";
export const CNS_WATCHER = "CNS_WATCHER";
export const ALl_MEAL_WATCHER = "ALl_MEAL_WATCHER";
export const ALl_MEAL_LIST = "ALl_MEAL_LIST";

// Admin >> Kitchen >> Guest Book 
export const CREATE_GUEST_BOOK_WATCHER = "CREATE_GUEST_BOOK_WATCHER";
export const CANCEL_GUEST_BOOK_WATCHER = "CANCEL_GUEST_BOOK_WATCHER";
export const GUEST_NAME_LIST_WATCHER = "GUEST_NAME_LIST_WATCHER";
export const CNS_WATCHER_GUEST = "CNS_WATCHER_GUEST";
export const ALl_MEAL_WATCHER_GUEST = "ALl_MEAL_WATCHER_GUEST";
export const ALl_MEAL_GUEST_LIST = "ALl_MEAL_GUEST_LIST";
export const GUEST_USER_COUNT_WATCHER = "GUEST_USER_COUNT_WATCHER"

// Admin >> Kitchen >> Roster Management 
export const GET_ROSTER_WATCHER = "GET_ROSTER_WATCHER";
export const CREATE_ROSTER_WATCHER = "CREATE_ROSTER_WATCHER";
export const UPDATE_ROSTER_WATCHER = "UPDATE_ROSTER_WATCHER";  export const DELETE_ROSTER_WATCHER = "DELETE_ROSTER_WATCHER";

// Admin >> Reports >> User History 
export const GET_USER_HISTORY_WATCHER = "GET_USER_HISTORY_WATCHER";
export const GET_USER_HISTORY_VIEW_WATCHER = "GET_USER_HISTORY_VIEW_WATCHER";

// Admin >> Reports >> Kiosk User History 
export const GET_KIOSK_USER_HISTORY_WATCHER = "GET_KIOSK_USER_HISTORY_WATCHER";
export const GET_KIOSK_USER_HISTORY_VIEW_WATCHER = "GET_KIOSK_USER_HISTORY_VIEW_WATCHER";

// Admin >> Reports >> Employee/Contractor
export const GET_EMPLOYEE_CONTRACTOR_WATCHER = "GET_EMPLOYEE_CONTRACTOR_WATCHER"

// Admin >> Reports >> Order Tracking
export const GET_ORDER_TRACKING_WATCHER = "GET_ORDER_TRACKING_WATCHER"
export const GET_ORDER_TRACKING_VIEW_WATCHER = "GET_ORDER_TRACKING_VIEW_WATCHER"

// Admin >> Reports >> Kiosk Order Tracking
export const GET_KIOSK_ORDER_TRACKING_WATCHER = "GET_KIOSK_ORDER_TRACKING_WATCHER"
export const GET_KIOSK_ORDER_TRACKING_VIEW_WATCHER = "GET_KIOSK_ORDER_TRACKING_VIEW_WATCHER"

// Admin >> Reports >> Swipe History
export const GET_CONTRACTOR_SWIPE_HISTORY_WATCHER = "GET_CONTRACTOR_SWIPE_HISTORY_WATCHER"

// Admin >> Reports >> Guest Swipe History
export const GET_GUEST_SWIPE_HISTORY_WATCHER = "GET_GUEST_SWIPE_HISTORY_WATCHER"

// Admin >> Reports >> Delivery History
export const GET_DELIVERY_HISTORY_WATCHER = "GET_DELIVERY_HISTORY_WATCHER"

// Admin >> Reports >> Guest Delivery History
export const GET_GUEST_DELIVERY_HISTORY_WATCHER = "GET_GUEST_DELIVERY_HISTORY_WATCHER"

// Admin >> Reports >> Rating
export const GET_RATING_WATCHER = "GET_DELIVERY_HISTORY_WATCHER"

// Admin >> Reports >> Feedbacks
export const GET_RATING_FEEDBACK_WATCHER = "GET_RATING_FEEDBACK_WATCHER"
export const UPDATE_FEEDBACK_WATCHER = "UPDATE_FEEDBACK_WATCHER"

// Admin >> Reports >> Activity
export const GET_ACTIVITY_WATCHER = "GET_ACTIVITY_WATCHER"

// Admin >> Login Carousel
export const GET_CAROUSEL_IMAGE_WATCHER = "GET_CAROUSEL_IMAGE_WATCHER"
export const CREATE_CAROUSEL_WATCHER = "CREATE_CAROUSEL_WATCHER"
export const UPDATE_CAROUSEL_WATCHER = "UPDATE_CAROUSEL_WATCHER"
export const DELETE_CAROUSEL_WATCHER = "DELETE_CAROUSEL_WATCHER"

// Admin >> User Carousel
export const GET_USER_CAROUSEL_IMAGE_WATCHER = "GET_USER_CAROUSEL_IMAGE_WATCHER"
export const CREATE_USER_CAROUSEL_WATCHER = "CREATE_USER_CAROUSEL_WATCHER"
export const UPDATE_USER_CAROUSEL_WATCHER = "UPDATE_USER_CAROUSEL_WATCHER"
export const DELETE_USER_CAROUSEL_WATCHER = "DELETE_USER_CAROUSEL_WATCHER"
export const FOOD_WASTAGE_WATCHER = "FOOD_WASTAGE_WATCHER"

//Admin >> Freeze Booking
export const GET_FREEZE_BOOKING_WATCHER = "GET_FREEZE_BOOKING_WATCHER"
export const CREATE_FREEZE_BOOKING_WATCHER = "CREATE_FREEZE_BOOKING_WATCHER"
export const UPDATE_FREEZE_BOOKING_WATCHER = "UPDATE_FREEZE_BOOKING_WATCHER"
export const DELETE_FREEZE_BOOKING_WATCHER = "DELETE_FREEZE_BOOKING_WATCHER"
export const GET_SPECIAL_MENU_WATCHER = "GET_SPECIAL_MENU_WATCHER"

// Admin >> Reports >> Special History
export const GET_SPECIAL_HISTORY_WATCHER = "GET_SPECIAL_HISTORY_WATCHER";
export const GET_SPECIAL_HISTORY_VIEW_WATCHER = "GET_SPECIAL_HISTORY_VIEW_WATCHER";

// Admin >> Reports >> Order History
export const GET_ORDER_HISTORY_WATCHER = "GET_ORDER_HISTORY_WATCHER";

// Admin >> Reports >> Guest Order History
export const GET_GUEST_ORDER_HISTORY_WATCHER = "GET_GUEST_ORDER_HISTORY_WATCHER";

// Admin >> Reports >> Day By Day Order 
export const GET_BREAKFAST_DAY_BY_DAY_ORDER_WATCHER = "GET_BREAKFAST_DAY_BY_DAY_ORDER_WATCHER";
export const GET_LUNCH_DAY_BY_DAY_ORDER_WATCHER = "GET_LUNCH_DAY_BY_DAY_ORDER_WATCHER";
export const GET_DINNER_DAY_BY_DAY_ORDER_WATCHER = "GET_DINNER_DAY_BY_DAY_ORDER_WATCHER";
export const GET_SNACKS_DAY_BY_DAY_ORDER_WATCHER = "GET_SNACKS_DAY_BY_DAY_ORDER_WATCHER";
export const GET_SUPPER_DAY_BY_DAY_ORDER_WATCHER = "GET_SUPPER_DAY_BY_DAY_ORDER_WATCHER";

// Admin >> Reports >> Push Notification 
export const GET_PUSH_NOTIFICATION_WATCHER = "GET_PUSH_NOTIFICATION_WATCHER";
export const CREATE_PUSH_NOTIFICATION_WATCHER = "CREATE_PUSH_NOTIFICATION_WATCHER";

export const CREATE_SCHEDULE_NOTIFICATION_WATCHER = "CREATE_SCHEDULE_NOTIFICATION_WATCHER";

//Admin >> Special Order Tracking
export const GET_SPECIAL_ORDER_TRACKING_WATCHER = "GET_SPECIAL_ORDER_TRACKING_WATCHER";
export const GET_SPECIAL_ORDER_TRACKING_VIEW_WATCHER = "GET_SPECIAL_ORDER_TRACKING_VIEW_WATCHER";

//Admin >> Contractor Invoice
export const GET_CONTRACTOR_INVOICE_WATCHER = "GET_CONTRACTOR_INVOICE_WATCHER";

//Admin >> Sale Report
export const GET_SALE_REPORT_WATCHER = "GET_SALE_REPORT_WATCHER";
export const GET_SALE_MENU_WATCHER = "GET_SALE_MENU_WATCHER"

//----------------------------------------------------------------------------------------------------------------------------------------------//

// ADMIN Reducer Actions

// Admin >> Dashboard >> Order Summary 
export const ORDER_SUMMARY_LAST_WEEK_DATA = "ORDER_SUMMARY_LAST_WEEK_DATA";
export const ORDER_SUMMARY_LAST_DAY_DATA = "ORDER_SUMMARY_LAST_DAY_DATA";
export const ORDER_SUMMARY_FILTERED_DATA = "ORDER_SUMMARY_FILTERED_DATA";
export const SWIPE_ORDER_SUMMARY_DATA = "SWIPE_ORDER_SUMMARY_DATA";
export const MEAL_COUNT_DATA = "MEAL_COUNT_DATA";

// Admin >> User Mangement >> Employee Management
export const EMPLOYEE_LIST = "EMPLOYEE_LIST";
export const ROLE_NAME_LIST = "ROLE_NAME_LIST";
export const DIVISION_LIST = "DIVISION_LIST";
export const ACCESS_POINT_LIST = "ACCESS_POINT_LIST";
export const DEPARTMENT_LIST = "DEPARTMENT_LIST";
export const TRIP_TYPE_LIST = "TRIP_TYPE_LIST";
export const TYPE_OF_CAB = "TYPE_OF_CAB";
export const CONTRACTOR = "CONTRACTOR";
export const VENDOR_NAME_LIST = "VENDOR_NAME_LIST";
export const CAFETERIA_LIST = "CAFETERIA_LIST";
export const GUEST_DROP_LIST = "GUEST_DROP_LIST";
export const GET_LIST_BY_ID_LIST = "GET_LIST_BY_ID_LIST";
export const DEPARTMENT_NAME_LIST = "DEPARTMENT_NAME_LIST";
export const CATEGORY_NAME_LIST = "CATEGORY_NAME_LIST";

// Admin >> User Mangement >> Contractor Management
export const CONTRACTOR_LIST = "CONTRACTOR_LIST";
export const ALL_CONTRACTOR_USER_LIST = "ALL_CONTRACTOR_USER_LIST";
export const VIEW_CONTRACTOR_USER_LIST = "VIEW_CONTRACTOR_USER_LIST";

// Admin >> User Mangement >> Vendor Management
export const VENDOR_LIST = "VENDOR_LIST";

// Admin >> User Mangement >> Guest Management
export const GUEST_LIST = "GUEST_LIST";
export const ALL_GUEST_USER_LIST = "ALL_GUEST_USER_LIST";
export const VIEW_GUEST_USER_LIST = "VIEW_GUEST_USER_LIST";

// Admin >> Kitchen >> Menu Mater 
export const MENU_MASTER_LIST = "MENU_MASTER_LIST";
export const MEAL_DROPDOWN_LIST = "MEAL_DROPDOWN_LIST";
export const MENU_DROPDOWN_LIST = "MENU_DROPDOWN_LIST";

// Admin >> Kitchen >> Menu Item Master 
export const MENU_ITEM_MASTER_LIST = "MENU_ITEM_MASTER_LIST";

// Admin >> Kitchen >> Contractor Book 
export const CONTRACTOR_BOOK_LIST = "CONTRACTOR_BOOK_LIST";
export const CONTRACTOR_NAME_LIST = "CONTRACTOR_NAME_LIST";
export const CNS_RATE_LIST = "CNS_RATE_LIST";
export const CNS_MENUID_LIST = "CNS_MENUID_LIST";

// Admin >> Kitchen >> Guest Book 
export const GUEST_BOOK_LIST = "GUEST_BOOK_LIST";
export const GUEST_NAME_LIST = "GUEST_NAME_LIST";
export const CNS_RATE_GUEST_LIST = "CNS_RATE_GUEST_LIST";
export const CNS_MENUID_GUEST_LIST = "CNS_MENUID_GUEST_LIST";
export const GET_GUEST_COUNT_LIST = "GET_GUEST_COUNT_LIST"

// Admin >> Kitchen >> Roster management 
export const ROSTER_LIST = "ROSTER_LIST";

// Admin >> Reports >> Employee/Contractor
export const EMPLOYEE_CONTRACTOR_LIST = "EMPLOYEE_CONTRACTOR_LIST"

// Admin >> Reports >> User History 
export const USER_HISTORY_LIST = "USER_HISTORY_LIST";
export const USER_HISTORY_VIEW_LIST = "USER_HISTORY_VIEW_LIST";

// Admin >> Reports >> Kiosk User History 
export const KIOSK_USER_HISTORY_LIST = "KIOSK_USER_HISTORY_LIST";
export const KIOSK_USER_HISTORY_VIEW_LIST = "KIOSK_USER_HISTORY_VIEW_LIST";

// Admin >> Reports >> Order Tracking 
export const ORDER_TRACKING_LIST = "ORDER_TRACKING_LIST";
export const ORDER_TRACKING_VIEW_LIST = "ORDER_TRACKING_VIEW_LIST";

// Admin >> Reports >> Kiosk Order Tracking 
export const KIOSK_ORDER_TRACKING_LIST = "KIOSK_ORDER_TRACKING_LIST";
export const KIOSK_ORDER_TRACKING_VIEW_LIST = "KIOSK_ORDER_TRACKING_VIEW_LIST";

// Admin >> Reports >> Order Tracking 
export const CONTRACTOR_SWIPE_HISTORY_LIST = "CONTRACTOR_SWIPE_HISTORY_LIST";

// Admin >> Reports >> Guest Swipe History 
export const GUEST_SWIPE_HISTORY_LIST = "GUEST_SWIPE_HISTORY_LIST";

// Admin >> Reports >> Order Tracking 
export const DELIVERY_HISTORY_LIST = "DELIVERY_HISTORY_LIST";

// Admin >> Reports >> Guest Order Tracking 
export const GUEST_DELIVERY_HISTORY_LIST = "GUEST_DELIVERY_HISTORY_LIST"

// Admin >> Reports >> Rating 
export const RATING_LIST = "RATING_LIST";

// Admin >> Reports >> Feedback
export const RATING_FEEDBACK_LIST = "RATING_FEEDBACK_LIST"

// Admin >> Reports >> Activity
export const ACTIVITY_LIST = "ACTIVITY_LIST"

// Admin >> Reports >> Login Carousel
export const CAROUSEL_IMAGE_LIST = "CAROUSEL_IMAGE_LIST"

// Admin >> Reports >> User Carousel
export const USER_CAROUSEL_IMAGE_LIST = "USER_CAROUSEL_IMAGE_LIST"
export const FOOD_WASTAGE_LIST = "FOOD_WASTAGE_LIST"

//Admin >> Freeze Booking
export const FREEZE_BOOKING_LIST = "FREEZE_BOOKING_LIST"
export const SPECIAL_MENU_LIST = "SPECIAL_MENU_LIST"

// Admin >> Reports >> Special History 
export const SPECIAL_HISTORY_LIST = "SPECIAL_HISTORY_LIST";
export const SPECIAL_HISTORY_VIEW_LIST = "SPECIAL_HISTORY_VIEW_LIST";

// Admin >> Reports >> Special History 
export const ORDER_HISTORY_LIST = "ORDER_HISTORY_LIST";

// Admin >> Reports >> Guest History 
export const GUEST_ORDER_HISTORY_LIST = "GUEST_ORDER_HISTORY_LIST";

// Admin >> Reports >> Day By Day Order 
export const DAY_BY_DAY_ORDER_LIST = "DAY_BY_DAY_ORDER_LIST";
export const BREAKFAST_ORDER_LIST = "BREAKFAST_ORDER_LIST";
export const LUNCH_ORDER_LIST = "LUNCH_ORDER_LIST";
export const DINNER_ORDER_LIST = "DINNER_ORDER_LIST";
export const SUPPER_ORDER_LIST = "SUPPER_ORDER_LIST";
export const SNACKS_ORDER_LIST = "SNACKS_ORDER_LIST";

// Admin >> Reports >> Push Notification 
export const PUSH_NOTIFICATION_LIST = "PUSH_NOTIFICATION_LIST";

export const SCHEDULE_NOTIFICATION_LIST = "SCHEDULE_NOTIFICATION_LIST"

//Admin >> Special Order Tracking
export const SPECIAL_ORDER_LIST = "SPECIAL_ORDER_LIST"
export const SPECIAL_ORDER_VIEW_LIST = "SPECIAL_ORDER_VIEW_LIST"

//Admin >> Contractor Invoice
export const CONTRACTOR_INVOICE_LIST = "CONTRACTOR_INVOICE_LIST"

//Admin >> Sale Report
export const SALE_REPORT_LIST = "SALE_REPORT_LIST"
export const SALE_MENU_LIST = "SALE_MENU_LIST"

//Admin >> Cab Reports
export const GET_CAB_REPORTS_WATCHER = "GET_CAB_REPORTS_WATCHER"
export const GET_CAB_REPORTS = "GET_CAB_REPORTS"

