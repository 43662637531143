import React, { useState, useEffect, useRef } from 'react';
import {
    TableCell,
    Typography,
    TableRow,
    Autocomplete
} from '@mui/material';
import ActionButtons from '../../dashboard/actionButtons';
import EditTextField from '../../../overrides/editField';
import { updateMenuItemMasterWatcher, deleteMenuItemMasterWatcher } from '../../../../redux/actionCreators/admin/menuItemMaster';
import { useDispatch, useSelector } from 'react-redux'
import { getMenuDropdownWatcher } from '../../../../redux/actionCreators/admin/menuMaster';


const MenuItemTableRow = ({ rowData, index, setDisableEdit, disableEdit, page }) => {

    const dispatch = useDispatch();
    const { MenuMasterReducers: { mealDropdownList, menuDropdownList } } = useSelector((state) => state)
    const meal = (mealDropdownList.length && mealDropdownList[3]) || []

    const [status, setStatus] = useState('list');
    const [editData, setEditData] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [mealVal, setMealVal] = useState('')
    const tempinitsform = useRef();
    const tempinitform = useRef();
    const tempform = useRef();

    const initsform = () => {
        if (mealVal) {
            let data = { "NMEALID": `${mealVal}` }
            dispatch(getMenuDropdownWatcher(data))
        }
    };
    
    tempinitsform.current = initsform;
    
    useEffect(() => {
       tempinitsform.current();
    }, [mealVal]);

    // useEffect(() => {
    //     if (mealVal) {
    //         let data = { "NMEALID": `${mealVal}` }
    //         dispatch(getMenuDropdownWatcher(data))
    //     }

    // }, [mealVal])

    const initform = () => {
        setEditData(rowData)
    };
    
    tempinitform.current = initform;
    
    useEffect(() => {
       tempinitform.current();
    }, []);

    // useEffect(() => {
    //     setEditData(rowData)
    // }, [])

    const init = () => {
        setDisableEdit(false);
    };
    
    tempform.current = init;
    
    useEffect(() => {
       tempform.current();
    }, [page]);

    // useEffect(() => {
    //     setDisableEdit(false);
    // }, [page])

    const handleEditChange = (e) => {
        const { name, value } = e.target
        setEditData({ ...editData, [name]: value })
    }

    const handleEdit = () => {
        let data = { ...rowData }
        data.cMenuName = data.cMenuName[0]
        setEditData(data);
        setStatus('edit');
        setDisableEdit(true);
        // if(!mealVal){
        let data1 = { "NMEALID": `${rowData.nMealID}` }
        dispatch(getMenuDropdownWatcher(data1))

        // }
    }

    const handleDelete = async () => {
        let data = { "ACTION": "DELETE" }
        await dispatch(deleteMenuItemMasterWatcher(rowData.nID, { data }))
        setDeleteDialogOpen(false);
        setStatus('list');
    }

    const tickIconFunction = async () => {
        let data = { ...editData }
        data.ACTION = "MenuItemUpdate"
        await dispatch(updateMenuItemMasterWatcher(rowData.nID, data));
        setStatus('list');
        setDisableEdit(false);
    }

    const cancelIconFunction = () => {
        setEditData({});
        setStatus('list');
        setDisableEdit(false);

    }

    let editMealList = meal.filter((a) => a.nMealApplicable === rowData.typeID) || []
    let editMealName = editMealList.find(m => m.id === rowData.nMealID) || {}

    return (
        <>
            <TableRow
                hover={status === 'list' ? true : false}
                tabIndex={-1}
            >
                {status === 'list' ?
                    <>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.ID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cMealName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cMenuName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cItemName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cItemDesc}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.nQuantity}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.nRate}
                            </Typography>
                        </TableCell>
                    </>
                    :
                    <>
                        <TableCell align="left" >
                            <Typography variant='t14_600'>
                                {rowData.ID}
                            </Typography>
                        </TableCell>

                        <TableCell align="left">
                            <Autocomplete
                                id="combo-box-demo"
                                name="nMealID"
                                fullWidth
                                options={editMealList}
                                defaultValue={editMealName}
                                size="small"
                                autoHighlight
                                onChange={(event, value) => {
                                    setEditData({ ...editData, "nMealID": value.id });
                                    setMealVal(value.id);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <EditTextField {...params}
                                    sx={{ minWidth: '160px' }}
                                    name='nMealID'
                                />}
                            />
                        </TableCell>

                        <TableCell align="left">
                            <Autocomplete
                                id="combo-box-demo"
                                name="nMenuID"
                                fullWidth
                                size="small"
                                autoHighlight
                                value={menuDropdownList.find(m => +m.id === +editData.nMenuID) || null}
                                options={menuDropdownList}
                                onChange={(event, value) => {
                                    setEditData({ ...editData, "nMenuID": value.id });
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <EditTextField {...params}
                                    sx={{ minWidth: '160px' }}
                                    name='nMenuID'
                                />}
                            />
                        </TableCell>

                        <TableCell align="left">
                            <EditTextField
                                name='cItemName'
                                value={editData.cItemName}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='cItemDesc'
                                value={editData.cItemDesc}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='nQuantity'
                                value={editData.nQuantity}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='nRate'
                                onChange={handleEditChange}
                                value={editData.nRate} />
                        </TableCell>
                    </>
                }
                <TableCell align="right">
                    <ActionButtons type={'menuMaster'} setStatus={setStatus} status={status} setEditData={setEditData} rowData={rowData} tickIconFunction={tickIconFunction} handleDelete={handleDelete} handleEdit={handleEdit} cancelIconFunction={cancelIconFunction} setDeleteDialogOpen={setDeleteDialogOpen} deleteDialogOpen={deleteDialogOpen} disableEdit={disableEdit} />
                </TableCell>
            </TableRow>
        </>
    )
}

export default MenuItemTableRow