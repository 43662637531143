import React from 'react';
import EmpChildTableHead from './empChildHead';
import EditTextField from '../../../overrides/editField';

// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    Autocomplete
} from '@mui/material';
import { useSelector } from 'react-redux'


const TABLE_HEAD = [
    { id: 'cUserName', label: 'User Name', alignRight: false },
    { id: 'nAccessPoint', label: 'Access Points', alignRight: false },
    { id: 'nDivisionID', label: 'Division', alignRight: false },
    { id: 'cPassword', label: 'Password', alignRight: false },
    { id: 'cRFID', label: 'RFID', alignRight: false },
    { id: 'categoryName', label: 'Category Name', alignRight: false },
    { id: 'departmentName', label: 'Department Name', alignRight: false },
];

const EmployeeChildTable = ({ expandOpen, rowData, order, orderBy, handleRequestSort, handleEditChange, status, editData, setEditData, passErr }) => {

    const { EmployeeReducers: { accessPointList, divisionList, departmentNameList, categoryList } } = useSelector((state) => state)

    let division = divisionList.find(m => m.id === rowData.nDivisionID) || {}
    let category = categoryList.find(m => m.name === rowData.categoryName) || {}
    let department = departmentNameList.find(m => m.name === rowData.departmentName) || {}
    let getAccesspointNames = rowData.nAccessPoint?.split(',') || []
    let accessPoints = accessPointList.filter(m => getAccesspointNames.includes(m.name)) || []

    return (
        <>
            {expandOpen &&
                <TableContainer sx={{ overflow: 'unset' }}>
                    <Table>
                        <EmpChildTableHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            //   rowCount={USERLIST.length}
                            //   numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            <TableRow
                                // hover
                                tabIndex={-1}
                                sx={{ '& > *': { borderBottom: 'unset' } }}
                            >
                                {status === 'list' ?
                                    <>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.cUserName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.nAccessPoint}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.cDivisionName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.cPassword}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.cRFID || `---`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.categoryName || `---`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.departmentName || `---`}
                                            </Typography>
                                        </TableCell>
                                    </>
                                    :
                                    <>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.cUserName}
                                            </Typography>
                                        </TableCell>

                                        <TableCell align="left">
                                            <Autocomplete
                                                id="combo-box-demo"
                                                name="nAccessPoint"
                                                multiple
                                                fullWidth
                                                options={accessPointList}
                                                size="small"
                                                autoHighlight
                                                defaultValue={accessPoints}
                                                // value={roleName.name}
                                                onChange={(event, value) => {
                                                    let Val = value.map(m => m.name)
                                                    setEditData({ ...editData, nAccessPoint: `${Val}` });
                                                }}
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => <EditTextField {...params}
                                                    sx={{ minWidth: '160px', '& .MuiOutlinedInput-root': { height: 'auto' } }}
                                                    name='nAccessPoint'
                                                />}
                                            />
                                        </TableCell>

                                        <TableCell align="left">
                                            <Autocomplete
                                                id="combo-box-demo"
                                                name="nDivisionID"
                                                fullWidth
                                                options={divisionList}
                                                size="small"
                                                autoHighlight
                                                defaultValue={Object.values(division).length === 0 ? divisionList.find(m => m.id === 0) : division}
                                                // value={roleName.name}
                                                onChange={(event, value) => {
                                                    setEditData({ ...editData, nDivisionID: `${value.id}` });
                                                }}
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => <EditTextField {...params}
                                                    sx={{ minWidth: '160px' }}
                                                    name='nDivisionID'
                                                />}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <EditTextField
                                                name='cPassword'
                                                value={editData.cPassword}
                                                onChange={handleEditChange}
                                                onBlur={handleEditChange}
                                                error={passErr ? true : false}
                                                helperText={passErr ? passErr : ''}
                                                sx={{ minWidth: '160px' }}
                                            />
                                        </TableCell>
                                        {rowData.cRoleName === 'Employee' ?
                                            <>
                                                <TableCell align="left">
                                                    <EditTextField
                                                        name='cRFID'
                                                        value={editData.cRFID}
                                                        onChange={handleEditChange}
                                                    />
                                                </TableCell>

                                                <TableCell align="left">
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        name="nCategory"
                                                        fullWidth
                                                        options={categoryList}
                                                        size="small"
                                                        autoHighlight
                                                        defaultValue={Object.values(category).length === 0 ? categoryList.find(m => m.id === 0) : category}
                                                        // value={roleName.name}
                                                        onChange={(event, value) => {
                                                            setEditData({ ...editData, nCategory: `${value.id}` });
                                                        }}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => <EditTextField {...params}
                                                            sx={{ minWidth: '160px' }}
                                                            name='nCategory'
                                                        />}
                                                    />
                                                </TableCell>

                                                <TableCell align="left">
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        name="nDepartment"
                                                        fullWidth
                                                        options={departmentNameList}
                                                        size="small"
                                                        autoHighlight
                                                        defaultValue={Object.values(department).length === 0 ? departmentNameList.find(m => m.id === 0) : department}
                                                        // value={roleName.name}
                                                        onChange={(event, value) => {
                                                            setEditData({ ...editData, nDepartment: `${value.id}` });
                                                        }}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => <EditTextField {...params}
                                                            sx={{ minWidth: '160px' }}
                                                            name='nDepartment'
                                                        />}
                                                    />
                                                </TableCell>
                                            </>
                                            :
                                            <>
                                                <TableCell align="left">
                                                    ---
                                                </TableCell>
                                                <TableCell align="left">
                                                    ---
                                                </TableCell>
                                                <TableCell align="left">
                                                    ---
                                                </TableCell>
                                            </>
                                        }
                                    </>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    )
}

export default EmployeeChildTable;