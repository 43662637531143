import { call, put, takeEvery, select } from "redux-saga/effects";
import { LOGIN_WATCHER, GET_PROFILE_WATCHER, PROFILE_UPDATE_WATCHER, GET_ROLE_NAMES_WATCHER, FORGET_PASSWORD_WATCHER, PASSWORD_UPDATE_WATCHER, USER_CHECK_WATCHER, SEND_MAIL_WATCHER } from "../../actionTypes/profile";
import { updateLogin, updateProfile, updateRoleNames } from "../../actionCreators/profile";
import Service from "../../../services/profile"
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { GetRoutes } from "../../../utils/constants";
import { updateAlert } from "../../actionCreators/alert";
import Cookies from 'universal-cookie';

function* loginEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.login, action.payload);
    const getData = data.recordset[0] || {}
    const newData = [{ ...getData, isRemember: action.remember }]
      

    if(data.recordset.length){
      const path = GetRoutes(data.recordset[0].cRoleName)
      if(action.remember){
        localStorage.setItem("login", JSON.stringify(newData))
      }else{
        sessionStorage.setItem("login", JSON.stringify(newData))
      }
      // cookies.set('login', JSON.stringify({ refreshToken : getData.refreshToken, accessToken : getData.accessToken }))
      action.goTo(path)
      yield put(updateLogin(newData));
      yield put(updateAlert('Successfully logged in', 'success'));
    }else{
      yield put(updateAlert('Username or Password is incorrect', 'error'));
    }
    yield put(updateFalseLoader());

  } catch (e) {
    yield put(updateAlert(e.message, 'error'));
   }
}

function* UserCheckEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.login, action.payload);

    yield put(updateFalseLoader());

  } catch (e) { }
}

function* getProfileEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.getProfile, action.id, action.payload);

    const { loginState } = yield select((state) => state.ProfileReducers)

    const oldData = loginState[0] || {}

    const newData = [{ ...oldData, ...data.recordset[0] }]
    if (oldData.isRemember) {
      localStorage.setItem("login", JSON.stringify(newData))
    } else {
      sessionStorage.setItem("login", JSON.stringify(newData))
    }
    yield put(updateLogin(newData));
    yield put(updateProfile(newData));
    yield put(updateFalseLoader());

  } catch (e) { }
}

function* profileUpdateEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.profileUpdate, action.id, action.payload);
    let { data: profile } = yield call(Service.getProfile, action.id, action.getProfile);

    const { loginState } = yield select((state) => state.ProfileReducers)

    const oldData = loginState[0] || {}

    const newData = [{ ...oldData, ...profile.recordset[0] }]
    if (oldData.isRemember) {
      localStorage.setItem("login", JSON.stringify(newData))
    } else {
      sessionStorage.setItem("login", JSON.stringify(newData))
    }

    yield put(updateLogin(newData));
    yield put(updateProfile(newData));
    yield put(updateFalseLoader());

  } catch (e) { }
}

function* passwordUpdateEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.passwordUpdate, action.id, action.payload);
    action.handleBack()
    yield put(updateFalseLoader());

  } catch (e) { }
}

function* forgetPasswordEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.forgetPassword, action.id, action.payload);

    yield put(updateFalseLoader());

  } catch (e) { }
}

function* getRoleNamesEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.getRoleNames, action.payload);

    yield put(updateRoleNames(data.recordset));
    yield put(updateFalseLoader());

  } catch (e) { }
}

function* sendMailEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.sendMail, action.payload);
    action.setOpen(false)
    yield put(updateAlert('Mail sent successfully', 'success'));
    yield put(updateFalseLoader());

  } catch (e) {
    yield put(updateAlert(e.response?.data.message, 'error'));
    yield put(updateFalseLoader());
   }
}

//saga 
export const profileSaga = [
  takeEvery(LOGIN_WATCHER, loginEffect),
  takeEvery(GET_PROFILE_WATCHER, getProfileEffect),
  takeEvery(PROFILE_UPDATE_WATCHER, profileUpdateEffect),
  takeEvery(PASSWORD_UPDATE_WATCHER, passwordUpdateEffect),
  takeEvery(FORGET_PASSWORD_WATCHER, forgetPasswordEffect),
  takeEvery(GET_ROLE_NAMES_WATCHER, getRoleNamesEffect),
  takeEvery(USER_CHECK_WATCHER, UserCheckEffect),
  takeEvery(SEND_MAIL_WATCHER, sendMailEffect),
]