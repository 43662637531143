import React from 'react';
import { useState } from 'react';
import EmpTableHead from './empTableHeader';
import Scrollbar from '../../../Scrollbar';
import EmpTableRow from './empTableRow';
import { useSelector } from 'react-redux'
// material
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Pagination,
    Typography,
    Box
} from '@mui/material';

const TABLE_HEAD = [
    { id: 'cEmployeeID', label: 'ID Number', alignRight: false },
    { id: 'cEmployeeName', label: 'User Name', alignRight: false },
    { id: 'cEmailID', label: 'Email ID', alignRight: false },
    { id: 'cMobileNumber', label: 'Mobile Number', alignRight: false },
    { id: 'role', label: 'Role', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {

    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

const EmployeeManagementTable = ({searchVal, searchText, page, setPage, role}) => {

    const { EmployeeReducers: { employeeList } } = useSelector((state) => state)

    const firstIndex = 0;

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('idNumber');
    const [filterName] = useState('');
    const [rowsPerPage] = useState(5);

    // useEffect(() => {
    //     setEmployeeData(employee.slice(firstIndex, 5))
    // }, [setEmployeeData, employee])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, value) => {
        setPage(value);
        // setEmployeeData(employee.slice(firstIndex + 5 * (value - 1), 5 * value));
    };
    const tableData =  searchText ? searchVal : employeeList
    const filteredData = applySortFilter(tableData, getComparator(order, orderBy), filterName);
    const isUserNotFound = filteredData?.length === 0;

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 500, overflow: 'unset' }}>
                        <Table>
                            <EmpTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    // .slice(page * 5, page * 5 + 5)
                                    .map((m, i) => {
                                        return (
                                            <EmpTableRow role={role} key={m.nID} rowData={m} handleRequestSort={handleRequestSort} order={order} orderBy={orderBy} />
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(tableData?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default EmployeeManagementTable