import { userHistoryList, kioskUserHistoryList, specialHistoryList, orderHistoryList, guestOrderHistoryList, dayByDayOrderList, breakfastOrderList, lunchOrderList, dinnerOrderList, supperOrderList, snacksOrderList, pushNotificationList, orderTrackingList, contractorSwipeHistoryList, guestSwipeHistoryList, deliveryHistoryList, guestDeliveryHistoryList, ratingFeedbackList, activityList, specialOrderList, contractorInvoiceList, specialHistoryViewList, userHistoryViewList, kioskUserHistoryViewList, saleReportList, orderTrackingViewList, specialOrderViewList, kioskOrderTrackingList, getCabReports } from "../../actionCreators/admin/reports";
import { call, put, takeEvery } from "redux-saga/effects";
import ReportsServices from "../../../services/admin/reports";
import { GET_SPECIAL_HISTORY_WATCHER, GET_USER_HISTORY_WATCHER, GET_KIOSK_USER_HISTORY_WATCHER, GET_ORDER_HISTORY_WATCHER, GET_DINNER_DAY_BY_DAY_ORDER_WATCHER, GET_BREAKFAST_DAY_BY_DAY_ORDER_WATCHER, GET_LUNCH_DAY_BY_DAY_ORDER_WATCHER, GET_SNACKS_DAY_BY_DAY_ORDER_WATCHER, GET_SUPPER_DAY_BY_DAY_ORDER_WATCHER, GET_PUSH_NOTIFICATION_WATCHER, GET_ORDER_TRACKING_WATCHER, GET_CONTRACTOR_SWIPE_HISTORY_WATCHER, GET_GUEST_SWIPE_HISTORY_WATCHER, GET_DELIVERY_HISTORY_WATCHER, GET_GUEST_DELIVERY_HISTORY_WATCHER, GET_RATING_FEEDBACK_WATCHER, GET_ACTIVITY_WATCHER, CREATE_PUSH_NOTIFICATION_WATCHER, GET_SPECIAL_ORDER_TRACKING_WATCHER, GET_CONTRACTOR_INVOICE_WATCHER, GET_USER_HISTORY_VIEW_WATCHER, GET_KIOSK_USER_HISTORY_VIEW_WATCHER, GET_SPECIAL_HISTORY_VIEW_WATCHER, GET_SALE_REPORT_WATCHER, GET_SALE_MENU_WATCHER, GET_SPECIAL_ORDER_TRACKING_VIEW_WATCHER, GET_ORDER_TRACKING_VIEW_WATCHER, UPDATE_FEEDBACK_WATCHER, GET_GUEST_ORDER_HISTORY_WATCHER, GET_KIOSK_ORDER_TRACKING_WATCHER, GET_KIOSK_ORDER_TRACKING_VIEW_WATCHER, GET_CAB_REPORTS_WATCHER } from "../../actionTypes/admin";
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* getOrderTrackingEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getOrderTracking, action.payload);
    yield put(orderTrackingList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
} 

function* getKioskOrderTrackingEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getKioskOrderTracking, action.payload);
    yield put(kioskOrderTrackingList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getContractorSwipeHistoryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getContractorSwipeHistory, action.payload);
    yield put(contractorSwipeHistoryList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getGuestSwipeHistoryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getGuestSwipeHistory, action.payload);
    yield put(guestSwipeHistoryList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getDeliveryHistoryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getDeliveryHistory, action.payload);
    yield put(deliveryHistoryList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
} 

function* getGuestDeliveryHistoryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getGuestDeliveryHistory, action.payload);
    yield put(guestDeliveryHistoryList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

// function* getAdminRateListEffect(action) {
//   try {
//     yield put(updateOpenLoader());
//     let { data } = yield call(ReportsServices.getAdminRateList, action.payload);
//     yield put(ratingList(data.recordset));
//     yield put(updateFalseLoader());
//   } catch (e) { }
// }

function* getRatingFeedbackListEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getRatingFeedbackList, action.payload);
    yield put(ratingFeedbackList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getActivityListEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getActivityList, action.payload);
    yield put(activityList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getUserHistoryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getReports, action.payload);
    yield put(userHistoryList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getKioskUserHistoryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getKioskUserHistory, action.payload);
    yield put(kioskUserHistoryList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getUserHistoryViewEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getReports, action.payload);
    yield put(userHistoryViewList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getKioskUserHistoryViewEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getKioskUserHistory, action.payload);
    yield put(kioskUserHistoryViewList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getSpecialHistoryViewEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getReports, action.payload);
    yield put(specialHistoryViewList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getSpecialHistoryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getReports, action.payload);
    yield put(specialHistoryList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getOrderHistoryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getOrderHistory, action.payload);
    yield put(orderHistoryList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getGuestOrderHistoryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getGuestOrderHistory, action.payload);
    yield put(guestOrderHistoryList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getDinnerDayByDayOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getDayByDayOrder, action.payload);
    yield put(dayByDayOrderList(data.recordset));
    yield put(dinnerOrderList(data.recordsets));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getBreakfastDayByDayOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getDayByDayOrder, action.payload);
    yield put(breakfastOrderList(data.recordsets));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getLunchDayByDayOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getDayByDayOrder, action.payload);
    yield put(lunchOrderList(data.recordsets));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getSupperDayByDayOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getDayByDayOrder, action.payload);
    yield put(supperOrderList(data.recordsets));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getSnacksDayByDayOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getDayByDayOrder, action.payload);
    yield put(snacksOrderList(data.recordsets));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getPushNotificationEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getPushNotification, action.payload);
    yield put(pushNotificationList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* createPushNotificationEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.createPushNotification, action.payload);
    action.setOpen(false)
    yield put(pushNotificationList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Created Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getOrderTrackingViewEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.verifyPaytm, action.payload);
    yield put(orderTrackingViewList(data));
    action.setOpen(true)
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getKioskOrderTrackingViewEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.verifyPaytm, action.payload);
    yield put(orderTrackingViewList(data));
    action.setOpen(true)
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getSpecialOrderTrackingViewEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getReports, action.payload);
    yield put(specialOrderViewList(data.recordsets));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getSpecialOrderTrackingEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getSpecialOrderTracking, action.payload);
    yield put(specialOrderList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getContractorInvoiceEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getReports, action.payload);
    yield put(contractorInvoiceList(data.recordsets));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getSaleReportEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getSaleReport, action.payload);
    yield put(saleReportList(data.recordsets));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getSaleMenuEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ReportsServices.getSaleMenu, action.payload);
    yield put(saleReportList(data.recordsets));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* updateFeedbackEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(ReportsServices.updateFeedback, action.id, action.payload);
    let { data: get } = yield call(ReportsServices.getRatingFeedbackList, { "Action": "adminFeedList" });
    yield put(ratingFeedbackList(get.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Updated Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getCabReportsEffect(action) {
  try {
      yield put(updateOpenLoader());
      let { data } = yield call(ReportsServices.getCabReports, action.payload);
      console.log("data 3", data)
      yield put(getCabReports(data.recordset));
      yield put(updateFalseLoader());
      //yield put(updateAlert('Success', 'success'));
  } catch (e) {
      yield put(updateFalseLoader());
      yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

//saga 
export const ReportsSaga = [
  takeEvery(GET_USER_HISTORY_WATCHER, getUserHistoryEffect),
  takeEvery(GET_KIOSK_USER_HISTORY_WATCHER, getKioskUserHistoryEffect),
  takeEvery(GET_ORDER_TRACKING_WATCHER, getOrderTrackingEffect),
  takeEvery(GET_KIOSK_ORDER_TRACKING_WATCHER, getKioskOrderTrackingEffect),
  takeEvery(GET_CONTRACTOR_SWIPE_HISTORY_WATCHER, getContractorSwipeHistoryEffect),
  takeEvery(GET_GUEST_SWIPE_HISTORY_WATCHER, getGuestSwipeHistoryEffect),
  takeEvery(GET_DELIVERY_HISTORY_WATCHER, getDeliveryHistoryEffect),
  takeEvery(GET_GUEST_DELIVERY_HISTORY_WATCHER, getGuestDeliveryHistoryEffect),
  takeEvery(GET_RATING_FEEDBACK_WATCHER, getRatingFeedbackListEffect),
  takeEvery(GET_ACTIVITY_WATCHER, getActivityListEffect),
  takeEvery(GET_SPECIAL_HISTORY_WATCHER, getSpecialHistoryEffect),
  takeEvery(GET_ORDER_HISTORY_WATCHER, getOrderHistoryEffect),
  takeEvery(GET_GUEST_ORDER_HISTORY_WATCHER, getGuestOrderHistoryEffect),
  takeEvery(GET_BREAKFAST_DAY_BY_DAY_ORDER_WATCHER, getBreakfastDayByDayOrderEffect),
  takeEvery(GET_LUNCH_DAY_BY_DAY_ORDER_WATCHER, getLunchDayByDayOrderEffect),
  takeEvery(GET_DINNER_DAY_BY_DAY_ORDER_WATCHER, getDinnerDayByDayOrderEffect),
  takeEvery(GET_SNACKS_DAY_BY_DAY_ORDER_WATCHER, getSnacksDayByDayOrderEffect),
  takeEvery(GET_SUPPER_DAY_BY_DAY_ORDER_WATCHER, getSupperDayByDayOrderEffect),
  takeEvery(GET_PUSH_NOTIFICATION_WATCHER, getPushNotificationEffect),
  takeEvery(GET_SPECIAL_ORDER_TRACKING_WATCHER, getSpecialOrderTrackingEffect),
  takeEvery(CREATE_PUSH_NOTIFICATION_WATCHER, createPushNotificationEffect),
  takeEvery(GET_CONTRACTOR_INVOICE_WATCHER, getContractorInvoiceEffect),
  takeEvery(GET_SALE_REPORT_WATCHER, getSaleReportEffect),
  takeEvery(GET_SALE_MENU_WATCHER, getSaleMenuEffect),
  takeEvery(GET_USER_HISTORY_VIEW_WATCHER, getUserHistoryViewEffect),
  takeEvery(GET_KIOSK_USER_HISTORY_VIEW_WATCHER, getKioskUserHistoryViewEffect),
  takeEvery(GET_SPECIAL_HISTORY_VIEW_WATCHER, getSpecialHistoryViewEffect),
  takeEvery(GET_SPECIAL_ORDER_TRACKING_VIEW_WATCHER, getSpecialOrderTrackingViewEffect),
  takeEvery(GET_ORDER_TRACKING_VIEW_WATCHER, getOrderTrackingViewEffect),
  takeEvery(GET_KIOSK_ORDER_TRACKING_VIEW_WATCHER, getKioskOrderTrackingViewEffect),
  takeEvery(UPDATE_FEEDBACK_WATCHER, updateFeedbackEffect),
  takeEvery(GET_CAB_REPORTS_WATCHER, getCabReportsEffect),
]
