import React, { useState, useEffect } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card, Button, Stack, Autocomplete, TextField } from "@mui/material";
import OrderHistoryTable from '../../../components/admin/reports/orderHistory/table';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderHistoryWatcher } from '../../../redux/actionCreators/admin/reports';
import { TABLE_HEAD } from '../../../components/admin/reports/orderHistory/table';
import CommonHeader from '../../../components/admin/reports/contractorInvoice/commonHeader';
import { getCurDate } from '../../../utils/common';
import { Link } from 'react-router-dom';
import { getContractorNameListWatcher } from '../../../redux/actionCreators/admin/contractorBook';

const ContractorInvoice = () => {
    const { ReportsReducers: { OrderHistoryList }, ContractorBookReducers: { contractorNameList } } = useSelector((state) => state)

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const [contractorID, setContractorID] = useState(0)
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);
    const dataList = OrderHistoryList.filter(d => contractorID ? contractorID === d.nContractorID : true)

    useEffect(() => {
        let data = { "ACTION": "AdminContractorList" }
        dispatch(getOrderHistoryWatcher(data))
    }, [dispatch])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = dataList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        const data = {
            ACTION: "AdminSearchContractorList",
            FromDate: fromDate,
            ToDate: toDate,
            UserId: "33",
        }
        dispatch(getOrderHistoryWatcher(data))
        setPage(1)
    }

    useEffect(() => {
        dispatch(getContractorNameListWatcher())
    }, [dispatch])


    return (
        <>
            <Box p="24px">
                <Breadcrumbs content={["Reports", "Contractor Invoice"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Contractor Invoice</Typography>
                    <Stack sx={{ ml: 'auto' }} flexDirection='row'>
                        <Box>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            fullWidth
                            options={contractorNameList}
                            name="contractorID"
                            autoHighlight
                            // defaultValue={values.contractorID === "0" ? selectObj : selectObj}
                            getOptionLabel={(option) => option.Name}
                            onChange={(event, value) => {
                                setContractorID(value?.ID);
                            }}
                            sx={{ width: '300px' }}
                            size='small'
                            renderInput={(params) => <TextField {...params}
                                name="contractorID"
                                label="Select Contractor"
                                required={true}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: 'common.white',
                                        '&:hover fieldset': {
                                            borderColor: 'primary.main',
                                        },
                                    },

                                }}
                            />}
                        />
                        </Box>
                        <Button disabled={!fromDate || !toDate || !contractorID} sx={{ml: '20px'}} component={Link} to={`/invoice/contractor/${getCurDate(fromDate)}/${getCurDate(toDate)}/${contractorID}`} target='_blank' variant='contained' >
                            <Typography variant='t16_400'>
                            Invoice
                            </Typography>
                        </Button>
                    </Stack>

                </Box>
                <Box p='10px 0'>
                    <CommonHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={OrderHistoryList} columnData={TABLE_HEAD} toDate={toDate} setToDate={setToDate} fromDate={fromDate} setFromDate={setFromDate}/>
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <OrderHistoryTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} dataList={dataList}/>
                </Card>

            </Box>
        </>
    )
}

export default ContractorInvoice