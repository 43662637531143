import React, { useState } from 'react'
import { Box, Card, Typography } from '@mui/material'
import Breadcrumbs from '../../../components/breadcrumbs'
import FeedbackTable from '../../../components/admin/reports/feedbacks/table'
import FeedBackHeader from '../../../components/admin/reports/feedbacks/headerCard'
import { useSelector } from 'react-redux'
import { TABLE_HEAD } from '../../../components/admin/reports/feedbacks/table'

export default function Feedbacks() {

    const { ReportsReducers: { RatingFeedbackList } } = useSelector((state) => state)

    // const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = RatingFeedbackList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    return (
        <>
            <Box p="24px">
                <Breadcrumbs content={["Reports", "Feedback"]} />
                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant='t24_700' > Feedback </Typography>
                </Box>
                <Box p='10px 0'>
                    <FeedBackHeader handleSearch={handleSearch} searchText={searchText} rowData={RatingFeedbackList} columnData={TABLE_HEAD} />
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <FeedbackTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                </Card>
            </Box>
        </>
    )
}
