import React from 'react';
import { useState } from 'react';
import VendorChildHead from './vendorChildHead';
// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
} from '@mui/material';
import EditTextField from '../../../overrides/editField';


const TABLE_HEAD = [
    { id: 'Email 2', label: 'Email (to)', alignRight: false },
    { id: 'Email 3', label: 'Email (to)', alignRight: false },
    { id: 'Email 4', label: 'Email (to)', alignRight: false },
    { id: 'Email 5', label: 'Email (cc)', alignRight: false },
    { id: 'vendorAddress', label: 'Vendor Address', alignRight: false },

];

const VendorChildTable = ({ expandOpen, rowData, handleEditChange, status, editData }) => {

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <>
            {expandOpen &&
                <TableContainer sx={{ overflow:'unset' }}>
                    <Table>
                        <VendorChildHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            //   rowCount={USERLIST.length}
                            //   numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            <TableRow
                                // hover
                                tabIndex={-1}
                                sx={{ '& > *': { borderBottom: 'unset' } }}
                            >
                                {status === 'list' ?
                                    <>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.email2 || '—'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.email3 || '—'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.email4 || '—'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.ccEmail || '—'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.address || '—'}
                                            </Typography>
                                        </TableCell>
                                    </>
                                    :
                                    <>
                                        <TableCell align="left">
                                            <EditTextField
                                                name='email2'
                                                value={editData.email2}
                                                onChange={handleEditChange}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <EditTextField
                                                name='email3'
                                                value={editData.email3}
                                                onChange={handleEditChange}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <EditTextField
                                                name='email4'
                                                value={editData.email4}
                                                onChange={handleEditChange}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <EditTextField
                                                name='ccEmail'
                                                value={editData.ccEmail}
                                                onChange={handleEditChange}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <EditTextField
                                                name='address'
                                                value={editData.address}
                                                onChange={handleEditChange}
                                            />
                                        </TableCell>
                                    </>
                                }
                            </TableRow>
                        </TableBody>

                    </Table>
                </TableContainer>
            }
        </>
    )
}

export default VendorChildTable;