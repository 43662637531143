import React, { useState } from 'react';
import {
    TableCell,
    Typography,
    TableRow
} from '@mui/material';
import DropAction from './dropAction';
// import { EditOutlined } from '@mui/icons-material';
// import ActionButtons from '/actionButton';
// import { useDispatch } from 'react-redux'
// import EditTextField from '../../../overrides/editField';
// import { updateFeedbackWatcher } from '../../../../redux/actionCreators/admin/reports';

const KioskTableRow = ({ rowData, index }) => {

    // const dispatch = useDispatch();

    const [status] = useState('list');
    // const [editData, setEditData] = useState({});


    // const handleEditChange = (e) => {
    //     const { name, value } = e.target
    //     setEditData({ ...editData, [name]: value })
    // }

    // const handleEdit = () => {
    //     let data = { ...rowData }
    //     //data.cMenuName = data.cMenuName[0]
    //     setEditData(data);
    //     setStatus('edit');
    // }

    // const tickIconFunction = async () => {
    //     let values = { ...editData }
    //     let data = {
    //         "Action": "replyFeed",
    //         "nReply": values.Reply
    //     }
    //     await dispatch(updateFeedbackWatcher(rowData.nID, data));
    //     setStatus('list');
    // }

    // const cancelIconFunction = () => {
    //     setEditData({});
    //     setStatus('list');
    // }

    return (
        <>
            <TableRow
                hover={status === 'list' ? true : false}
                tabIndex={-1}
            >
                {/* {status === 'list' ?
                    <> */}
                        <TableCell align="left" >
                            <Typography variant='t14_600'>
                                {rowData.cEmployeeName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" >
                            <Typography variant='t14_600'>
                                {rowData.cMenuName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" >
                            <Typography variant='t14_600'>
                                {rowData.dOrderDate}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" >
                            <Typography variant='t14_600'>
                                {rowData.nQuantity}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" >
                            <Typography variant='t14_600'>
                                {rowData.TotalRate}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" >
                            <Typography variant='t14_600'>
                                {rowData.kioskStatus}
                            </Typography>
                        </TableCell>
                        <TableCell align="right" >
                            <DropAction rowData={rowData}/>
                        </TableCell>
                    {/* </>
                    :
                    <>
                        <TableCell>
                            <Typography>
                                {rowData.EmployeeName}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {rowData.MenuName}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {rowData.Command}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <EditTextField
                                name='Reply'
                                value={editData.Reply || ""  }
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {rowData.FeedDate}
                            </Typography>
                        </TableCell>
                    </>
                }
                <TableCell align="left" sx={{ width: '150px' }}>
                    <ActionButtons type={'feedback'} setStatus={setStatus} status={status} setEditData={setEditData} rowData={rowData} tickIconFunction={tickIconFunction}
                        handleEdit={handleEdit} cancelIconFunction={cancelIconFunction} />
                </TableCell> */}
            </TableRow>
        </>
    )
}

export default KioskTableRow