import React from 'react';
import { useState } from 'react';
import Scrollbar from '../../../Scrollbar';
import MenuTableHead from './tableHead';
import { useSelector } from 'react-redux'
// import { getMenuMasterWatcher } from '../../../../redux/actionCreators/admin/menuMaster';
import MenuTableRow from './tableRow';
// material
import {
    Table,
    TableBody,
    TableContainer,
    Pagination,
    Box,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';

const TABLE_HEAD = [
    { id: 'cMealName', label: 'Meal Name', alignRight: false },
    { id: 'cMenuName', label: 'Menu Code', alignRight: false },
    { id: 'cvendor', label: 'Vendor', alignRight: false },
    { id: 'ctype', label: 'Meal Type', alignRight: false },
    { id: 'cMenuDesc', label: 'Menu Description', alignRight: false },
    { id: 'nTotalRate', label: 'Total Rate', alignRight: false },
    { id: 'bIsActive', label: 'Status', alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

const MenuMasterTable = ({searchVal, searchText, page, setPage }) => {

    const { MenuMasterReducers: { menuMasterList } } = useSelector((state) => state)

    let firstIndex = 0;

    // const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const tableData = searchText ? searchVal : menuMasterList
    const filteredData = applySortFilter(tableData, getComparator(order, orderBy));
    const isUserNotFound = filteredData?.length === 0;

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800, overflow:'unset' }}>
                        <Table>
                            <MenuTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                //   rowCount={USERLIST.length}
                                //   numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    // .slice(page * 5, page * 5 + 5)
                                    .map((m, i) => {
                                        return (
                                            <MenuTableRow key ={i} rowData={m} index={i}/>
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(tableData?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default MenuMasterTable;