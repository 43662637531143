import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Dishes from "./dishes";
import { useDispatch, useSelector } from "react-redux";
import {
  kioskWatcher, kioskGetCartWatcher
} from "../../../redux/actionCreators/user/kiosk";
import SummaryDrawer from './summaryDrawer'
import Scrollbar from "../../../components/Scrollbar";
import useResponsive from "../../../hooks/useResponsive";

const CategoryPage = () => {

  const dispatch = useDispatch();

  const { ProfileReducers: {loginState}} = useSelector(state => state)

  const nUserID = loginState[0]?.nID || 0

  const isMobile = useResponsive('down', 'sm');

  useEffect(() => {
    const data = {
      "ACTION":"SPECIALSELECT",
      "nMealID": 0
  }

  const cartData = {
    "ACTION":"CHECKLIST",
     nUserID
  }
    dispatch(kioskWatcher(data));
    dispatch(kioskGetCartWatcher(cartData));
  }, [dispatch, nUserID]);

  return (
    <Box p="24px" pb='0px'>
      <Box display="flex" mb='24px' flexDirection="column">
        <Typography variant="t13_500" sx={{ color: "grey.600" }}>
          Home
        </Typography>

        <Box mt="11px" display="flex" alignItems='center'>
          <Typography  variant="t24_700">
            Kiosk
          </Typography>
          <Box ml='auto'>
          <SummaryDrawer />

          </Box>
        </Box>
      </Box>

      <Box height={isMobile ? 'auto' : 'calc(100vh - 186px)'}>
        <Scrollbar >
          <Dishes />
        </Scrollbar>
      </Box>
    </Box>
  );
};

export default CategoryPage;
