// material
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button, Avatar, Typography } from '@mui/material';
// components
import MenuIcon from '@mui/icons-material/Menu';
// import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CartButton from './cartButton'
// import CssTextField from '../../components/overrides/searchField';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { updateLogin } from '../../redux/actionCreators/profile';
import { useDispatch, useSelector } from 'react-redux';
import { updateAlert } from '../../redux/actionCreators/alert';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { base_url } from '../../http-common';
import Cafeteria from './cafeteria';
// import LogoutDialog from '../../components/logoutDialog';
// import { useState } from 'react';
// import Scrollbar from '../../components/Scrollbar';
import useResponsive from '../../hooks/useResponsive';
import NotificationAction from './notificationAction';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 236;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
  // boxShadow: 'none',
  // backdropFilter: 'blur(6px)',
  // WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: theme.palette.grey[0],
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  },
  boxShadow: `0px 2px 10px ${theme.palette.grey[200]}`
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// const CafeteriaLocations = [
//   { label: 'Cafeteria 01' },
//   { label: 'Cafeteria 02' },
//   { label: 'Cafeteria 03' },
// ];

// ----------------------------------------------------------------------

export default function DashboardNavbar({ onOpenSidebar, setOpenNotification }) {

  const { pathname } = useLocation();
  const getRole = !!matchPath({ path: '/user', end: false }, pathname) 
  // const isContractor = !!matchPath({ path: '/contractor', end: false }, pathname)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isMobile = useResponsive('down', 'sm');

  const { ProfileReducers: { loginState } } = useSelector(state => state)

  const roleName = loginState[0]?.cRoleName

  const handleProfile = () => {
    const getPath = pathname.split('/')[1]
    navigate(`/${getPath}/profile`)
  }

  const handleLogout = () => {
    sessionStorage.clear()
    localStorage.clear()
    dispatch(updateLogin([]))
    dispatch(updateAlert('Successfully logged out', 'success'))
    // setOpen(false)
  }

  // const [open, setOpen] = useState(false)

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        {isMobile && getRole ?
          <Cafeteria />
          :

          <Stack direction="row" alignItems="center" spacing={{ xs: 2, sm: 3 }}>
            {
              roleName === 'KioskAdmin' &&
                <NotificationAction setOpenNotification={setOpenNotification} />
            }
            <Button variant='outlined' sx={{ height: 40 }} endIcon={<LogoutIcon />} onClick={handleLogout}>
              <Typography color="common.black" variant="t14_400">
                Logout
              </Typography>
            </Button>
            {/* <LogoutDialog open={open} setOpen={setOpen} handleLogout={handleLogout} /> */}

            {getRole &&
              <>
                <Cafeteria />
                <CartButton />
              </>
            }
            {/* {isContractor &&
              <>
                <Cafeteria />
              </>
            } */}

            {/* <Box pr='10px'>
                <Badge color="error" variant="dot">
                  <NotificationsNoneIcon sx={{ color: 'common.black' }} />
                </Badge>
              </Box> */}


            <Box position='relative' onClick={handleProfile} sx={{ cursor: 'pointer' }} >
              <Button variant='contained' sx={{ justifyContent: 'left', height: '40px', pr: 5 }}>
                <Typography variant='t16_400' >{loginState[0]?.cEmployeeName}</Typography>
              </Button>

              <Avatar sx={{ position: 'absolute', top: -2, bottom: 0, right: -20, width: 45, height: 45, border: theme => `3px solid ${theme.palette.common.white}` }} src={`${base_url}/${loginState[0]?.cImage}`}>
                <PersonIcon fontSize='large' />
              </Avatar>
            </Box>
          </Stack>
        }

      </ToolbarStyle>
    </RootStyle>
  );
} 
